/* eslint-disable */
import Modal from "antd/lib/modal/Modal";
import { DownloadOutlined } from "@ant-design/icons";

import React, { useEffect, useRef, useState } from "react";
import { Button, Select } from "antd";
import ReactJson from "react-json-view";

import { data } from "../../constants";

import { useDispatch } from "react-redux";
import { addFileName } from "../../store/Save/actions";

const { Option } = Select;

const PreviewFileSelect = ({ isClose, ok, pdfs = [] }) => {
  const link = useRef();

  const dispatch = useDispatch();
  const [value, setValue] = useState(pdfs[0]);
  const handleChange = (val) => setValue(val);

  const [downloadLink, setDownloadLink] = useState("");
  const makeTextFile = () => {
    const data1 = new Blob([JSON.stringify(data)], { type: "text/plain" });

    if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink);

    setDownloadLink(window.URL.createObjectURL(data1));
  };

  useEffect(() => {
    makeTextFile();
  }, [data]);

  useEffect(() => {
    dispatch(addFileName(value));
  }, [value]);
  return (
    <Modal
      title="Select Files"
      visible
      onCancel={isClose}
      style={{ top: 20 }}
      onOk={ok}
    >
      <Select
        defaultValue={pdfs[0]}
        style={{ width: "-webkit-fill-available" }}
        onChange={handleChange}
      >
        {pdfs.map((item) => (
          <Option value={item}>{item}</Option>
        ))}
      </Select>
    </Modal>
  );
};

export default PreviewFileSelect;
