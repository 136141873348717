/* eslint-disable */
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";

import ReactJson from "react-json-view";
import { Button } from "antd";
import { Helmet } from "react-helmet";

const PreviewModal = ({ json, isOk }) => {
  const [downloadLink, setDownloadLink] = useState("");
  const makeTextFile = () => {
    const data = new Blob([JSON.stringify(json)], { type: "text/plain" });

    if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink);

    setDownloadLink(window.URL.createObjectURL(data));
  };

  useEffect(() => {
    makeTextFile();
  }, [json]);

  return (
    <Modal
      title="Preview Json"
      visible
      closeIcon={
        <div style={{ display: "flex", marginRight: 20 }}>
          <a
            download="preview.json"
            style={{ marginRight: 20 }}
            href={downloadLink}
          >
            download
          </a>
          <p onClick={isOk}>Close</p>
        </div>
      }
      onCancel={isOk}
      style={{ top: 20 }}
      onOk={isOk}
    >
      <ReactJson src={json} style={{ width: "auto" }} name="data" />
      <Helmet>
        <style>{`
          .ant-modal-close-x {
            width: auto !important;
          }
        `}</style>
      </Helmet>
    </Modal>
  );
};
export default PreviewModal;
