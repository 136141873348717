/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './LandingPage.css';
import {
  Row, Container, Col, Button,
} from 'react-bootstrap';
import keysToRemove from "../LocalStorageItems"
function LandingPage(props) {
  const handleGetStarted = () => {
    if (localStorage.getItem("authenticatedUser") !== null) {
      keysToRemove.forEach(k => localStorage.removeItem(k))
      props.history.push('/home')

    }
    else {
      localStorage.clear()
      props.history.push('/signIn')
    }

  }
  return (
    <>
      <div className="Background">
        <Container className="text-div">
          <div>
            <p>Felix <br />
          Solutions</p>
          </div>
          <Row>
            <Col sm="3" md="3" lg="3">
            <Button
                className="getStarted"
                onClick={()=>{
                  window.open("https://sway.office.com/nzqe2HQqc9Kaf01z?play","_blank")
                }}
              >
                View Documentation
            </Button>
            <br />
              <Button
                className="getStarted"
                onClick={() => handleGetStarted()}
              >
                Login
            </Button>
            
            </Col>
          </Row>
        </Container>
        <br />
      </div>
    </>
  )
}

export default LandingPage;
