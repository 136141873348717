import * as actionTypes from "./actionTypes";
import axios from "axios";
import env from "../../environment";
import { refreshToken, validateApi } from "../../constants";

export const isReloaded = (data) => ({
  type: actionTypes.RELOADED,
  payload: data,
});

export const formType = (data) => ({
  type: actionTypes.FORM,
  payload: data,
});

export const disableCheckAddActive = (data) => ({
  type: actionTypes.DISABLE_CHECK_ADD_ACTIVE,
  payload: data,
});

export const disableLineItemActive = (data) => ({
  type: actionTypes.DISABLE_LINE_ITEM_ACTIVE,
  payload: data,
});

export const deleteClaimAddData = (data) => ({
  type: actionTypes.DELETE_CLAIM_ADD_DATA,
  payload: data,
});

export const disableClaimAddActive = (data) => ({
  type: actionTypes.DISABLE_CLAIM_ADD_ACTIVE,
  payload: data,
});

export const disableCardAddActive = (data) => ({
  type: actionTypes.DISABLE_CARD_ADDRESS_ACTIVE,
  payload: data,
});

export const disableClaimExtractionActive = (data) => ({
  type: actionTypes.DISABLE_CLAIM_EXTRACTION_ACTIVE,
  payload: data,
});

export const deleteCheckHeadData = (data) => ({
  type: actionTypes.DELETE_CHECK_HEAD_DATA,
  payload: data,
});

export const disableCheckHeadActive = (data) => ({
  type: actionTypes.DISABLE_CHECK_HEAD_ACTIVE,
  payload: data,
});

export const deleteClaimFieldsData = (data) => ({
  type: actionTypes.DELETE_CLAIM_FIELDS_DATA,
  payload: data,
});

export const disableClaimFieldsActive = (data) => ({
  type: actionTypes.DISABLE_CLAIM_FIELDS_ACTIVE,
  payload: data,
});

export const deleteClaimHeadData = (data) => ({
  type: actionTypes.DELETE_CLAIM_HEAD_DATA,
  payload: data,
});

export const disableClaimHeadActive = (data) => ({
  type: actionTypes.DISABLE_CLAIM_HEAD_ACTIVE,
  payload: data,
});

export const disableTemplatePageIdentificationActive = (data) => ({
  type: actionTypes.DISABLE_TEMPLATE_PAGE_IDENTIFIER_ACTIVE,
  payload: data,
});

export const imageText = (data) => ({
  type: actionTypes.TEXT,
  payload: data,
});

export const removeTextImage = () => ({
  type: actionTypes.RMEOVE_TEXT,
});

export const templateActive = (data) => ({
  type: actionTypes.TEMPLATE_ACTIVE,
  payload: data,
});

export const claimHeaderIdenActive = (data) => ({
  type: actionTypes.CLAIM_HEADER_IDEN_ACTIVE,
  payload: data,
});

export const negateActive = (data) => ({
  type: actionTypes.NEGATE_ACTIVE,
  payload: data,
});

export const identificationActive = (data) => ({
  type: actionTypes.INDETIFICATION_ACTIVE,
  payload: data,
});

export const identificationText = (data) => ({
  type: actionTypes.INDETIFICATION_TEXT,
  payload: data,
});

export const templateText = (data) => ({
  type: actionTypes.TEMPLATE_TEXT,
  payload: data,
});

export const claimHeaderIdenText = (data) => ({
  type: actionTypes.CLAIM_HEADER_IDEN_TEXT,
  payload: data,
});
export const negateText = (data) => ({
  type: actionTypes.NEGATE_TEXT,
  payload: data,
});

export const negateOpen = (data) => ({
  type: actionTypes.NEGATE_OPEN,
  payload: data,
});

export const templateOpen = (data) => ({
  type: actionTypes.TEMPLATE_OPEN,
  payload: data,
});

export const identificationOpen = (data) => ({
  type: actionTypes.IDENTIFICATION_OPEN,
  payload: data,
});

export const negateOpen1 = (data) => ({
  type: actionTypes.NEGATE_OPEN1,
  payload: data,
});

export const templateOpen1 = (data) => ({
  type: actionTypes.TEMPLATE_OPEN1,
  payload: data,
});

export const identificationOpen1 = (data) => ({
  type: actionTypes.IDENTIFICATION_OPEN1,
  payload: data,
});

export const checkAddressActive = (data) => ({
  type: actionTypes.CEHCK_ADDRESS_ACTIVE,
  payload: data,
});
export const cardAddressActive = (data) => ({
  type: actionTypes.CARD_ADDRESS_ACTIVE,
  payload: data,
});

export const checkAddressText = (data) => ({
  type: actionTypes.CEHCK_ADDRESS_TEXT,
  payload: data,
});
export const cardAddressText = (data) => ({
  type: actionTypes.CARD_ADDRESS_TEXT,
  payload: data,
});

export const checkAddressOpen = (data) => ({
  type: actionTypes.CEHCK_ADDRESS_OPEN,
  payload: data,
});

export const cardAddressOpen = (data) => ({
  type: actionTypes.CARD_ADDRESS_OPEN,
  payload: data,
});

export const checkAddressActive2 = () => ({
  type: actionTypes.CEHCK_ADDRESS_ACTIVE_2,
});

export const checkAddressActive3 = () => ({
  type: actionTypes.CEHCK_ADDRESS_ACTIVE_3,
});

export const checkAddressBlockText = (data) => ({
  type: actionTypes.CHECK_ADDRESS,
  payload: data,
});

export const checkAddressBlock2Text = (data) => ({
  type: actionTypes.CHECK_ADDRESS_2,
  payload: data,
});

export const checkAddressBlock3Text = (data) => ({
  type: actionTypes.CHECK_ADDRESS_2,
  payload: data,
});

export const checkHeaderText = (data) => ({
  type: actionTypes.CHECK_HEADER_TEXT,
  payload: data,
});

export const cardHeaderText = (data) => ({
  type: actionTypes.CARD_HEADER_TEXT,
  payload: data,
});

export const above = (data) => ({
  type: actionTypes.ABOVE,
  payload: data,
});

export const below = (data) => ({
  type: actionTypes.BELOW,
  payload: data,
});

export const checkHeaderActive = (data) => ({
  type: actionTypes.CHECK_HEADER_ACTIVE,
  payload: data,
});
export const cardHeaderActive = (data) => ({
  type: actionTypes.CARD_HEADER_ACTIVE,
  payload: data,
});

export const saveClaimData = (data) => ({
  type: actionTypes.CLAIM_EXTRACTION_ACTIVE,
  payload: data,
});

export const claimExtractionActive = (data) => ({
  type: actionTypes.CLAIM_EXTRACTION_ACTIVE,
  payload: data,
});

export const claimExtractionText = (data) => ({
  type: actionTypes.CLAIM_EXTRACTION_TEXT,
  payload: data,
});

export const claimAddressActive = (data) => ({
  type: actionTypes.CLAIM_ADDRESS_ACTIVE,
  payload: data,
});

export const claimAddressText = (data) => ({
  type: actionTypes.CLAIM_ADDRESS_TEXT,
  payload: data,
});

export const claimHeaderActive = (data) => ({
  type: actionTypes.CLAIM_HEADER_ACTIVE,
  payload: data,
});

export const claimHeaderText = (data) => ({
  type: actionTypes.CLAIM_HEADER_TEXT,
  payload: data,
});

export const claimFieldsActive = (data) => ({
  type: actionTypes.CLAIM_FIELDS_ACTIVE,
  payload: data,
});

export const claimFieldsText = (data) => ({
  type: actionTypes.CLAIM_FIELDS_TEXT,
  payload: data,
});

export const lineItemPropertiesActive = (data) => ({
  type: actionTypes.LINE_ITEM_PROPERTIES_ACTIVE,
  payload: data,
});

export const lineItemPropertiesText = (data) => ({
  type: actionTypes.LINE_ITEM_PROPERTIES_TEXT,
  payload: data,
});

export const lineFieldPropertiesActive = (data) => ({
  type: actionTypes.LINE_ITEM_FIELD_PROPERTIES_ACTIVE,
  payload: data,
});

export const lineFieldPropertiesText = (data) => ({
  type: actionTypes.LINE_ITEM_FIELD_PROPERTIES_TEXT,
  payload: data,
});

export const saveCreds = (data) => ({
  type: actionTypes.SAVE_CREDS,
  payload: data,
});

export const currentPDF = (data) => ({
  type: actionTypes.CURRENTPDF,
  payload: data,
});

export const disableActive = () => ({
  type: actionTypes.DISABLE_ACTIVE,
});

export const disableActive1 = () => ({
  type: actionTypes.DISABLE_ACTIVE1,
});

export const deleteFormData = () => ({
  type: actionTypes.DELETE_DATA,
});

export const deleteFormItem = (data) => ({
  type: actionTypes.DELETE_FORM_ITEM,
  payload: data,
});

export const errorInDropdown = (data) => {
  return {
    type: actionTypes.ERROR_IN_DROPDOWN,
    payload: data,
  };
};

export const saveDropDown = (data) => ({
  type: actionTypes.SAVE_DROPDOWN,
  payload: data,
});

const dropdownData = async (dispatch) => {
  const auth = {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("accessToken"))}`,
  };

  const result1 = await axios.get(
    `${env.BASE_URL}formmetadata?category=CheckAddress`,
    {
      headers: auth,
    }
  );

  const result2 = await axios.get(
    `${env.BASE_URL}formmetadata?category=CheckHeaders`,
    {
      headers: auth,
    }
  );
  const cardAddressData = await axios.get(
    `${env.BASE_URL}formmetadata?category=CardAddress`,
    {
      headers: auth,
    }
  );
  const cardHeaderData = await axios.get(
    `${env.BASE_URL}formmetadata?category=CardHeaders`,
    {
      headers: auth,
    }
  );

  const result3 = await axios.get(
    `${env.BASE_URL}formmetadata?category=ClaimAddress`,
    {
      headers: auth,
    }
  );

  const result4 = await axios.get(
    `${env.BASE_URL}formmetadata?category=ClaimHeaders`,
    {
      headers: auth,
    }
  );

  const result6 = await axios.get(
    `${env.BASE_URL}formmetadata?category=ClaimFields`,
    {
      headers: auth,
    }
  );

  const result5 = await axios.get(
    `${env.BASE_URL}formmetadata?category=LineFields`,
    {
      headers: auth,
    }
  );

  if (result1.data) {
    dispatch(
      saveDropDown({
        name: "checkAddress",
        data: result1.data,
      })
    );

    dispatch(errorInDropdown(""));
  }
  if (result2.data) {
    dispatch(
      saveDropDown({
        name: "checkHeaders",
        data: result2.data,
      })
    );
  }
  if (cardAddressData) {
    dispatch(
      saveDropDown({
        name: "cardAddress",
        data: cardAddressData.data,
      })
    );
  }
  if (cardHeaderData) {
    dispatch(
      saveDropDown({
        name: "cardHeaders",
        data: cardHeaderData.data,
      })
    );
  }

  if (result3.data) {
    dispatch(
      saveDropDown({
        name: "claimAddress",
        data: result3.data,
      })
    );
  }
  if (result4.data) {
    dispatch(
      saveDropDown({
        name: "claimHeaders",
        data: result4.data,
      })
    );
  }
  if (result5.data) {
    dispatch(
      saveDropDown({
        name: "lineFields",
        data: result5.data,
      })
    );
    dispatch(
      saveDropDown({
        name: "alsoAddAs",
        data: result5.data,
      })
    );
  }
  if (result6.data) {
    dispatch(
      saveDropDown({
        name: "claimFields",
        data: result6.data,
      })
    );
  }
};
export const getDropDown = () => async (dispatch) => {
  try {
    validateApi().then((valid) => {
      if (!valid) {
        refreshToken().then(() => {
          dropdownData(dispatch);
        });
      } else {
        dropdownData(dispatch);
      }
    });
  } catch (error) {
    console.log(error);
    dispatch(errorInDropdown(error));
  }
};
