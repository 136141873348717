/* eslint-disable */

import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Box from "../../Box";
import Text from "../../Text";
import UploadImage from "../../../assets/icons/upload.png";
import PdfImage from "../../../assets/icons/pdf.png";
import Cancel from "../../../assets/icons/cancel.png";
import Check from "../../../assets/icons/check.png";
import Footer from "../../Footer/index";
import Loader from "../../Loader/loader";
import axios from "axios";
import env from "../../../environment";

import AWS from "aws-sdk";
import Swal from "sweetalert2";
import Navbar from "../../Navbar/Navbar";
import LZString from 'lz-string';
import { refreshToken, validateApi } from "../../../constants";
import { useSelector, useDispatch } from "react-redux";


AWS.config.update({
  accessKeyId: "AKIAU5PCM4NSVK5HT6HJ",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: "test-eob/EOB_INPUT" },
  region: "us-east-1",
});

const uploadFile = (file) => {
  const params = {
    ACL: "public-read",
    Key: file.name,
    ContentType: file.type,
    Body: file,
  };
  myBucket
    .putObject(params)
    .on("httpUploadProgress", (evt) => {
      // that's how you can keep track of your upload progress
      this.setState({
        progress: Math.round((evt.loaded / evt.total) * 100),
      });
    })
    .send((err) => {
      if (err) {
        // handle the error here
      }
    });
};

const Upload = (props) => {
  const [loader, setLoader] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const templateIDFromSave = useSelector(({ save }) => save.templateID);
  const nameFromSave = useSelector(({ save }) => save.name);

  const name = useSelector(({ form }) => form?.name);
  const templateID = useSelector(({ form }) => form?.templateID);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "application/pdf,image/tiff,.tif ",
    maxFiles: 5,
    maxSize: 2000000,
    onDrop: (acceptedFiles, fileRejections) => {
      console.log("acceptedFiles", acceptedFiles);
      console.log("fileRejections", fileRejections.length);
      if (fileRejections.length > 5) {
        console.log("in drop");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You can upload only upto 5 files",
          type: "error",
          confirmButtonColor: "rgb(51, 83, 109)",
        });
      }
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "File size is too large, Select files less than 2MB!!",
              type: "error",
              confirmButtonColor: "rgb(51, 83, 109)",
            });
          }
        });
      });
    },
  });
const handleProcessImage=(data)=>{
  const header = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${JSON.parse(
      localStorage.getItem("accessToken")
    )}`,
  };
  axios
  .post(
    `${env.BASE_URL}processimage`,
    data,
    {
      headers: header,
    },
    { timeout: 300000 }
  )
  .then((res) => {
    setLoader(false);    
    if(res.status===202)
    {
      props.history.push({
        pathname: "/home",
        state: "2"
      });
    }
  })
  .catch((err) => {
    setLoader(false);
    if (err?.response?.status === 401) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Session Expired!",
        type: "error",
        confirmButtonColor: "#54545E",
      }).then(() => {
        localStorage.clear()
        props.history.push('/signin')
      });
    }
    else{
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      type: "error",
      confirmButtonColor: "rgb(51, 83, 109)",
    }).then(() => {
      props.history.push("/upload");
    });
  }
  });
}
  const submitFiles = () => {
    if(selectedFiles.length<=0)
    {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please upload atleast one file!",
        type: "error",
        confirmButtonColor: "rgb(51, 83, 109)",
      })    }
    else
    {
    setLoader(true);
    const data = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      data.append(`file${i}`, selectedFiles[i]);
    }
    data.append("payer_template_id",templateIDFromSave?templateIDFromSave:templateID)
    data.append("payer_name",nameFromSave?nameFromSave:name)
      validateApi().then((valid) => {
      if (!valid) {
        refreshToken().then(() => {
          handleProcessImage(data);
        });
      } else {
        handleProcessImage(data);
      }
    });
 
    }
    };
  const files = selectedFiles.map((file) => (
    <Box key={file.path}>{file.path}</Box>
  ));

  React.useEffect(() => {
    if (acceptedFiles.length) {
      // checking if already present file and max files
      if (
        selectedFiles.find((file) => file.name.includes(acceptedFiles[0].name))
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File Already Exist! You cannot upload same files",
          type: "error",
          confirmButtonColor: "rgb(51, 83, 109)",
        });
        return;
      }
      let updatedSelected = [...selectedFiles, ...acceptedFiles];
      if (updatedSelected.length > 5) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You can upload only upto 5 files",
          type: "error",
          confirmButtonColor: "rgb(51, 83, 109)",
        });
        return;
      }
      setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    }
  }, [acceptedFiles]);

  // useEffect(()=>{

  // },[selectedFiles])

  const handleRemoveFile = (filename) => {
    const filterFile = selectedFiles.filter((file) => file.name !== filename);
    setSelectedFiles(filterFile);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <Navbar component="upload" />
          <Box h="auto" w="600px" b="1px solid #707070" margin="30px auto">
            <Box margin="20px 30px">
              <Text
                fontWeight="bold"
                textColor="rgb(51, 83, 109)"
                fontSize="20px"
                margin={0}
              >
                Upload Files
              </Text>
            </Box>
            <Box b="1px dashed #707070" h="auto" w="80%" margin="40px auto">
              <Box margin="20px 30px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img src={UploadImage} style={{ height: 50 }} alt="upload" />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  margin="0 auto"
                  textAlign="center"
                >
                  <Box
                    {...getRootProps({ className: "dropzone" })}
                    h="272px"
                    position="absolute"
                    w="400px"
                  >
                    <input {...getInputProps()} />
                  </Box>
                  <Text
                    fontWeight="bold"
                    textColor="rgb(51, 83, 109)"
                    fontSize="20px"
                  >
                    Drag and drop here
                  </Text>
                  <Text
                    fontWeight="bold"
                    textColor="rgb(51, 83, 109)"
                    fontSize="20px"
                    marginTop="0"
                  >
                    Or
                  </Text>
                </Box>

                <Box
                  h="40px"
                  w="144px"
                  background=" rgb(51, 83, 109) 0% 0% no-repeat padding-box;"
                  border="1px solid #707070;"
                  textAlign="center"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  margin="0 auto"
                >
                  <Text textColor="white" fontSize="20px">
                    Browse
                  </Text>
                </Box>
              </Box>
            </Box>
            {files && (
              <Box h="auto" w="80%" margin="20px auto">
                {selectedFiles.length > 0 && (
                  <Text>{selectedFiles.length} files selected </Text>
                )}
                {files.map((fileObj, index) => (
                  <Box key={index}>
                    <Box
                      display="flex"
                      alignItems="center"
                      b="1px solid gray"
                      padding="0 10px"
                      marginBottom="10px"
                    >
                      <img
                        src={PdfImage}
                        style={{ height: 30 }}
                        alt="pdfImage"
                      />
                      <Text
                        margin="10px 5px"
                        width="70%"
                        style={{ wordBreak: "break-word" }}
                      >
                        {fileObj.props.children}
                      </Text>
                      <Box marginLeft="auto">
                        <img
                          src={Check}
                          style={{ height: 20, marginRight: 20 }}
                          alt="check"
                        />
                        <img
                          src={Cancel}
                          alt="cancel"
                          style={{ height: 20, cursor: "pointer" }}
                          onClick={() =>
                            handleRemoveFile(fileObj.props.children)
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          {files && <Footer click={submitFiles} component="upload" />}
        </div>
      )}
    </>
  );
};

export default Upload;
