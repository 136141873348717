import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import NoResultFound from '../../assets/images/NoResultFound1.png';

class NODataFound extends Component {
    constructor(props) {
        super(props);
        console.log(props);
    }

    handleCreate = () => {
        this.props.history.push('/create')
    }

    render() {
        return (
            <div>
                <br />
                <br />
                <br />
                <br />
                <center>
                    <img
                        src={NoResultFound}
                        style={{
                            height: '30%', width: '30%', position: 'relative',
                        }}
                        alt="alternate "
                    />
                </center>
                <br />
                <br />

                <Button style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -127%)', backgroundColor: "rgb(51, 83, 109)" }} onClick={this.handleCreate}>Create Template</Button>
            </div>
        );
    }
}

export default withRouter(NODataFound);
