/* eslint-disable */

import React from "react";
import { changeStatus, saveState, submitJson } from "../../store/Save/actions";
import { useDispatch, useSelector } from "react-redux";
import Box from "../Box";
import Text from "../Text";
import { Alert } from "rsuite";
import axios from "axios";
import env from "../../environment";
import Swal from "sweetalert2";
import { refreshToken, validateApi } from "../../constants";
import PreviewFileSelect from "../PreviewModal/PreviewFileSelect";

const Footer = (props) => {
  const dispatch = useDispatch();

  const [submitButton, setSubmitButton] = React.useState("Submit");
  const [previewButton, setPreviewButton] = React.useState("Preview");
  const [fileSelect, setFileSelect] = React.useState(false);

  const [templateNegate1, setTemplateNegate1] = React.useState([
    {
      block: "",
      isAdd: true,
    },
  ]);

  const [templateIden1, setTemplateIden1] = React.useState([
    {
      block: "",
      isAdd: true,
      data: {},
    },
  ]);
  const [templateIden3, setTemplateIden3] = React.useState([
    {
      block: "",
      isAdd: true,
      data: {},
    },
  ]);
  const [templateIden2, setTemplateIden2] = React.useState([
    {
      block: "",
      isAdd: true,
      data: {},
    },
  ]);

  const name = useSelector(({ form }) => form?.name);
  const templateIDFromSave = useSelector(({ save }) => save.templateID);
  const isPreview = useSelector(({ save }) => save.submitType);
  const nameFromSave = useSelector(({ save }) => save.name);
  const templateID = useSelector(({ form }) => form?.templateID);
  const status = useSelector(({ save }) => save?.status);
  const saveStatus = useSelector(({ save }) => save?.save);
  const state = useSelector(({ save }) => save.template);

  const handleSubmit = async (type) => {
    if (props.component === "upload") {
      props.click(type);
    } else if (
      localStorage.getItem("validateConfig") === "#ef930d" ||
      !localStorage.getItem("validateConfig") ||
      localStorage.getItem("validateConfig") === "rgb(51,83,109)"
    ) {
      await handleTemplateValidate(type);
    } else {
      props.click(type);
    }
  };

  const handleSubmitSave = () => {
    dispatch(
      submitJson({
        status: "Saved",
        name: name ? name : nameFromSave,
        templateID: templateID ? templateID : templateIDFromSave,
      })
    );
  };

  React.useEffect(() => {
    if (state) {
      if (state.templatePageIdentification1) {
        setTemplateIden1(state.templatePageIdentification1);
      }
      if (state.templatePageIdentification3) {
        setTemplateIden3(state.templatePageIdentification3);
      }

      if (state.templatePageIdentification2) {
        setTemplateIden2(state.templatePageIdentification2);
      }
      if (state.neagate1) setTemplateNegate1(state.neagate1);
    }
  }, [
    state.templatePageIdentification1,
    state.templatePageIdentification2,
    state.templatePageIdentification3,
    state.neagate1,
  ]);

  const checkValidateStrings = async (type, data) => {
    const auth = {
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem("accessToken")
      )}`,
    };
    try {
      const checkDropdown = await axios.post(
        `${env.BASE_URL}validateidentification`,
        data,
        { headers: auth }
      );
      if (checkDropdown.data === "Validation Successful") {
        localStorage.setItem("validateConfig", "green");
        setSubmitButton("Submit");
        setTimeout(() => {
          props.click(type);
        }, 1500);
      } else {
        localStorage.setItem("validateConfig", "#ef930d");
        Swal.fire({
          icon: "error",
          title: "oops",
          text: "validate Unsuccessfull",
          type: "error",
          confirmButtonColor: "#54545E",
        });
      }
    } catch (error) {
      setSubmitButton("Submit");
      localStorage.setItem("validateConfig", "#ef930d");
      if (error?.request?.status === 403) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(error.request.response),
          type: "error",
          confirmButtonColor: "rgb(51, 83, 109)",
        });
        dispatch(changeStatus(""));

        dispatch(saveState(false));
      } else if (error?.response?.status === 401) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Session Expired!",
          type: "error",
          confirmButtonColor: "#54545E",
        }).then(() => {
          localStorage.clear();
          props.history.push("/signin");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
          type: "error",
          confirmButtonColor: "rgb(51, 83, 109)",
        });
      }
    }
  };
  const handleTemplateValidate = async (type) => {
    if (type !== "preview") setSubmitButton("validating...");
    else setPreviewButton("validating...");
    let temp1 = templateIden1?.map((item) => {
      try {
        let Datalength = Object.keys(item?.block).length;
        return Datalength;
      } catch {
        console.log(item);
      }
    });
    let a = templateIden1?.length;
    console.log(a);
    let count = 0;
    temp1.map((item) => {
      if (item === 0) {
        count++;
      }
    });

    let temp2 = templateIden2?.map((item) => {
      try {
        let Datalength2 = Object.keys(item?.block).length;
        return Datalength2;
      } catch {
        console.log(item);
      }
    });
    let b = templateIden2?.length;
    console.log(a);
    let count2 = 0;
    temp2.map((item) => {
      if (item === 0) {
        count2++;
      }
    });
    if (a === count && b == count2) {
      setSubmitButton("Submit");
      setPreviewButton("Preview");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select Atleast one block to validate",
        type: "error",
        confirmButtonColor: "rgb(51, 83, 109)",
      });
    } else {
      let payerData1 = templateIden1?.map((item) => item?.data);
      let payerData2 = templateIden2?.map((item) => item?.data);
      let totalData;
      let data2 = payerData2.map((item) => item.Text);
      if (data2[0] === undefined) {
        totalData = [[...payerData1]];
      } else {
        var newArray = payerData2.filter(
          (obj) => !(obj && Object.keys(obj).length === 0)
        );

        totalData = [[...payerData1], [...newArray]];
      }

      let negateData = templateNegate1?.map((item) => item?.block);
      let data = {};
      let newNegateData = negateData.filter((item) => item.length > 0);
      if (newNegateData.length > 0) {
        data = {
          templateId: templateIDFromSave ? templateIDFromSave : templateID,
          payer_template_identification_strings: totalData,
          template_negate_strings: newNegateData,
        };
      } else {
        data = {
          templateId: templateIDFromSave ? templateIDFromSave : templateID,
          payer_template_identification_strings: totalData,
        };
      }
      // const validateApicheck = validateApi()
      // if (!validateApicheck) {
      //   refreshToken()
      // }
      validateApi().then((valid) => {
        if (!valid) {
          refreshToken().then(() => {
            checkValidateStrings(type, data);
          });
        } else {
          checkValidateStrings(type, data);
        }
      });
    }
  };

  const close = () => setFileSelect(false);
  const okPreview = () => {
    close();
    handleSubmit("preview");
  };

  React.useEffect(() => {
    if (status === "Saved" || saveStatus === "Saved") {
      Alert.success("Save Successfull");
      dispatch(changeStatus(""));
      dispatch(saveState(false));
      setTimeout(() => {
        window.location.href = "/form";
      }, 500);
    }
    if (saveStatus === "Failed") {
      Alert.error(`Save Unsuccessfull!`);
      dispatch(changeStatus(""));
      dispatch(saveState(false));
    }
    if (saveStatus === "Created") {
      dispatch(saveState(false));
    }
  }, [status, saveStatus]);

  return (
    <Box
      w="1250px"
      display="flex"
      margin="0 auto"
      justifyContent={props.component === "upload" ? "center" : "flex-end"}
      alignItems="center"
    >
      {fileSelect && (
        <PreviewFileSelect pdfs={props.pdfs} isClose={close} ok={okPreview} />
      )}
      {props.component === "upload" || props.component === "form" ? null : (
        <>
          <Box
            cursor="pointer"
            display="flex"
            justifyContent="center"
            alignItems="center"
            background=" rgb(51, 83, 109) 0% 0% no-repeat padding-box;"
            border="1px solid #707070;"
            h="40px"
            w="144px"
            marginTop="17px"
            onClick={props.click}
          >
            <Text textColor="white">Back</Text>
          </Box>
        </>
      )}
      <Box display="flex">
        {props.component === "upload" ? null : (
          <>
            <Box
              cursor={
                saveStatus === "Saving" || saveStatus === "Creating"
                  ? "not-allowed"
                  : "pointer"
              }
              display="flex"
              justifyContent="center"
              alignItems="center"
              background=" rgb(51, 83, 109) 0% 0% no-repeat padding-box;"
              border="1px solid #707070;"
              h="40px"
              marginRight="10px"
              w="144px"
              marginTop="17px"
              onClick={handleSubmitSave}
            >
              <Text textColor="white">
                {saveStatus === "Saving" ? "Saving..." : "Save"}
              </Text>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              background=" rgb(51, 83, 109) 0% 0% no-repeat padding-box;"
              border="1px solid #707070;"
              h="40px"
              marginRight="10px"
              w="144px"
              marginTop="17px"
              style={{ float: "right" }}
              onClick={() => setFileSelect(true)}
              style={{
                cursor:
                  saveStatus === "Saving" || saveStatus === "Creating"
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              <Text textColor="white">
                {props.home &&
                saveStatus === "Creating" &&
                isPreview === "preview"
                  ? "Previewing..."
                  : "Preview"}
              </Text>
            </Box>
          </>
        )}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          background=" rgb(51, 83, 109) 0% 0% no-repeat padding-box;"
          border="1px solid #707070;"
          h="40px"
          marginRight="10px"
          w="144px"
          marginTop="17px"
          style={{ float: "right" }}
          onClick={() => handleSubmit("create")}
          style={{
            cursor:
              saveStatus === "Saving" || saveStatus === "Creating"
                ? "not-allowed"
                : "pointer",
          }}
        >
          <Text textColor="white">
            {props.home
              ? saveStatus === "Creating" && isPreview === "create"
                ? "Creating..."
                : submitButton
              : "Continue"}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
