/* eslint-disable */
import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import deepPurple from "@material-ui/core/colors/deepPurple";
import Swal from "sweetalert2";
import "./SignIn.css";
import axios from "axios";
import env from "../../environment";
import { Link, withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
const CryptoJS = require("crypto-js");

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#2979ff",
    },
    secondary: {
      main: "#f44336",
    },
  },
});

function SignIn(props) {
  const classes = useStyles();
  const handleSubmit = (props) => {
    if (username === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Enter username",
        type: "error",
        confirmButtonColor: "rgb(51, 83, 109)",
      });
    } else if (password === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Enter password",
        type: "error",
        confirmButtonColor: "rgb(51, 83, 109)",
      });
    } else {
      axios
        .post(
          `${env.BASE_URL}login`,
          {},
          {
            auth: {
              username: username,
              password: password,
            },
          }
        )
        .then((res) => {
          const authenticatedUser = "true";
          const ciphertext = CryptoJS.AES.encrypt(
            authenticatedUser,
            "my-secret-key@123"
          ).toString();
          localStorage.setItem("authenticatedUser", ciphertext);
          localStorage.setItem(
            "accessToken",
            JSON.stringify(res?.data?.access_token)
          );
          localStorage.setItem(
            "refreshToken",
            JSON.stringify(res?.data?.refresh_token)
          );
          props.history.push("/home");
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
              type: "error",
              confirmButtonColor: "rgb(51, 83, 109)",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              type: "error",
              confirmButtonColor: "rgb(51, 83, 109)",
            });
          }
        });
    }
  };
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleKeypress = (e, props) => {
    if (e.charCode === 13) {
      handleSubmit(props);
    }
  };

  return (
    <>
      <div
        className="container-fluid signInMain"
        style={{
          height: "100vh",
          position: "absolute",
          backgroundColor: "rgb(51, 83, 109)",
        }}
      >
        <Container
          maxWidth="xs"
          style={{
            backgroundColor: "whitesmoke",
            top: "25%",
            position: "relative",
            padding: "15px",
          }}
        >
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={`${classes.avatar} avtarLogo`}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <ThemeProvider theme={theme}>
              <form className={classes.form} noValidate>
                <TextField
                  variant="standard"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  onChange={(e) => setUserName(e.target.value)}
                  autoFocus
                  onKeyPress={(e) => handleKeypress(e, props)}
                />
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={(e) => handleKeypress(e, props)}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  style={{ backgroundColor: "rgb(51, 83, 109)" }}
                  onClick={() => handleSubmit(props)}
                >
                  Sign In
                </Button>
              </form>
              <Grid container>
                <Grid item xs>
                  {/* <Link href="#" variant="body2">
                Update password?
              </Link> */}
                </Grid>
                <Grid item>
                  <Link to="updatepassword" variant="body2">
                    Update password?
                    {/* {"Don't have an account? Sign Up"} */}
                  </Link>
                </Grid>
              </Grid>
            </ThemeProvider>
            <br />
          </div>
        </Container>
      </div>
    </>
  );
}
export default withRouter(SignIn);
