/* eslint-disable */

const omega_staging = {
  BASE_URL: "https://pcube-backend-api.omegahmstech.com/",
};
const omega_prod = {
  BASE_URL: "https://pcube-backend-api.omegahmstech.com/",
};

const felix_dev = {
  BASE_URL: "http://localhost:5000/",
};

const rcm_demo = {
  BASE_URL: "https://api.rcm.demos.felixai.xyz/",
};

const felix_staging = {
  BASE_URL: "https://omegav2-api-staging.felixhealthcare.ai/",
};
const felix_prod = {
  BASE_URL: "https://omegav2-api-staging.felixhealthcare.ai/",
};

let config;

switch (process.env.REACT_APP_STAGE) {
  case "omega_staging":
    config = omega_staging;
    break;
  case "omega_production":
    config = omega_prod;
    break;
  case "felix_development":
    config = felix_dev;
    break;
  case "felix_staging":
    config = felix_staging;
    break;
  case "felix_production":
    config = felix_prod;
    break;
  case "rcm_demo":
    config = rcm_demo;
    break;
  default:
    config = felix_dev;
}
export default {
  ...config,
};
