/* eslint-disable */
import * as actionTypes from "./actionTypes";
import { produce } from "immer";
import _ from "lodash";
import { snakeCase } from "snake-case";
import { data } from "jquery";

export const initialState = {
  loadingUpdate: false,
  save: false,
  loadingHome: false,
  check: {
    checkAddressOptions: [],
    ckadd: [
      {
        dropdown: "",
        isReset: true,
        default: true,
        json: "",
        isActive: false,
        mandatory: false,
        data: {},
        minLenOfAddressLine: 1,
        isAdd: false,
        blocks: "",
      },
    ],
    cardAdd: [
      {
        dropdown: "",
        isReset: true,
        default: true,
        json: "",
        isActive: false,
        mandatory: false,
        data: {},
        minLenOfAddressLine: 1,
        isAdd: false,
        blocks: "",
      },
    ],
    ckhead: [
      {
        default: false,
        value: "",
        data_value: {},
        dropdown: "",
        isReset: true,
        data_endIdentifier: {},
        data: {},
        isActive: true,
        mandatory: false,
        formBasedExtractor: true,
        blockExtractor: false,
        identifier: "",
        extractType: "form",
        endIdentifier: "",
        idlimit: 1,
        indexRange1: "0",
        indexRange2: "",
        isAdvanced: false,
        noOfBlocks: "",
        valueBlock: "",
        valueType: "text",
      },
    ],

    cardHead: [
      {
        default: false,
        isReset: true,
        value: "",
        data_value: {},
        dropdown: "",
        data_endIdentifier: {},
        data: {},
        isActive: true,
        mandatory: false,
        formBasedExtractor: true,
        blockExtractor: false,
        identifier: "",
        extractType: "form",
        endIdentifier: "",
        idlimit: 1,
        indexRange1: "0",
        indexRange2: "",
        isAdvanced: false,
        noOfBlocks: "",
        valueBlock: "",
      },
    ],
    ckHeadOpen: false,
    ckAddOpen: false,
    cardAddOpen: false,
    cdHeadOpen: false,
    selectedCheckAdd: [],
    selectedCheckHead: [],
    selectedCardAdd: [],
    selectedCardHead: [],
  },

  template: {},
  status: "",
  isError: false,
  claims: {
    claimHeaderIden: {},
    clheadIden: [],

    clex: [
      {
        top: "",
        data_top: {},
        data_footer: {},
        data_bottom: {},
        data_heder: {},
        bottom: "",
        header: "",
        footer: "",
        isAdvanced: false,
        bottomLine: 0,
        captureBlockTillNextStart: false,
        pageBreakContinuationIdentifier: "",
        pageBreakdownIdentifier: "",
        isHeaderPresent: false,
        bottomLinesToSkip: 0,
        extractType: true,
        isHeaderPresent: false,
      },
    ],
    clhead: [
      {
        dropdown: "",
        isActive: true,
        isReset: true,
        json: "",
        mandatory: false,
        formBasedExtractor: true,
        blockExtractor: false,
        identifier: "",
        extractType: "form",
        data: {},
        data_end_identifier: {},
        identifier: "",
        endIdentifier: "",
        idlimit: "1",
        indexRange1: "0",
        indexRange2: "",
        isAdvanced: false,
        noOfBlocks: "1",
        values: "",
        value: "",
        data_value: {},
        valueType: "text",
      },
    ],
    cladd: [
      {
        dropdown: "",
        json: "",
        isReset: true,
        isActive: true,
        mandatory: false,
        blocks: "",
        minLenOfAddressLine: 1,
        isAdd: false,
      },
    ],
    clfield: [
      {
        mandatory: false,
        value: "",
        data_value: {},
        indexRegexPrev: "",
        minLength: 1,
        maxLength: 256,
        regex: "",
        prevValueType: "",
        nextValueeeType: "",
        indexRangeIndexExtractor2: "",
        indexRangeIndexExtractor1: 0,
        line: 0,
        showBlock1: false,
        showBlock2: false,
        isReset: true,
        block: "",
        data: {},

        keyValueExtractor: true,
        indexBasedExtractor: false,
        nameType: "first_last_middle",
        json: "",
        dropdown: "",
        identifier: "",
        extractType: "form",
        isAdvanced: false,
        endIdentifier: "",
        idlimit: "1",
        indexRange1: "0",
        indexRange2: "",
        noOfBlocks: "1",
        isNameType: false,
        valueType: "text",
        data_end_identifier: {},
      },
    ],
    clexopen: false,
    clheaderIdenopen: false,
    claddopen: false,
    clheaderopen: false,
    clfieldsopen: false,
    selectedClaimAdd: [],
    selectedClaimHead: [],
    selectedClaimFeilds: [],
  },
  line: {
    lineitem: [
      {
        lineItemIdentifierType: "pcode",
        isAbove: false,
        isBelow: false,
        minLengthOfAboveLineItem: 1,
        regex: "",
        date: "",
        lineItemIdentifier: "",
        data: {},
        data_conflictPatternIdentifier: {},
        pcode: "5",
        minLengthOfLine: 1,
        startFromLineIdentifier: true,
        conflictPatternIdentifier: "",

        isAdvanced: false,
        lineStartsWith: "",

        conflictPattern: "",
        conflictPatternRegex: "",
        conflictPatternPcodeLength: 5,
        conflictPatternDate: "",
        aboveConflictPatternRegex: "",
        aboveConflictPatternPcodeLength: 5,
        aboveConflictPatternDate: "",
        belowConflictPatternRegex: "",
        belowConflictPatternPcodeLength: 5,
        belowConflictPatternDate: "",
        wrapLineThreshhold: 1,

        firstDollarBlock: "",
        abovelineMatchingPattern: "date",
        aboveLineMatchingDate: "",
        aboveLineMatchingRegex: "",
        minLengthOfAboveMatchingPcode: 5,
        aboveLineStartBlock: "",
        abovelineConflictPattern: "",
        aboveLineDollarValueBlock: "",
        belowLineMatchingPattern: "date",
        minLengthOfBelowMatchingPcode: 5,
        belowLineMatchingDate: "",
        belowLineMatchingRegex: "",
        belowLineConflictPattern: "",
        belowLineStartBlock: "",
        belowLineDollarValueBlock: "",

        maxLengthOfAboveLineItem: 1,
        minLengthOfAboveLineItem: 1,

        maxLengthofBelowLineItem: 1,
      },
    ],
    linefields: [
      {
        dropdown1: "",
        isReset: true,
        addFieldsAs: "",
        showBlock1: false,
        showBlock2: false,
        showNewField: false,
        splitLength: 1,
        joinAs: "text",
        dropdown2: "Baseline",
        data: {},
        data1: {},
        data2: {},
        mandatory: false,
        fieldExtractor: true,
        formBasedExtraction: false,
        blockExtractor: false,
        identifier: "",
        block: "",
        block1: "",
        block2: "",
        json: "",
        position: "",
        extractType: "form",
        isOptional: false,
        valueType: "",
        blockType: "text",
        valueBlock: "",
        isAdvanced: false,
        isWrappableFilled: false,
        wrapFrom: "below",
        indexRange1: 0,
        indexRange2: "",
        idlimit: 1,
        noOfBlocks: 1,
        isMultiValue: false,
        alsoAddAs: "",
        alsoAddJson: "",
        wrapValuesInto: "text",
        isSplittable: false,
        captureSplitValueAs: "append to next field",
        newFieldValue: "",
        newFieldValueJson: "",
        newFieldName: "",
        newFieldNameJson: "",
        separatorBasedSplit: true,
      },
    ],
    lineItemOpen: false,
    lineFieldOpen: false,
    selectedLineFields: data.selectedLineField,
    selectedAlsoAddAsFields: data.selectedAlsoAddField,
  },
  isLoaded: false,
  isSaved: false,
  redirectToHome: false,
  canCreate: false,
  templateJson: {
    payer_template_identification_strings: [],
    template_negate_strings: [],
    start_identification_strings: [],
  },
  checkJson: {
    check_address_fields: [],
    check_header_fields: [],
    card_address_fields: [],
    card_header_fields: [],
  },
  claimJson: {
    claim_extraction_properties: {},
    claim_address_fields: [],
    claim_header_fields: [],
    form_fields: [],
    claim_header_identification: [],

    line_configuration: {
      line_items_properties: {
        line_item_identifier: {},
        line_item_identifier_type: "date",
        line_item_identifier_format: "DD/MM-DD/MM/YY",
        start_from_line_identifier: true,
        min_length_of_line_item: 6,
      },
      field_properties: {
        base: [],
      },
    },
  },
  lineJson: {
    line_items_properties: {
      line_item_identifier: {},
      start_from_line_identifier: "",
      min_length_of_line_item: "0",
      line_item_identifier_type: "date",
      line_item_identifier_format: "DD/MM-DD/MM/YY",
    },
    field_properties: {
      base: [],
    },
  },
  isCreated: false,
  isSaved: false,
  canCreate: "",
  status: "",
};

export const saveReduer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_FROM_API: {
      const data = action.payload;

      //debugger;

      return produce(state, (draftState) => {
        draftState.name = data.name;
        draftState.templateID = data.templateID;
        draftState.loadingUpdate = "loaded";

        draftState.template = {
          templatePageIdentification1: data?.templatePageIdentification1?.length
            ? data.templatePageIdentification1
            : [
                {
                  block: "",
                  isAdd: true,
                  data: {},
                },
              ],
          templatePageIdentification2: data?.templatePageIdentification2.length
            ? data.templatePageIdentification2
            : [
                {
                  block: "",
                  data: {},
                },
              ],
          templatePageIdentification3: data?.identification1.length
            ? data.identification1
            : [
                {
                  block: "",
                  isAdd: true,
                  data: {},
                },
              ],
          neagate1: data.negate.length
            ? data.negate
            : [
                {
                  block: "",
                },
              ],
        };
        draftState.check = {
          ckadd: !data.ckadd.length
            ? [
                {
                  dropdown: "",
                  isReset: true,
                  default: true,
                  json: "",
                  isActive: false,
                  mandatory: false,
                  data: {},
                  minLenOfAddressLine: 1,
                  isAdd: false,
                  blocks: "",
                },
              ]
            : data.ckadd,
          cardAdd: !data.cardAdd.length
            ? [
                {
                  dropdown: "",
                  default: true,
                  isReset: true,
                  json: "",
                  isActive: false,
                  mandatory: false,
                  data: {},
                  minLenOfAddressLine: 1,
                  isAdd: false,
                  blocks: "",
                },
              ]
            : data.cardAdd,
          ckhead: data.ckhead.length
            ? data.ckhead
            : [
                {
                  default: false,
                  value: "",
                  data_value: {},
                  dropdown: "",
                  isReset: true,
                  data_endIdentifier: {},
                  data: {},
                  isActive: true,
                  mandatory: false,
                  formBasedExtractor: true,
                  blockExtractor: false,
                  identifier: "",
                  extractType: "form",
                  endIdentifier: "",
                  idlimit: 1,
                  indexRange1: "0",
                  indexRange2: "",
                  isAdvanced: false,
                  noOfBlocks: 1,
                  valueBlock: "",
                  valueType: "text",
                },
              ],

          cardHead: data.cardHead.length
            ? data.cardHead
            : [
                {
                  default: false,
                  isReset: true,
                  value: "",
                  data_value: {},
                  dropdown: "",
                  data_endIdentifier: {},
                  data: {},
                  isActive: true,
                  mandatory: false,
                  formBasedExtractor: true,
                  blockExtractor: false,
                  identifier: "",
                  extractType: "form",
                  endIdentifier: "",
                  idlimit: 1,
                  indexRange1: "0",
                  indexRange2: "",
                  isAdvanced: false,
                  noOfBlocks: 1,
                  valueBlock: "",
                  valueType: "text",
                },
              ],
          selectedCheckAdd: data.selectedCheckAddr,
          selectedCheckHead: data.selectedCheckHead,
          selectedCardAdd: data.selectedCardAddr,
          selectedCardHead: data.selectedCardHead,
        };
        draftState.claims = {
          clex: data.clex.length
            ? data.clex
            : [
                {
                  top: "",
                  data_top: {},
                  data_footer: {},
                  data_bottom: {},
                  data_heder: {},
                  bottom: "",
                  header: "",
                  footer: "",
                  isAdvanced: false,
                  bottomLine: 0,
                  captureBlockTillNextStart: false,
                  pageBreakContinuationIdentifier: "",
                  pageBreakdownIdentifier: "",
                  isHeaderPresent: false,
                  bottomLinesToSkip: 0,
                  extractType: true,
                },
              ],
          clheadIden: data?.clheadIden?.length
            ? data.clheadIden
            : [
                {
                  block: "",
                  isAdd: true,

                  data: {},
                },
              ],
          clhead: data.clhead.length
            ? data.clhead
            : [
                {
                  dropdown: "",
                  value: "",
                  data_value: {},
                  isReset: true,
                  isActive: true,
                  json: "",
                  mandatory: false,
                  formBasedExtractor: true,
                  blockExtractor: false,
                  identifier: "",
                  extractType: "form",
                  data: {},
                  data_end_identifier: {},
                  identifier: "",
                  endIdentifier: "",
                  idlimit: "1",
                  indexRange1: "0",
                  indexRange2: "",
                  isAdvanced: false,
                  noOfBlocks: 1,
                  values: "",
                  valueType: "text",
                },
              ],
          clfield: data.clfield.length
            ? data.clfield
            : [
                {
                  mandatory: false,
                  value: "",
                  data_value: {},
                  nextValueType: "",
                  prevValueType: "",
                  indexRegexPrev: "",
                  regex: "",
                  minLength: 1,
                  maxLength: 256,
                  isReset: true,
                  line: 0,
                  indexRangeIndexExtractor2: "",
                  indexRangeIndexExtractor1: 0,
                  showBlock1: false,
                  showBlock2: false,
                  block: "",
                  data: {},
                  block2: "",
                  data2: {},
                  block3: "",
                  data3: {},
                  keyValueExtractor: true,
                  indexBasedExtractor: false,
                  nameType: "first_last_middle",
                  json: "",
                  dropdown: "",
                  identifier: "",
                  extractType: "form",
                  isAdvanced: false,
                  endIdentifier: "",
                  idlimit: "1",
                  indexRange1: "0",
                  indexRange2: "",
                  noOfBlocks: "1",
                  isNameType: false,
                  valueType: "text",
                  data_end_identifier: {},
                },
              ],
          cladd: data.cladd.length
            ? data.cladd
            : [
                {
                  dropdown: "",
                  json: "",
                  isReset: true,
                  isActive: true,
                  mandatory: false,
                  blocks: "",
                  minLenOfAddressLine: 1,
                  isAdd: false,
                },
              ],
          selectedClaimAdd: data.selectedClaimAddress,
          selectedClaimHead: data.selectedClaimHeader,
          selectedClaimFeilds: data.selectedClaimFields,
        };

        draftState.line = {
          lineitem: data.lineitem,

          linefields: data.linefp.length
            ? data.linefp
            : [
                {
                  dropdown1: "",
                  isReset: true,
                  addFieldsAs: "",
                  showBlock1: false,
                  showBlock2: false,
                  showNewField: false,
                  splitLength: 1,
                  joinAs: "text",
                  dropdown2: "Baseline",
                  data: {},
                  data1: {},
                  data2: {},
                  mandatory: false,
                  fieldExtractor: true,
                  formBasedExtraction: false,
                  blockExtractor: false,
                  identifier: "",
                  block: "",
                  block1: "",
                  block2: "",
                  json: "",
                  position: "",
                  extractType: "form",
                  isOptional: false,
                  valueType: "",
                  blockType: "text",
                  valueBlock: "",
                  isAdvanced: false,
                  isWrappableFilled: false,
                  wrapFrom: "below",
                  indexRange1: 0,
                  indexRange2: "",
                  idlimit: 1,
                  noOfBlocks: 1,
                  isMultiValue: false,
                  alsoAddAs: "",
                  alsoAddJson: "",
                  wrapValuesInto: "text",
                  isSplittable: false,
                  captureSplitValueAs: "append to next field",
                  newFieldValue: "",
                  newFieldValueJson: "",
                  newFieldName: "",
                  newFieldNameJson: "",
                  separatorBasedSplit: true,
                },
              ],
          selectedLineFields: data?.selectedLineField,
          selectedAlsoAddAsFields: data?.selectedAlsoAddField,
        };
      });
    }
    case actionTypes.SAVE_CHECK: {
      return {
        ...state,
        check: action.payload,
      };
    }

    case actionTypes.CHANGE_LOADING_HOME: {
      return {
        ...state,
        loadingHome: action.payload,
      };
    }

    case actionTypes.SAVE_STATE: {
      return {
        ...state,
        save: action.payload,
        status: action.payload,
        canCreate: false,
      };
    }

    case actionTypes.SAVE_CREDS_IN_SAVE: {
      const finalState = {
        ...state,
        name: action.payload.name,
        templateID: action.payload.templateID,
        state: action.payload.startStatus,
        message: action.payload.message,
        files: action.payload.files,
        version: action.payload.version,
      };
      return finalState;
    }

    case actionTypes.REMOVE_CHECK_STATUS: {
      return {
        ...state,
        state: 0,
        message: "",
      };
    }

    case actionTypes.SAVE_TEMPLATE: {
      return {
        ...state,
        template: action.payload,
      };
    }
    case actionTypes.DELETE_DATA: {
      return {
        check: {
          checkAddressOptions: [],
          ckadd: [
            {
              dropdown: "",
              default: true,
              isReset: true,
              json: "",
              isActive: false,
              mandatory: false,
              data: {},
              minLenOfAddressLine: 1,
              isAdd: false,
              blocks: "",
            },
          ],
          cardAdd: [
            {
              dropdown: "",
              default: true,
              isReset: true,
              json: "",
              isActive: false,
              mandatory: false,
              data: {},
              minLenOfAddressLine: 1,
              isAdd: false,
              blocks: "",
            },
          ],
          ckhead: [
            {
              default: false,
              dropdown: "",
              value: "",
              data_value: {},
              isReset: true,
              data_endIdentifier: {},
              data: {},
              isActive: true,
              mandatory: false,
              formBasedExtractor: true,
              blockExtractor: false,
              identifier: "",
              extractType: "form",
              endIdentifier: "",
              idlimit: 1,
              indexRange1: "0",
              indexRange2: "",
              isAdvanced: false,
              noOfBlocks: "1",
              valueBlock: "",
              valueType: "text",
            },
          ],
          cardHead: [
            {
              default: false,
              dropdown: "",
              isReset: true,
              value: "",
              data_value: {},
              data_endIdentifier: {},
              data: {},
              isActive: true,
              mandatory: false,
              formBasedExtractor: true,
              blockExtractor: false,
              identifier: "",
              extractType: "form",
              endIdentifier: "",
              idlimit: 1,
              indexRange1: "0",
              indexRange2: "",
              isAdvanced: false,
              noOfBlocks: "1",
              valueBlock: "",
              valueType: "text",
            },
          ],
          ckHeadOpen: false,
          ckAddOpen: false,
          cardAddOpen: false,
          cdHeadOpen: false,
          selectedCheckAdd: [],
          selectedCheckHead: [],
          selectedCardAdd: [],
          selectedCardHead: [],
        },
        template: {},
        status: "",
        isError: false,
        claims: {
          claimHeaderIden: {},
          clheadIden: [],
          clex: [
            {
              top: "",
              data_top: {},
              data_footer: {},
              data_bottom: {},
              data_heder: {},
              bottom: "",
              header: "",
              footer: "",
              isAdvanced: false,
              bottomLine: 0,
              captureBlockTillNextStart: false,
              pageBreakContinuationIdentifier: "",
              pageBreakdownIdentifier: "",
              isHeaderPresent: false,
              bottomLinesToSkip: 0,
            },
          ],
          clhead: [
            {
              dropdown: "",
              value: "",
              data_value: {},
              isActive: true,
              isReset: true,
              json: "",
              mandatory: false,
              formBasedExtractor: true,
              blockExtractor: false,
              identifier: "",
              extractType: "form",
              data: {},
              data_end_identifier: {},
              identifier: "",
              endIdentifier: "",
              idlimit: "1",
              indexRange1: "0",
              indexRange2: "",
              isAdvanced: false,
              noOfBlocks: "1",
              values: "",
              valueType: "text",
            },
          ],
          cladd: [
            {
              dropdown: "",
              json: "",
              isReset: true,
              isActive: true,
              mandatory: false,
              blocks: "",
              minLenOfAddressLine: 1,
              isAdd: false,
            },
          ],
          clfield: [
            {
              mandatory: false,
              value: "",
              data_value: {},
              indexRegexPrev: "",
              minLength: 1,
              maxLength: 256,
              regex: "",
              nextValueType: "",
              prevValueType: "",

              nameType: "first_last_middle",
              json: "",
              line: 0,
              indexRangeIndexExtractor2: "",
              indexRangeIndexExtractor1: 0,
              showBlock1: false,
              showBlock2: false,
              isReset: true,
              block: "",
              data: {},
              block2: "",
              data2: {},
              block3: "",
              data3: {},
              keyValueExtractor: true,
              indexBasedExtractor: false,
              dropdown: "",
              identifier: "",
              extractType: "form",
              isAdvanced: false,
              endIdentifier: "",
              idlimit: "1",
              indexRange1: "0",
              indexRange2: "",
              noOfBlocks: "1",
              isNameType: false,
              valueType: "text",
              data_end_identifier: {},
            },
          ],
          clexopen: false,
          clheaderIdenopen: false,
          claddopen: false,
          clheaderopen: false,
          clfieldsopen: false,
          selectedClaimAdd: [],
          selectedClaimHead: [],
          selectedClaimFeilds: [],
        },
        line: {
          lineitem: [
            {
              lineItemIdentifierType: "pcode",
              isAbove: false,
              isBelow: false,
              regex: "",
              date: "",
              lineItemIdentifier: "",
              data: {},
              pcode: "5",
              minLengthOfLine: 1,
              startFromLineIdentifier: true,

              isAdvanced: false,
              lineStartsWith: "",
              conflictPatternIdentifier: "",
              conflictPattern: "",
              conflictPatternRegex: "",
              conflictPatternPcodeLength: 5,
              conflictPatternDate: "",
              aboveConflictPatternRegex: "",
              aboveConflictPatternPcodeLength: 5,
              aboveConflictPatternDate: "",
              belowConflictPatternRegex: "",
              belowConflictPatternPcodeLength: 5,
              belowConflictPatternDate: "",
              wrapLineThreshhold: 1,

              firstDollarBlock: "",
              abovelineMatchingPattern: "date",
              aboveLineMatchingDate: "",
              aboveLineMatchingRegex: "",
              minLengthOfAboveMatchingPcode: 5,
              aboveLineStartBlock: "",
              abovelineConflictPattern: "",
              aboveLineDollarValueBlock: "",
              belowLineMatchingPattern: "date",
              minLengthOfBelowMatchingPcode: 5,
              belowLineMatchingDate: "",
              belowLineMatchingRegex: "",
              belowLineConflictPattern: "",
              belowLineStartBlock: "",
              belowLineDollarValueBlock: "",
              minLengthOfAboveLineItem: 1,
              maxLengthOfAboveLineItem: 1,
              minLengthOfBelowLineItem: 1,
              maxLengthofBelowLineItem: 1,
            },
          ],
          linefields: [
            {
              dropdown1: "",
              isReset: true,
              addFieldsAs: "",
              showBlock1: false,
              showBlock2: false,
              showNewField: false,
              splitLength: 1,
              joinAs: "text",
              dropdown2: "Baseline",
              data: {},
              data1: {},
              data2: {},
              mandatory: false,
              fieldExtractor: true,
              formBasedExtraction: false,
              blockExtractor: false,
              identifier: "",
              block: "",
              block1: "",
              block2: "",
              json: "",
              position: "",
              extractType: "form",
              isOptional: false,
              valueType: "",
              blockType: "text",
              valueBlock: "",
              isAdvanced: false,
              isWrappableFilled: false,
              wrapFrom: "below",
              indexRange1: 0,
              indexRange2: "",
              idlimit: 1,
              noOfBlocks: 1,
              isMultiValue: false,
              alsoAddAs: "",
              alsoAddJson: "",
              wrapValuesInto: "text",
              isSplittable: false,
              captureSplitValueAs: "append to next field",
              newFieldValue: "",
              newFieldValueJson: "",
              newFieldName: "",
              newFieldNameJson: "",
              separatorBasedSplit: true,
            },
          ],
          lineItemOpen: false,
          lineFieldOpen: false,
          selectedLineFields: [],
          selectedAlsoAddAsFields: [],
        },
        isLoaded: false,
        isSaved: false,
        redirectToHome: false,
        canCreate: false,
        templateJson: {
          payer_template_identification_strings: [],
          template_negate_strings: [],
          start_identification_strings: [],
        },
        checkJson: {
          check_address_fields: [],
          check_header_fields: [],
          card_address_fields: [],
          card_header_fields: [],
        },
        claimJson: {
          claim_extraction_properties: {},
          claim_address_fields: [],
          claim_header_fields: [],
          form_fields: [],

          line_configuration: {
            line_items_properties: {
              line_item_identifier: {},
              line_item_identifier_type: "date",
              line_item_identifier_format: "DD/MM-DD/MM/YY",
              start_from_line_identifier: true,
              min_length_of_line_item: 6,
            },
            field_properties: {
              base: [],
            },
          },
        },
        lineJson: {
          line_items_properties: {
            line_item_identifier: {},
            start_from_line_identifier: "",
            min_length_of_line_item: "0",
            line_item_identifier_type: "date",
            line_item_identifier_format: "DD/MM-DD/MM/YY",
          },
          field_properties: {
            base: [],
          },
        },
        isCreated: false,
        isSaved: false,
        canCreate: "",
        loadingUpdate: "loading",
        status: "",
      };
    }
    case actionTypes.SAVE_CLAIM: {
      return {
        ...state,
        claims: action.payload,
      };
    }

    case actionTypes.STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case actionTypes.ADD_EXTRA_BLOCK: {
      return {
        ...state,
        line: {
          ...state.line,
          linefields: action.payload,
        },
      };
    }

    case actionTypes.DELETE_EXTRA_BLOCK: {
      return {
        ...state,
        line: {
          ...state.line,
          linefields: action.payload,
        },
      };
    }

    case actionTypes.SAVE_CHECK_ADDRESS: {
      return {
        ...state,
        check: {
          ...state.check,
          ckadd: action.payload,
        },
      };
    }
    case actionTypes.SAVE_CARD_ADDRESS: {
      return {
        ...state,
        check: {
          ...state.check,
          cardAdd: action.payload,
        },
      };
    }

    case actionTypes.SAVE_CLAIM_ADDRESS: {
      return {
        ...state,
        claims: {
          ...state.claims,
          cladd: action.payload,
        },
      };
    }

    case actionTypes.SAVE_CHECK_HEADER: {
      return {
        ...state,
        check: {
          ...state.check,
          ckhead: action.payload,
        },
      };
    }
    case actionTypes.SAVE_CARD_HEADER: {
      return {
        ...state,
        check: {
          ...state.check,
          cardHead: action.payload,
        },
      };
    }

    case actionTypes.SAVE_CLAIM_EXTRAXCTION: {
      return {
        ...state,
        claims: {
          ...state.claims,
          clex: action.payload,
        },
      };
    }

    case actionTypes.SELECTED_CHECK_HEADER: {
      return {
        ...state,
        check: {
          ...state.check,
          selectedCheckHead: action.payload,
        },
      };
    }
    case actionTypes.SELECTED_CARD_HEADER: {
      return {
        ...state,
        check: {
          ...state.check,
          selectedCardHead: action.payload,
        },
      };
    }

    case actionTypes.SELECTED_CLAIM_ADDRESS: {
      return {
        ...state,
        claims: {
          ...state.claims,
          selectedClaimAdd: action.payload,
        },
      };
    }

    case actionTypes.SELECTED_CLAIM_HEADER: {
      return {
        ...state,
        claims: {
          ...state.claims,
          selectedClaimHead: action.payload,
        },
      };
    }

    case actionTypes.SELECTED_CLAIM_FIELDS: {
      return {
        ...state,
        claims: {
          ...state.claims,
          selectedClaimFeilds: action.payload,
        },
      };
    }

    case actionTypes.SELECTED_LINE_FIELDS: {
      return {
        ...state,
        line: {
          ...state.line,
          selectedLineFields: action.payload,
        },
      };
    }
    case actionTypes.SELECTED_ALSO_ADD_AS_FIELDS: {
      return {
        ...state,
        line: {
          ...state.line,
          selectedAlsoAddAsFields: action.payload,
        },
      };
    }

    case actionTypes.SAVE_CLAIM_HEADER: {
      return {
        ...state,
        claims: {
          ...state.claims,
          clhead: action.payload,
        },
      };
    }

    case actionTypes.SAVE_CLAIM_HEADER_IDEN: {
      return {
        ...state,
        claims: {
          ...state.claims,
          clheadIden: action.payload,
        },
      };
    }

    case actionTypes.SELECTED_CHECK_ADDRESS: {
      return {
        ...state,
        check: {
          ...state.check,
          selectedCheckAdd: action.payload,
        },
      };
    }
    case actionTypes.SELECTED_CARD_ADDRESS: {
      return {
        ...state,
        check: {
          ...state.check,
          selectedCardAdd: action.payload,
        },
      };
    }

    case actionTypes.SAVE_CLAIM_FIELDS: {
      return {
        ...state,
        claims: {
          ...state.claims,
          clfield: action.payload,
        },
      };
    }

    case actionTypes.CHECK_ADDRESS_OPEN: {
      return {
        ...state,
        check: {
          ...state.check,
          ckAddOpen: action.payload,
        },
      };
    }

    case actionTypes.CARD_ADDRESS_OPEN: {
      return {
        ...state,
        check: {
          ...state.check,
          cardAddOpen: action.payload,
        },
      };
    }

    case actionTypes.LINE_ITEM_OPEN: {
      return {
        ...state,
        line: {
          ...state.line,
          lineItemOpen: action.payload,
        },
      };
    }

    case actionTypes.LINE_FIELD_OPEN: {
      return {
        ...state,
        line: {
          ...state.line,
          lineFieldOpen: action.payload,
        },
      };
    }

    case actionTypes.CLAIM_EXTRACTION_OPEN: {
      return {
        ...state,
        claims: {
          ...state.claims,
          clexopen: action.payload,
        },
      };
    }

    case actionTypes.CLAIM_HEADER_IDENTIFICATION_OPEN: {
      return {
        ...state,
        claims: {
          ...state.claims,
          clheaderIdenopen: action.payload,
        },
      };
    }
    case actionTypes.CLAIM_HEADER_OPEN: {
      return {
        ...state,

        claims: {
          ...state.claims,
          clheadopen: action.payload,
        },
      };
    }

    case actionTypes.CLAIM_FIELDS_OPEN: {
      return {
        ...state,

        claims: {
          ...state.claims,
          clfieldsopen: action.payload,
        },
      };
    }

    case actionTypes.CLAIM_ADDRESS_OPEN: {
      return {
        ...state,
        claims: {
          ...state.claims,
          claddopen: action.payload,
        },
      };
    }

    case actionTypes.CHECK_HEADER_OPEN: {
      return {
        ...state,

        check: {
          ...state.check,
          ckHeadOpen: action.payload,
        },
      };
    }
    case actionTypes.CARD_HEADER_OPEN: {
      return {
        ...state,

        check: {
          ...state.check,
          cdHeadOpen: action.payload,
        },
      };
    }

    case actionTypes.NO: {
      return {
        ...state,
        no: action.payload,
      };
    }

    case actionTypes.SAVE_LINE_ITEM: {
      return {
        ...state,
        line: {
          ...state.line,
          lineitem: action.payload,
        },
      };
    }

    case actionTypes.SAVE_LINE_FIELD: {
      return {
        ...state,
        line: {
          ...state.line,
          linefields: action.payload,
        },
      };
    }

    case actionTypes.SAVE_JSON: {
      const status = action.payload;

      // check address

      let ckaddress = [];
      let ckheader = [];
      let cardaddress = [];
      let cardheader = [];
      //
      if (!_.isEmpty(state.check)) {
        state.check.ckadd.map((ck) => {
          let obj = {
            name: ck.json,
            min_len_of_address_lines: ck.minLenOfAddressLine,
            is_mandatory: ck.mandatory,
            Blocks: [ck.data],
          };

          ckaddress = ckaddress.concat(obj);
        });

        //card address
        state.check.cardAdd.map((cd) => {
          let obj = {
            name: cd.json,
            min_len_of_address_lines: cd.minLenOfAddressLine,
            is_mandatory: cd.mandatory,
            Blocks: [cd.data],
          };

          cardaddress = cardaddress.concat(obj);
        });
        // check header

        state.check.ckhead.map((ck) => {
          let data = {
            name: ck.json,
            is_mandatory: ck.mandatory,
          };

          if (ck?.formBasedExtractor) {
            data["identifier"] = ck?.data;
            data["id_limit"] = Number(ck?.idlimit);
            data["value_range"] = `${ck?.indexRange1}:${ck?.indexRange2}`;
            data["look_up_till"] = Number(ck?.noOfBlocks);
            data["extract_type"] = ck.extractType;
            data["value"] = ck?.data_value;
            if (!_.isEmpty(ck?.data_endIdentifier))
              data["end_identifier"] = ck?.data_endIdentifier;
          } else {
            data["Blocks"] = ck?.data;
            data["value_type"] = ck?.valueType;
          }

          ckheader = ckheader.concat(data);
        });
        //Card Header
        state.check.cardHead.map((ck) => {
          let data = {
            name: ck.json,
            is_mandatory: ck.mandatory,
          };

          if (ck?.formBasedExtractor) {
            data["identifier"] = ck?.data;
            data["id_limit"] = Number(ck?.idlimit);
            data["value_range"] = `${ck?.indexRange1}:${ck?.indexRange2}`;
            data["look_up_till"] = Number(ck?.noOfBlocks);
            data["extract_type"] = ck.extractType;
            data["value"] = ck?.data_value;

            if (!_.isEmpty(ck?.data_endIdentifier))
              data["end_identifier"] = ck?.data_endIdentifier;
          } else {
            data["Blocks"] = ck?.data;
            data["value_type"] = ck?.valueType;
          }

          cardheader = cardheader.concat(data);
        });
      }

      //claims extraction

      let clextraction = {};
      let clheader = [];

      let claddress = [];
      let clfields = [];
      let clheadIden = [];
      if (!_.isEmpty(state.claims)) {
        state.claims.clex.map((ck) => {
          let headerPresent = "header";
          if (!ck?.isHeaderPresent) headerPresent = "section";

          let data = {
            top_identifier:
              ck?.data_top && !_.isEmpty(ck?.data_top) ? [ck?.data_top] : [],
            bottom_identifier:
              ck?.data_bottom && !_.isEmpty(ck?.data_bottom)
                ? [ck?.data_bottom]
                : [],

            page_break_cont_identifier: ck?.data_pbci,

            page_break_identifier: ck?.data_pbi,
            extract_type: headerPresent,
            bottom_additional_line: ck?.bottomLine,
            capture_blocks_till_next_start: ck?.captureBlockTillNextStart,
            is_header_present: ck?.isHeaderPresent,
            bottom_line_to_skip: ck?.bottomLinesToSkip,
          };

          if (!_.isEmpty(ck?.data_heder)) {
            data["header_identifier"] = ck?.data_heder;
          }
          if (!_.isEmpty(ck?.data_footer)) {
            data["footer_identifier"] = ck?.data_footer;
          }

          clextraction = data;
        });

        state.claims.clhead.map((ck) => {
          let data = {
            name: ck.json,
            is_mandatory: ck.mandatory,
          };
          if (ck?.formBasedExtractor) {
            data["identifier"] = ck?.data;
            data["extract_type"] = ck.extractType;
            data["value"] = ck.data_value;

            data["id_limit"] = Number(ck?.idlimit);
            data["look_up_till"] = Number(ck?.noOfBlocks);
            data["value_range"] = `${ck?.indexRange1}:${ck?.indexRange2}`;
            if (!_.isEmpty(ck?.data_end_identifier))
              data["end_identifier"] = ck?.data_end_identifier;
            if (!_.isEmpty(ck?.data)) data["identifier"] = ck?.data;
          } else {
            data["Blocks"] = ck?.data;
            data["value_type"] = ck?.valueType;
          }

          clheader = [...clheader, data];
        });

        state.claims.cladd.map((ck) => {
          let data = {
            name: ck.json,
            mandatory: ck.mandatory,
            min_len_of_address_lines: parseInt(ck.minLenOfAddressLine, 10),
            // 1 or 3 ?
            Blocks: [ck.data],
          };
          claddress = [...claddress, data];
        });
        if (state.claims.clheadIden.length && state.claims.clheadIden[0].block)
          state.claims.clheadIden.map((ck) => {
            clheadIden = [...clheadIden, ck.data];
          });

        state.claims.clfield.map((ck) => {
          let data = {
            name: ck.json,
            is_mandatory: ck.mandatory,
          };

          if (ck?.indexBasedExtractor) {
            data["extractor"] = "index";
            data["value_type"] = ck?.valueType;

            if (ck?.valueType === "date") {
              data["value_format"] = ck?.date;
            } else if (ck?.valueType === "regex") {
              data["value_format"] = ck?.regex;
            }

            data["identifier"] = ck.data;
            data["line_number"] = ck?.line;

            data[
              "value_range"
            ] = `${ck?.indexRangeIndexExtractor1}:${ck?.indexRangeIndexExtractor2}`;

            data["min_length_of_block"] = ck?.minLength;
            data["max_length_of_block"] = ck?.maxLength;
            data["next_value_type"] = ck?.nextValueType;
            data["prev_value_type"] = ck?.prevValueType;

            if (ck?.nextValueType === "date") {
              data["next_value_format"] = ck?.nextValueDate;
            } else if (ck?.nextValueType === "regex") {
              data["next_value_format"] = ck?.indexRegex;
            }

            if (ck?.prevValueType === "date") {
              data["prev_value_format"] = ck?.prevValueDate;
            } else if (ck?.prevValueType === "regex") {
              data["prev_value_format"] = ck?.indexRegexPrev;
            }
          }

          if (ck?.keyValueExtractor) {
            data["extractor"] = "key";
            data["value"] = ck?.data_value;

            data["identifier"] = ck.data_identifier.Text
              ? ck.data_identifier
              : "";
            data["extract_type"] = ck?.extractType;
            // data["extractor"] = ck?.indexBasedExtractor ? "index" : "value";
            data["id_limit"] = Number(ck?.idlimit);
            data["look_up_till"] = Number(ck?.noOfBlocks);
            data["value_range"] = `${ck?.indexRange1}:${ck?.indexRange2}`;
            if (!_.isEmpty(ck?.data_end_identifier))
              data["end_identifier"] = ck?.data_end_identifier.Text
                ? ck?.data_end_identifier
                : "";
          }

          if (
            ck.dropdown &&
            ck.dropdown.includes("Name") &&
            ck.dropdown !== "Payee Name In EOB (C6)"
          ) {
            data["name_type"] = ck.nameType;
            data["is_categorize_name"] = true;
          }
          clfields = [...clfields, data];
        });
      }

      // line
      let lineItemProps = {};

      let baseProps = [];
      let aboveProps = [];
      let belowProps = [];

      if (!_.isEmpty(state.line)) {
        state.line.lineitem.map((li) => {
          lineItemProps = {
            isAbove: li?.isAbove,
            isBelow: li?.isBelow,
            start_from_line_identifier: li.startFromLineIdentifier,

            line_item_identifier_type: li.lineItemIdentifierType,
            start_from_line_identifier: li?.startFromLineIdentifier,
            min_len_of_line_item: Number(li?.minLengthOfLine),
            conflict_pattern: li?.data_conflictPatterndata1,
            wrap_line_threshold: li?.wrapLineThreshhold,

            max_length_of_above_line_item: Number(li?.maxLengthOfAboveLineItem),
            min_length_of_above_line_item: Number(li?.minLengthOfAboveLineItem),
            max_length_of_below_line_item: Number(li?.maxLengthofBelowLineItem),

            line_starts_with:
              !_.isEmpty(li?.data_lineStartsWith) &&
              li?.data_lineStartsWith?.Text
                ? li?.data_lineStartsWith
                : "",
            first_dollar_block:
              !_.isEmpty(li?.data_firstDollarBlock) &&
              li?.data_firstDollarBlock?.Text
                ? li?.data_firstDollarBlock
                : "",

            line_conflict_pattern_type: li?.conflictPattern,
            below_line_start_block: _.isEmpty(li?.data_belowLineStartBlock)
              ? ""
              : li?.data_belowLineStartBlock,

            below_line_dollar_value_block:
              !_.isEmpty(li?.data_belowlineDollarValueBlock) &&
              li?.data_belowlineDollarValueBlock["Text"]
                ? li?.data_belowlineDollarValueBlock
                : "",
            above_line_start_block: _.isEmpty(li?.data_aboveLineStartBlock)
              ? ""
              : li?.data_aboveLineStartBlock,

            line_item_identifier: li.data,
          };

          if (li?.lineItemIdentifierType === "pcode") {
            lineItemProps["line_item_identifier_max_length"] = Number(li.pcode);
          }

          if (li?.lineItemIdentifierType === "date") {
            lineItemProps["line_item_identifier_format"] = li.date;
          }
          if (li?.lineItemIdentifierType === "regex") {
            lineItemProps["line_item_identifier_format"] = li.regex;
          }

          //conflict pattern

          if (li?.conflictPattern !== "") {
            lineItemProps["line_conflict_pattern_type"] = li?.conflictPattern;
          }

          if (li?.conflictPattern === "date") {
            lineItemProps["line_conflict_pattern_format"] =
              li?.conflictPatternDate;
          }

          if (li?.conflictPattern === "regex") {
            lineItemProps["line_conflict_pattern_format"] =
              li?.conflictPatternRegex;
          }
          if (li?.conflictPattern === "pcode") {
            lineItemProps["line_conflict_pattern_max_length"] =
              li?.conflictPatternPcodeLength;
          }

          if (li?.conflictPattern === "identifier") {
            lineItemProps["line_conflict_pattern_identifier"] =
              li?.data_conflictPatternIdentifier;
          }

          // new changes

          //above line matching pattern

          if (li?.isAbove) {
            lineItemProps["above_line_dollar_value_block"] =
              !_.isEmpty(li?.data_aboveLineDollarValueBlock) &&
              li?.data_aboveLineDollarValueBlock["Text"]
                ? li?.data_aboveLineDollarValueBlock
                : "";
            lineItemProps["above_line_conflict_pattern_type"] =
              li?.abovelineConflictPattern;

            lineItemProps["above_line_matching_pattern_type"] =
              li?.abovelineMatchingPattern;
            lineItemProps["min_length_of_above_line_item"] =
              li?.minLengthOfAboveLineItem;

            if (li?.abovelineMatchingPattern === "pcode") {
              lineItemProps["above_line_matching_pattern_max_length"] = Number(
                li.minLengthOfAboveMatchingPcode
              );
            }

            if (li?.abovelineMatchingPattern === "date") {
              lineItemProps["above_line_matching_pattern_format"] =
                li?.aboveLineMatchingDate;
            }

            if (li?.abovelineMatchingPattern === "regex") {
              lineItemProps["above_line_matching_pattern_format"] =
                li?.aboveLineMatchingRegex;
            }
          }
          if (li?.isAbove && li?.abovelineConflictPattern !== "") {
            lineItemProps["above_line_conflict_pattern_type"] =
              li?.abovelineConflictPattern;
            //above line conflict pattern

            if (li?.abovelineConflictPattern === "date") {
              lineItemProps["above_line_conflict_pattern_format"] =
                li?.aboveConflictPatternDate;
            }

            if (li?.abovelineConflictPattern === "regex") {
              lineItemProps["above_line_conflict_pattern_format"] =
                li?.aboveConflictPatternRegex;
            }
            if (li?.abovelineConflictPattern === "pcode") {
              lineItemProps["above_line_conflict_pattern_max_length"] =
                li?.aboveConflictPatternPcodeLength;
            }
          }

          if (li?.isBelow) {
            // lineItemProps["below_line_matching_pattern_type"] =
            // li?.belowLineMatchingPattern;
            lineItemProps["below_line_matching_pattern_type"] =
              li?.belowLineMatchingPattern;
            lineItemProps["min_length_of_below_line_item"] =
              li?.minLengthOfBelowLineItem;

            // below line matching pattern

            if (li?.belowlineMatchingPattern === "pcode") {
              lineItemProps["below_line_matching_pattern_max_length"] = Number(
                li?.minLengthOfBelowMatchingPcode
              );
            }

            if (li?.belowLineMatchingPattern === "regex") {
              lineItemProps["below_line_matching_pattern_format"] =
                li.belowLineMatchingRegex;
              lineItemProps["below_line_matching_pattern_type"] =
                li.belowLineMatchingPattern;
            }

            if (li?.belowLineMatchingPattern === "date") {
              lineItemProps["below_line_matching_pattern_format"] =
                li?.belowLineMatchingDate;
            }
          }
          // below line conflict pattern

          if (li?.isBelow && li?.belowLineConflictPattern !== "") {
            lineItemProps["below_line_conflict_pattern_type"] =
              li?.belowLineConflictPattern;

            if (li?.belowLineConflictPattern === "regex") {
              lineItemProps["below_line_conflict_pattern_format"] =
                li.belowConflictPatternRegex;
            }
            if (li?.belowLineConflictPattern === "date") {
              lineItemProps["below_line_conflict_pattern_format"] =
                li?.belowConflictPatternDate;
            }
            if (li?.belowLineConflictPattern === "pcode") {
              lineItemProps["below_line_conflict_pattern_max_length"] = Number(
                li?.belowConflictPatternPcodeLength
              );
            }
          }
        });

        // field properties
        state.line.linefields.map((li) => {
          let data = {
            name: li.json,
            is_mandatory: li.mandatory,
            split_type: li?.separatorBasedSplit ? "separator" : "fixed",
          };
          if (li?.alsoAddJson) {
            data["also_add_as"] = li?.alsoAddJson;
          }

          let a = [];
          if (!_.isEmpty(li?.data) && li?.data.Text) a = [...a, li?.data];
          data["Block"] = a;
          if (!_.isEmpty(li?.data1) && li?.data1.Text) {
            a = [...a, li?.data1];
            data["Block"] = a;
          }

          if (!_.isEmpty(li?.data2) && li?.data2.Text) {
            a = [...a, li?.data2];
            data["Block"] = a;
          }

          data["value_type"] = li.valueType;
          data["is_optional"] = li?.isOptional;
          data["is_multi_value"] = li?.isMultiValue;
          // data["also_add_as"]=li?.alsoAddAs;
          data["is_wrappable"] = li?.isWrappableFilled;
          data["is_splittable"] = li?.isSplittable;
          data["split_length"] = Number(li?.splitLength);
          data["add_fields_as"] = li?.addFieldsAs;

          if (li.isWrappableFilled) {
            data["wrap_from"] = li?.wrapFrom;
            data["wrap_as"] = li?.wrapValuesInto;
          }
          if (li.wrapValuesInto === "new_field") {
            data["wrap_new_field_name"] = li?.newFieldNameJson;
          }
          if (li.isSplittable) {
            // data["separator_based_split"] = li?.separatorBasedSplit;
            data["split_add_type"] = snakeCase(li?.captureSplitValueAs);
          }

          if (li.isMultiValue) {
            data["join_as"] = li?.joinAs;
          }
          if (
            li.captureSplitValueAs === "new field" ||
            li.captureSplitValueAs === "MANDATORY new field"
          ) {
            data["split_new_field_name"] = li?.newFieldValueJson;
          }

          if (li.dropdown2 === "Baseline") {
            baseProps = [...baseProps, data];
          }
          if (li.dropdown2 === "Above") {
            aboveProps = [...aboveProps, data];
          }
          if (li.dropdown2 === "Below") {
            belowProps = [...belowProps, data];
          }
        });
      }

      // final json

      if (status === "Created") {
        // check validations here

        if (!state.template?.templatePageIdentification1[0]?.block) {
          alert("Please Add Template Identification");
          return {
            ...state,
            canCreate: false,
          };
        }

        if (!state.template?.templatePageIdentification1[0]?.block) {
          alert("Please Add Template Identification");
          return {
            ...state,
            canCreate: false,
          };
        }

        //  check validations if dropdown filled

        // check address

        let isDropdown = undefined;
        let emptyHeader = false;

        ckheader.map((item) => {
          if (!item.name) {
            emptyHeader = true;
            return;
          }
          if (item.name) {
            if (_.isEmpty(item.identifier) && _.isEmpty(item.Blocks))
              isDropdown = false;
          }
          if (!item.name && _.isEmpty(item.identifier)) isDropdown = true;
        });
        if (emptyHeader) {
          ckheader = ckheader.filter((item) => {
            if (item.name) return item;
          });
        }
        if (isDropdown === false) {
          alert("Please fill up   check header, or remove the dropdown value");
          return {
            ...state,
            canCreate: false,
          };
        } else if (isDropdown) ckheader = [];

        isDropdown = undefined;
        let emptyAddress = false;

        ckaddress.map((item) => {
          if (!item.name) {
            emptyAddress = true;
            return;
          }
          if (item.name) {
            if (!item.Blocks.length) isDropdown = false;
          }
          if (!item.name && item.Blocks.length) isDropdown = true;
        });

        if (emptyAddress) {
          ckaddress = ckaddress.filter((item) => {
            if (item.name) return item;
          });
        }

        if (isDropdown) {
          ckaddress = [];
        }
        if (isDropdown === false) {
          alert(
            "Please fill up blocks and min length in  check address, or remove the dropdown value"
          );
          return {
            ...state,
            canCreate: false,
          };
        }

        //check for card address and header

        let isCardDropdown = undefined;
        let cardEmptyHeader = false;

        cardheader.map((item) => {
          if (!item.name) {
            cardEmptyHeader = true;
            return;
          }
          if (item.name) {
            if (_.isEmpty(item.identifier) && _.isEmpty(item.Blocks))
              isCardDropdown = false;
          }
          if (!item.name && _.isEmpty(item.identifier)) isCardDropdown = true;
        });
        if (cardEmptyHeader) {
          cardheader = cardheader.filter((item) => {
            if (item.name) return item;
          });
        }
        if (isCardDropdown === false) {
          alert("Please fill up   card header, or remove the dropdown value");
          return {
            ...state,
            canCreate: false,
          };
        } else if (isCardDropdown) cardheader = [];

        let isCardAddressDropdown = undefined;
        let cardEmptyAddress = false;

        cardaddress.map((item) => {
          if (!item.name) {
            cardEmptyAddress = true;
            return;
          }
          if (item.name) {
            if (!item.Blocks.length) isCardAddressDropdown = false;
          }
          if (!item.name && item.Blocks.length) isCardAddressDropdown = true;
        });

        if (cardEmptyAddress) {
          cardaddress = cardaddress.filter((item) => {
            if (item.name) return item;
          });
        }

        if (isCardAddressDropdown) {
          cardaddress = [];
        }
        if (isCardAddressDropdown === false) {
          alert(
            "Please fill up blocks and min length in  card address, or remove the dropdown value"
          );
          return {
            ...state,
            canCreate: false,
          };
        }

        // check for claim address and header

        isDropdown = undefined;
        let emptyClaimHeader = false;

        clheader.map((item) => {
          if (!item.name) {
            emptyClaimHeader = true;
            return;
          }
          if (item.name) {
            if (_.isEmpty(item.identifier) && _.isEmpty(item.Blocks))
              isDropdown = false;
          }
          if (!item.name && _.isEmpty(item.identifier)) isDropdown = true;
        });
        if (emptyClaimHeader) {
          clheader = clheader.filter((item) => {
            if (item.name) return item;
          });
        }
        if (isDropdown === false) {
          alert("Please fill up claim header, or remove the dropdown value");
          return {
            ...state,
            canCreate: false,
          };
        } else if (isDropdown) clheader = [];

        let emptyClaimAddress = false;
        claddress.map((item) => {
          if (!item.name) {
            emptyClaimAddress = true;
            return;
          }
          if (item.name) {
            if (!item.Blocks[0] || !item.Blocks) isDropdown = false;
          }
          if (!item.name && !item.Blocks[0]) isDropdown = true;
        });
        if (emptyClaimAddress) {
          claddress = claddress.filter((item) => {
            if (item.name) return item;
          });
        }

        if (isDropdown === false) {
          alert(
            "Please fill up blocks and min length in  claim address, or remove the dropdown value"
          );
          return {
            ...state,
            canCreate: false,
          };
        } else if (isDropdown) claddress = [];

        if (!state.claims?.clex[0].top && !state.claims?.clex[0].bottom) {
          alert("Please Add Top/Bottom Identifier in claims");
          return {
            ...state,
            canCreate: false,
          };
        }

        // claim fields

        let isClaimFields = true;
        state.claims?.clfield.map((item) => {
          if (item.keyValueExtractor) {
            if (!item.dropdown || _.isEmpty(item.data_identifier)) {
              isClaimFields = false;
              return;
            }
          } else if (item?.indexBasedExtractor) {
            if (!item.dropdown || _.isEmpty(item.data)) {
              isClaimFields = false;
              return;
            }
            if (!item.valueType) {
              isClaimFields = false;
              return;
            }
            if (item.valueType === "date" && !item.date) {
              isClaimFields = false;
              return;
            }
          }
        });
        if (!isClaimFields) {
          alert("Please fill all values in claim fields");
          return {
            ...state,
            canCreate: false,
          };
        }

        if (
          _.isEmpty(state.line?.lineitem[0].data) ||
          !state.line?.lineitem[0].data?.Text
        ) {
          alert("Please add mandatory fields in line item porperties");
          return {
            ...state,
            canCreate: false,
          };
        }

        if (
          _.isEmpty(state.line?.lineitem[0].data) &&
          !state.line?.lineitem[0].lineItemIdentifierType
        ) {
          alert("Please add mandatory fields in line item porperties");
          return {
            ...state,
            canCreate: false,
          };
        }

        if (
          (state.line?.lineitem[0].isAbove &&
            !state.line?.lineitem[0].abovelineMatchingPattern) ||
          (state.line?.lineitem[0].isBelow &&
            !state.line?.lineitem[0].belowLineMatchingPattern)
        ) {
          alert("Please fill above/below line matching pattern in line");
          return {
            ...state,
            canCreate: false,
          };
        }

        if (state.line?.lineitem[0].isAbove) {
          if (state.line?.lineitem[0]?.abovelineMatchingPattern === "date") {
            if (!state.line?.lineitem[0]?.aboveLineMatchingDate) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }
          if (state.line?.lineitem[0]?.abovelineMatchingPattern === "regex") {
            if (!state.line?.lineitem[0]?.aboveLineMatchingRegex) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }
          if (state.line?.lineitem[0]?.abovelineMatchingPattern === "pcode") {
            if (!state.line?.lineitem[0]?.minLengthOfAboveMatchingPcode) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }

          if (state.line?.lineitem[0]?.abovelineConflictPattern === "pcode") {
            if (!state.line?.lineitem[0]?.aboveConflictPatternPcodeLength) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }

          if (state.line?.lineitem[0]?.abovelineConflictPattern === "date") {
            if (!state.line?.lineitem[0]?.aboveConflictPatternDate) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }
          if (state.line?.lineitem[0]?.abovelineConflictPattern === "regex") {
            if (!state.line?.lineitem[0]?.aboveConflictPatternRegex) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }

          if (_.isEmpty(state.line?.lineitem[0]?.data_aboveLineStartBlock)) {
            alert("Please fill mandatory fields in  line item");
            return {
              ...state,
              canCreate: false,
            };
          }
          if (
            !_.isEmpty(state.line?.lineitem[0]?.data_aboveLineStartBlock) &&
            !state.line?.lineitem[0]?.data_aboveLineStartBlock["Text"]
          ) {
            alert("Please fill mandatory fields in  line item");
            return {
              ...state,
              canCreate: false,
            };
          }
        }

        if (state.line?.lineitem[0].isBelow) {
          if (state.line?.lineitem[0]?.belowlineMatchingPattern === "date") {
            if (!state.line?.lineitem[0]?.belowLineMatchingDate) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }
          if (state.line?.lineitem[0]?.belowlineMatchingPattern === "regex") {
            if (!state.line?.lineitem[0]?.belowLineMatchingRegex) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }
          if (state.line?.lineitem[0]?.belowlineMatchingPattern === "pcode") {
            if (!state.line?.lineitem[0]?.minLengthOfBelowMatchingPcode) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }

          if (state.line?.lineitem[0]?.belowlineConflictPattern === "pcode") {
            if (!state.line?.lineitem[0]?.belowConflictPatternPcodeLength) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }
          if (state.line?.lineitem[0]?.belowlineConflictPattern === "date") {
            if (!state.line?.lineitem[0]?.belowConflictPatternDate) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }
          if (state.line?.lineitem[0]?.belowlineConflictPattern === "regex") {
            if (!state.line?.lineitem[0]?.belowConflictPatternRegex) {
              alert("Please fill mandatory fields in  line item");
              return {
                ...state,
                canCreate: false,
              };
            }
          }
          if (_.isEmpty(state.line?.lineitem[0]?.data_belowLineStartBlock)) {
            alert("Please fill mandatory fields in  line item");
            return {
              ...state,
              canCreate: false,
            };
          }

          if (!state.line?.lineitem[0]?.data_belowLineStartBlock?.Text) {
            alert("Please fill mandatory fields in  line item");
            return {
              ...state,
              canCreate: false,
            };
          }
        }
        // item identifier

        if (
          !state.claims?.clfield[0].dropdown &&
          !state.claims?.clfield[0].identifier
        ) {
          alert("Please fill basic of claim field");
          return {
            ...state,
            canCreate: false,
          };
        }

        let isLineFields = true;
        !state.line?.linefields.map((item) => {
          if (!item.dropdown || _.isEmpty(item.data.Text)) {
            isLineFields = false;
            return;
          } else if (
            (item.captureSplitValueAs === "new field" ||
              item.captureSplitValueAs === "MANDATORY new field") &&
            !item.newFieldValueJson
          ) {
            isLineFields = false;
            return;
          }
          if (item.wrapValuesInto === "new_field" && !item.newFieldNameJson) {
            isLineFields = false;
            return;
          }

          // if (item.valueType === "Select") {
          //   isLineFields = false;
          //   return;
          // }
        });
        if (!isLineFields) {
          alert("Please fill mandatory/basic fields in line field properties");
          return {
            ...state,
            canCreate: false,
          };
        }

        if (!state.line?.linefields[0].dropdown2 === "Baseline") {
          alert("There sould be one baseline line field ");
          return {
            ...state,
            canCreate: false,
          };
        }
      }

      let tempIden = [];
      let tempIden2 = [];
      let negate = [];
      let startIden = [];

      if (!_.isEmpty(state?.template)) {
        if (state?.template?.templatePageIdentification1[0].data)
          state?.template?.templatePageIdentification1.forEach((item) => {
            if (!_.isEmpty(item.data)) tempIden = [...tempIden, item.data];
          });
        if (state?.template?.templatePageIdentification2[0].data)
          state?.template?.templatePageIdentification2.forEach((item) => {
            if (!_.isEmpty(item.data)) tempIden2 = [...tempIden2, item.data];
          });
        if (state?.template?.templatePageIdentification3[0].data)
          state?.template?.templatePageIdentification3.forEach((item) => {
            if (!_.isEmpty(item.data)) startIden = [...startIden, item.data];
          });

        if (state.template.neagate1.length)
          if (state.template.neagate1[0].block)
            state?.template?.neagate1.forEach((item) => {
              if (item.block) negate = [...negate, item.block];
            });
      }

      // using immer
      let templates = [];

      if (tempIden.length) templates = [...templates, tempIden];
      if (tempIden2.length) templates = [...templates, tempIden2];

      return produce(state, (draftState) => {
        draftState.templateJson.payer_template_identification_strings =
          templates;

        draftState.templateJson.start_identification_strings = startIden.length
          ? [startIden]
          : startIden;
        draftState.templateJson.template_negate_strings = negate;
        draftState.checkJson.check_address_fields = ckaddress;
        draftState.checkJson.check_header_fields = ckheader;
        draftState.checkJson.card_address_fields = cardaddress;
        draftState.checkJson.card_header_fields = cardheader;
        draftState.claimJson.claim_header_identification =
          clheadIden.length && clheadIden[0].Text ? clheadIden : [];
        draftState.claimJson.claim_extraction_properties = clextraction;
        draftState.claimJson.claim_header_fields = clheader;
        draftState.claimJson.claim_address_fields = claddress;
        draftState.claimJson.form_fields = clfields;
        draftState.claimJson.line_configuration.line_items_properties =
          lineItemProps;
        draftState.claimJson.line_configuration.field_properties.base =
          baseProps;
        draftState.claimJson.line_configuration.field_properties.above =
          aboveProps;
        draftState.claimJson.line_configuration.field_properties.below =
          belowProps;
        draftState.isSaved = true;
        draftState.canCreate = true;
      });
    }

    case actionTypes.SUBMIT_JSON: {
      const { status } = action.payload;
      return {
        ...state,

        isCreated: true,
        redirectToHome: true,
        status: status === "Created" ? "isCreated" : "Saved",
      };
    }

    case actionTypes.SUBMIT_TYPE: {
      return {
        ...state,
        submitType: action.payload,
      };
    }

    case actionTypes.ADD_FILENAME: {
      return {
        ...state,
        filename: action.payload,
      };
    }

    case actionTypes.PREVIEW_JSON: {
      return {
        ...state,
        previewJson: action.payload,
      };
    }

    case actionTypes.GET_SAVED_DATA: {
      return state;
    }

    case actionTypes.LOADING_UPDATE: {
      return {
        ...state,
        loadingUpdate: action.payload,
      };
    }

    case actionTypes.ERROR: {
      return {
        ...state,
        isError: true,
        isCreated: false,
        isSaved: false,
        status: "Failed",
        save: "Failed",
        error: action.payload?.response?.data,
        statusCode: action.payload.response?.status,
      };
    }

    default: {
      return state;
    }
  }
};
