/* eslint-disable */
import React, { useEffect, useState } from "react";
import Box from "../Box";
import Text from "../Text";
import Down from "../../assets/icons/down.svg";
import Up from "../../assets/icons/up.svg";
import Add from "../../assets/icons/add.svg";
import Cross from "../../assets/icons/cross.svg";
import Minus from "../../assets/icons/minus-red.png";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "@material-ui/core";
import Help from "../../assets/icons/help.png";
import _ from "lodash";
import {
  templateActive,
  templateText,
  identificationText,
  identificationActive,
  negateText,
  negateOpen,
  templateOpen,
  identificationOpen,
  negateOpen1,
  templateOpen1,
  identificationOpen1,
  disableActive,
  deleteFormItem,
  disableActive1,
  disableTemplatePageIdentificationActive,
} from "../../store/Form/actions";
import { saveTemplate } from "../../store/Save/actions";
import axios from "axios";
import env from "../../environment";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { refreshToken, validateApi } from "../../constants";
const Template = (props) => {
  const dispatch = useDispatch();

  // selectors

  const state = useSelector(({ save }) => save?.template);
  const templateIDFromSave = useSelector(({ save }) => save.templateID);
  const nameFromSave = useSelector(({ save }) => save.name);

  const temp1 = useSelector(({ form }) => form?.template1);
  const temp2 = useSelector(({ form }) => form?.template2);
  const temp3 = useSelector(({ form }) => form?.template3);
  const temp4 = useSelector(({ form }) => form?.template4);
  const temp5 = useSelector(({ form }) => form?.template5);
  const temp6 = useSelector(({ form }) => form?.template6);
  const temp7 = useSelector(({ form }) => form?.template7);
  const temp8 = useSelector(({ form }) => form?.template8);
  const temp9 = useSelector(({ form }) => form?.template9);
  const temp10 = useSelector(({ form }) => form?.template10);
  const temp11 = useSelector(({ form }) => form?.template11);
  const temp12 = useSelector(({ form }) => form?.template12);
  const temp13 = useSelector(({ form }) => form?.template13);
  const temp14 = useSelector(({ form }) => form?.template14);
  const temp15 = useSelector(({ form }) => form?.template15);
  const temp16 = useSelector(({ form }) => form?.template16);
  const temp17 = useSelector(({ form }) => form?.template17);
  const temp18 = useSelector(({ form }) => form?.template18);
  const temp19 = useSelector(({ form }) => form?.template19);
  const temp20 = useSelector(({ form }) => form?.template20);
  const temp21 = useSelector(({ form }) => form?.template21);
  const temp22 = useSelector(({ form }) => form?.template22);
  const temp23 = useSelector(({ form }) => form?.template23);

  const tempActive1 = useSelector(({ form }) => form?.templateActive1);
  const tempActive2 = useSelector(({ form }) => form?.templateActive2);
  const tempActive3 = useSelector(({ form }) => form?.templateActive3);
  const tempActive4 = useSelector(({ form }) => form?.templateActive4);
  const tempActive5 = useSelector(({ form }) => form?.templateActive5);
  const tempActive6 = useSelector(({ form }) => form?.templateActive6);
  const tempActive7 = useSelector(({ form }) => form?.templateActive7);
  const tempActive8 = useSelector(({ form }) => form?.templateActive8);
  const tempActive9 = useSelector(({ form }) => form?.templateActive9);
  const tempActive10 = useSelector(({ form }) => form?.templateActive10);
  const tempActive11 = useSelector(({ form }) => form?.templateActive11);
  const tempActive12 = useSelector(({ form }) => form?.templateActive12);
  const tempActive13 = useSelector(({ form }) => form?.templateActive13);
  const tempActive14 = useSelector(({ form }) => form?.templateActive14);
  const tempActive15 = useSelector(({ form }) => form?.templateActive15);
  const tempActive16 = useSelector(({ form }) => form?.templateActive16);
  const tempActive17 = useSelector(({ form }) => form?.templateActive17);
  const tempActive18 = useSelector(({ form }) => form?.templateActive18);
  const tempActive19 = useSelector(({ form }) => form?.templateActive19);
  const tempActive20 = useSelector(({ form }) => form?.templateActive20);
  const tempActive21 = useSelector(({ form }) => form?.templateActive21);
  const tempActive22 = useSelector(({ form }) => form?.templateActive22);
  const tempActive23 = useSelector(({ form }) => form?.templateActive23);

  const tempState = useSelector(({ form }) => form);

  const name = useSelector(({ form }) => form?.name);
  const templateID = useSelector(({ form }) => form?.templateID);

  const [negate, setNegate] = React.useState(false);
  const fromSave = templateIDFromSave && nameFromSave ? true : false; // from update click

  //  state?.negateOpen ? state?.negateOpen :
  const [showPageIdentification, setShowPageIdentification] =
    React.useState(false);
  const [startIdentification, setStartIdentification] = React.useState(false);

  const [newIdenRow, setNewIdenRow] = React.useState(false);
  const [deletedIndex, setDeletedIndex] = React.useState([]);
  const [deletedIndex0, setDeletedIndex0] = React.useState([]);
  const [deletedStart, setDeletedStart] = React.useState([]);
  const [newTempIdenRow, setNewTempIdenRow] = React.useState(false);
  const [newTempNegateRow, setNewTempNegateRow] = React.useState(false);
  const [startIden, setStartIden] = React.useState([
    {
      block: "",
      data: {},
      isAdd: true,
      no: 21,
    },
  ]);

  const [templateNegate1, setTemplateNegate1] = React.useState([
    {
      block: "",
      isAdd: true,
    },
  ]);
  const [templateNegate2, setTemplateNegate2] = React.useState([
    {
      block: "",
      isAdd: true,
    },
  ]);

  const [templateIden1, setTemplateIden1] = React.useState([
    {
      block: "",
      isAdd: true,
      data: {},
      no: 1,
    },
  ]);
  const [templateIden3, setTemplateIden3] = React.useState([
    {
      block: "",
      isAdd: true,
      data: {},
    },
  ]);
  const [templateIden2, setTemplateIden2] = React.useState([
    {
      block: "",
      isAdd: true,
      data: {},
      no: 11,
    },
  ]);
  const [templateResponse, setTemplateResponse] = useState(
    localStorage.getItem("validateConfig") !== null
      ? localStorage.getItem("validateConfig")
      : "rgb(51,83,109)"
  );

  // load initial Data

  React.useEffect(() => {
    if (localStorage.getItem("validateConfig"))
      setTemplateResponse(localStorage.getItem("validateConfig"));
  }, [localStorage.getItem("validateConfig") !== null]);

  React.useEffect(() => {
    if (state) {
      if (state.templatePageIdentification1) {
        const modifiedTemplatePageIdentification1 =
          state.templatePageIdentification1.map((item, i) => ({
            ...item,
            no: i + 1,
          }));
        setTemplateIden1(modifiedTemplatePageIdentification1);

        if (state.templatePageIdentification1.length) {
          if (state.templatePageIdentification1[0].block) {
            setShowPageIdentification(true);
          }
        }
      }
      if (state.templatePageIdentification3) {
        const modifiedTemplatePageIdentification3 =
          state.templatePageIdentification3.map((item, i) => ({
            ...item,
            no: i + 1,
          }));
        setTemplateIden3(modifiedTemplatePageIdentification3);
        if (state.templatePageIdentification3.length) {
          if (state.templatePageIdentification3[0].block) {
            setStartIdentification(true);
          }
        }
      }

      if (state.templatePageIdentification2) {
        const modifiedTemplatePageIdentification2 =
          state.templatePageIdentification2.map((item, i) => ({
            ...item,
            no: i + 1,
          }));
        setTemplateIden2(modifiedTemplatePageIdentification2);
        if (state.templatePageIdentification2.length) {
          if (state.templatePageIdentification2[0].block) {
            setNewTempIdenRow(true);
          }
        }
      }

      if (state.templateRow) setNewIdenRow(state.templateRow);
      if (state.showPageIden) setShowPageIdentification(state.showPageIden);
      if (state.showtemplatePageIdentification)
        setShowPageIdentification(state.showtemplatePageIdentification);
      if (state.neagate1) setTemplateNegate1(state.neagate1);

      if (state.showNegate) setNegate(state.showNegate);
      if (state.templatePageIdentification1)
        setStartIden(state.templatePageIdentification1);

      if (state.showIdentification) {
        setStartIdentification(state.showIdentification);
      }
      if (state.negateRow) setNewTempNegateRow(state.idenOpen);
      if (state.templateRow) setNewTempIdenRow(state.templateRow);
      if (state.newIdenRow) setNewIdenRow(state.newIdenRow);
    }
  }, []);

  React.useEffect(() => {
    const tempIden3 = templateIden3;
    const tempIden1 = templateIden1;
    const tempIden2 = templateIden2;
    const tempIden2Open = newTempIdenRow;
    const neg1 = templateNegate1;
    const neg2 = templateNegate2;
    const negOpen = negate;
    const iden1 = startIden;
    const data = {
      templatePageIdentification1: tempIden1,
      templatePageIdentification2: tempIden2,
      templatePageIdentification3: tempIden3,
      showPageIden: showPageIdentification,
      templateRow: tempIden2Open,
      neagate1: neg1,
      neagate2: neg2,
      showNegate: negOpen,
      negateRow: newTempNegateRow,
      iden1,
      showIdentification: startIdentification,
    };
    dispatch(saveTemplate(data));
  }, [
    templateIden1,
    templateIden2,
    templateIden3,
    newTempIdenRow,
    showPageIdentification,
    templateNegate1,
    templateNegate2,
    negate,
    startIdentification,
  ]);

  const handleTemplateNegate = () => {
    setNegate(!negate);
    dispatch(negateOpen(!negate));
  };

  console.log(templateIden3);

  const addStartIdentificationRow1 = (e) => {
    e.stopPropagation();
    if (templateIden3.length === 3) return;

    const newBlock = {
      block: "",
      isClose: true,
      data: {},
      no: templateIden3.length + 21,
    };

    const vals = templateIden3;
    if (deletedStart.length) {
      const num = deletedStart[0];
      if (num === 22) vals.splice(1, 0, newBlock);
      if (num === 23) vals.splice(2, 0, newBlock);
      setTemplateIden3([...vals]);
      const newDelArray = deletedStart;
      newDelArray.shift();
      setDeletedStart(newDelArray);
    } else setTemplateIden3([...vals, newBlock]);
  };

  const addTemplateIdenRow1 = (e) => {
    e.stopPropagation();
    setTemplateResponse("rgb(51,83,109)");
    props.colorCheck("#ef930d");
    localStorage.setItem("validateConfig", "rgb(51,83,109)");
    if (templateIden1.length === 10) return;

    const newBlock = {
      block: "",
      isClose: true,
      data: {},
      isAdd: false,
      no: templateIden1.length + 1,
    };

    const vals = templateIden1;
    if (deletedIndex0.length) {
      vals.splice(deletedIndex0[0] - 1, 0, newBlock);
      setTemplateIden1([...vals]);
      const newDelArray = deletedIndex0;
      newDelArray.shift();
      setDeletedIndex0(newDelArray);
    } else setTemplateIden1([...vals, newBlock]);
  };

  const addTemplateIdenRow2 = (e) => {
    e.stopPropagation();
    if (templateIden2.length === 10) return;
    setTemplateResponse("rgb(51,83,109)");
    props.colorCheck("#ef930d");
    localStorage.setItem("validateConfig", "rgb(51,83,109)");
    const newBlock = {
      block: "",
      isClose: true,
      data: {},
      isAdd: false,
      no: templateIden2.length + 11,
    };

    const vals = templateIden2;
    if (deletedIndex.length) {
      vals.splice(deletedIndex[0] - 11, 0, newBlock);
      setTemplateIden2([...vals]);
      const newDelArray = deletedIndex;
      newDelArray.shift();
      setDeletedIndex(newDelArray);
    } else setTemplateIden2([...vals, newBlock]);
  };
  const addTemplateNegateRow1 = (e) => {
    e.stopPropagation();
    if (templateNegate1.length === 3) return;

    const newBlock = {
      block: "",
      isClose: true,
    };
    setTemplateNegate1([...templateNegate1, newBlock]);
  };

  const handleDeleteStartIden1 = (index, temp) => {
    if (index === 0) return;
    // const values = [...templateIden3];
    const newTemp = templateIden3.filter(({ no }) => no !== temp.no);

    setTemplateIden3(newTemp);
    setDeletedStart([...deletedStart, temp.no]);
    // values.splice(index, 1);
    // setTemplateIden3(values);
    dispatch(
      templateText({
        name: `template${temp.no}`,
        text: "",
      })
    );
    dispatch(
      templateText({
        name: `templatedata${temp.no}`,
        text: "",
      })
    );
    dispatch(
      templateActive({
        name: `templateActive${temp.no}`,
      })
    );
  };

  const handleDeleteTemplateIden1 = (temp) => {
    //  debugger;
    if (temp.no === 1) return;
    setTemplateResponse("rgb(51,83,109)");
    props.colorCheck("#ef930d");
    localStorage.setItem("validateConfig", "rgb(51,83,109)");

    const newTemp = templateIden1.filter((item) => item.no !== temp.no);

    // setTemplateIden1(values);
    setTemplateIden1(newTemp);
    setDeletedIndex0([...deletedIndex0, temp.no]);

    dispatch(
      templateText({
        name: `template${temp.no}`,
        text: "",
      })
    );
    dispatch(
      templateText({
        name: `templatedata${temp.no}`,
        text: {},
      })
    );

    dispatch(
      templateText({
        name: `templateActive${temp.no}`,
        text: false,
      })
    );
  };

  const handleDeleteTemplateIden2 = (index, temp) => {
    if (index === 0) return;
    setTemplateResponse("rgb(51,83,109)");
    props.colorCheck("#ef930d");
    localStorage.setItem("validateConfig", "rgb(51,83,109)");
    // const values = [...templateIden2];
    const newTemp = templateIden2.filter((item) => item.no !== temp.no);

    // setTemplateIden1(values);
    setTemplateIden2(newTemp);
    setDeletedIndex([...deletedIndex, temp.no]);

    dispatch(
      templateText({
        name: `template${temp.no}`,
        text: "",
      })
    );
    dispatch(
      templateText({
        name: `templatedata${temp.no}`,
        text: {},
      })
    );

    dispatch(
      templateActive({
        name: `templateActive${temp.no}`,
        text: false,
      })
    );
  };

  const handleDeleteTemplateNegate1 = (index) => {
    if (index === 0) return;
    const values = [...templateNegate1];
    values.splice(index, 1);
    setTemplateNegate1(values);
  };

  const handlePageIdentification = () => {
    setShowPageIdentification(!showPageIdentification);
    dispatch(templateOpen(!showPageIdentification));
  };
  const handleStartIdentification = () => {
    setStartIdentification(!startIdentification);
    dispatch(identificationOpen(!startIdentification));
  };

  useEffect(() => {
    if (deletedIndex0.length > 1) {
      const sorted = deletedIndex0.sort((a, b) => a - b);
      setDeletedIndex0(sorted);
    }
    if (deletedIndex.length > 1) {
      const sorted = deletedIndex.sort((a, b) => a - b);
      setDeletedIndex(sorted);
    }
    if (deletedStart.length > 1) {
      const sorted = deletedStart.sort((a, b) => a - b);
      setDeletedStart(sorted);
    }
  }, [deletedIndex0, deletedIndex, deletedStart]);

  React.useEffect(() => {
    if (tempActive1 && temp1) {
      const values = [...templateIden1];
      values[0]["block"] = temp1;
      if (!_.isEmpty(tempState["templatedata1"])) {
        values[0]["data"] = tempState["templatedata1"];
        values[0]["data"]["Text"] = temp1;
        return setTemplateIden1(values);
      }
    }

    if (tempActive2 && temp2) {
      const values = [...templateIden1];
      if (values.length >= 2) {
        values[1].block = temp2;
        if (!_.isEmpty(tempState["templatedata2"])) {
          values[1].data = tempState["templatedata2"];
          values[1].data["Text"] = temp2;
          return setTemplateIden1(values);
        }
      }
    }

    if (tempActive3 && temp3) {
      const values = [...templateIden1];
      if (values.length >= 3) {
        values[2].block = temp3;
        if (!_.isEmpty(tempState["templatedata3"])) {
          values[2].data = tempState["templatedata3"];
          values[2].data["Text"] = temp3;
          setTemplateIden1(values);
        }
      }
    }

    if (temp4 && tempActive4) {
      const values = [...templateIden1];
      if (values.length >= 4) {
        values[3].block = temp4;
        if (!_.isEmpty(tempState["templatedata4"])) {
          values[3].data = tempState["templatedata4"];
          values[3].data["Text"] = temp4;
          setTemplateIden1(values);
        }
      }
    }

    if (tempActive11 && temp11) {
      const values = [...templateIden2];
      values[0].block = temp11;
      if (!_.isEmpty(tempState["templatedata11"])) {
        values[0].data = tempState["templatedata11"];
        values[0].data["Text"] = temp11;
        setTemplateIden2(values);
      }
    }

    if (tempActive12 && temp12) {
      const values = [...templateIden2];
      if (values.length >= 2) {
        values[1].block = temp12;
        if (!_.isEmpty(tempState["templatedata12"])) {
          values[1].data = tempState["templatedata12"];
          values[1].data["Text"] = temp12;
          setTemplateIden2(values);
        }
      }
    }

    if (tempActive13 && temp13) {
      const values = [...templateIden2];
      if (values.length >= 3) {
        values[2].block = temp13;
        if (!_.isEmpty(tempState["templatedata13"])) {
          values[2].data = tempState["templatedata13"];
          values[2].data["Text"] = temp13;
          setTemplateIden2(values);
        }
      }
    }
    if (tempActive14 && temp14) {
      const values = [...templateIden2];
      if (values.length >= 4) {
        values[3].block = temp14;
        if (!_.isEmpty(tempState["templatedata14"])) {
          values[3].data = tempState["templatedata14"];
          values[3].data["Text"] = temp14;
          setTemplateIden2(values);
        }
      }
    }

    if (tempActive15 && temp15) {
      const values = [...templateIden2];
      if (values.length >= 5) {
        values[4].block = temp15;
        if (!_.isEmpty(tempState["templatedata15"])) {
          values[4].data = tempState["templatedata15"];
          values[4].data["Text"] = temp15;
          setTemplateIden2(values);
        }
      }
    }
    if (tempActive16 && temp16) {
      const values = [...templateIden2];
      if (values.length >= 6) {
        values[5].block = temp16;
        if (!_.isEmpty(tempState["templatedata16"])) {
          values[5].data = tempState["templatedata16"];
          values[5].data["Text"] = temp16;
          setTemplateIden2(values);
        }
      }
    }

    if (tempActive5 && temp5) {
      const values = [...templateIden1];
      if (values.length >= 5) {
        values[4].block = temp5;
        if (!_.isEmpty(tempState["templatedata5"])) {
          values[4].data = tempState["templatedata5"];
          values[4].data["Text"] = temp5;
          return setTemplateIden1(values);
        }
      }
    }
    if (tempActive6 && temp6) {
      const values = [...templateIden1];
      if (values.length >= 6) {
        values[5].block = temp6;
        if (!_.isEmpty(tempState["templatedata6"])) {
          values[5].data = tempState["templatedata6"];
          values[5].data["Text"] = temp6;
          return setTemplateIden1(values);
        }
      }
    }

    if (tempActive17 && temp17) {
      const values = [...templateIden2];
      if (values.length >= 7) {
        values[6].block = temp17;
        if (!_.isEmpty(tempState["templatedata17"])) {
          values[6].data = tempState["templatedata17"];
          values[6].data["Text"] = temp17;
          return setTemplateIden2(values);
        }
      }
    }

    if (tempActive18 && temp18) {
      const values = [...templateIden2];
      if (values.length >= 8) {
        values[7].block = temp18;
        if (!_.isEmpty(tempState["templatedata18"])) {
          values[7].data = tempState["templatedata18"];
          values[7].data["Text"] = temp18;
          return setTemplateIden2(values);
        }
      }
    }
    if (tempActive19 && temp19) {
      const values = [...templateIden2];
      if (values.length >= 9) {
        values[8].block = temp19;
        if (!_.isEmpty(tempState["templatedata19"])) {
          values[8].data = tempState["templatedata19"];
          values[8].data["Text"] = temp19;
          return setTemplateIden2(values);
        }
      }
    }
    if (tempActive20 && temp20) {
      const values = [...templateIden2];
      if (values.length >= 10) {
        values[9].block = temp20;
        if (!_.isEmpty(tempState["templatedata20"])) {
          values[9].data = tempState["templatedata20"];
          values[9].data["Text"] = temp20;
          return setTemplateIden2(values);
        }
      }
    }

    if (tempActive21 && temp21) {
      const values = [...templateIden3];
      //if (values.length >= 8) {
      values[0].block = temp21;
      if (!_.isEmpty(tempState["templatedata21"])) {
        values[0].data = tempState["templatedata21"];
        values[0].data["Text"] = temp21;
        return setTemplateIden3(values);
      }
      //}
    }

    if (tempActive22 && temp22) {
      const values = [...templateIden3];
      if (values.length >= 2) {
        values[1].block = temp22;
        if (!_.isEmpty(tempState["templatedata22"])) {
          values[1].data = tempState["templatedata22"];
          values[1].data["Text"] = temp22;
          return setTemplateIden3(values);
        }
      }
    }

    if (tempActive23 && temp23) {
      const values = [...templateIden3];
      if (values.length >= 3) {
        values[2].block = temp23;
        if (!_.isEmpty(tempState["templatedata23"])) {
          values[2].data = tempState["templatedata23"];
          values[2].data["Text"] = temp23;
          return setTemplateIden3(values);
        }
      }
    }

    if (tempActive7 && temp7) {
      const values = [...templateIden1];
      if (values.length >= 7) {
        values[6].block = temp7;
        if (!_.isEmpty(tempState["templatedata7"])) {
          values[6].data = tempState["templatedata7"];
          values[6].data["Text"] = temp7;
          return setTemplateIden1(values);
        }
      }
    }
    if (tempActive8 && temp8) {
      const values = [...templateIden1];
      if (values.length >= 8) {
        values[7].block = temp8;
        if (!_.isEmpty(tempState["templatedata8"])) {
          values[7].data = tempState["templatedata8"];
          values[7].data["Text"] = temp8;
          return setTemplateIden1(values);
        }
      }
    }
    if (tempActive9 && temp9) {
      const values = [...templateIden1];
      if (values.length >= 9) {
        values[8].block = temp9;
        if (!_.isEmpty(tempState["templatedata9"])) {
          values[8].data = tempState["templatedata9"];
          values[8].data["Text"] = temp9;
          return setTemplateIden1(values);
        }
      }
    }
    if (tempActive10 && temp10) {
      const values = [...templateIden1];
      if (values.length >= 10) {
        values[9].block = temp10;
        if (!_.isEmpty(tempState["templatedata10"])) {
          values[9].data = tempState["templatedata10"];
          values[9].data["Text"] = temp10;
          return setTemplateIden1(values);
        }
      }
    }
  }, [
    temp1,
    temp2,
    temp3,
    temp4,
    temp5,
    temp6,
    temp7,
    temp8,
    temp9,
    temp10,
    temp11,
    temp12,
    temp13,
    temp14,
    temp15,
    temp16,
    temp17,
    temp18,
    temp19,
    temp20,
    temp21,
    temp22,
    temp23,
    tempActive1,
    tempActive2,
    tempActive3,
    tempActive4,
    tempActive5,
    tempActive6,
    tempActive7,
    tempActive8,
    tempActive9,
    tempActive10,
    tempActive11,
    tempActive12,
    tempActive13,
    tempActive14,
    tempActive15,
    tempActive16,
    tempActive17,
    tempActive18,
    tempActive19,
    tempActive20,
    tempActive21,
    tempActive22,
    tempActive23,
    tempState,
  ]);

  React.useEffect(() => {
    if (templateResponse === "green") {
      props.colorCheck("green");
      localStorage.setItem("validateConfig", "green");

      return;
    }
  }, []);

  const handleTemplateStringValidate = async (data) => {
    try {
      const auth = {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem("accessToken")
        )}`,
      };
      const checkDropdown = await axios.post(
        `${env.BASE_URL}validateidentification`,
        data,
        { headers: auth }
      );
      if (checkDropdown.data === "Validation Successful") {
        setTemplateResponse("green 0% 0% no-repeat padding-box");
        localStorage.setItem("validateConfig", "green");
      } else {
        setTemplateResponse("#ef930d 0% 0% no-repeat padding-box");
        localStorage.setItem("validateConfig", "#ef930d");
      }

      props.colorCheck(
        checkDropdown.data === "Validation Successful" ? "green" : "#ef930d"
      );
      localStorage.setItem(
        "validateConfig",
        checkDropdown.data === "Validation Successful" ? "green" : "#ef930d"
      );
    } catch (error) {
      props.colorCheck("#ef930d");
      setTemplateResponse("#ef930d 0% 0% no-repeat padding-box");
      localStorage.setItem("validateConfig", "#ef930d");
      if (error?.request?.status === 403) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(error.request.response),
          type: "error",
          confirmButtonColor: "rgb(51, 83, 109)",
        });
      } else if (error?.response?.status === 401) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Session Expired!",
          type: "error",
          confirmButtonColor: "#54545E",
        }).then(() => {
          localStorage.clear();
          props.history.push("/signin");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          type: "error",
          confirmButtonColor: "rgb(51, 83, 109)",
        });
      }
    }
  };

  const handleTemplateValidate = async () => {
    let temp1 = templateIden1?.map((item) => {
      try {
        let Datalength = Object.keys(item?.block).length;
        return Datalength;
      } catch {
        console.log(item);
      }
    });
    let a = templateIden1?.length;

    let count = 0;
    temp1.map((item) => {
      if (item === 0) {
        count++;
      }
    });

    let temp2 = templateIden2?.map((item) => {
      try {
        let Datalength2 = Object.keys(item?.block).length;
        return Datalength2;
      } catch {
        console.log(item);
      }
    });
    let b = templateIden2?.length;
    console.log(a);
    let count2 = 0;
    temp2.map((item) => {
      if (item === 0) {
        count2++;
      }
    });
    if (a === count && b == count2) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select Atleast one block to validate",
        type: "error",
        confirmButtonColor: "rgb(51, 83, 109)",
      });
    } else {
      let payerData1 = templateIden1?.map((item) => item?.data);
      let payerData2 = templateIden2?.map((item) => item?.data);
      let totalData;
      let data2 = payerData2.map((item) => item.Text);
      if (data2[0] === undefined) {
        totalData = [[...payerData1]];
      } else {
        var newArray = payerData2.filter(
          (obj) => !(obj && Object.keys(obj).length === 0)
        );

        totalData = [[...payerData1], [...newArray]];
      }

      let negateData = templateNegate1?.map((item) => item?.block);
      let data = {};
      let newNegateData = negateData.filter((item) => item.length > 0);
      if (newNegateData.length > 0) {
        data = {
          templateId: templateIDFromSave ? templateIDFromSave : templateID,
          payer_template_identification_strings: totalData,
          template_negate_strings: newNegateData,
        };
      } else {
        data = {
          templateId: templateIDFromSave ? templateIDFromSave : templateID,
          payer_template_identification_strings: totalData,
        };
      }
      validateApi().then((valid) => {
        if (!valid) {
          refreshToken().then(() => {
            handleTemplateStringValidate(data);
          });
        } else {
          handleTemplateStringValidate(data);
        }
      });
    }
  };

  const handleResetTemplate = (e) => {
    e.stopPropagation();
    const newTemp1 = [
      {
        block: "",
        isAdd: true,
        data: {},
        no: 1,
      },
    ];
    const newTemp2 = [
      {
        block: "",
        isAdd: true,
        data: {},
        no: 1,
      },
    ];
    setTemplateIden1(newTemp1);
    setTemplateIden2(newTemp2);
    // first block
    for (let i = 1; i <= 10; i++) {
      dispatch(
        templateText({
          name: `template${i}`,
          text: "",
        })
      );
      dispatch(
        templateText({
          name: `templatedata${i}`,
          text: {},
        })
      );
      dispatch(
        templateText({
          name: `templateActive${i}`,
          text: false,
        })
      );
    }
    for (let i = 11; i <= 20; i++) {
      dispatch(
        templateText({
          name: `template${i}`,
          text: "",
        })
      );
      dispatch(
        templateText({
          name: `templatedata${i}`,
          text: {},
        })
      );
      dispatch(
        templateText({
          name: `templateActive${i}`,
          text: false,
        })
      );
    }
    // for (let i = 10; i <= 16; i++) {
    //   dispatch(
    //     templateText({
    //       name: `template${i}`,
    //       text: "",
    //     })
    //   );
    //   dispatch(
    //     templateText({
    //       name: `templatedata${i}`,
    //       text: {},
    //     })
    //   );
    //   dispatch(
    //     templateText({
    //       name: `templateActive${i}`,
    //       text: false,
    //     })
    //   );
    // }
    // // second block
    // for (let i = 4; i <= 6; i++) {
    //   dispatch(
    //     templateText({
    //       name: `template${i}`,
    //       text: "",
    //     })
    //   );
    //   dispatch(
    //     templateText({
    //       name: `templatedata${i}`,
    //       text: {},
    //     })
    //   );
    //   dispatch(
    //     templateText({
    //       name: `templateActive${i}`,
    //       text: false,
    //     })
    //   );
    // }
    // for (let i = 17; i <= 23; i++) {
    //   dispatch(
    //     templateText({
    //       name: `template${i}`,
    //       text: "",
    //     })
    //   );
    //   dispatch(
    //     templateText({
    //       name: `templatedata${i}`,
    //       text: {},
    //     })
    //   );
    //   dispatch(
    //     templateText({
    //       name: `templateActive${i}`,
    //       text: false,
    //     })
    //   );
    // }
    setDeletedIndex([]);
    setDeletedIndex0([]);
  };
  const handleResetNegate = (e) => {
    e.stopPropagation();
    const newNeg = [
      {
        block: "",
        isAdd: true,
      },
    ];
    setTemplateNegate1(newNeg);
    for (let i = 1; i <= 3; i++)
      dispatch(
        negateText({
          name: `negateText${i}`,
          text: "",
        })
      );
    setDeletedIndex0([]);
    setDeletedIndex([]);
  };
  const handleResetStart = (e) => {
    e.stopPropagation();
    const newStart = [
      {
        block: "",
        isAdd: true,
        data: {},
        no: 21,
      },
    ];
    setTemplateIden3(newStart);

    for (let i = 21; i <= 23; i++) {
      dispatch(
        templateText({
          name: `template${i}`,
          text: "",
        })
      );
      dispatch(
        templateText({
          name: `templatedata${i}`,
          text: {},
        })
      );
      dispatch(
        templateActive({
          name: `templatedata${i}`,
          text: false,
        })
      );
    }
    setDeletedStart([]);
  };

  return (
    <Box
      h="600px"
      // w="600px"
      margin="0px auto"
      // position="absolute"
      left="130px"
      top="400px"
    >
      <Box maxH="608px" overflowX="hidden" className="inner">
        <Box display="flex" justifyContent="space-between" margin="10px 20px">
          <Box display="flex" flexDirection="column">
            <Text fontWeight={"bolder"} margin="0" textColor="#57565B">
              Template ID
            </Text>
            <Box
              // /h="38px"
              w="200px"
              marginTop="5px"
              padding="10px"
              background="#D3D3D3"
              borderRadius="6px"
            >
              <Text fontWeight={"bolder"} textColor="#7D7C81" margin="-2px">
                {fromSave ? templateIDFromSave : templateID}
              </Text>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <Text fontWeight={"bolder"} margin="0" textColor="#57565B">
              Payer Name
            </Text>
            <Box
              // /h="38px"
              marginTop="5px"
              w="200px"
              padding="10px"
              background="#D3D3D3"
              borderRadius="6px"
            >
              <Text fontWeight={"bolder"} textColor="#7D7C81" margin="-2px">
                {fromSave ? nameFromSave : name}
              </Text>
            </Box>
          </Box>
        </Box>
        {/* page identification */}
        <center>
          <Box
            cursor="pointer"
            b="1px solid gray"
            h="37px"
            w="115px"
            textAlign="center"
            bl="none"
            br="1px solid black"
            bt="none"
            background={templateResponse}
            opacity="1"
            padding="1px"
            onClick={() => handleTemplateValidate()}
          >
            <Text
              margin="8px"
              textColor={
                [templateResponse === "green"]
                  ? "white"
                  : templateResponse === "#ef930d"
                  ? "white"
                  : "rgb(51, 83, 109)"
              }
              fontWeight="bold"
            >
              VALIDATE
            </Text>
          </Box>
        </center>

        <Box
          h="auto"
          margin="26px 20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="10px"
        >
          <Box padding="9px" cursor="pointer">
            <Box
              display="flex"
              justifyContent="space-between"
              onClick={handlePageIdentification}
            >
              <Box display="flex">
                <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                  Template Page Identification:{" "}
                  <span style={{ color: "red", fontSize: 20 }}>*</span>
                </Text>
                <Tooltip
                  interactive
                  title={
                    <a
                      href="https://sway.office.com/0NEUFrxvJqrP46u1?ref=Link"
                      target="_blank"
                      style={{ color: "white" }}
                    >
                      Select the block of the payer’s name and the blocks that
                      are broadly unique to this template (Click for more
                      information)
                    </a>
                  }
                  placement="right"
                >
                  <img
                    src={Help}
                    style={{ height: 12, marginTop: 8, marginLeft: 10 }}
                    alt="tooltip"
                  />
                </Tooltip>
              </Box>

              <Box display="flex">
                {showPageIdentification && (
                  <Box marginRight="10px">
                    <button onClick={handleResetTemplate}>Reset</button>
                  </Box>
                )}
                <img
                  src={showPageIdentification ? Up : Down}
                  style={{ cursor: "pointer",height:"7px",width:"15px",margin: "8px 8px 8px 8px" }}
                />
              </Box>
            </Box>

            {showPageIdentification && (
              <>
                <Box display="flex" alignItems="baseline">
                  <img
                    src={Add}
                    style={{ marginRight: 10, cursor: "cursor" }}
                    onClick={() => {
                      setTemplateResponse("rgb(51,83,109)");
                      props.colorCheck("#ef930d");
                      localStorage.setItem("validateConfig", "rgb(51,83,109)");
                      // if (templateIden1.length < 3) return;
                      setNewTempIdenRow(true);
                      dispatch(templateOpen1(true));
                    }}
                  />
                  <Box
                    background="white"
                    b="1px solid black"
                    h="auto"
                    padding="6px"
                    margin="10px 0px"
                    w="456px"
                  >
                    {templateIden1.map((temp, index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        key={index}
                        margin="10px 0"
                      >
                        <Tooltip title={temp?.block}>
                          <input
                            id={`templateIden1st${index}`}
                            onChange={(e) => {
                              const values = [...templateIden1];
                              if (values[index].block.length <= 0) {
                                alert(
                                  "please select value from image to proceed"
                                );
                                e.preventDefault();
                              } else {
                                setTemplateResponse("rgb(51,83,109)");
                                props.colorCheck("#ef930d");
                                localStorage.setItem(
                                  "validateConfig",
                                  "rgb(51,83,109)"
                                );
                                values[index].block = e.target.value;
                                if (!e.target.value) {
                                  values[index].data = {};
                                } else {
                                  values[index].data["Text"] = e.target.value;
                                }
                                setTemplateIden1(values);
                                dispatch(
                                  templateText({
                                    name: `template${index + 1}`,

                                    text: e.target.value,
                                  })
                                );
                              }
                            }}
                            onClick={() => {
                              setTemplateResponse("rgb(51,83,109)");
                              props.colorCheck("#ef930d");
                              localStorage.setItem(
                                "validateConfig",
                                "rgb(51,83,109)"
                              );

                              dispatch(
                                templateActive({
                                  name: `templateActive${index + 1}`,
                                })
                              );
                            }}
                            value={temp?.block}
                            type="text"
                            placeholder="Select the block in the image"
                            style={{
                              width: 400,
                              marginLeft: 10,
                              fontStyle: "italic",
                              padding: 4,
                            }}
                          />
                        </Tooltip>
                        {temp.isAdd && (
                          <img
                            src={Add}
                            style={{
                              marginLeft: 10,
                              cursor: "cursor",
                              height: 17,
                            }}
                            onClick={addTemplateIdenRow1}
                          />
                        )}
                        {temp.isClose && (
                          <img
                            src={Minus}
                            onClick={() => handleDeleteTemplateIden1(temp)}
                            style={{
                              marginLeft: 10,
                              cursor: "cursor",
                              height: 17,
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>

                {newTempIdenRow && (
                  <Box display="flex" alignItems="baseline">
                    <img
                      src={Cross}
                      style={{ marginRight: 10, cursor: "cursor" }}
                      onClick={() => {
                        console.log("hre");
                        setTemplateResponse("rgb(51,83,109)");
                        props.colorCheck("#ef930d");
                        localStorage.setItem(
                          "validateConfig",
                          "rgb(51,83,109)"
                        );
                        setNewTempIdenRow(false);
                        setTemplateIden2([
                          {
                            block: "",
                            isAdd: true,
                            data: {},
                            no: 11,
                          },
                        ]);

                        for (let i = 11; i <= 20; i++) {
                          dispatch(
                            templateActive({
                              name: `templateActive${i}`,
                              text: false,
                            })
                          );
                          dispatch(
                            templateText({
                              name: `template${i}`,
                              text: "",
                            })
                          );
                          dispatch(
                            templateText({
                              name: `templatedata${i}`,
                              text: {},
                            })
                          );
                        }
                      }}
                    />
                    <Box
                      background="white"
                      b="1px solid black"
                      h="auto"
                      padding="6px"
                      margin="10px 0px"
                      w="456px"
                    >
                      {templateIden2.map((temp, index) => (
                        <Box
                          display="flex"
                          alignItems="center"
                          key={index}
                          margin="10px 0"
                        >
                          <Tooltip title={temp?.block}>
                            <input
                              id={`templateIden2nd${index}`}
                              onClick={() => {
                                //
                                setTemplateResponse("rgb(51,83,109)");
                                props.colorCheck("#ef930d");
                                localStorage.setItem(
                                  "validateConfig",
                                  "rgb(51,83,109)"
                                );

                                dispatch(
                                  templateActive({
                                    name: `templateActive${index + 11}`,
                                  })
                                );
                              }}
                              onChange={(e) => {
                                const values = [...templateIden2];
                                if (values[index].block.length <= 0) {
                                  alert(
                                    "please select value from image to proceed"
                                  );
                                  e.preventDefault();
                                } else {
                                  setTemplateResponse("rgb(51,83,109)");
                                  props.colorCheck("#ef930d");
                                  localStorage.setItem(
                                    "validateConfig",
                                    "rgb(51,83,109)"
                                  );
                                  values[index].block = e.target.value;
                                  if (!e.target.value) {
                                    values[index].data = {};
                                  } else {
                                    values[index].data["Text"] = e.target.value;
                                    setTemplateIden2(values);
                                    dispatch(
                                      templateText({
                                        name: `template${index + 11}`,
                                        text: e.target.value,
                                      })
                                    );
                                  }
                                }
                              }}
                              placeholder="Select the block in the image"
                              value={temp?.block}
                              type="text"
                              style={{
                                width: 400,
                                marginLeft: 10,
                                fontStyle: "italic",
                                padding: 4,
                              }}
                            />
                          </Tooltip>
                          {temp.isAdd && (
                            <img
                              src={Add}
                              style={{
                                marginLeft: 10,
                                cursor: "cursor",
                                height: 17,
                              }}
                              onClick={(e) => addTemplateIdenRow2(e, index)}
                            />
                          )}
                          {temp.isClose && (
                            <img
                              src={Minus}
                              onClick={() =>
                                handleDeleteTemplateIden2(index, temp)
                              }
                              style={{
                                marginLeft: 10,
                                cursor: "cursor",
                                height: 17,
                              }}
                            />
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>

        {/* Template Negate */}
        <Box
          h="auto"
          margin="26px 20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="10px"
        >
          <Box margin="10px" padding="10px" cursor="pointer">
            <Box
              display="flex"
              justifyContent="space-between"
              onClick={handleTemplateNegate}
            >
              <Box display="flex">
                <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                  Template Negate Text:{" "}
                  <span style={{ color: "#909090", fontStyle: "italic" }}>
                    (optional)
                  </span>
                </Text>
                <Tooltip
                  interactive
                  title={
                    <a
                      href="https://sway.office.com/0NEUFrxvJqrP46u1?ref=Link"
                      target="_blank"
                      style={{ color: "white" }}
                    >
                      The blocks selected in this field will be searched for in
                      the sample and if these strings are found in the sample,
                      this payer will be negated. (Click for more information)
                    </a>
                  }
                  placement="right"
                >
                  <img
                    src={Help}
                    style={{ height: 12, marginLeft: 10, marginTop: 3 }}
                    alt="tooltip"
                  />
                </Tooltip>
              </Box>
              <Box display="flex">
                {negate && (
                  <Box marginRight="10px">
                    <button onClick={handleResetNegate}>Reset</button>
                  </Box>
                )}

                <img src={negate ? Up : Down} style={{ cursor: "pointer",height:"7px",width:"15px",marginTtop: "6px"  }} />
              </Box>
            </Box>
            {negate && (
              <>
                <Box display="flex" alignItems="baseline">
                  <Box
                    background="white"
                    b="1px solid black"
                    h="auto"
                    padding="6px"
                    margin="10px 0px"
                    w="456px"
                    marginLeft="40px"
                    style={{ marginLeft: 40 }}
                  >
                    {templateNegate1.map((temp, index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        margin="10px 0"
                        key={index}
                      >
                        <Tooltip title={temp?.block}>
                          <input
                            onClick={() => {
                              dispatch(disableActive());
                            }}
                            id={`TemplateNegateText${index}`}
                            onChange={(e) => {
                              setTemplateResponse("rgb(51,83,109)");
                              props.colorCheck("#ef930d");
                              localStorage.setItem(
                                "validateConfig",
                                "rgb(51,83,109)"
                              );
                              const val = e.target.value;
                              const n = templateNegate1;
                              const values = [...n];
                              values[index].block = val;

                              setTemplateNegate1(values);
                              dispatch(
                                negateText({
                                  name: `negateText${index + 1}`,
                                  text: e.target.value,
                                })
                              );
                            }}
                            placeholder="Enter negate text"
                            value={temp?.block}
                            type="text"
                            style={{
                              width: 400,
                              marginLeft: 10,
                              fontStyle: "italic",
                              padding: 4,
                            }}
                          />
                        </Tooltip>
                        {temp.isAdd && (
                          <img
                            src={Add}
                            style={{
                              marginLeft: 10,
                              cursor: "cursor",
                              height: 17,
                            }}
                            onClick={addTemplateNegateRow1}
                          />
                        )}
                        {temp.isClose && (
                          <img
                            src={Minus}
                            onClick={() => handleDeleteTemplateNegate1(index)}
                            style={{
                              marginLeft: 10,
                              cursor: "cursor",
                              height: 17,
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>

        {/* start identification */}
        <Box
          margin="10px 20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="10px"
        >
          <Box margin="10px" padding="10px" cursor="pointer">
            <Box
              display="flex"
              justifyContent="space-between"
              onClick={handleStartIdentification}
            >
              <Box display="flex">
                <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                  Start Identification:{" "}
                  <span style={{ color: "#909090", fontStyle: "italic" }}>
                    (optional)
                  </span>
                </Text>
                <Tooltip
                  interactive
                  title={
                    <a
                      href="https://sway.office.com/0NEUFrxvJqrP46u1?ref=Link"
                      target="_blank"
                      style={{ color: "white" }}
                    >
                      Start identification block is used to separate the files
                      when there are multiple EOBs within a single file. Click
                      for more information
                    </a>
                  }
                  placement="right"
                >
                  <img
                    src={Help}
                    style={{ height: 12, marginLeft: 10, marginTop: 4 }}
                    alt="tooltip"
                  />
                </Tooltip>
              </Box>
              <Box display="flex">
                {startIdentification && (
                  <Box marginRight="10px">
                    <button onClick={handleResetStart}>Reset</button>
                  </Box>
                )}
                <img
                  src={startIdentification ? Up : Down}
                  style={{ cursor: "pointer",height:"7px",width:"15px",marginTop: "6px"  }}
                />
              </Box>
            </Box>
            {startIdentification && (
              <>
                <Box display="flex" alignItems="baseline">
                  <Box
                    background="white"
                    b="1px solid black"
                    h="auto"
                    padding="6px"
                    margin="10px 0px"
                    w="456px"
                    style={{ marginLeft: 40 }}
                  >
                    {templateIden3.map((start, index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        key={index}
                        margin="10px 0"
                      >
                        <Tooltip title={start?.block}>
                          <input
                            id={`startIdentification${index}`}
                            onClick={() => {
                              dispatch(
                                templateActive({
                                  name: `templateActive${index + 21}`,
                                })
                              );
                            }}
                            value={start?.block}
                            onChange={(e) => {
                              const values = [...templateIden3];
                              if (values[index].block.length <= 0) {
                                alert(
                                  "please select value from image to proceed"
                                );
                                e.preventDefault();
                              } else {
                                values[index].block = e.target.value;
                                if (!e.target.value) {
                                  values[index].data = {};
                                } else {
                                  values[index].data["Text"] = e.target.value;
                                }

                                setStartIden(values);
                                dispatch(
                                  templateText({
                                    name: `template${index + 21}`,
                                    text: e.target.value,
                                  })
                                );
                              }
                            }}
                            type="text"
                            placeholder="Select the block in the image"
                            style={{
                              width: 400,
                              marginLeft: 10,
                              fontStyle: "italic",
                              padding: 4,
                            }}
                          />
                        </Tooltip>
                        {start?.isAdd && (
                          <img
                            src={Add}
                            onClick={addStartIdentificationRow1}
                            style={{
                              marginLeft: 10,
                              cursor: "cursor",
                              height: 17,
                            }}
                          />
                        )}

                        {start.isClose && (
                          <img
                            src={Minus}
                            onClick={() => handleDeleteStartIden1(index, start)}
                            style={{
                              marginLeft: 10,
                              cursor: "cursor",
                              height: 17,
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(Template);
