/* eslint-disable  */
import React, { useEffect } from "react";
import Box from "../Box";
import Text from "../Text";
import Down from "../../assets/icons/down.svg";
import Up from "../../assets/icons/up.svg";
import Add from "../../assets/icons/add.svg";
import { Tooltip } from "@material-ui/core";
import Help from "../../assets/icons/help.png";
import { labelColor, labelFont } from "../../utils";
import useState from "react-usestateref";
import Cross from "../../assets/icons/cross.svg";
import { useSelector, useDispatch } from "react-redux";
import Minus from "../../assets/icons/minus-red.png";
import {
  lineItemPropertiesActive,
  lineFieldPropertiesActive,
  lineFieldPropertiesText,
  lineItemPropertiesText,
  disableActive,
  above,
  below,
  getDropDown,
  saveDropDown,
  disableLineItemActive,
} from "../../store/Form/actions";
import "./Claim.css";
import {
  addExtraBlock,
  deleteExtraBlock,
  lineFieldOpen,
  lineItemOpen,
  saveLine,
  saveLineField,
  saveLineItem,
  selectedLineFields,
  selectedAlsoAddAsFields,
} from "../../store/Save/actions";
import { SelectPicker } from "rsuite";
import { withRouter } from "react-router-dom";
import { dateDictionary, valueTypeRegexData } from "../../constants";
import { type } from "jquery";

const Line = (props) => {
  const dispatch = useDispatch();

  // gettting dropdowns
  const lineFieldsDropdown = useSelector(({ form }) => form?.lineFields);
  const alsoAddAsDropdown = useSelector(({ form }) => form?.alsoAddAs);

  const lineItemProperties = useSelector(({ save }) => save.line.lineitem);
  const selectedFieldOptions = useSelector(
    ({ save }) => save.line.selectedLineFields
  );
  const selectedAlsoAddOptions = useSelector(
    ({ save }) => save.line.selectedAlsoAddAsFields
  );

  const fieldProperties = useSelector(({ save }) => save.line.linefields);

  const [showConflictPatternDate, setShowConflictPatternDate] = useState(false);
  const [showConflictPatternRegex, setShowConflictPatternRegex] =
    useState(false);
  const [showConflictPatternPcode, setShowConflictPatternPcode] =
    useState(false);
  const [showConflictPatternIdentifier, setShowConflictPatternIdentifier] =
    useState(false);
  const [lineFieldsError, setLineFieldsError] = useState([false]);

  console.log("fp", fieldProperties[0]);

  useEffect(() => {
    if (lineItemProperties[0].lineItemIdentifierType === "pcode") {
      setShowPcodeInput(true);
      setShowRegexInput(false);
      setShowDateInput(false);
    }

    if (lineItemProperties[0].conflictPattern === "pcode") {
      setShowConflictPatternPcode(true);
      setShowConflictPatternRegex(false);
      setShowConflictPatternDate(false);
      setShowConflictPatternIdentifier(false);
    }
    if (lineItemProperties[0].conflictPattern === "date") {
      setShowConflictPatternPcode(false);
      setShowConflictPatternRegex(false);
      setShowConflictPatternDate(true);
      setShowConflictPatternIdentifier(false);
    }

    if (lineItemProperties[0].conflictPattern === "identifier") {
      setShowConflictPatternPcode(false);
      setShowConflictPatternRegex(false);
      setShowConflictPatternDate(false);
      setShowConflictPatternIdentifier(true);
    }

    if (lineItemProperties[0].conflictPattern === "regex") {
      setShowConflictPatternPcode(false);
      setShowConflictPatternRegex(true);
      setShowConflictPatternDate(false);
      setShowConflictPatternIdentifier(false);
    }

    if (lineItemProperties[0].conflictPattern === "amount($)") {
      setShowConflictPatternPcode(false);
      setShowConflictPatternRegex(false);
      setShowConflictPatternDate(false);
      setShowConflictPatternIdentifier(false);
    }

    if (lineItemProperties[0].conflictPattern === "") {
      setShowConflictPatternPcode(false);
      setShowConflictPatternRegex(false);
      setShowConflictPatternDate(false);
      setShowConflictPatternIdentifier(false);
    }

    if (lineItemProperties[0].lineItemIdentifierType === "date") {
      setShowPcodeInput(false);
      setShowRegexInput(false);
      setShowDateInput(true);
    }
    if (lineItemProperties[0].lineItemIdentifierType === "regex") {
      setShowPcodeInput(false);
      setShowRegexInput(true);
      setShowDateInput(false);
    }

    if (fieldProperties.length && fieldProperties[0].dropdown) {
      dispatch(lineFieldOpen(true));
      const nlf = fieldProperties;
      nlf[0].isAdd = false;
      nlf[0].isClose = false;
      nlf[0].isReset = true;
      dispatch(saveLineField(nlf));
    }
  }, [lineItemProperties, fieldProperties]);

  const showLineItemProperties = useSelector(
    ({ save }) => save.line.lineItemOpen
  );

  const showFieldProperties = useSelector(
    ({ save }) => save.line.lineFieldOpen
  );

  const state = useSelector(({ form }) => form);
  const lineStartsWith = useSelector(({ form }) => form?.lineStartsWith1);
  const lineItemIdentifier1 = useSelector(
    ({ form }) => form?.lineItemIdentifier1
  );
  const conflictPattern = useSelector(({ form }) => form?.conflictPattern1);
  const conflictPatternIdentifier = useSelector(
    ({ form }) => form?.conflictPatternIdentifier1
  );
  const firstDollarBlock = useSelector(({ form }) => form?.firstDollarBlock1);

  const abovelineStartBlock1 = useSelector(
    ({ form }) => form?.aboveLineStartBlock1
  );
  const abovelineStartBlockActive1 = useSelector(
    ({ form }) => form?.aboveLineStartBlockActive1
  );
  const abovelineDollarValueBlock1 = useSelector(
    ({ form }) => form?.aboveLineDollarValue1
  );
  const abovelineDollarValueBlockActive1 = useSelector(
    ({ form }) => form?.aboveLineDollarValueActive1
  );

  const belowlineStartBlock1 = useSelector(
    ({ form }) => form?.belowLineStartBlock1
  );
  const belowlineStartBlockActive1 = useSelector(
    ({ form }) => form?.belowLineStartBlockActive1
  );
  const belowlineDollarValueBlock1 = useSelector(
    ({ form }) => form?.belowLineDollarValueBlock1
  );
  const belowlineDollarValueBlockActive1 = useSelector(
    ({ form }) => form?.belowLineDollarValueBlockActive1
  );
  const lineStartsWithActive = useSelector(
    ({ form }) => form?.lineStartsWithActive1
  );
  const firstDollarBlockActive = useSelector(
    ({ form }) => form?.firstDollarBlockActive1
  );

  const [showNameType, setShowNameType] = React.useState(false);
  const [showNewFieldValue, setShowNewFieldValue] = React.useState(0);
  const [showSplitLength, setShowSplitLength] = React.useState(false);
  const [separatorBasedSplit, setSeparatorBasedSplit] = React.useState("true");
  const [isBelow, setIsBelow] = React.useState(false);
  const [showPcodeInput, setShowPcodeInput] = React.useState(true);
  const [showRegexInput, setShowRegexInput] = React.useState(false);
  const [showDateInput, setShowDateInput] = React.useState(false);
  const [matchingPatternPcode, setMatchingPatternPcode] = React.useState(false);
  const [matchingPatternDoolarAmount, setmatchingPatternDoolarAmount] =
    React.useState(false);
  const [matchingPatternDate, setMatchingPatternDate] = React.useState(true);
  const [matchingPatternRegex, setMatchingPatternRegex] = React.useState(false);
  const [conflictPatternPcode, setConflictPatternPcode] = React.useState(false);
  const [conflictPatternDoolarAmount, setConflictPatternDoolarAmount] =
    React.useState(false);
  const [conflictPatternDate, setConflictPatternDate] = React.useState(true);
  const [conflictPatternRegex, setConflictPatternRegex] = React.useState(false);
  const [belowConflictPatternPcode, setBelowConflictPatternPcode] =
    React.useState(false);
  const [
    belowconflictPatternDoolarAmount,
    setBelowConflictPatternDoolarAmount,
  ] = React.useState(false);
  const [belowconflictPatternDate, setBelowConflictPatternDate] =
    React.useState(true);
  const [belowconflictPatternRegex, setBelowConflictPatternRegex] =
    React.useState(false);

  const [fieldProperties1, setFieldProperties] = useState([
    {
      dropdown1: "",
      splitLength: 1,
      joinAs: "text",
      dropdown2: "Baseline",
      data: {},
      data1: {},
      data2: {},
      showBlock1: false,
      showBlock2: false,
      mandatory: false,
      fieldExtractor: true,
      formBasedExtraction: false,
      blockExtractor: false,
      identifier: "",
      block: "",
      json: "",
      position: "",
      extractType: "form",
      isOptional: false,
      valueType: "ANY",
      blockType: "text",
      valueBlock: "",
      isAdvanced: false,
      isWrappableFilled: false,
      wrapFrom: "below",
      indexRange1: 0,
      indexRange2: "",
      idlimit: 1,
      noOfBlocks: 1,
      isMultiValue: false,
      wrapValuesInto: "text",
      isSplittable: false,
      captureSplitValueAs: "append to next field",
      newFieldValue: "Claim Id (E1)",
      newFieldValueJson: "claim_id",
      newFieldName: "Claim Id (E1)",
      newFieldNameJson: "claim_id",
      separatorBasedSplit: true,
      alsoAddAs: "",
    },
  ]);

  const [fieldPropertiesOptions, setFieldPropertiesOptions] = React.useState(
    []
  );

  const [alsoAddAsOptions, setAlsoAddAsOptions] = React.useState([]);
  const [fieldOptionsDisabled, setFieldOptionsDisabled] = React.useState([]);

  const [alsoAddAsOptionsDisabled, setAlsoAddAsOptionsDisabled] =
    React.useState([]);
  const [isAbove, setIsAbove] = React.useState(false);

  const [isBase, setIsBase] = React.useState(false);
  const [belowMatchingDate, setBelowMatchingDate] = React.useState(true);
  const [belowMatchingDollarAmount, setBelowMatchingDollarAmount] =
    React.useState(false);
  const [belowMatchingPcode, setBelowMatchingPcode] = React.useState(false);
  const [belowMatchingRegex, setBelowMatchingRegex] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);

  const addBlock = (index) => {
    const val = [...fieldProperties];
    if (!val[index].showBlock1) val[index].showBlock1 = true;
    else val[index].showBlock2 = true;
    dispatch(addExtraBlock(val));
  };

  const deleteBlock = (index, block) => {
    const vals = [...fieldProperties];
    if (block === "showIndex1") {
      vals[index].showBlock1 = false;
      vals[index].block1 = "";
      vals[index].data1 = {};
    } else {
      vals[index].showBlock2 = false;
      vals[index].block2 = "";
      vals[index].data2 = {};
    }
    dispatch(deleteExtraBlock(vals));
  };

  const handleShowLineItemProperties = () =>
    dispatch(lineItemOpen(!showLineItemProperties));

  const handleShowFieldProperties = () =>
    dispatch(lineFieldOpen(!showFieldProperties));

  const handleReset = (index) => {
    let key;
    selectedFieldOptions.forEach((item) => {
      key = Object.keys(item);
      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedFieldOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedLineFields(newOptions));
    const newfpjson = {
      dropdown1: "",
      isReset: true,
      addFieldsAs: "",
      dropdown: "",
      showBlock1: false,
      showBlock2: false,
      showNewField: false,
      splitLength: 1,
      joinAs: "text",
      dropdown2: "Baseline",
      data: {},
      data1: {},
      data2: {},
      mandatory: false,
      fieldExtractor: true,
      formBasedExtraction: false,
      blockExtractor: false,
      identifier: "",

      block: "",
      block1: "",
      block2: "",
      json: "",
      position: "",
      extractType: "form",
      isOptional: false,
      valueType: "",
      blockType: "text",
      valueBlock: "",
      isAdvanced: false,
      isWrappableFilled: false,
      wrapFrom: "below",
      indexRange1: 0,
      indexRange2: "",
      idlimit: 1,
      noOfBlocks: 1,
      isMultiValue: false,
      alsoAddAs: "",
      alsoAddJson: "",
      wrapValuesInto: "text",
      isSplittable: false,
      captureSplitValueAs: "append to next field",
      newFieldValue: "",
      newFieldValueJson: "",
      newFieldName: "",
      newFieldNameJson: "",
      separatorBasedSplit: true,
    };
    let vals = fieldProperties;

    vals[index] = newfpjson;

    vals[index].isReset = true;

    vals[index].isAdd = index !== 0 ? true : false;
    vals[index].isClose = index !== 0 ? true : false;

    dispatch(
      lineFieldPropertiesText({
        name: `lineFieldPropertiesBlock${index + 1}`,
        text: "",
      })
    );
    dispatch(
      lineFieldPropertiesText({
        name: `lineFieldPropertiesBlockdata${index + 1}`,
        text: {},
      })
    );
    dispatch(
      lineFieldPropertiesText({
        name: `Extra1lineFieldPropertiesBlock${index + 1}`,
        text: "",
      })
    );
    dispatch(
      lineFieldPropertiesText({
        name: `Extra1lineFieldPropertiesBlockdata${index + 1}`,
        text: "",
      })
    );

    dispatch(
      lineFieldPropertiesText({
        name: `Extra2lineFieldPropertiesBlock${index + 1}`,
        text: "",
      })
    );
    dispatch(
      lineFieldPropertiesText({
        name: `Extra2lineFieldPropertiesBlockdata${index + 1}`,
        text: "",
      })
    );
    dispatch(
      selectedLineFields([
        ...selectedFieldOptions,
        { [index]: vals[index].dropdown },
      ])
    );
    dispatch(saveLineField(vals));
  };

  const handleResetLine = () => {
    const newLineProperties = {
      lineItemIdentifierType: "pcode",
      isAbove: false,
      isBelow: false,

      regex: "",
      date: "",
      lineItemIdentifier: "",
      data: {},
      data_conflictPatternIdentifier: {},
      pcode: "5",
      minLengthOfLine: 1,
      startFromLineIdentifier: true,
      conflictPatternIdentifier: "",
      isAdvanced: false,
      lineStartsWith: "",
      conflictPattern: "",
      conflictPatternRegex: "",
      conflictPatternPcodeLength: 5,
      conflictPatternDate: "",
      aboveConflictPatternRegex: "",
      aboveConflictPatternPcodeLength: 5,
      aboveConflictPatternDate: "",
      belowConflictPatternRegex: "",
      belowConflictPatternPcodeLength: 5,
      belowConflictPatternDate: "",
      wrapLineThreshhold: 1,

      firstDollarBlock: "",
      abovelineMatchingPattern: "date",
      aboveLineMatchingDate: "",
      aboveLineMatchingRegex: "",
      minLengthOfAboveMatchingPcode: 5,
      aboveLineStartBlock: "",
      abovelineConflictPattern: "",
      aboveLineDollarValueBlock: "",
      belowLineMatchingPattern: "date",
      minLengthOfBelowMatchingPcode: 5,
      belowLineMatchingDate: "",
      belowLineMatchingRegex: "",
      belowLineConflictPattern: "",
      belowLineStartBlock: "",
      belowLineDollarValueBlock: "",

      maxLengthOfAboveLineItem: 1,
      minLengthOfAboveLineItem: 1,

      maxLengthofBelowLineItem: 1,
    };

    dispatch(
      lineItemPropertiesText({
        name: "lineItemIdentifier1",
        text: "",
      })
    );
    dispatch(
      lineItemPropertiesText({
        name: "lineItemIdentifierdata1",
        text: {},
      })
    );
    dispatch(
      disableLineItemActive({
        name: "lineItemIdentifierActive1",
      })
    );

    dispatch(
      lineItemPropertiesText({
        name: "aboveLineStartBlock1",
        text: "",
      })
    );
    dispatch(
      lineItemPropertiesText({
        name: "aboveLineStartBlockdata1",
        text: "",
      })
    );
    dispatch(
      lineItemPropertiesText({
        name: "aboveLineDollarValue1",
        text: "",
      })
    );
    dispatch(
      lineItemPropertiesText({
        name: "aboveLineDollarValuedata1",
        text: {},
      })
    );
    dispatch(
      disableLineItemActive({
        name: "aboveLineDollarValueActive1",
      })
    );

    dispatch(
      lineItemPropertiesText({
        name: "belowLineStartBlock1",
        text: "",
      })
    );
    dispatch(
      lineItemPropertiesText({
        name: "belowLineStartBlockdata1",
        text: {},
      })
    );
    dispatch(
      disableLineItemActive({
        name: "belowLineStartBlockActive1",
      })
    );

    dispatch(
      lineItemPropertiesText({
        name: "belowLineDollarValueBlock1",
        text: "",
      })
    );
    dispatch(
      lineItemPropertiesText({
        name: "belowLineDollarValueBlockdata1",
        text: {},
      })
    );
    dispatch(
      disableLineItemActive({
        name: "belowLineDollarValueBlockActive1",
      })
    );

    dispatch(
      lineItemPropertiesText({
        name: "lineStartsWith1",
        text: "",
      })
    );
    dispatch(
      lineItemPropertiesText({
        name: "lineStartsWithdata1",
        text: {},
      })
    );
    dispatch(
      disableLineItemActive({
        name: "lineStartsWithActive1",
      })
    );

    dispatch(
      lineItemPropertiesText({
        name: "firstDollarBlock1",
        text: "",
      })
    );
    dispatch(
      lineItemPropertiesText({
        name: "firstDollarBlockdata1",
        text: {},
      })
    );
    dispatch(
      disableLineItemActive({
        name: "firstDollarBlockActive1",
      })
    );

    dispatch(
      lineItemPropertiesText({
        name: "conflictPatternIdentifier1",
        text: "",
      })
    );
    dispatch(
      lineItemPropertiesText({
        name: "conflictPatternIdentifierdata1",
        text: {},
      })
    );
    dispatch(
      disableLineItemActive({
        name: "conflictPatternIdentifierActive1",
      })
    );
    dispatch(saveLineItem([newLineProperties]));
  };

  function matchInArray(str, expr) {
    let flag = false;
    let flag2 = false;
    expr.forEach((x) => {
      flag = x.test(str);
      if (flag) {
        flag2 = flag;
      }
    });
    return flag2;
  }

  const handleChangeAboveLine = () => {
    const values = [...lineItemProperties];
    values[0].isAbove = !values[0].isAbove;
    dispatch(saveLineItem(values));
  };
  const handleChangeBelowLine = () => {
    const values = [...lineItemProperties];
    values[0].isBelow = !values[0].isBelow;

    dispatch(saveLineItem(values));
  };

  console.log("item", lineItemProperties);

  const [dateOptions] = React.useState([
    "Select",
    "MM/DD/YYYY (09/01/2021)",
    "MM-DD-YYYY (09-01-2021)",
    "DD/MM/YYYY (25/01/2020)",
    "YYYY/MM/DD (2021/01/13)",
    "MM/DD/YY (03/23/21)",
    "MMM/DD/YYYY (Apr/15/2021)",
    "MMM/DD/YY (Apr/15/21)",
    "DD/MMM/YYYY (15/Apr/2021)",
    "DD/MMM/YY (15/Apr/21)",
    "MM/DD/YYYY-MM/DD/YYYY (03/01/2021-03/01/2021)",
    "MM-DD-YYYY-MM-DD-YYYY (03-01-2021-03-01-2021)",
    "MM/DD/YY-MM/DD/YY (03/01/21-03/01/21)",
    "MM/DD/YYYY MM/DD/YYYY (03/01/2021 03/01/2021)",
    "MM/DD-MM/DD/YYYY (03/01-03/01/2021)",
    "MM/DD-MM/DD/YY (03/01-03/01/21)",
    "MMDDYYYY (03012021)",
    "MMDDYY (030121)",
    "MMDDYYYY-MMDDYYYY (03012021-03012021)",
    "MMDD-MMDDYYYY (0301-03012021)",
    "MMDDYY-MMDDYY (030121-030121)",
    "MMDD-MMDDYY (0301-030121)",
  ]);

  React.useEffect(() => {
    if (lineFieldsDropdown && lineFieldsDropdown.length) {
      const ckAddOptions = lineFieldsDropdown.map(
        ({ DisplayName, JsonKey }, key) => {
          let a = {
            key,
            value: DisplayName,
            label: DisplayName,
            JsonKey,
          };
          return a;
        }
      );
      setFieldPropertiesOptions(ckAddOptions);
    }
  }, [lineFieldsDropdown]);

  React.useEffect(() => {
    if (alsoAddAsDropdown && alsoAddAsDropdown.length) {
      const addOptions = alsoAddAsDropdown.map(
        ({ DisplayName, JsonKey }, key) => {
          let a = {
            key,
            value: DisplayName,
            label: DisplayName,
            JsonKey,
          };
          return a;
        }
      );
      setAlsoAddAsOptions(addOptions);
    }
  }, [alsoAddAsDropdown]);
  React.useEffect(() => {
    Object.entries(state).map(([key, value], index) => {
      if (key.includes("lineFieldProperties") && !key.includes("Active")) {
        const values = [...fieldProperties];
        let lastNumber = parseInt(key.slice(-1), 10);

        const secondLast = parseInt(key.slice(-2), 10);
        let keyname;
        if (secondLast) {
          keyname = key.substring(0, key.length - 2);
          lastNumber = secondLast;
        } else keyname = key.substring(0, key.length - 1);
        if (
          keyname === "lineFieldPropertiesValue" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          values[lastNumber - 1].valueBlock = state[key];
          if (!_.isEmpty(state[`lineFieldPropertiesValuedata${lastNumber}`])) {
            values[lastNumber - 1].data_value_block =
              state[`lineFieldPropertiesValuedata${lastNumber}`];
            values[lastNumber - 1].data_value_block["Text"] = state[key];
          }

          dispatch(saveLineField(values));
        }
        if (
          keyname === "lineFieldPropertiesBlock" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          if (values.length >= lastNumber) {
            if (state[key] !== values[lastNumber - 1].block) {
              values[lastNumber - 1].valueType = "Select";
              let lineFieldsErrorStatus = lineFieldsError;
              lineFieldsErrorStatus[lastNumber - 1] = false;
              setLineFieldsError(lineFieldsErrorStatus);
            }
            values[lastNumber - 1].block = state[key];

            if (
              !_.isEmpty(state[`lineFieldPropertiesBlockdata${lastNumber}`])
            ) {
              values[lastNumber - 1].data =
                state[`lineFieldPropertiesBlockdata${lastNumber}`];
              values[lastNumber - 1].data["Text"] = state[key];
            }
          }
          dispatch(saveLineField(values));
        }

        if (
          keyname === "Extra1lineFieldPropertiesBlock" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          if (values.length >= lastNumber) {
            values[lastNumber - 1].block1 = state[key];
            if (
              !_.isEmpty(
                state[`Extra1lineFieldPropertiesBlockdata${lastNumber}`]
              )
            ) {
              values[lastNumber - 1].data1 =
                state[`Extra1lineFieldPropertiesBlockdata${lastNumber}`];
              values[lastNumber - 1].data1["Text"] = state[key];
            }
          }
          dispatch(saveLineField(values));
        }

        if (
          keyname === "Extra2lineFieldPropertiesBlock" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          if (values.length >= lastNumber) {
            values[lastNumber - 1].block2 = state[key];
            if (
              !_.isEmpty(
                state[`Extra2lineFieldPropertiesBlockdata${lastNumber}`]
              )
            ) {
              values[lastNumber - 1].data2 =
                state[`Extra2lineFieldPropertiesBlockdata${lastNumber}`];
              values[lastNumber - 1].data2["Text"] = state[key];
            }
          }
          dispatch(saveLineField(values));
        }

        if (
          keyname === "lineFieldPropertiesIdentfier" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          values[lastNumber - 1].identifier = state[key];
          if (
            !_.isEmpty(state[`lineFieldPropertiesIdentfierdata${lastNumber}`])
          ) {
            values[lastNumber - 1].data_identifier =
              state[`lineFieldPropertiesIdentfierdata${lastNumber}`];
            values[lastNumber - 1].data_identifier["Text"] = state[key];
          }

          dispatch(saveLineField(values));
        }
        if (
          keyname === "lineFieldPropertiesEndIdentifier" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          values[lastNumber - 1].endIdentifier = state[key];
          if (
            !_.isEmpty(
              state[`lineFieldPropertiesEndIdentifierdata${lastNumber}`]
            )
          ) {
            values[lastNumber - 1].data_end_identifier =
              state[`lineFieldPropertiesEndIdentifierdata${lastNumber}`];
            values[lastNumber - 1].data_end_identifier["Text"] = state[key];
          }

          dispatch(saveLineField(values));
        }
      }
    });
  }, [state]);

  React.useEffect(() => {
    const s = selectedFieldOptions?.map((op) => {
      return Object.values(op);
    });

    const total = [...s];

    setFieldOptionsDisabled([].concat(...total));
  }, [selectedFieldOptions]);

  React.useEffect(() => {
    const addOptions = selectedAlsoAddOptions?.map((op) => {
      return Object.values(op);
    });
    const lineFieldOptions = selectedFieldOptions.map((op) => {
      return Object.values(op);
    });
    const total = [...addOptions, ...lineFieldOptions];
    setAlsoAddAsOptionsDisabled([].concat(...total));
  }, [selectedAlsoAddOptions, selectedFieldOptions]);

  const addFieldProperties = (e) => {
    e.stopPropagation();

    dispatch(
      saveLineField([
        ...fieldProperties,
        {
          dropdown1: "",
          splitLength: 1,
          isClose: true,
          isAdd: true,
          joinAs: "text",
          dropdown2: "Baseline",
          data: {},
          data1: {},
          data2: {},
          mandatory: false,
          fieldExtractor: true,
          formBasedExtraction: false,
          blockExtractor: false,
          identifier: "",
          block: "",
          block1: "",
          block2: "",
          json: "",
          position: "",
          extractType: "form",
          isOptional: false,
          valueType: "ANY",
          blockType: "text",
          valueBlock: "",
          isAdvanced: false,
          isWrappableFilled: false,
          wrapFrom: "below",
          indexRange1: 0,
          indexRange2: "",
          idlimit: 1,
          noOfBlocks: 1,
          isMultiValue: false,
          wrapValuesInto: "text",
          isSplittable: false,
          captureSplitValueAs: "append to next field",
          newFieldValue: "",
          newFieldValueJson: "",
          newFieldName: "",
          newFieldNameJson: "",
          separatorBasedSplit: true,
          isReset: true,
        },
      ])
    );
  };

  React.useEffect(() => {
    if (lineItemIdentifier1) {
      const values = [...lineItemProperties];
      values[0].lineItemIdentifier = lineItemIdentifier1;
      if (!_.isEmpty(state["lineItemIdentifierdata1"])) {
        values[0].data = state["lineItemIdentifierdata1"];
        values[0].data["Text"] = lineItemIdentifier1;
        if (values[0].lineItemIdentifierType === "date") {
          if (values[0].date.length > 0) {
            let regex = dateDictionary[values[0].date.split(" ")[0]];
            let dateValidate = regex.test(values[0].lineItemIdentifier);
            if (!dateValidate) {
              // values[0].data = state["lineItemIdentifierdata1"];
              // values[0].data["Text"] = "";
              values[0].lineItemIdentifier = "";
              values[0].data = {};
              dispatch(saveLineItem(values));
              dispatch(
                lineItemPropertiesText({
                  name: `lineItemIdentifier1`,
                  text: "",
                })
              );
              alert("please enter value matching with date format");
            } else {
              values[0].data = state["lineItemIdentifierdata1"];
              values[0].data["Text"] = lineItemIdentifier1;
              dispatch(saveLineItem(values));
            }
          }
        }
      }
    }
    if (conflictPattern) {
      const values = [...lineItemProperties];
      values[0].conflictPattern = conflictPattern;
      if (!_.isEmpty(state["conflictPatterndata1"])) {
        values[0].data_conflictPattern = state["conflictPatterndata1"];
        values[0].data_conflictPattern["Text"] = conflictPattern;
      }
      dispatch(saveLineItem(values));
    }

    if (conflictPatternIdentifier) {
      const values = [...lineItemProperties];
      values[0].conflictPatternIdentifier = conflictPatternIdentifier;
      if (!_.isEmpty(state["conflictPatternIdentifierdata1"])) {
        values[0].data_conflictPatternIdentifier =
          state["conflictPatternIdentifierdata1"];
        values[0].data_conflictPatternIdentifier["Text"] =
          conflictPatternIdentifier;
      }
      dispatch(saveLineItem(values));
    }
    if (lineStartsWith) {
      const values = [...lineItemProperties];
      values[0].lineStartsWith = lineStartsWith;
      if (!_.isEmpty(state["lineStartsWithdata1"])) {
        values[0].data_lineStartsWith = state["lineStartsWithdata1"];
        values[0].data_lineStartsWith["Text"] = lineStartsWith;
      }
      dispatch(saveLineItem(values));
    }

    if (firstDollarBlock) {
      const values = [...lineItemProperties];

      values[0].firstDollarBlock = firstDollarBlock;
      if (!_.isEmpty(state["firstDollarBlockdata1"])) {
        values[0].data_firstDollarBlock = state["firstDollarBlockdata1"];
        values[0].data_firstDollarBlock["Text"] = firstDollarBlock;
      }

      dispatch(saveLineItem(values));
    }

    // above line

    if (abovelineStartBlock1) {
      const values = [...lineItemProperties];

      values[0].aboveLineStartBlock = abovelineStartBlock1;
      if (!_.isEmpty(state["aboveLineStartBlockdata1"])) {
        values[0].data_aboveLineStartBlock = state["aboveLineStartBlockdata1"];
        values[0].data_aboveLineStartBlock["Text"] = abovelineStartBlock1;
      }

      dispatch(saveLineItem(values));
    }

    if (abovelineDollarValueBlock1) {
      const values = [...lineItemProperties];

      values[0].aboveLineDollarValueBlock = abovelineDollarValueBlock1;
      if (!_.isEmpty(state["aboveLineDollarValuedata1"])) {
        values[0].data_aboveLineDollarValueBlock =
          state["aboveLineDollarValuedata1"];
        values[0].data_aboveLineDollarValueBlock["Text"] =
          abovelineDollarValueBlock1;
      }

      dispatch(saveLineItem(values));
    }

    // below line

    if (belowlineStartBlock1) {
      const values = [...lineItemProperties];

      values[0].belowLineStartBlock = belowlineStartBlock1;
      if (!_.isEmpty(state["belowLineStartBlockdata1"])) {
        values[0].data_belowLineStartBlock = state["belowLineStartBlockdata1"];
        values[0].data_belowLineStartBlock["Text"] = belowlineStartBlock1;
      }
      dispatch(saveLineItem(values));
    }
    if (belowlineDollarValueBlock1) {
      const values = [...lineItemProperties];

      values[0].belowLineDollarValueBlock = belowlineDollarValueBlock1;

      if (!_.isEmpty(state["belowLineDollarValueBlockdata1"])) {
        values[0].data_belowlineDollarValueBlock =
          state["belowLineDollarValueBlockdata1"];

        values[0]["data_belowlineDollarValueBlock"]["Text"] =
          belowlineDollarValueBlock1;
      }

      dispatch(saveLineItem(values));
    }
  }, [
    lineStartsWith,
    lineItemProperties.date,
    firstDollarBlock,
    firstDollarBlockActive,
    lineStartsWithActive,
    abovelineStartBlock1,
    abovelineStartBlockActive1,
    abovelineDollarValueBlock1,
    abovelineDollarValueBlockActive1,
    belowlineStartBlock1,
    belowlineStartBlockActive1,
    belowlineDollarValueBlock1,
    belowlineDollarValueBlockActive1,
    lineItemIdentifier1,
    conflictPattern,
    conflictPatternIdentifier,
  ]);

  const handleDeleteFieldProperties = (index) => {
    let key, addKey;
    selectedFieldOptions.forEach((item) => {
      key = Object.keys(item);
      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedFieldOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedLineFields(newOptions));
    let vals = [...fieldProperties];
    vals[index].dropdown = null;
    vals[index].json = null;
    vals.splice(index, 1);

    dispatch(saveLineField(vals));
  };

  useEffect(() => {
    if (lineItemProperties[0].lineItemIdentifierType === "date") {
      setShowDateInput(true);
      setShowRegexInput(false);
      setShowPcodeInput(false);
    } else if (lineItemProperties[0].lineItemIdentifierType === "pcode") {
      setShowDateInput(false);
      setShowRegexInput(false);
      setShowPcodeInput(true);
    } else if (lineItemProperties[0].lineItemIdentifierType === "regex") {
      setShowDateInput(false);
      setShowRegexInput(true);
      setShowPcodeInput(false);
    }
  }, [lineItemProperties[0].lineItemIdentifierType]);

  return (
    <Box
      h="600px"
      //w="678px"
      margin="0px auto"
    >
      <Box maxH="608px" overflowX="hidden" className="inner">
        {/* chekboxes */}
        <Box display="flex" justifyContent="space-around" marginTop="20px">
          <Box>
            <input
              type="checkbox"
              id="baseline"
              checked
              style={{ marginRight: 4 }}
            />
            <label for="baseline">Base Line</label>
          </Box>
          <Box>
            <input
              type="checkbox"
              id="aboveline"
              checked={
                lineItemProperties.length && lineItemProperties[0].isAbove
              }
              style={{ marginRight: 4 }}
              onChange={handleChangeAboveLine}
            />
            <label for="aboveline">Above Line </label>
          </Box>
          <Box>
            <input
              type="checkbox"
              id="belowline"
              checked={
                lineItemProperties.length && lineItemProperties[0].isBelow
              }
              style={{ marginRight: 4 }}
              onChange={handleChangeBelowLine}
            />
            <label for="belowline">Below Line </label>
          </Box>
        </Box>

        {/* Line Item properties */}

        <Box
          h="auto"
          margin="20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="6px"
          padding="4px 5px"
        >
          <Box padding="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={handleShowLineItemProperties}
              cursor="pointer"
            >
              <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                Line Item properties
              </Text>
              <Box display="flex" alignItems="center">
                <img
                  src={showLineItemProperties ? Up : Down}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  alt="show"
                />
              </Box>
            </Box>
            {showLineItemProperties && (
              <>
                {lineItemProperties.map((lip, index) => (
                  <Box
                    key={index}
                    id={`lineProperties${index}`}
                    background="white"
                    b="1px solid black"
                    h="auto"
                    paddingTop="10px"
                    margin="10px 0px"
                  >
                    {/* line properties */}

                    <Box
                      background="#DEDEDE 0% 0% no-repeat padding-box;"
                      borderRadius="5px"
                      padding="10px 0px"
                      margin="0 10px"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        margin="0 10px"
                        alignItems="center"
                      >
                        <Text
                          fontStyle="italic"
                          fontWeight="bold"
                          margin="10px"
                        >
                          BASIC
                        </Text>
                        <Box>
                          <button onClick={handleResetLine}>Reset</button>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        h="40px"
                      >
                        <Box
                          display="flex"
                          justifyContent="felx-start"
                          alignItems="center"
                          w="252px"
                        >
                          <Text
                            marginLeft="10px"
                            fontWeight="bold"
                            marginBottom="0"
                          >
                            Line Item Identifier Type{" "}
                            <span style={{ color: "red", fontSize: 20 }}>
                              *
                            </span>
                          </Text>
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                Select the format of line item identifier block.
                                (Click for more information)
                              </a>
                            }
                            placement="right"
                          >
                            <img
                              src={Help}
                              style={{ height: 12, marginLeft: 6 }}
                              alt="tooltip"
                            />
                          </Tooltip>
                        </Box>

                        <Box marginLeft1="26px" display="flex">
                          <select
                            onChange={(e) => {
                              const val = e.target.value;

                              const values = [...lineItemProperties];
                              values[0].lineItemIdentifierType = val;

                              dispatch(saveLineItem(values));
                              dispatch(
                                lineItemPropertiesText({
                                  name: "lineItemIdentifierType",
                                  text: val,
                                })
                              );
                            }}
                            value={lip?.lineItemIdentifierType}
                            style={{
                              width: 120,
                              fontWeight: "bold",
                              padding: 3,
                            }}
                          >
                            {["date", "pcode", "regex"].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </Box>
                      </Box>

                      {showPcodeInput && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          h="40px"
                        >
                          <Box
                            display="flex"
                            justifyContent="felx-start"
                            alignItems="center"
                            w="252px"
                          >
                            <Text
                              marginLeft="10px"
                              fontWeight="bold"
                              marginBottom="0px"
                            >
                              Pcode Length{" "}
                            </Text>
                          </Box>
                          <input
                            onChange={(e) => {
                              const values = [...lineItemProperties];
                              values[index].pcode = e.target.value;
                              dispatch(saveLineItem(values));
                              dispatch(
                                lineItemPropertiesText({
                                  name: "pcode",
                                  text: e.target.value,
                                })
                              );
                            }}
                            value={lip.pcode}
                            type="number"
                            min="0"
                            style={{
                              width: 50,
                              // marginLeft: 125,
                              marginRight: 20,
                              fontStyle: "italic",
                              padding: 4,
                              border: "1px solid gray",
                              borderRadius: 4,
                            }}
                          />
                        </Box>
                      )}

                      {showDateInput && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          h="40px"
                        >
                          <Box
                            display="flex"
                            justifyContent="felx-start"
                            alignItems="center"
                            w="252px"
                          >
                            <Text
                              marginLeft="10px"
                              fontWeight="bold"
                              marginBottom="0px"
                            >
                              Date Format
                              <span style={{ color: "red", fontSize: 20 }}>
                                *
                              </span>
                            </Text>
                          </Box>
                          <Box>
                            <Tooltip title={lip?.date}>
                              <select
                                onChange={(e) => {
                                  const values = [...lineItemProperties];
                                  values[index].date = e.target.value;
                                  values[0].lineItemIdentifier = "";
                                  values[0].data = {};
                                  dispatch(saveLineItem(values));
                                  dispatch(
                                    lineItemPropertiesText({
                                      name: "lineItemIdentifier1",
                                      text: "",
                                    })
                                  );
                                }}
                                value={lip?.date}
                                style={{
                                  width: 120,
                                  fontWeight: "bold",
                                  padding: 3,
                                }}
                              >
                                {dateOptions.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}

                      {showRegexInput && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          h="40px"
                        >
                          <Box
                            display="flex"
                            justifyContent="felx-start"
                            alignItems="center"
                            w="252px"
                          >
                            <Text
                              marginLeft="10px"
                              fontWeight="bold"
                              marginBottom="0px"
                            >
                              Regex
                              <span style={{ color: "red", fontSize: 20 }}>
                                *
                              </span>
                            </Text>
                          </Box>
                          <Box>
                            <Tooltip title={lip?.regex}>
                              <input
                                onChange={(e) => {
                                  const values = [...lineItemProperties];
                                  values[index].regex = e.target.value;
                                  dispatch(saveLineItem(values));
                                  dispatch(
                                    lineItemPropertiesText({
                                      name: "regex",
                                      text: e.target.value,
                                    })
                                  );
                                }}
                                value={lip?.regex}
                                type="text"
                                style={{
                                  width: 100,
                                  // marginLeft: 20,
                                  marginRight: 20,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        h="40px"
                      >
                        <Box
                          display="flex"
                          justifyContent="felx-start"
                          alignItems="center"
                          w="252px"
                        >
                          <Text
                            marginLeft="10px"
                            fontWeight="bold"
                            marginBottom="0"
                          >
                            Line Item Identifier Block{" "}
                            <span style={{ color: "red", fontSize: 20 }}>
                              *
                            </span>
                          </Text>
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                The block which has a clear format and is
                                present in every line is selected as the
                                line-item identifier. The block which identifies
                                a line item of the claim is selected here.(Click
                                for more information)
                              </a>
                            }
                            placement="right"
                          >
                            <img
                              src={Help}
                              style={{ height: 12, marginLeft: 6 }}
                              alt="tooltip"
                            />
                          </Tooltip>
                        </Box>

                        <Box display="flex" w="149px">
                          <Tooltip title={lip?.lineItemIdentifier}>
                            <input
                              onClick={() => {
                                dispatch(
                                  lineItemPropertiesActive({
                                    name: `lineItemIdentifierActive1`,
                                  })
                                );
                              }}
                              onChange={(e) => {
                                const values = [...lineItemProperties];
                                if (
                                  values[index].lineItemIdentifier.length <= 0
                                ) {
                                  alert(
                                    "please select value from image to proceed"
                                  );
                                  e.preventDefault();
                                } else {
                                  if (
                                    values[index].lineItemIdentifierType ===
                                    "date"
                                  ) {
                                    if (values[index].date.length > 0) {
                                      values[index].lineItemIdentifier =
                                        e.target.value;
                                      values[index].data["Text"] =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: `lineItemIdentifier1`,
                                          text: e.target.value,
                                        })
                                      );
                                    }
                                  }
                                }
                              }}
                              value={lip?.lineItemIdentifier}
                              type="text"
                              placeholder="Click on a block in image"
                              defaultValue="0"
                              style={{
                                width: 208,
                                marginRight: 20,
                                fontStyle: "italic",
                                padding: 4,
                                border: "1px solid gray",
                                borderRadius: 4,
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        h="40px"
                      >
                        <Box
                          display="flex"
                          justifyContent="felx-start"
                          alignItems="center"
                          w="252px"
                        >
                          <Text
                            marginLeft="10px"
                            fontWeight="bold"
                            marginBottom="0"
                          >
                            Min length of line{" "}
                            <span
                              style={{ color: "#909090", fontStyle: "italic" }}
                            >
                              (optional)
                            </span>
                          </Text>
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                Expected minimum length of line.(Click for more
                                information)
                              </a>
                            }
                            placement="right"
                          >
                            <img
                              src={Help}
                              style={{ height: 12, marginLeft: 6 }}
                              alt="tooltip"
                            />
                          </Tooltip>
                        </Box>
                        <Box>
                          <input
                            onChange={(e) => {
                              const values = [...lineItemProperties];
                              values[index].minLengthOfLine = Number(
                                e.target.value
                              );
                              dispatch(saveLineItem(values));
                              dispatch(
                                lineItemPropertiesText({
                                  name: "minLengthOfLine",
                                  text: Number(e.target.value),
                                })
                              );
                            }}
                            value={lip?.minLengthOfLine}
                            min="1"
                            type="number"
                            style={{
                              width: 50,

                              marginRight: 20,
                              fontStyle: "italic",
                              padding: 4,
                              border: "1px solid gray",
                              borderRadius: 4,
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        h="40px"
                      >
                        <Box
                          display="flex"
                          justifyContent="felx-start"
                          alignItems="center"
                          w="252px"
                        >
                          <Text
                            marginLeft="10px"
                            fontWeight="bold"
                            marginBottom="0"
                          >
                            Start from line identifier
                          </Text>
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                This identifies the line-item identifier and
                                will extract everything right side to the
                                identifier. This is used when the line that we
                                need starts with line-item identifier In case
                                the line-item identifier is found in the middle
                                of the line and there are few fields that is
                                required to extract on its left side, then set
                                this to False.(Click for more information)
                              </a>
                            }
                            placement="right"
                          >
                            <img
                              src={Help}
                              style={{ height: 12, marginLeft: 6 }}
                              alt="tooltip"
                            />
                          </Tooltip>
                        </Box>
                        <Box>
                          <select
                            onChange={(e) => {
                              const values = [...lineItemProperties];
                              const val = e.target.value;
                              if (val === "false")
                                values[index].startFromLineIdentifier = false;
                              else values[index].startFromLineIdentifier = true;

                              dispatch(saveLineItem(values));
                              dispatch(
                                lineItemPropertiesText({
                                  name: "startFromLineIdentifier",
                                  text: values[index].startFromLineIdentifier,
                                })
                              );
                            }}
                            value={lip?.startFromLineIdentifier}
                            style={{
                              width: 120,
                              fontWeight: "bold",
                              padding: 3,
                            }}
                          >
                            {["true", "false"].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </Box>
                      </Box>
                      {lineItemProperties[0].isAbove && (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box
                              display="flex"
                              justifyContent="felx-start"
                              alignItems="center"
                              w="252px"
                            >
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Above line matching pattern
                              </Text>
                              <span style={{ color: "red", fontSize: 20 }}>
                                *
                              </span>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Select the format which identifies this line
                                    matching pattern.(Click for more
                                    information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>

                            <Box display="flex">
                              <select
                                onChange={(e) => {
                                  const val = e.target.value;
                                  // if (val === "Select") return;
                                  const values = [...lineItemProperties];
                                  values[0].abovelineMatchingPattern = val;
                                  if (
                                    lip?.abovelineMatchingPattern === "date"
                                  ) {
                                    setMatchingPatternDate(true);
                                    setMatchingPatternPcode(false);
                                    setmatchingPatternDoolarAmount(false);
                                    setMatchingPatternRegex(false);
                                  }
                                  if (
                                    lip?.abovelineMatchingPattern ===
                                    "amount($)"
                                  ) {
                                    setMatchingPatternDate(false);
                                    setMatchingPatternPcode(false);
                                    setmatchingPatternDoolarAmount(true);
                                    setMatchingPatternRegex(false);
                                  }
                                  if (
                                    lip?.abovelineMatchingPattern === "pcode"
                                  ) {
                                    setMatchingPatternDate(false);
                                    setMatchingPatternPcode(true);
                                    setmatchingPatternDoolarAmount(false);
                                    setMatchingPatternRegex(false);
                                  }
                                  if (
                                    lip?.abovelineMatchingPattern === "regex"
                                  ) {
                                    setMatchingPatternDate(false);
                                    setMatchingPatternPcode(false);
                                    setmatchingPatternDoolarAmount(false);
                                    setMatchingPatternRegex(true);
                                  }

                                  dispatch(saveLineItem(values));
                                }}
                                value={lip?.abovelineMatchingPattern}
                                style={{
                                  width: 120,
                                  fontWeight: "bold",
                                  padding: 3,
                                }}
                              >
                                {[
                                  "Select",
                                  "date",
                                  "amount($)",
                                  "pcode",
                                  "regex",
                                ].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>
                          {lip?.abovelineMatchingPattern === "regex" && (
                            <Tooltip title={lip?.aboveLineMatchingRegex}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0px"
                                  >
                                    Regex
                                    <span
                                      style={{ color: "red", fontSize: 20 }}
                                    >
                                      *
                                    </span>
                                  </Text>
                                </Box>
                                <Box>
                                  <input
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      values[index].aboveLineMatchingRegex =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "regex",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.aboveLineMatchingRegex}
                                    type="text"
                                    style={{
                                      width: 100,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Tooltip>
                          )}

                          {lip?.abovelineMatchingPattern === "date" && (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box
                                display="flex"
                                justifyContent="felx-start"
                                alignItems="center"
                                w="252px"
                              >
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0px"
                                >
                                  Date Format
                                  <span style={{ color: "red", fontSize: 20 }}>
                                    *
                                  </span>
                                </Text>
                              </Box>
                              <Box>
                                <Tooltip title={lip?.aboveLineMatchingDate}>
                                  <select
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      values[index].aboveLineMatchingDate =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "matchingDate",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.aboveLineMatchingDate}
                                    style={{
                                      width: 120,
                                      fontWeight: "bold",
                                      padding: 3,
                                    }}
                                  >
                                    {dateOptions.map((item) => (
                                      <option key={item} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                </Tooltip>
                              </Box>
                            </Box>
                          )}

                          {lip?.abovelineMatchingPattern === "pcode" && (
                            <>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0"
                                  >
                                    Pcode length{" "}
                                  </Text>
                                  <Tooltip title="tooltip" placement="right">
                                    <img
                                      src={Help}
                                      style={{ height: 12, marginLeft: 6 }}
                                      alt="tooltip"
                                    />
                                  </Tooltip>
                                </Box>
                                <Box>
                                  <input
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];

                                      values[
                                        index
                                      ].minLengthOfAboveMatchingPcode =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                    }}
                                    value={lip?.minLengthOfAboveMatchingPcode}
                                    min="1"
                                    type="number"
                                    style={{
                                      width: 50,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </>
                          )}
                          {lip?.isAbove && (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box
                                display="flex"
                                justifyContent="felx-start"
                                alignItems="center"
                                w="252px"
                              >
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0"
                                >
                                  Above line dollar value block
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      In this line select the first dollar
                                      amount block.(Click for more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="tooltip"
                                  />
                                </Tooltip>
                              </Box>
                              <Box>
                                <Tooltip title={lip?.aboveLineDollarValueBlock}>
                                  <input
                                    onClick={() => {
                                      dispatch(
                                        lineItemPropertiesActive({
                                          name: `aboveLineDollarValueActive${
                                            index + 1
                                          }`,
                                        })
                                      );
                                    }}
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      if (
                                        values[index].aboveLineDollarValueBlock
                                          .length <= 0
                                      ) {
                                        alert(
                                          "please select value from image to proceed"
                                        );
                                        e.preventDefault();
                                      } else {
                                        values[
                                          index
                                        ].aboveLineDollarValueBlock =
                                          e.target.value;
                                        values[
                                          index
                                        ].data_aboveLineDollarValueBlock[
                                          "Text"
                                        ] = e.target.value;
                                        dispatch(saveLineItem(values));
                                        dispatch(
                                          lineItemPropertiesText({
                                            name: `aboveLineDollarValue${
                                              index + 1
                                            }`,
                                            text: e.target.value,
                                          })
                                        );
                                      }
                                    }}
                                    value={lip?.aboveLineDollarValueBlock}
                                    placeholder="click on a block in image"
                                    type="text"
                                    style={{
                                      width: 190,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Box>
                          )}
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box
                              display="flex"
                              justifyContent="felx-start"
                              alignItems="center"
                              w="252px"
                            >
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Above line conflict pattern
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    If the above selected block is found in any
                                    line items, then that line item will be
                                    discarded.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>

                            <Box display="flex">
                              <select
                                onChange={(e) => {
                                  const val = e.target.value;

                                  const values = [...lineItemProperties];
                                  values[0].abovelineConflictPattern =
                                    val === "Select" ? "" : val;

                                  dispatch(saveLineItem(values));
                                }}
                                value={lip?.abovelineConflictPattern}
                                style={{
                                  width: 120,
                                  fontWeight: "bold",
                                  padding: 3,
                                }}
                              >
                                {[
                                  "Select",
                                  "date",
                                  "amount($)",
                                  "pcode",
                                  "regex",
                                ].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>

                          {lip?.abovelineConflictPattern === "regex" && (
                            <Tooltip title={lip?.aboveConflictPatternRegex}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0px"
                                  >
                                    Regex
                                    <span
                                      style={{ color: "red", fontSize: 20 }}
                                    >
                                      *
                                    </span>
                                  </Text>
                                </Box>
                                <Box>
                                  <input
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      values[index].aboveConflictPatternRegex =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "aboveConflictPatternRegex",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.aboveConflictPatternRegex}
                                    type="text"
                                    style={{
                                      width: 100,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Tooltip>
                          )}
                          {lip?.abovelineConflictPattern === "pcode" && (
                            <>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0"
                                  >
                                    Pcode length{" "}
                                  </Text>
                                  <Tooltip title="tooltip" placement="right">
                                    <img
                                      src={Help}
                                      style={{ height: 12, marginLeft: 6 }}
                                      alt="tooltip"
                                    />
                                  </Tooltip>
                                </Box>
                                <Box>
                                  <input
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];

                                      values[
                                        index
                                      ].aboveConflictPatternPcodeLength =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "aboveConflictPatternPcodeLength",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.aboveConflictPatternPcodeLength}
                                    min="1"
                                    type="number"
                                    style={{
                                      width: 50,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </>
                          )}
                          {lip?.abovelineConflictPattern === "date" && (
                            <Tooltip title={lip?.aboveConflictPatternDate}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0px"
                                  >
                                    Date Format
                                    <span
                                      style={{ color: "red", fontSize: 20 }}
                                    >
                                      *
                                    </span>
                                  </Text>
                                </Box>
                                <Box>
                                  <select
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      values[index].aboveConflictPatternDate =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "aboveConflictPatternDate",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.aboveConflictPatternDate}
                                    style={{
                                      width: 120,
                                      fontWeight: "bold",
                                      padding: 3,
                                    }}
                                  >
                                    {dateOptions.map((item) => (
                                      <option key={item} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                </Box>
                              </Box>
                            </Tooltip>
                          )}
                          {lip?.isAbove && (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box
                                display="flex"
                                justifyContent="felx-start"
                                alignItems="center"
                                w="252px"
                              >
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0"
                                >
                                  Above line start block
                                </Text>
                                <span style={{ color: "red", fontSize: 20 }}>
                                  *
                                </span>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      Select first block in this line.(Click for
                                      more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="tooltip"
                                  />
                                </Tooltip>
                              </Box>
                              <Box>
                                <Tooltip title={lip?.aboveLineStartBlock}>
                                  <input
                                    onClick={() => {
                                      dispatch(
                                        lineItemPropertiesActive({
                                          name: `aboveLineStartBlockActive${
                                            index + 1
                                          }`,
                                        })
                                      );
                                    }}
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      if (
                                        values[index].aboveLineStartBlock
                                          .length <= 0
                                      ) {
                                        alert(
                                          "please select value from image to proceed"
                                        );
                                        e.preventDefault();
                                      } else {
                                        values[index].aboveLineStartBlock =
                                          e.target.value;
                                        values[index].data_aboveLineStartBlock[
                                          "Text"
                                        ] = e.target.value;

                                        dispatch(saveLineItem(values));
                                        dispatch(
                                          lineItemPropertiesText({
                                            name: `aboveLineStartBlock${
                                              index + 1
                                            }`,
                                            text: e.target.value,
                                          })
                                        );
                                      }
                                    }}
                                    value={lip?.aboveLineStartBlock}
                                    placeholder="click on a block in image"
                                    type="text"
                                    style={{
                                      width: 190,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Box>
                          )}

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box
                              display="flex"
                              justifyContent="felx-start"
                              alignItems="center"
                              w="252px"
                            >
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Max length of above line item
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    It describes the maximum length of above
                                    line item.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <input
                                onChange={(e) => {
                                  const values = [...lineItemProperties];
                                  values[index].maxLengthOfAboveLineItem =
                                    e.target.value;
                                  dispatch(saveLineItem(values));
                                  dispatch(
                                    lineItemPropertiesText({
                                      name: "maxLengthOfAboveLineItem",
                                      text: e.target.value,
                                    })
                                  );
                                }}
                                value={lip?.maxLengthOfAboveLineItem}
                                type="number"
                                min="0"
                                style={{
                                  width: 50,

                                  marginRight: 20,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                          </Box>

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box
                              display="flex"
                              justifyContent="felx-start"
                              alignItems="center"
                              w="252px"
                            >
                              <Text
                                fontWeight="bold"
                                marginBottom="0"
                                marginLeft="10px"
                              >
                                Min length of above line item
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    It describes the maximum length of above
                                    line item.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <input
                                onChange={(e) => {
                                  const values = [...lineItemProperties];
                                  values[index].minLengthOfAboveLineItem =
                                    e.target.value;
                                  dispatch(saveLineItem(values));
                                }}
                                value={lip?.minLengthOfAboveLineItem}
                                type="number"
                                min="0"
                                style={{
                                  width: 50,

                                  marginRight: 20,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                      {lineItemProperties[0].isBelow && (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box
                              display="flex"
                              justifyContent="felx-start"
                              alignItems="center"
                              w="252px"
                            >
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Below line matching pattern
                              </Text>
                              <span style={{ color: "red", fontSize: 20 }}>
                                *
                              </span>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Select the format which identifies this line
                                    matching pattern.(Click for more
                                    information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box display="flex">
                              <select
                                onChange={(e) => {
                                  const val = e.target.value;
                                  // if (val === "Select") return;
                                  const values = [...lineItemProperties];
                                  values[0].belowLineMatchingPattern = val;
                                  dispatch(saveLineItem(values));
                                }}
                                value={lip?.belowLineMatchingPattern}
                                style={{
                                  width: 120,
                                  fontWeight: "bold",
                                  padding: 3,
                                }}
                              >
                                {[
                                  "Select",
                                  "date",
                                  "amount($)",
                                  "pcode",
                                  "regex",
                                ].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>
                          {lip?.belowLineMatchingPattern === "regex" && (
                            <Tooltip title={lip?.belowLineMatchingRegex}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0px"
                                  >
                                    Regex
                                    <span
                                      style={{ color: "red", fontSize: 20 }}
                                    >
                                      *
                                    </span>
                                  </Text>
                                </Box>
                                <Box>
                                  <input
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      values[index].belowLineMatchingRegex =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "belowLineMatchingRegex",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.belowLineMatchingRegex}
                                    type="text"
                                    style={{
                                      width: 100,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Tooltip>
                          )}

                          {lip?.belowLineMatchingPattern === "date" && (
                            <Tooltip title={lip?.belowLineMatchingDate}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0px"
                                  >
                                    Date Format
                                    <span
                                      style={{ color: "red", fontSize: 20 }}
                                    >
                                      *
                                    </span>
                                  </Text>
                                </Box>
                                <Box>
                                  <select
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      values[index].belowLineMatchingDate =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "belowLineMatchingDate",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.belowLineMatchingDate}
                                    style={{
                                      width: 120,
                                      fontWeight: "bold",
                                      padding: 3,
                                    }}
                                  >
                                    {dateOptions.map((item) => (
                                      <option key={item} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                </Box>
                              </Box>
                            </Tooltip>
                          )}

                          {lip?.belowLineMatchingPattern === "pcode" && (
                            <>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0"
                                  >
                                    Pcode length
                                  </Text>
                                  <Tooltip title="tooltip" placement="right">
                                    <img
                                      src={Help}
                                      style={{ height: 12, marginLeft: 6 }}
                                      alt="tooltip"
                                    />
                                  </Tooltip>
                                </Box>
                                <Box>
                                  <input
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];

                                      values[
                                        index
                                      ].minLengthOfBelowMatchingPcode =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "minLengthOfBelowMatchingPcode",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.minLengthOfBelowMatchingPcode}
                                    min="1"
                                    type="number"
                                    style={{
                                      width: 50,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </>
                          )}

                          {/* <Box>
                              <input
                                onClick={() => {
                                  dispatch(
                                    lineItemPropertiesActive({
                                      name: `belowLineMatchingPatternActive${index + 1
                                        }`,
                                    })
                                  );
                                }}
                                onChange={(e) => {
                                  const values = [...lineItemProperties];
                                  values[index].belowLineMatchingPattern =
                                    e.target.value;
                                  dispatch(saveLineItem(values))
                                  dispatch(
                                    lineItemPropertiesText({
                                      name: `belowLineMatchingPattern${index + 1
                                        }`,
                                      text: e.target.value,
                                    })
                                  );
                                }}
                                value={lip?.belowLineMatchingPattern}
                                placeholder="click on a block in image"
                                type="text"
                                style={{
                                  width: 190,
                                  marginLeft: 15,
                                  marginRight: 20,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box> */}

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box
                              display="flex"
                              justifyContent="felx-start"
                              alignItems="center"
                              w="252px"
                            >
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Below line conflict pattern
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    If the below selected block is found in any
                                    line items, then that line item will be
                                    discarded.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            {/* <Box>
                              <input
                                onClick={() => {
                                  dispatch(
                                    lineItemPropertiesActive({
                                      name: `belowLineConflictPatternActive${index + 1
                                        }`,
                                    })
                                  );
                                }}
                                onChange={(e) => {
                                  const values = [...lineItemProperties];
                                  values[index].belowLineConflictPattern =
                                    e.target.value;
                                  dispatch(saveLineItem(values))
                                  dispatch(
                                    lineItemPropertiesText({
                                      name: `belowLineConflictPattern${index + 1
                                        }`,
                                      text: e.target.value,
                                    })
                                  );
                                }}
                                value={lip?.belowLineConflictPattern}
                                placeholder="click on a block in image"
                                type="text"
                                style={{
                                  width: 190,
                                  marginLeft: 27,
                                  marginRight: 20,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box> */}

                            <Box display="flex">
                              <select
                                onChange={(e) => {
                                  const val = e.target.value;

                                  const values = [...lineItemProperties];
                                  values[0].belowLineConflictPattern =
                                    val === "Select" ? "" : val;

                                  dispatch(saveLineItem(values));
                                }}
                                value={lip?.belowLineConflictPattern}
                                style={{
                                  width: 120,
                                  fontWeight: "bold",
                                  padding: 3,
                                }}
                              >
                                {[
                                  "Select",
                                  "date",
                                  "amount($)",
                                  "pcode",
                                  "regex",
                                ].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>

                          {lip?.belowLineConflictPattern === "regex" && (
                            <Tooltip title={lip?.belowConflictPatternRegex}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0px"
                                  >
                                    Regex
                                    <span
                                      style={{ color: "red", fontSize: 20 }}
                                    >
                                      *
                                    </span>
                                  </Text>
                                </Box>
                                <Box>
                                  <input
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      values[index].belowConflictPatternRegex =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "regebelowConflictPatternRegexx",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.belowConflictPatternRegex}
                                    type="text"
                                    style={{
                                      width: 100,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Tooltip>
                          )}
                          {lip?.belowLineConflictPattern === "date" && (
                            <Tooltip title={lip?.belowConflictPatternDate}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0px"
                                  >
                                    Date Format
                                    <span
                                      style={{ color: "red", fontSize: 20 }}
                                    >
                                      *
                                    </span>
                                  </Text>
                                </Box>
                                <Box>
                                  <select
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      values[index].belowConflictPatternDate =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "belowConflictPatternDate",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.belowConflictPatternDate}
                                    style={{
                                      width: 120,
                                      fontWeight: "bold",
                                      padding: 3,
                                    }}
                                  >
                                    {dateOptions.map((item) => (
                                      <option key={item} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                </Box>
                              </Box>
                            </Tooltip>
                          )}
                          {lip?.belowLineConflictPattern === "pcode" && (
                            <>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0"
                                  >
                                    Pcode length{" "}
                                  </Text>
                                  <Tooltip title="tooltip" placement="right">
                                    <img
                                      src={Help}
                                      style={{ height: 12, marginLeft: 6 }}
                                      alt="tooltip"
                                    />
                                  </Tooltip>
                                </Box>
                                <Box>
                                  <input
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];

                                      values[
                                        index
                                      ].belowConflictPatternPcodeLength =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "belowConflictPatternPcodeLength",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.belowConflictPatternPcodeLength}
                                    min="1"
                                    type="number"
                                    style={{
                                      width: 50,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </>
                          )}

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box
                              display="flex"
                              justifyContent="felx-start"
                              alignItems="center"
                              w="252px"
                            >
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Max length of Below line item
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Expected maximum length of below line item.
                                    (Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <input
                                onChange={(e) => {
                                  const values = [...lineItemProperties];
                                  values[index].maxLengthofBelowLineItem =
                                    e.target.value;
                                  dispatch(saveLineItem(values));
                                  dispatch(
                                    lineItemPropertiesText({
                                      name: "maxLengthofBelowLineItem",
                                      text: e.target.value,
                                    })
                                  );
                                }}
                                value={lip?.maxLengthofBelowLineItem}
                                type="number"
                                min="0"
                                defaultValue="1"
                                style={{
                                  width: 50,

                                  marginRight: 20,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                          </Box>
                        </>
                      )}

                      <Box
                        display="none"
                        alignItems="center"
                        justifyContent="flex-start"
                        h="40px"
                      >
                        <Box
                          display="flex"
                          justifyContent="felx-start"
                          alignItems="center"
                          w="252px"
                        >
                          <Text marginLeft="10px" fontWeight="bold">
                            Min length below above line{" "}
                            <span
                              style={{ color: "#909090", fontStyle: "italic" }}
                            >
                              (optional)
                            </span>
                          </Text>
                          <Tooltip
                            title="Expected minimum length"
                            placement="right"
                          >
                            <img
                              src={Help}
                              style={{ height: 12, marginLeft: 6 }}
                              alt="tooltip"
                            />
                          </Tooltip>
                        </Box>
                        <Box>
                          <input
                            onChange={(e) => {
                              const values = [...lineItemProperties];
                              values[index].minLengthOfBelowAboveLine =
                                e.target.value;
                              dispatch(saveLineItem(values));
                              dispatch(
                                lineItemPropertiesText({
                                  name: "minLengthOfBelowAboveLine",
                                  text: e.target.value,
                                })
                              );
                            }}
                            value={lip?.minLengthOfBelowAboveLine}
                            type="number"
                            min="0"
                            defaultValue="0"
                            style={{
                              width: 50,

                              marginRight: 20,
                              fontStyle: "italic",
                              padding: 4,
                              border: "1px solid gray",
                              borderRadius: 4,
                            }}
                          />
                        </Box>
                      </Box>
                      {lip?.isBelow && (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box
                              display="flex"
                              justifyContent="felx-start"
                              alignItems="center"
                              w="252px"
                            >
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Below line start block
                              </Text>
                              <span style={{ color: "red", fontSize: 20 }}>
                                *
                              </span>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Select first block in this line.(Click for
                                    more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>

                            <Box>
                              <Tooltip title={lip?.belowLineStartBlock}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      lineItemPropertiesActive({
                                        name: `belowLineStartBlockActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...lineItemProperties];
                                    if (
                                      values[index].belowLineStartBlock
                                        .length <= 0
                                    ) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[index].belowLineStartBlock =
                                        e.target.value;
                                      values[index].data_belowLineStartBlock[
                                        "Text"
                                      ] = e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: `belowLineStartBlock${
                                            index + 1
                                          }`,
                                          text: e.target.value,
                                        })
                                      );
                                    }
                                  }}
                                  value={lip?.belowLineStartBlock}
                                  placeholder="click on a block in image"
                                  type="text"
                                  style={{
                                    width: 190,

                                    marginRight: 20,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box
                              display="flex"
                              justifyContent="felx-start"
                              alignItems="center"
                              w="252px"
                            >
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Below line dollar value block
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    In this line select the first dollar amount
                                    block.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <Tooltip title={lip?.belowLineDollarValueBlock}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      lineItemPropertiesActive({
                                        name: `belowLineDollarValueBlockActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...lineItemProperties];
                                    if (
                                      values[index].belowLineDollarValueBlock
                                        .length <= 0
                                    ) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[index].belowLineDollarValueBlock =
                                        e.target.value;
                                      values[
                                        index
                                      ].data_belowlineDollarValueBlock["Text"] =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: `belowLineDollarValueBlock${
                                            index + 1
                                          }`,
                                          text: e.target.value,
                                        })
                                      );
                                    }
                                  }}
                                  value={lip?.belowLineDollarValueBlock}
                                  placeholder="click on a block in image"
                                  type="text"
                                  style={{
                                    width: 190,

                                    marginRight: 20,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box
                              display="flex"
                              justifyContent="felx-start"
                              alignItems="center"
                              w="252px"
                            >
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Min length of Below line item
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    It describes the expected minimum length of
                                    below line item.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <input
                                onChange={(e) => {
                                  const values = [...lineItemProperties];
                                  values[index].minLengthOfBelowLineItem =
                                    e.target.value;
                                  dispatch(saveLineItem(values));
                                  dispatch(
                                    lineItemPropertiesText({
                                      name: "minLengthOfBelowLineItem",
                                      text: e.target.value,
                                    })
                                  );
                                }}
                                value={lip?.minLengthOfBelowLineItem}
                                type="number"
                                min="0"
                                defaultValue="1"
                                style={{
                                  width: 50,

                                  marginRight: 20,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>

                    {/* advanced */}
                    <Box
                      background="#DEDEDE 0% 0% no-repeat padding-box;"
                      borderRadius="5px"
                      margin="10px"
                      h="auto"
                      padding="5px"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        h="auto"
                        onClick={() => {
                          const values = [...lineItemProperties];
                          values[index].isAdvanced = !values[index].isAdvanced;
                          dispatch(saveLineItem(values));
                          dispatch(
                            lineItemPropertiesText({
                              name: "isAdvanced",
                              text: values[index].isAdvanced,
                            })
                          );
                        }}
                        cursor="pointer"
                      >
                        <Text
                          marginLeft="10px"
                          fontWeight="bold"
                          style={{ fontStyle: "italic" }}
                          marginBottom="0"
                          padding="3px 0"
                        >
                          ADVANCED
                        </Text>
                        <img
                          src={lip?.isAdvanced ? Up : Down}
                          style={{ marginRight: 20, cursor: "pointer" }}
                          alt="show"
                        />
                      </Box>
                      {lip?.isAdvanced && (
                        <Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Line starts with{" "}
                                {/* <span style={{ color: "red", fontSize: 20 }}>*</span> */}
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    If Start from line identifier is false then
                                    first block in line is start with.(Click for
                                    more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>

                            <Box
                              marginRight="auto"
                              marginLeft="56px"
                              display="flex"
                              alignItems="center"
                            >
                              <Tooltip title={lip?.lineStartsWith}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      lineItemPropertiesActive({
                                        name: `lineStartsWithActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...lineItemProperties];

                                    if (
                                      values[index].lineStartsWith.length <= 0
                                    ) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[index].lineStartsWith =
                                        e.target.value;
                                      values[index].data_lineStartsWith[
                                        "Text"
                                      ] = e.target.value;
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: `lineStartsWith${index + 1}`,
                                          text: e.target.value,
                                        })
                                      );
                                      dispatch(saveLineItem(values));
                                    }
                                  }}
                                  value={lip?.lineStartsWith}
                                  placeholder="click on a block in image"
                                  type="text"
                                  style={{
                                    width: 190,
                                    marginLeft: 67,
                                    marginRight: 20,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                First dollar block
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    In the line select the first dollar amount
                                    block.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box marginRight="19px">
                              <Tooltip title={lip?.firstDollarBlock}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      lineItemPropertiesActive({
                                        name: `firstDollarBlockActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...lineItemProperties];
                                    if (
                                      values[index].firstDollarBlock.length <= 0
                                    ) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[index].firstDollarBlock =
                                        e.target.value;
                                      values[index].data_firstDollarBlock[
                                        "Text"
                                      ] = e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: `firstDollarBlock${index + 1}`,
                                          text: e.target.value,
                                        })
                                      );
                                    }
                                  }}
                                  value={lip?.firstDollarBlock}
                                  placeholder="click on a block in image"
                                  type="text"
                                  style={{
                                    width: 190,
                                    marginLeft: 106,
                                    marginRight: 20,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                            marginRight="107px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Conflict pattern
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    If the block selected here is found in any
                                    line items, then that line item will be
                                    discarded.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <select
                                onChange={(e) => {
                                  const val = e.target.value;
                                  const values = [...lineItemProperties];
                                  values[0].conflictPattern =
                                    val === "Select" ? "" : val;
                                  dispatch(saveLineItem(values));
                                }}
                                value={lip?.conflictPattern}
                                style={{
                                  width: 120,
                                  fontWeight: "bold",
                                  padding: 3,
                                }}
                              >
                                {[
                                  "Select",
                                  "date",
                                  "amount($)",
                                  "pcode",
                                  "regex",
                                  "identifier",
                                ].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>

                          {showConflictPatternPcode && (
                            <>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0"
                                  >
                                    Pcode length{" "}
                                  </Text>
                                  <Tooltip title="tooltip" placement="right">
                                    <img
                                      src={Help}
                                      style={{ height: 12, marginLeft: 6 }}
                                      alt="tooltip"
                                    />
                                  </Tooltip>
                                </Box>
                                <Box>
                                  <input
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      values[index].conflictPatternPcodeLength =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "conflictPatternPcodeLength",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.conflictPatternPcodeLength}
                                    min="1"
                                    defaultValue="5"
                                    type="number"
                                    style={{
                                      width: 50,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </>
                          )}

                          {showConflictPatternIdentifier && (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center">
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0px"
                                >
                                  Conflict pattern identifier
                                </Text>
                                {/* <Tooltip title="tooltip" placement="right"> */}
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                      style={{ color: "white" }}
                                    >
                                      Select the block if the conflict pattern
                                      identifier is present in the line, it will
                                      neglect that particular line. (Click for
                                      more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="tooltip"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginLeft="41px">
                                <Tooltip title={lip?.conflictPatternIdentifier}>
                                  <input
                                    placeholder="click on a block in image"
                                    onClick={() => {
                                      dispatch(
                                        lineItemPropertiesActive({
                                          name: `conflictPatternIdentifierActive${
                                            index + 1
                                          }`,
                                        })
                                      );
                                    }}
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      if (
                                        values[index]
                                          ?.data_conflictPatternIdentifier
                                          ?.length ||
                                        values[index]?.conflictPatternIdentifier
                                          ?.length <= 0
                                      ) {
                                        e.preventDefault();
                                        alert(
                                          "please select value from image to proceed"
                                        );
                                        e.preventDefault();
                                      } else {
                                        values[
                                          index
                                        ].conflictPatternIdentifier =
                                          e.target.value;
                                        values[
                                          index
                                        ].data_conflictPatternIdentifier[
                                          "Text"
                                        ] = e.target.value;
                                        dispatch(saveLineItem(values));
                                        dispatch(
                                          lineItemPropertiesText({
                                            name: `conflictPatternIdentifier${
                                              index + 1
                                            }`,
                                            text: e.target.value,
                                          })
                                        );
                                      }
                                    }}
                                    value={lip?.conflictPatternIdentifier}
                                    type="text"
                                    style={{
                                      width: 167,
                                      marginLeft: 20,

                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Box>
                          )}

                          {showConflictPatternRegex && (
                            <Tooltip title={lip?.conflictPatternRegex}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="felx-start"
                                  alignItems="center"
                                  w="252px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0px"
                                  >
                                    Regex
                                    <span
                                      style={{ color: "red", fontSize: 20 }}
                                    >
                                      *
                                    </span>
                                  </Text>
                                </Box>
                                <Box>
                                  <input
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      values[index].conflictPatternRegex =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "conflictPatternRegex",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.conflictPatternRegex}
                                    type="text"
                                    style={{
                                      width: 100,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Tooltip>
                          )}

                          {showConflictPatternDate && (
                            <Tooltip title={lip?.conflictPatternDate}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box display="flex" alignItems="center">
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0px"
                                  >
                                    Date Format
                                    <span
                                      style={{ color: "red", fontSize: 20 }}
                                    >
                                      *
                                    </span>
                                  </Text>
                                </Box>
                                <Box marginLeft="152px">
                                  <select
                                    onChange={(e) => {
                                      const values = [...lineItemProperties];
                                      values[index].conflictPatternDate =
                                        e.target.value;
                                      dispatch(saveLineItem(values));
                                      dispatch(
                                        lineItemPropertiesText({
                                          name: "conflictPatternDate",
                                          text: e.target.value,
                                        })
                                      );
                                    }}
                                    value={lip?.conflictPatternDate}
                                    style={{
                                      width: 120,
                                      fontWeight: "bold",
                                      padding: 3,
                                    }}
                                  >
                                    {dateOptions.map((item) => (
                                      <option key={item} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                </Box>
                              </Box>
                            </Tooltip>
                          )}

                          {/* 
                            <Box marginLeft="98px" marginRight="auto">
                              <input
                                placeholder="click on a block in image"
                                onClick={() => {
                                  dispatch(
                                    lineItemPropertiesActive({
                                      name: `conflictPatternActive${index + 1}`,
                                    })
                                  );
                                }}
                                onChange={(e) => {
                                  const values = [...lineItemProperties];
                                  if (
                                    values[index].conflictPattern.length <= 0
                                  ) {
                                    alert(
                                      "please select value from image to proceed"
                                    );
                                    e.preventDefault();
                                  } else {
                                    values[index].conflictPattern =
                                      e.target.value;
                                    values[index].data_conflictPattern["Text"] =
                                      e.target.value;
                                    dispatch(saveLineItem(values));
                                    dispatch(
                                      lineItemPropertiesText({
                                        name: `conflictPattern${index + 1}`,
                                        text: e.target.value,
                                      })
                                    );
                                  }
                                }}
                                value={lip?.conflictPattern}
                                type="text"
                                style={{
                                  width: 167,
                                  marginLeft: 20,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box> */}

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0"
                              >
                                Wrap line threshold
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/DBYGQs1PhizFkqNw?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    When there is a wrap scenario, the whole
                                    line where the wrap field is available is
                                    taken and the length is checked. If the
                                    length exceeds the maximum length mentioned
                                    then the wrap line will be discarded and
                                    that field will not be wrapped.(Click for
                                    more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <input
                                onChange={(e) => {
                                  const values = [...lineItemProperties];
                                  values[index].wrapLineThreshhold =
                                    e.target.value;
                                  dispatch(saveLineItem(values));
                                  dispatch(
                                    lineItemPropertiesText({
                                      name: "wrapLineThreshhold",
                                      text: e.target.value,
                                    })
                                  );
                                }}
                                value={lip?.wrapLineThreshhold}
                                type="number"
                                min="1"
                                defaultValue="1"
                                style={{
                                  width: 50,
                                  marginLeft: 20,
                                  marginRight: 177,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                          </Box>

                          <Box
                            display="none"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text marginLeft="10px" fontWeight="bold">
                                Extra column insertion
                              </Text>
                              <Tooltip title="tooltip" placement="right">
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box marginRight="136px">
                              <select
                                defaultValue="none"
                                style={{
                                  width: 100,
                                  fontWeight: "bold",
                                  marginRight: 30,
                                }}
                              >
                                {["claim", "page", "none"].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>

                          <Box
                            display="none"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text marginLeft="10px" fontWeight="bold">
                                Extra column name identifier
                              </Text>
                              <Tooltip title="tooltip" placement="right">
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box marginRight="10px">
                              <input
                                type="text"
                                style={{
                                  width: 240,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                          </Box>

                          <Box
                            display="none"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text marginLeft="10px" fontWeight="bold">
                                Remark reason code index
                              </Text>
                              <Tooltip title="tooltip" placement="right">
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box marginRight="127px">
                              <select
                                defaultValue="false"
                                style={{
                                  width: 100,
                                  fontWeight: "bold",
                                  padding: 3,
                                  marginRight: 30,
                                }}
                              >
                                {["true", "false"].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>

                          <Box
                            display="none"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text marginLeft="10px" fontWeight="bold">
                                Extra column margin
                              </Text>
                              <Tooltip title="tooltip" placement="right">
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>

                            <Box marginRight="127px">
                              <select
                                defaultValue="base"
                                style={{
                                  width: 100,
                                  fontWeight: "bold",
                                  padding: 3,
                                  marginRight: 30,
                                }}
                              >
                                {["above", "below", "base"].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>

        {/* field properties */}
        <Box
          h="auto"
          margin="20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="6px"
          padding="4px 5px"
        >
          <Box padding="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={handleShowFieldProperties}
              cursor="pointer"
            >
              <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                Line Fields
              </Text>
              <Tooltip
                interactive
                title={
                  <a
                    href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                    style={{ color: "white" }}
                  >
                    All the line fields should be selected from the same line.
                    (Click for more information)
                  </a>
                }
                placement="right"
              >
                <img
                  src={Help}
                  style={{ height: 12, marginLeft: -346 }}
                  alt="tooltip"
                />
              </Tooltip>
              <Box display="flex" alignItems="center">
                <img
                  src={Add}
                  style={{ marginLeft: 20, cursor: "pointer", height: 20 }}
                  onClick={addFieldProperties}
                  alt="add"
                />
                <img
                  src={showFieldProperties ? Up : Down}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  alt="show"
                />
              </Box>
            </Box>

            {showFieldProperties && (
              <>
                {fieldProperties.map((fp, index) => (
                  <Box
                    key={index}
                    id={`fieldProperties${index}`}
                    background="white"
                    b="1px solid black"
                    h="auto"
                    paddingTop="10px"
                    margin="10px 0px"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      margin="0 10px 20px 10px"
                    >
                      <Box display="flex" alignItems="center">
                        <input
                          onChange={(e) => {
                            const values = [...fieldProperties];
                            values[index].mandatory = e.target.checked;
                            dispatch(saveLineField(values));
                          }}
                          checked={fp?.mandatory}
                          type="checkbox"
                          id="mandatory"
                          defaultChecked
                          style={{ marginRight: 2 }}
                        />
                        <label style={{ fontStyle: "italic" }}>Mandatory</label>
                      </Box>
                      {/* reset */}
                      <Box display="flex" justifyContent="space-between">
                        <Box
                          display={fp.isReset && index === 0 ? "flex" : "none"}
                        >
                          <button onClick={() => handleReset(index)}>
                            Reset
                          </button>
                        </Box>
                        <Box
                          display={fp?.isClose ? "flex" : "none"}
                          justifyContent="flex-end"
                        >
                          <Box display={fp.isReset}>
                            <button onClick={() => handleReset(index)}>
                              Reset
                            </button>
                          </Box>
                          <img
                            src={Add}
                            style={{
                              marginLeft: 20,
                              cursor: "pointer",
                              height: 20,
                              marginRight: 10,
                              display: fp?.isAdd ? "flex" : "none",
                            }}
                            onClick={addFieldProperties}
                            alt="add"
                          />

                          <img
                            src={Cross}
                            alt="delete"
                            style={{
                              height: 13,
                              width: 13,
                              marginRight: 10,
                              marginBottom: 10,
                              cursor: "pointer",
                            }}
                            onClick={(event) =>
                              handleDeleteFieldProperties(index, event)
                            }
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      margin="5px 10px"
                    >
                      <Tooltip title={!fp?.dropdown ? "empty" : fp?.dropdown}>
                        <SelectPicker
                          // searchable={false}
                          onClean={() => {
                            let svl = selectedFieldOptions.filter((item) => {
                              const val = Object.values(item);
                              if (val[0] !== fp?.dropdown) {
                                return item;
                              }
                            });

                            dispatch(selectedLineFields(svl));
                            const vals = [...fieldProperties];
                            vals[index].dropdown = null;
                            vals[index].json = null;
                          }}
                          value={fp.dropdown}
                          disabledItemValues={fieldOptionsDisabled}
                          onChange={(v) => {
                            const values = [...fieldProperties];
                            if (!values[index].dropdown) {
                              values[index].dropdown = v;
                              const json = fieldPropertiesOptions.find(
                                (item) => item.value === v
                              );

                              values[index].json = json.JsonKey;
                              dispatch(saveLineField(values));
                              if (v !== "Unwanted")
                                dispatch(
                                  selectedLineFields([
                                    ...selectedFieldOptions,
                                    { [index]: v },
                                  ])
                                );
                            }
                          }}
                          data={fieldPropertiesOptions}
                          style={{ width: 344 }}
                          renderMenu={(menu) => {
                            if (fieldPropertiesOptions.length === 0) {
                              return (
                                <p
                                  style={{
                                    padding: 4,
                                    color: "#999",
                                    textAlign: "center",
                                  }}
                                >
                                  <i
                                    className="fa fa-circle-o-notch fa-spin"
                                    style={{ fontSize: "16px" }}
                                  ></i>{" "}
                                  &nbsp; Loading...
                                </p>
                              );
                            }
                            return menu;
                          }}
                        />
                      </Tooltip>
                      <SelectPicker
                        // searchable={false}
                        onClean={() => {
                          const vals = [...fieldProperties];
                          vals[index].dropdown2 = null;
                          setFieldProperties(vals);
                        }}
                        value={fp.dropdown2}
                        onChange={(v) => {
                          const values = [...fieldProperties];
                          if (v === "Above") {
                            values[index].isAbove = true;
                          }
                          values[index].dropdown2 = v;
                          dispatch(saveLineField(values));
                        }}
                        data={[
                          {
                            key: "Baseline",
                            label: "Baseline",
                            value: "Baseline",
                          },
                          {
                            key: "Above",
                            label: "Above",
                            value: "Above",
                          },
                          {
                            key: "Below",
                            label: "Below",
                            value: "Below",
                          },
                        ]}
                        style={{ width: 124 }}
                      />
                    </Box>

                    <Box
                      background="#DEDEDE 0% 0% no-repeat padding-box;"
                      borderRadius="5px"
                      padding="10px 0px"
                      margin="10px"
                    >
                      <Box
                        marginLeft="auto"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Text
                          marginLeft="10px"
                          fontWeight="bold"
                          fontStyle="italic"
                        >
                          {"Line item  extractor".toUpperCase()}
                        </Text>
                      </Box>

                      {fp?.fieldExtractor ? (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h={
                              fp?.showBlock1 || fp?.showBlock2 ? "auto" : "40px"
                            }
                          >
                            <Box display="flex" alignItems="center" w="253px">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                              >
                                Block
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Select a block in this line. Click for more
                                    information
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box display="flex" flexDirection="column">
                              <Box display="flex" alignItems="center">
                                <Tooltip title={fp?.block}>
                                  <input
                                    onClick={() => {
                                      dispatch(
                                        lineFieldPropertiesActive({
                                          name: `lineFieldPropertiesBlockActive${
                                            index + 1
                                          }`,
                                        })
                                      );
                                    }}
                                    onChange={(e) => {
                                      const values = [...fieldProperties];
                                      values[index].valueType = "Select";
                                      if (values[index]?.block?.length <= 0) {
                                        alert(
                                          "please select value from image to proceed"
                                        );
                                        e.preventDefault();
                                      } else {
                                        values[index].block = e.target.value;
                                        values[index].data["Text"] =
                                          e.target.value;

                                        dispatch(saveLineField(values));
                                        dispatch(
                                          lineFieldPropertiesText({
                                            name: `lineFieldPropertiesBlock${
                                              index + 1
                                            }`,
                                            text: e.target.value,
                                          })
                                        );
                                      }
                                    }}
                                    value={fp?.block}
                                    type="text"
                                    placeholder="click a block on the image"
                                    style={{
                                      width: 200,

                                      // marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Tooltip>
                                <img
                                  src={Add}
                                  height={"16px"}
                                  width="16px"
                                  style={{ marginLeft: 8, cursor: "pointer" }}
                                  onClick={() => addBlock(index)}
                                />
                              </Box>

                              {fp?.showBlock1 && (
                                <Box
                                  display="flex"
                                  marginTop="10px"
                                  alignItems="center"
                                >
                                  <Tooltip title={fp?.block1}>
                                    <input
                                      onClick={() => {
                                        dispatch(
                                          lineFieldPropertiesActive({
                                            name: `Extra1lineFieldPropertiesBlockActive${
                                              index + 1
                                            }`,
                                          })
                                        );
                                      }}
                                      onChange={(e) => {
                                        const values = [...fieldProperties];
                                        if (
                                          values[index]?.block1?.length <= 0
                                        ) {
                                          alert(
                                            "please select value from image to proceed"
                                          );
                                          e.preventDefault();
                                        } else {
                                          if (!e.target.value) {
                                            values[index].block1 = "";
                                            values[index].data1 = {};
                                          }
                                          values[index].valueType = "Select";
                                          values[index].block1 = e.target.value;
                                          values[index].data1["Text"] =
                                            e.target.value;
                                          dispatch(saveLineField(values));
                                          dispatch(
                                            lineFieldPropertiesText({
                                              name: `Extra1lineFieldPropertiesBlock${
                                                index + 1
                                              }`,
                                              text: e.target.value,
                                            })
                                          );
                                        }
                                      }}
                                      value={fp?.block1}
                                      type="text"
                                      placeholder="click a block on the image"
                                      style={{
                                        width: 200,

                                        // marginRight: 20,
                                        fontStyle: "italic",
                                        padding: 4,
                                        border: "1px solid gray",
                                        borderRadius: 4,
                                      }}
                                    />
                                  </Tooltip>
                                  <img
                                    src={Minus}
                                    height={"16px"}
                                    width="16px"
                                    style={{ marginLeft: 8, cursor: "pointer" }}
                                    onClick={() =>
                                      deleteBlock(index, "showIndex1")
                                    }
                                  />
                                </Box>
                              )}
                              {fp?.showBlock2 && (
                                <Box
                                  display="flex"
                                  marginTop="10px"
                                  alignItems="center"
                                >
                                  <Tooltip title={fp?.block2}>
                                    <input
                                      onClick={() => {
                                        dispatch(
                                          lineFieldPropertiesActive({
                                            name: `Extra2lineFieldPropertiesBlockActive${
                                              index + 1
                                            }`,
                                          })
                                        );
                                      }}
                                      onChange={(e) => {
                                        const values = [...fieldProperties];
                                        if (
                                          values[index]?.block2?.length <= 0
                                        ) {
                                          alert(
                                            "please select value from image to proceed"
                                          );
                                          e.preventDefault();
                                        } else {
                                          if (!e.target.value) {
                                            values[index].block2 = "";
                                            values[index].data2 = {};
                                          }
                                          values[index].valueType = "Select";
                                          values[index].block2 = e.target.value;
                                          values[index].data2["Text"] =
                                            e.target.value;
                                          dispatch(saveLineField(values));
                                          dispatch(
                                            lineFieldPropertiesText({
                                              name: `Extra2lineFieldPropertiesBlock${
                                                index + 1
                                              }`,
                                              text: e.target.value,
                                            })
                                          );
                                        }
                                      }}
                                      value={fp?.block2}
                                      type="text"
                                      placeholder="click a block on the image"
                                      style={{
                                        width: 200,

                                        // marginRight: 20,
                                        fontStyle: "italic",
                                        padding: 4,
                                        border: "1px solid gray",
                                        borderRadius: 4,
                                      }}
                                    />
                                  </Tooltip>
                                  <img
                                    src={Minus}
                                    height={"16px"}
                                    width="16px"
                                    style={{ marginLeft: 8, cursor: "pointer" }}
                                    onClick={() =>
                                      deleteBlock(index, "showIndex2")
                                    }
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>
                          {(fp?.showBlock1 || fp?.showBlock2) && (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="253px">
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0px"
                                >
                                  Add fields as
                                </Text>
                                <Tooltip title="tooltip" placement="right">
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="tooltip"
                                  />
                                </Tooltip>
                              </Box>

                              <Box>
                                <select
                                  onChange={(e) => {
                                    const val = e.target.value;
                                    if (val === "Select") return;
                                    const values = [...fieldProperties];
                                    values[index].addFieldsAs = val;
                                    dispatch(saveLineField(values));
                                  }}
                                  value={fp?.addFieldsAs}
                                  defaultValue="false"
                                  style={{
                                    width: 120,
                                    fontWeight: "bold",
                                    padding: 3,
                                  }}
                                >
                                  {["Select", "list", "total"].map((item) => (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </Box>
                            </Box>
                          )}

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center" w="253px">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                              >
                                Is optional
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Field that are present in some line items
                                    and is not available in others are
                                    considered to be optional. Find a file in
                                    which the field is present and select them.
                                    (Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <select
                                onChange={(e) => {
                                  const values = [...fieldProperties];
                                  values[index].isOptional =
                                    !values[index].isOptional;
                                  dispatch(saveLineField(values));
                                }}
                                value={fp?.isOptional}
                                defaultValue="false"
                                style={{
                                  width: 120,
                                  fontWeight: "bold",
                                  padding: 3,
                                }}
                              >
                                {["true", "false"].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center" w="253px">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                              >
                                Value type
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Select the data type of the field . Click
                                    for more information
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <select
                                className={
                                  lineFieldsError[index] ? "borderBlink" : ""
                                }
                                onChange={(e) => {
                                  const values = [...fieldProperties];
                                  values[index].valueType =
                                    e.target.value === "Select"
                                      ? ""
                                      : e.target.value;

                                  if (
                                    fieldProperties[index]?.block?.length == 0
                                  ) {
                                    alert("please enter value in block");
                                  } else {
                                    dispatch(saveLineField(values));
                                    Object.keys(valueTypeRegexData).find(
                                      (key) => {
                                        if (e.target.value === "ANY") {
                                          let lineFieldsErrorStatus =
                                            lineFieldsError;
                                          lineFieldsErrorStatus[index] = false;
                                          setLineFieldsError(
                                            lineFieldsErrorStatus
                                          );
                                          return;
                                        }
                                        if (key === e.target.value) {
                                          let checkDate1 = true;
                                          let checkDate2 = true;
                                          let checkDate3 = true;
                                          if (e.target.value === "DATE") {
                                            if (
                                              fieldProperties[index]?.block
                                                ?.length > 0
                                            ) {
                                              checkDate1 = matchInArray(
                                                fieldProperties[index]?.block,
                                                valueTypeRegexData[key]
                                              );
                                            }
                                            if (
                                              fieldProperties[index]?.block1
                                                ?.length > 0
                                            ) {
                                              checkDate2 = matchInArray(
                                                fieldProperties[index]?.block1,
                                                valueTypeRegexData[key]
                                              );
                                            }
                                            if (
                                              fieldProperties[index]?.block2
                                                ?.length > 0
                                            ) {
                                              checkDate3 = matchInArray(
                                                fieldProperties[index]?.block2,
                                                valueTypeRegexData[key]
                                              );
                                            }
                                            console.log(
                                              checkDate1,
                                              checkDate2,
                                              checkDate3,
                                              fieldProperties[index]?.block2
                                            );
                                            if (
                                              !checkDate1 ||
                                              !checkDate2 ||
                                              !checkDate3
                                            ) {
                                              values[index].valueType =
                                                "Select";
                                              alert("Select valid value type");
                                              let lineFieldsErrorStatus =
                                                lineFieldsError;
                                              lineFieldsErrorStatus[
                                                index
                                              ] = true;
                                              setLineFieldsError(
                                                lineFieldsErrorStatus
                                              );
                                            } else {
                                              let lineFieldsErrorStatus =
                                                lineFieldsError;
                                              lineFieldsErrorStatus[
                                                index
                                              ] = false;
                                              setLineFieldsError(
                                                lineFieldsErrorStatus
                                              );
                                            }
                                          } else {
                                            let regex = new RegExp(
                                              valueTypeRegexData[key]
                                            );
                                            let checkBlock1 = true;
                                            let checkBlock2 = true;
                                            let checkBlock3 = true;

                                            if (
                                              fieldProperties[index]?.block
                                                ?.length > 0
                                            ) {
                                              // debugger
                                              checkBlock1 = regex.test(
                                                fieldProperties[index]?.block
                                              );
                                            }
                                            if (
                                              fieldProperties[index]?.block1
                                                ?.length > 0
                                            ) {
                                              checkBlock2 = regex.test(
                                                fieldProperties[index]?.block1
                                              );
                                            }
                                            if (
                                              fieldProperties[index]?.block2
                                                ?.length > 0
                                            ) {
                                              checkBlock3 = regex.test(
                                                fieldProperties[index]?.block2
                                              );
                                            }
                                            if (
                                              !checkBlock1 ||
                                              !checkBlock2 ||
                                              !checkBlock3
                                            ) {
                                              values[index].valueType =
                                                "Select";
                                              alert("Select valid value type");
                                              let lineFieldsErrorStatus =
                                                lineFieldsError;
                                              lineFieldsErrorStatus[
                                                index
                                              ] = true;
                                              setLineFieldsError(
                                                lineFieldsErrorStatus
                                              );
                                            } else {
                                              let lineFieldsErrorStatus =
                                                lineFieldsError;
                                              lineFieldsErrorStatus[
                                                index
                                              ] = false;
                                              setLineFieldsError(
                                                lineFieldsErrorStatus
                                              );
                                            }
                                          }
                                        }
                                      }
                                    );
                                  }
                                }}
                                value={fp?.valueType}
                                style={{
                                  width: 120,
                                  fontWeight: "bold",
                                  padding: 3,
                                  outline: "0px",
                                  border: lineFieldsError[index]
                                    ? "2px solid red"
                                    : "1px solid gray",
                                }}
                              >
                                {[
                                  "Select",
                                  "INTEGER",
                                  "FLOAT",
                                  "ALPHABETS",
                                  "ALPHANUMERIC",
                                  "NUMERIC",
                                  "ANY",
                                  "DATE",
                                ].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                            w="220px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                marginBottom="0px"
                                width={fp?.blockExtractor ? "95px" : "auto"}
                              >
                                {fp?.blockExtractor
                                  ? "Value block"
                                  : "Identifier"}
                              </Text>
                              <Tooltip title="tooltip" placement="right">
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>

                            <Box display="flex" alignItems="center">
                              {fp?.blockExtractor ? (
                                <input
                                  onClick={() => {
                                    dispatch(
                                      lineFieldPropertiesActive({
                                        name: `lineFieldPropertiesValueActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...fieldProperties];

                                    values[index].valueBlock = e.target.value;

                                    dispatch(saveLineField(values));
                                    dispatch(
                                      lineFieldPropertiesText({
                                        name: `lineFieldPropertiesValue${
                                          index + 1
                                        }`,
                                        text: e.target.value,
                                      })
                                    );
                                  }}
                                  value={fp?.valueBlock}
                                  placeholder="click on a block in image"
                                  type="text"
                                  style={{
                                    width: 221,
                                    marginLeft: fp?.blockExtractor ? 85 : 113,

                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              ) : (
                                <input
                                  onClick={() => {
                                    dispatch(
                                      lineFieldPropertiesActive({
                                        name: `lineFieldPropertiesIdentfierActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...fieldProperties];

                                    values[index].identifier = e.target.value;

                                    dispatch(saveLineField(values));
                                    dispatch(
                                      lineFieldPropertiesText({
                                        name: `lineFieldPropertiesIdentifier${
                                          index + 1
                                        }`,
                                        text: e.target.value,
                                      })
                                    );
                                  }}
                                  value={fp?.identifier}
                                  placeholder="click on a block in image"
                                  type="text"
                                  style={{
                                    width: 221,
                                    marginLeft: 139,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              )}

                              {/* <img src={Add} style={{ height: 20, marginRight: 10 }} /> */}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                              >
                                {fp?.blockExtractor
                                  ? " Value type"
                                  : "Extract type"}
                              </Text>
                              <Tooltip title="tooltip" placement="right">
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <select
                                onChange={(e) => {
                                  const values = [...fieldProperties];
                                  if (fp?.blockExtractor) {
                                    values[index].blockType = e.target.value;
                                  }
                                  if (fp?.formBasedExtraction) {
                                    values[index].extractType = e.target.value;
                                  }
                                  dispatch(saveLineField(values));
                                }}
                                value={
                                  fp?.blockExtractor
                                    ? fp?.blockType
                                    : fp?.extractType
                                }
                                style={{
                                  width: 250,
                                  fontWeight: "bold",
                                  padding: 3,
                                  marginRight: 30,
                                }}
                              >
                                {fp?.blockExtractor
                                  ? ["text", "date", "amount($)"].map(
                                      (item, i) => (
                                        <option key={i} value={item}>
                                          {item}
                                        </option>
                                      )
                                    )
                                  : ["form", "above", "below"].map((item) => (
                                      <option key={item} value={item}>
                                        {item}
                                      </option>
                                    ))}
                              </select>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                    {/* advanced */}
                    {!fp?.blockExtractor && (
                      <Box
                        background="#DEDEDE 0% 0% no-repeat padding-box;"
                        borderRadius="5px"
                        margin="10px"
                        h="auto"
                        padding="5px"
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          h="auto"
                          onClick={(e) => {
                            e.stopPropagation();
                            const values = [...fieldProperties];
                            values[index].isAdvanced =
                              !values[index].isAdvanced;
                            dispatch(saveLineField(values));
                          }}
                          cursor="pointer"
                          padding="3px 0"
                        >
                          <Text
                            marginLeft="10px"
                            fontWeight="bold"
                            fontStyle="italic"
                            marginBottom="0px"
                          >
                            ADVANCED
                          </Text>
                          <img
                            src={fp?.isAdvanced ? Up : Down}
                            style={{ marginRight: 20, cursor: "pointer" }}
                            alt="tooltip"
                          />
                        </Box>

                        {fp.isAdvanced && fp?.fieldExtractor && (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center" w="253px">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                              >
                                Also add as
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                    style={{ color: "white" }}
                                  >
                                    Select the field with same mapping. (Click
                                    for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="tooltip"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <Tooltip
                                title={fp?.alsoAddAs ? fp?.alsoAddAs : "empty"}
                              >
                                <SelectPicker
                                  onClean={() => {
                                    let svl = selectedFieldOptions.filter(
                                      (item) => {
                                        const val = Object.values(item);
                                        if (val[0] !== fp?.alsoAddAs) {
                                          return item;
                                        }
                                      }
                                    );

                                    dispatch(selectedLineFields(svl));

                                    const vals = [...fieldProperties];
                                    vals[index].alsoAddAs = null;
                                    vals[index].alsoAddJson = null;
                                  }}
                                  value={fp.alsoAddAs}
                                  disabledItemValues={alsoAddAsOptionsDisabled}
                                  onChange={(v) => {
                                    const values = [...fieldProperties];
                                    if (!values[index].alsoAddAs) {
                                      values[index].alsoAddAs = v;
                                      const json = alsoAddAsOptions.find(
                                        (item) => item.value === v
                                      );
                                      values[index].alsoAddJson = json.JsonKey;
                                      dispatch(saveLineField(values));
                                      if (v !== "Unwanted")
                                        dispatch(
                                          selectedLineFields([
                                            ...selectedFieldOptions,
                                            { [index]: v },
                                          ])
                                        );
                                    }
                                  }}
                                  data={alsoAddAsOptions}
                                  style={{ width: 224 }}
                                  renderMenu={(menu) => {
                                    if (alsoAddAsOptions.length === 0) {
                                      return (
                                        <p
                                          style={{
                                            padding: 4,
                                            color: "#999",
                                            textAlign: "center",
                                          }}
                                        >
                                          <i
                                            className="fa fa-circle-o-notch fa-spin"
                                            style={{ fontSize: "16px" }}
                                          ></i>{" "}
                                          &nbsp; Loading...
                                        </p>
                                      );
                                    }
                                    return menu;
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                        )}

                        {fp.isAdvanced && fp?.fieldExtractor && (
                          <Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="253px">
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0px"
                                >
                                  Is multi value
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      when a single field contains list of
                                      values and has to be clubbed together then
                                      this is set to true. Click for more
                                      information
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="tooltip"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <select
                                  onChange={(e) => {
                                    const values = [...fieldProperties];
                                    values[index].isMultiValue =
                                      !values[index].isMultiValue;
                                    dispatch(saveLineField(values));
                                  }}
                                  value={fp?.isMultiValue}
                                  style={{
                                    width: 120,
                                    fontWeight: "bold",
                                    padding: 3,
                                  }}
                                >
                                  {["false", "true"].map((item, i) => (
                                    <option key={i} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </Box>
                            </Box>
                            <Box
                              display={
                                fp?.isMultiValue == true ? "flex" : "none"
                              }
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="253px">
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0px"
                                >
                                  Join as
                                </Text>
                                <Tooltip title="tooltip" placement="right">
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="tooltip"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <select
                                  onChange={(e) => {
                                    const values = [...fieldProperties];
                                    values[index].joinAs = e.target.value;
                                    dispatch(saveLineField(values));
                                  }}
                                  value={fp?.joinAs}
                                  style={{
                                    width: 120,
                                    fontWeight: "bold",
                                    padding: 3,
                                  }}
                                >
                                  {["text", "array"].map((item, i) => (
                                    <option key={i} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="253px">
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0px"
                                >
                                  is Wrappable field
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      This is set to TRUE when the field is
                                      dispersed into multiple lines. Click for
                                      more information
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="tooltip"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <select
                                  onChange={(e) => {
                                    const values = [...fieldProperties];
                                    const val = e.target.value;
                                    if (val === "true")
                                      values[index].isWrappableFilled = true;
                                    else {
                                      values[index].isWrappableFilled = false;
                                      let svl = selectedFieldOptions.filter(
                                        (item) => {
                                          const val = Object.values(item);
                                          if (val[0] !== fp?.newFieldValue) {
                                            return item;
                                          }
                                        }
                                      );
                                      dispatch(selectedLineFields(svl));
                                      values[index].newFieldValue = null;
                                      values[index].newFieldValueJson = null;
                                    }

                                    dispatch(saveLineField(values));
                                  }}
                                  value={fp?.isWrappableFilled}
                                  style={{
                                    width: 120,
                                    fontWeight: "bold",
                                    padding: 3,
                                  }}
                                >
                                  {["true", "false"].map((item, i) => (
                                    <option key={i} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </Box>
                            </Box>
                            {fp.isWrappableFilled && (
                              <>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                  h="40px"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    w="253px"
                                  >
                                    <Text
                                      marginLeft="10px"
                                      fontWeight="bold"
                                      marginBottom="0px"
                                    >
                                      Wrap from
                                    </Text>
                                    <Tooltip
                                      interactive
                                      title={
                                        <a
                                          href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                          target="_blank"
                                          style={{ color: "white" }}
                                        >
                                          It is configured when is wrappable
                                          field is set to True. There are three
                                          types – below: when the wrappable
                                          field is from below, above- when the
                                          wrappable field is from above and
                                          both- when the wrappable field is
                                          present in both below and above.(Click
                                          for more information)
                                        </a>
                                      }
                                      placement="right"
                                    >
                                      <img
                                        src={Help}
                                        style={{ height: 12, marginLeft: 6 }}
                                        alt="tooltip"
                                      />
                                    </Tooltip>
                                  </Box>

                                  <Box marginRight="10px">
                                    <select
                                      onChange={(e) => {
                                        // if (e.target.value === "Select") return;
                                        const values = [...fieldProperties];
                                        values[index].wrapFrom = e.target.value;
                                        dispatch(saveLineField(values));
                                      }}
                                      value={fp?.wrapFrom}
                                      style={{
                                        width: 120,
                                        fontWeight: "bold",
                                        padding: 3,
                                      }}
                                    >
                                      {["above", "below", "both"].map(
                                        (item, i) => (
                                          <option key={i} value={item}>
                                            {item}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </Box>
                                </Box>

                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                  h="40px"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    w="253px"
                                  >
                                    <Text
                                      marginLeft="10px"
                                      fontWeight="bold"
                                      marginBottom="0px"
                                    >
                                      Wrap values into
                                    </Text>
                                    <Tooltip
                                      interactive
                                      title={
                                        <a
                                          href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                          target="_blank"
                                          style={{ color: "white" }}
                                        >
                                          It describes the type of required
                                          output. Whether in the form of text or
                                          list.(Click for more information)
                                        </a>
                                      }
                                      placement="right"
                                    >
                                      <img
                                        src={Help}
                                        style={{ height: 12, marginLeft: 6 }}
                                        alt="tooltip"
                                      />
                                    </Tooltip>
                                  </Box>

                                  <Box marginRight="10px">
                                    <select
                                      onChange={(e) => {
                                        // if (e.target.value === "Select") return;

                                        const values = [...fieldProperties];
                                        if (e.target.value !== "new_field") {
                                          values[index].newFieldName = null;
                                          values[index].newFieldNameJson = null;
                                        }
                                        values[index].wrapValuesInto =
                                          e.target.value;
                                        dispatch(saveLineField(values));
                                      }}
                                      value={fp?.wrapValuesInto}
                                      style={{
                                        width: 120,
                                        fontWeight: "bold",
                                        padding: 3,
                                      }}
                                    >
                                      {[
                                        "text",
                                        "list",
                                        "new_field",
                                        "non_zero",
                                        "sum",
                                        "add_to_next_field",
                                        "append_to_next_field",
                                      ].map((item, i) => (
                                        <option key={i} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                  </Box>
                                </Box>
                                <Box
                                  display={
                                    fp?.wrapValuesInto === "new_field"
                                      ? "flex"
                                      : "none"
                                  }
                                  alignItems="center"
                                  justifyContent="flex-start"
                                  h="40px"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    w="253px"
                                  >
                                    <Text
                                      marginLeft="10px"
                                      fontWeight="bold"
                                      marginBottom="0px"
                                    >
                                      New Field name
                                    </Text>
                                    <span
                                      style={{ color: "red", fontSize: 20 }}
                                    >
                                      *
                                    </span>
                                    <Tooltip
                                      interactive
                                      title={
                                        <a
                                          href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                          target="_blank"
                                          style={{ color: "white" }}
                                        >
                                          Wrap values will be added as new
                                          field.(Click for more information)
                                        </a>
                                      }
                                      placement="right"
                                    >
                                      <img
                                        src={Help}
                                        style={{ height: 12, marginLeft: 6 }}
                                        alt="tooltip"
                                      />
                                    </Tooltip>
                                  </Box>

                                  <Box marginRight="10px">
                                    <Tooltip
                                      title={
                                        fp?.newFieldName
                                          ? fp?.newFieldName
                                          : "empty"
                                      }
                                    >
                                      <SelectPicker
                                        onClean={() => {
                                          let svl = selectedFieldOptions.filter(
                                            (item) => {
                                              const val = Object.values(item);
                                              if (val[0] !== fp?.newFieldName) {
                                                return item;
                                              }
                                            }
                                          );

                                          dispatch(selectedLineFields(svl));

                                          const vals = [...fieldProperties];

                                          vals[index].newFieldName = null;
                                          vals[index].newFieldNameJson = null;
                                        }}
                                        value={fp.newFieldName}
                                        disabledItemValues={
                                          fieldOptionsDisabled
                                        }
                                        onChange={(v) => {
                                          const values = [...fieldProperties];
                                          if (!values[index].newFieldName) {
                                            values[index].newFieldName = v;
                                            const json =
                                              fieldPropertiesOptions.find(
                                                (item) => item.value === v
                                              );

                                            values[index].newFieldNameJson =
                                              json.JsonKey;
                                            dispatch(saveLineField(values));
                                            if (v !== "Unwanted")
                                              dispatch(
                                                selectedLineFields([
                                                  ...selectedFieldOptions,
                                                  { [index]: v },
                                                ])
                                              );
                                          }
                                        }}
                                        data={fieldPropertiesOptions}
                                        style={{ width: 217 }}
                                        renderMenu={(menu) => {
                                          if (
                                            fieldPropertiesOptions.length === 0
                                          ) {
                                            return (
                                              <p
                                                style={{
                                                  padding: 4,
                                                  color: "#999",
                                                  textAlign: "center",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-circle-o-notch fa-spin"
                                                  style={{ fontSize: "16px" }}
                                                ></i>{" "}
                                                &nbsp; Loading...
                                              </p>
                                            );
                                          }
                                          return menu;
                                        }}
                                      />
                                    </Tooltip>
                                    {/* <select
                                      onChange={(e) => {
                                        const values = [...fieldProperties];
                                        const val = e.target.value;
                                        const json =
                                          fieldPropertiesOptions.find(
                                            (item) => item.value === val
                                          );

                                        values[index].newFieldName = val;
                                        values[index].newFieldNameJson =
                                          json.JsonKey;
                                        dispatch(saveLineField(values));
                                      }}
                                      value={fp?.newFieldName}
                                      style={{
                                        fontWeight: "bold",
                                        padding: 3,
                                      }}
                                    >
                                      {fieldPropertiesOptions.map((item, i) => (
                                        <option key={i} value={item.label}>
                                          {item.label}
                                        </option>
                                      ))}
                                    </select> */}

                                    {/* <input
                                      placeholder="Enter text"
                                      type="text"
                                      value={fp?.newFieldName}
                                      onChange={(e) => {
                                        const values = [...fieldProperties];
                                        values[index].newFieldName =
                                          e.target.value;
                                        dispatch(saveLineField(values))
                                      }}
                                      style={{
                                        width: 216,
                                        fontStyle: "italic",
                                        padding: 4,
                                        border: "1px solid gray",
                                        borderRadius: 4,
                                      }}
                                    /> */}
                                  </Box>
                                </Box>
                              </>
                            )}
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="253px">
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0px"
                                >
                                  Field is splittable
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      If the values in a single field have to be
                                      split into different fields, then this is
                                      set to True.For
                                      Example:“02/12/2021-02/12/2021. (Click for
                                      more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="tooltip"
                                  />
                                </Tooltip>
                              </Box>

                              <Box marginRight="10px">
                                <select
                                  onChange={(e) => {
                                    const values = [...fieldProperties];
                                    values[index].isSplittable =
                                      !values[index].isSplittable;
                                    if (!values[index].isSplittable) {
                                      let svl = selectedFieldOptions.filter(
                                        (item) => {
                                          const val = Object.values(item);
                                          if (val[0] !== fp?.newFieldValue) {
                                            return item;
                                          }
                                        }
                                      );
                                      dispatch(selectedLineFields(svl));
                                      values[index].newFieldValue = null;
                                      values[index].newFieldValueJson = null;
                                      values[index].captureSplitValueAs =
                                        "append to next field";
                                    }
                                    dispatch(saveLineField(values));
                                  }}
                                  value={fp?.isSplittable}
                                  style={{
                                    width: 120,
                                    fontWeight: "bold",
                                    padding: 3,
                                  }}
                                >
                                  {["true", "false"].map((item, i) => (
                                    <option key={i} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </Box>
                            </Box>
                            {fp?.isSplittable && (
                              <>
                                <Box
                                  display={"flex"}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                  h="40px"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    w="253px"
                                  >
                                    <Text
                                      marginLeft="10px"
                                      fontWeight="bold"
                                      marginBottom="0px"
                                    >
                                      Separator based split
                                    </Text>
                                    <Tooltip
                                      interactive
                                      title={
                                        <a
                                          href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                          target="_blank"
                                          style={{ color: "white" }}
                                        >
                                          By default, the separation is based on
                                          length. If you need the separate the
                                          fields based on any punctuation or
                                          symbols then this is set to True.
                                          (Click for more information)
                                        </a>
                                      }
                                      placement="right"
                                    >
                                      <img
                                        src={Help}
                                        style={{ height: 12, marginLeft: 6 }}
                                        alt="tooltip"
                                      />
                                    </Tooltip>
                                  </Box>

                                  <Box marginRight="10px">
                                    <select
                                      onChange={(e) => {
                                        const values = [...fieldProperties];
                                        values[index].separatorBasedSplit =
                                          !values[index].separatorBasedSplit;
                                        dispatch(saveLineField(values));
                                        const val =
                                          e.target.value === "true"
                                            ? true
                                            : false;
                                        setShowSplitLength(val);
                                      }}
                                      value={fp?.separatorBasedSplit}
                                      style={{
                                        width: 120,
                                        fontWeight: "bold",
                                        padding: 3,
                                      }}
                                    >
                                      {["true", "false"].map((item, i) => (
                                        <option key={i} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                    </select>
                                  </Box>
                                </Box>
                                <Box
                                  display={
                                    fp?.separatorBasedSplit === "false" ||
                                    !fp?.separatorBasedSplit
                                      ? "flex"
                                      : "none"
                                  }
                                  alignItems="center"
                                  justifyContent="flex-start"
                                  h="40px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    fontWeight="bold"
                                    marginBottom="0px"
                                  >
                                    Split length
                                  </Text>

                                  <Box marginLeft="167px">
                                    <input
                                      onChange={(e) => {
                                        const values = [...fieldProperties];
                                        values[index].splitLength =
                                          e.target.value;
                                        dispatch(saveLineField(values));
                                      }}
                                      type="number"
                                      min="0"
                                      value={fp?.splitLength}
                                      style={{
                                        width: 50,
                                        marginRight: 20,
                                        fontStyle: "italic",
                                        padding: 4,
                                        border: "1px solid gray",
                                        borderRadius: 4,
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </>
                            )}
                            <Box
                              display="none"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="253px">
                                <Text marginLeft="10px" fontWeight="bold">
                                  Split length
                                </Text>
                                <Tooltip title="tooltip" placement="right">
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="tooltip"
                                  />
                                </Tooltip>
                              </Box>
                              <input
                                type="number"
                                min="0"
                                defaultValue="1"
                                style={{
                                  width: 50,
                                  marginRight: 20,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                            <Box
                              display={fp?.isSplittable ? "flex" : "none"}
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="253px">
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0px"
                                >
                                  Capture split value as
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      It definesthe way of capturing the split
                                      values.(Click for more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="tooltip"
                                  />
                                </Tooltip>
                              </Box>

                              <Box marginRight="10px">
                                <select
                                  onChange={(e) => {
                                    const values = [...fieldProperties];
                                    const val = e.target.value;
                                    console.log(
                                      "🚀 ~ file: Line.js ~ line 6326 ~ Line ~ val",
                                      val
                                    );

                                    values[index].captureSplitValueAs = val;
                                    if (
                                      val !== "new_field" ||
                                      val !== "MANDATORY new field"
                                    ) {
                                      let svl = selectedFieldOptions.filter(
                                        (item) => {
                                          const val = Object.values(item);
                                          if (val[0] !== fp?.newFieldValue) {
                                            return item;
                                          }
                                        }
                                      );
                                      dispatch(selectedLineFields(svl));
                                      values[index].newFieldValue = null;
                                      values[index].newFieldValueJson = null;
                                    }

                                    dispatch(saveLineField(values));
                                  }}
                                  value={fp?.captureSplitValueAs}
                                  style={{
                                    width: 190,
                                    fontWeight: "bold",
                                    padding: 3,
                                  }}
                                >
                                  {[
                                    "append to next field",
                                    "new field",
                                    "MANDATORY new field",
                                  ].map((item) => (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </Box>
                            </Box>

                            <Box
                              display={
                                (fp?.captureSplitValueAs === "new field" ||
                                  fp?.captureSplitValueAs ===
                                    "MANDATORY new field") &&
                                fp?.isSplittable
                                  ? "flex"
                                  : "none"
                              }
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="253px">
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0px"
                                >
                                  New field value
                                  <span style={{ color: "red", fontSize: 20 }}>
                                    *
                                  </span>
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      Split value is added as new field for
                                      selected mapping. (Click for more
                                      information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="tooltip"
                                  />
                                </Tooltip>
                              </Box>
                              <Tooltip
                                title={
                                  fp?.newFieldValue
                                    ? fp?.newFieldValue
                                    : "empty"
                                }
                              >
                                <SelectPicker
                                  onClean={() => {
                                    let svl = selectedFieldOptions.filter(
                                      (item) => {
                                        const val = Object.values(item);
                                        if (val[0] !== fp?.newFieldValue) {
                                          return item;
                                        }
                                      }
                                    );

                                    dispatch(selectedLineFields(svl));

                                    const vals = [...fieldProperties];

                                    vals[index].newFieldValue = null;
                                    vals[index].newFieldValueJson = null;
                                  }}
                                  value={fp.newFieldValue}
                                  disabledItemValues={fieldOptionsDisabled}
                                  onChange={(v) => {
                                    const values = [...fieldProperties];
                                    if (!values[index].newFieldValue) {
                                      values[index].newFieldValue = v;
                                      const json = fieldPropertiesOptions.find(
                                        (item) => item.value === v
                                      );

                                      values[index].newFieldValueJson =
                                        json.JsonKey;
                                      dispatch(saveLineField(values));
                                      if (v !== "Unwanted")
                                        dispatch(
                                          selectedLineFields([
                                            ...selectedFieldOptions,
                                            { [index]: v },
                                          ])
                                        );
                                    }
                                  }}
                                  data={fieldPropertiesOptions}
                                  style={{ width: 217 }}
                                  renderMenu={(menu) => {
                                    if (fieldPropertiesOptions.length === 0) {
                                      return (
                                        <p
                                          style={{
                                            padding: 4,
                                            color: "#999",
                                            textAlign: "center",
                                          }}
                                        >
                                          <i
                                            className="fa fa-circle-o-notch fa-spin"
                                            style={{ fontSize: "16px" }}
                                          ></i>{" "}
                                          &nbsp; Loading...
                                        </p>
                                      );
                                    }
                                    return menu;
                                  }}
                                />
                              </Tooltip>
                              {/* <select
                                onChange={(e) => {
                                  const values = [...fieldProperties];
                                  const val = e.target.value;
                                  const json = fieldPropertiesOptions.find(
                                    (item) => item.value === val
                                  );

                                  values[index].newFieldValue = val;
                                  values[index].newFieldValueJson =
                                    json.JsonKey;
                                  dispatch(saveLineField(values));
                                }}
                                value={fp?.newFieldValue}
                                style={{
                                  width: 190,
                                  fontWeight: "bold",
                                  padding: 3,
                                }}
                              >
                                {fieldPropertiesOptions.map((item, i) => (
                                  <option key={i} value={item.label}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                             */}
                            </Box>
                          </Box>
                        )}
                        {fp.isAdvanced && fp?.formBasedExtraction && (
                          <>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  End Identifier
                                </Text>
                                <Tooltip title="tooltip" placement="right">
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <input
                                  onClick={() => {
                                    dispatch(
                                      lineFieldPropertiesActive({
                                        name: `lineFieldPropertiesEndIdentifierActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...fieldProperties];
                                    values[index].endIdentifier =
                                      e.target.value;
                                    dispatch(saveLineField(values));
                                  }}
                                  value={fp?.endIdentifier}
                                  type="text"
                                  placeholder="click on a block in image"
                                  style={{
                                    width: 240,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  ID limit
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      If there are multiple occurrences of the
                                      identifier, The nth (integer value
                                      specified) occurrence will be chosen as
                                      the identifier block.(Click for more
                                      information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  {" "}
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <input
                                  onChange={(e) => {
                                    const values = [...fieldProperties];
                                    values[index].idlimit = e.target.value;
                                    dispatch(saveLineField(values));
                                  }}
                                  value={fp?.idlimit}
                                  type="number"
                                  min="1"
                                  defaultValue="1"
                                  style={{
                                    width: 60,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box w="215px" display="flex" alignItems="center">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  Index range
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      From the list of extracted blocks, blocks
                                      from index x (inclusive) till index y
                                      (exclusive) will be captured.(Click for
                                      more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box
                                marginRight="10px"
                                display="flex"
                                alignItems="center"
                              >
                                <input
                                  onChange={(e) => {
                                    const values = [...fieldProperties];
                                    values[index].indexRange1 = e.target.value;
                                    dispatch(saveLineField(values));
                                  }}
                                  value={fp?.indexRange1}
                                  type="number"
                                  defaultValue="0"
                                  min="-10"
                                  style={{
                                    width: 60,
                                    height: 24,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                    marginRight: 10,
                                  }}
                                />
                                <Text fontStyle="italic"> To </Text>
                                <input
                                  onChange={(e) => {
                                    const values = [...fieldProperties];
                                    values[index].indexRange2 = e.target.value;
                                    dispatch(saveLineField(values));
                                  }}
                                  value={fp?.indexRange2}
                                  type="number"
                                  max="10"
                                  style={{
                                    width: 60,
                                    height: 24,
                                    marginLeft: 10,

                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  No. of blocks
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/GkK9wVuC6hFoGD5N?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      It is used when the end identifier is not
                                      found. By default, it is 1.If there is a
                                      specific number of blocks that you need
                                      that has to be extracted after the
                                      identifier then the number of blocks field
                                      is changed.(Click for more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <input
                                  onChange={(e) => {
                                    const values = [...fieldProperties];
                                    if (e.target.value == "0") return;
                                    else
                                      values[index].noOfBlocks = e.target.value;
                                    dispatch(saveLineField(values));
                                  }}
                                  value={fp?.noOfBlocks}
                                  type="number"
                                  min="1"
                                  style={{
                                    width: 60,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>
                            <Box
                              display={showNameType ? "flex" : "none"}
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box w="218px" display="flex" alignItems="center">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  Name type
                                </Text>
                                <Tooltip title="tooltip" placement="right">
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                  />
                                </Tooltip>
                              </Box>

                              <select
                                onChange={(e) => {
                                  const values = [...fieldProperties];
                                  values[index].nameType = e.target.value;
                                  dispatch(saveLineField(values));
                                }}
                                value={fp?.nameType}
                                id="select"
                                style={{
                                  width: 200,

                                  fontWeight: "bold",
                                  padding: 3,
                                }}
                              >
                                {[
                                  "first_middle_last",
                                  "last_first_middle",
                                  "first_last_middle",
                                ].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(Line);
