/* eslint-disable */

import * as actionTypes from "./actionTypes";

const initialState = {
  form: "",
  imageText: "",
  reloaded: false,
  error: "",
  checkAddress: [],
  cardAddress: [],
  checkHeaders: [],
  cardHeaders: [],
  claimAddress: [],
  claimHeaders: [],
  claimFields: [],
  lineFields: [],
  alsoAddAs: [],
};

const FormReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FORM: {
      return {
        ...state,
        form: action.payload,
      };
    }
    case actionTypes.TEXT: {
      // checking for all empty states

      let isEmpty = true;
      Object.entries(state).map(([key, val]) => {
        if (key.includes("Active") && state[key]) {
          isEmpty = false;
        }
      });

      if (isEmpty) return state;

      let name = "";
      Object.entries(state).map(([key]) => {
        if (key.includes("Active") && state[key]) {
          name = key;
        }
      });

      return {
        ...state,
        [name.replace("Active", "")]: action.payload.text,
        [name.replace("Active", "data")]: action.payload.data,
      };
    }

    case actionTypes.TEMPLATE_ACTIVE: {
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });
      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.CLAIM_HEADER_IDEN_ACTIVE: {
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });
      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.TEMPLATE_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }
    case actionTypes.CLAIM_HEADER_IDEN_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }

    case actionTypes.ABOVE: {
      return {
        ...state,
        isAbove: action.payload,
      };
    }

    case actionTypes.DISABLE_CHECK_ADD_ACTIVE: {
      return {
        ...state,
        [action.payload.name]: false,
      };
    }

    case actionTypes.DISABLE_CARD_ADDRESS_ACTIVE: {
      return {
        ...state,
        [action.payload.name]: false,
      };
    }

    case actionTypes.DISABLE_CLAIM_EXTRACTION_ACTIVE: {
      return {
        ...state,
        [action.payload.name]: false,
      };
    }

    case actionTypes.DISABLE_LINE_ITEM_ACTIVE: {
      return {
        ...state,
        [action.payload.name]: false,
      };
    }

    case actionTypes.DISABLE_TEMPLATE_PAGE_IDENTIFIER_ACTIVE: {
      return {
        ...state,
        [action.payload.name]: false,
      };
    }

    case actionTypes.DISABLE_CLAIM_FIELDS_ACTIVE: {
      return {
        ...state,
        [action.payload.name]: false,
      };
    }

    case actionTypes.DISABLE_CLAIM_ADD_ACTIVE: {
      return {
        ...state,
        [action.payload.name]: false,
      };
    }

    case actionTypes.DISABLE_CHECK_ADD_ACTIVE: {
      return {
        ...state,
        [action.payload.name]: false,
      };
    }

    case actionTypes.BELOW: {
      return {
        ...state,
        isBelow: action.payload,
      };
    }

    // case actionTypes.DISABLE_ACTIVE: {
    //   Object.keys(state).map((stateItem) => {
    //     if (stateItem.includes(action.payload)) {
    //       if (stateItem.includes("Active")) {
    //         state[stateItem] = false;
    //       }
    //     }
    //   });

    //   return state;
    // }

    case actionTypes.DISABLE_ACTIVE1: {
      const ns = state;
      Object.entries(ns).map(([key]) => {
        if (key.includes("Active") && ns[key]) {
          ns[key] = false;
        }
      });

      return ns;
    }

    case actionTypes.RELOADED: {
      return {
        ...state,
        reloaded: action.payload,
      };
    }

    case actionTypes.SAVE_DROPDOWN: {
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    }

    case actionTypes.INDETIFICATION_ACTIVE: {
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });
      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.INDETIFICATION_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }

    case actionTypes.CLAIM_EXTRACTION_ACTIVE: {
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });
      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.NEGATE_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }

    case actionTypes.CEHCK_ADDRESS_OPEN: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }

    case actionTypes.CARD_ADDRESS_OPEN: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }
    case actionTypes.SAVE_CLAIM_DATA: {
      return {
        ...state,
        claimData: action.payload,
      };
    }

    case actionTypes.CLAIM_EXTRACTION_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }

    case actionTypes.CLAIM_ADDRESS_ACTIVE: {
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });
      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.ERROR_IN_DROPDOWN: {
      return {
        ...state,
        errorinDropdown: action.payload,
      };
    }

    case actionTypes.CLAIM_ADDRESS_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }

    case actionTypes.CLAIM_HEADER_ACTIVE: {
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });
      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.CLAIM_HEADER_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }

    case actionTypes.DELETE_DATA: {
      return {
        form: "template",
        checkAddress: [],
        cardAddress: [],
        checkHeaders: [],
        cardHeaders: [],
        claimAddress: [],
        claimHeaders: [],
        claimFields: [],
        lineFields: [],
      };
    }

    case actionTypes.CLAIM_FIELDS_ACTIVE: {
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });

      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.CLAIM_FIELDS_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }

    case actionTypes.CEHCK_ADDRESS_ACTIVE: {
      // debugger
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });
      console.log("namesss", action.payload.name);
      return {
        ...state,
        [action.payload.name]: true,
      };
    }
    case actionTypes.CARD_ADDRESS_ACTIVE: {
      // debugger
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });
      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.CEHCK_ADDRESS_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }
    case actionTypes.CARD_ADDRESS_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }
    case actionTypes.CHECK_HEADER_ACTIVE: {
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });

      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.CARD_HEADER_ACTIVE: {
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });
      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.CHECK_HEADER_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }
    case actionTypes.CARD_HEADER_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }

    case actionTypes.LINE_ITEM_PROPERTIES_ACTIVE: {
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });

      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.LINE_ITEM_PROPERTIES_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }

    case actionTypes.LINE_ITEM_FIELD_PROPERTIES_ACTIVE: {
      Object.entries(state).map(([key, value]) => {
        if (key.includes("Active") && state[key]) {
          state[key] = false;
        }
      });

      return {
        ...state,
        [action.payload.name]: true,
      };
    }

    case actionTypes.LINE_ITEM_FIELD_PROPERTIES_TEXT: {
      return {
        ...state,
        [action.payload.name]: action.payload.text,
      };
    }

    case actionTypes.CURRENTPDF: {
      return {
        ...state,
        pdf: action.payload,
      };
    }

    case actionTypes.NEGATE_OPEN: {
      return {
        ...state,
        negateOpen: action.payload,
      };
    }
    case actionTypes.TEMPLATE_OPEN: {
      return {
        ...state,
        templateOpen: action.payload,
      };
    }

    case actionTypes.IDENTIFICATION_OPEN: {
      return {
        ...state,
        identificationOpen: action.payload,
      };
    }

    case actionTypes.NEGATE_OPEN1: {
      return {
        ...state,
        negateOpen1: action.payload,
      };
    }
    case actionTypes.TEMPLATE_OPEN1: {
      return {
        ...state,
        templateOpen1: action.payload,
      };
    }

    case actionTypes.IDENTIFICATION_OPEN1: {
      return {
        ...state,
        identificationOpen1: action.payload,
      };
    }

    case actionTypes.SAVE_CREDS: {
      return {
        ...state,
        name: action.payload.name,
        templateID: action.payload.templateId,
      };
    }

    case actionTypes.DELETE_FORM_ITEM: {
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    }

    case actionTypes.DISABLE_ACTIVE: {
      const ns = state;
      Object.entries(ns).map(([key]) => {
        if (key.includes("Active") && ns[key]) {
          ns[key] = false;
        }
      });

      return ns;
    }

    default:
      return state;
  }
};

export default FormReducer;
