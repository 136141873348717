/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./Dashboard.css";
import "bootstrap/dist/css/bootstrap.css";
import Swal from "sweetalert2";
import Loader from "../Loader/loader";
import { getSavedData, setNo, updateLoading } from "../../store/Save/actions";
import { withRouter } from "react-router-dom";
import axios from "axios";
import env from "../../environment";
import NoDataFound from "../NoDataFound/NoDataFound";
import { getDropDown } from "../../store/Form/actions";
import { refreshToken, validateApi } from "../../constants";
const Dashboard = (props) => {
  const dispatch = useDispatch();
  const [localLoading, setLocalLoading] = useState(false);

  const {
    checkAddress,
    checkHeaders,
    cardAddress,
    cardHeaders,
    claimAddress,
    claimHeaders,
    lineFields,
    claimFields,
    alsoAddAs,
  } = useSelector(({ form }) => form);
  const loading = useSelector(({ save }) => save.loadingUpdate);

  const [engineResponse, setEngineResponse] = useState([]);

  const [responseCopy, setResponseCopy] = useState([]);
  const [loader, setLoader] = useState(true);

  const templateConfig = () => {
    const header = {
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem("accessToken")
      )}`,
    };
    axios
      .get(`${env.BASE_URL}templateconfigurations`, {
        headers: header,
      })
      .then((res) => {
        setLoader(false);
        const resp = res.data.data.map((item) => ({
          ...item,
          isLoading: false,
        }));

        let activeStructure = {
          count: resp.length,
          Saved: [],
          Created: [],
          Deployed: [],
          Trained: [],
        };
        resp.forEach((item) => {
          let tempArray = activeStructure[item.status];
          tempArray.push(item.status);
          activeStructure = { ...activeStructure, [item.status]: tempArray };
        });
        props.getActiveData(activeStructure, "active");
        setEngineResponse(resp);
        setResponseCopy(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
        if (err?.response?.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Session Expired!",
            type: "error",
            confirmButtonColor: "#54545E",
          }).then(() => {
            localStorage.clear();
            props.history.push("/signin");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            type: "error",
            confirmButtonColor: "#54545E",
          }).then(() => {
            window.location.href = "/home";
          });
        }
      });
    // });
  };
  React.useEffect(() => {
    validateApi().then((valid) => {
      if (!valid) {
        refreshToken().then(() => {
          templateConfig();
        });
      } else {
        templateConfig();
      }
    });
    dispatch(getDropDown());
  }, []);

  React.useEffect(() => {
    if (loading === "loaded") {
      dispatch(updateLoading("willReload"));
      // history.push("/form");
      window.location.href = "/form";
    }
  }, [loading]);

  const handleSearch = (e) => {
    let searchData = e.target.value;
    let searchDisplayData = responseCopy.filter((item) => {
      if (
        item.payer_name.toLowerCase().includes(searchData.toLowerCase()) ||
        item.payer_template_id
          .toLowerCase()
          .includes(searchData.toLowerCase()) ||
        item.status.toLowerCase().includes(searchData.toLowerCase())
      )
        return true;
    });
    setEngineResponse(searchDisplayData);
  };
  const handleCreateTemplate = () => {
    let templateId = responseCopy?.map((item) => item.payer_template_id);
    props.history.push({
      pathname: "/create",
      state: {
        templateId: templateId,
      },
    });
  };
  const handleDeploy = (payerTemplateId) => {
    Swal.fire({
      icon: "",
      text: "Are you sure you want to deploy?",
      // showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const header = {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("accessToken")
          )}`,
        };
        axios
          .patch(
            `${env.BASE_URL}templateconfigurations?payer_template_id=${payerTemplateId}`,
            {},
            {
              headers: header,
            }
          )
          .then((res) => {
            location.reload();
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              type: "error",
              confirmButtonColor: "#54545E",
            }).then(() => {
              window.location.href = "/home";
            });
          });
      } else if (result.isDenied) {
        window.location.href = "/home";
      }
    });
  };
  return (
    <div>
      <>
        {loader === true ? (
          <Loader />
        ) : (
          [
            responseCopy.length ? (
              <div className="container">
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <button
                      style={{
                        float: "right",
                        color: "white",
                        backgroundColor: "rgb(51 83 109)",
                        fontWeight: "bold",
                      }}
                      className="btn "
                      onClick={() => handleCreateTemplate()}
                    >
                      Create new template
                    </button>
                  </div>
                  <div className="col-md-12">
                    <h2
                      className="text-center"
                      style={{ color: "rgb(51 83 109)" }}
                    >
                      {/* ACTIVE TEMPLATES */}
                    </h2>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-1">
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        placeholder="Search by Payer name ,Template ID or Status...."
                        name="Search"
                        id="myInput"
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      ></input>
                    </div>

                    <table id="myTable">
                      <thead>
                        <tr className="header" key="header">
                          <th key={1}>Payer Name</th>
                          <th key={2}>Template Id</th>
                          <th key={3}>Version</th>
                          <th key={4}>Status</th>
                          <th key={5}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {engineResponse.map((item, key) => {
                          return (
                            <tr key={`row${key}`}>
                              <td key={`payerName${key}`}>{item.payer_name}</td>
                              <td key={`payerId${key}`}>
                                {item.payer_template_id}
                              </td>
                              <td key={`version${key}`}>{item.version_no}</td>
                              <td key={`status${key}`}>{item.status}</td>
                              {item.status === "Deployed" ? (
                                <td key={`action${key}`}>
                                  <button
                                    className="btn"
                                    style={{
                                      backgroundColor: "rgb(51 83 109)",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      const vals = [...engineResponse];
                                      vals[key].isLoading = true;
                                      setEngineResponse(vals);
                                      if (loading === "loading") {
                                        dispatch(updateLoading(false));
                                      }
                                      dispatch(
                                        getSavedData({
                                          templateID: item.payer_template_id,
                                          version: item.version_no,
                                          name: item.payer_name,
                                          checkDropdown: checkAddress,
                                          checkHeaderDropdown: checkHeaders,
                                          cardAddressDropdown: cardAddress,
                                          cardHeadersDropdown: cardHeaders,
                                          claimAddressDropdown: claimAddress,
                                          claimHeaderDropdown: claimHeaders,
                                          claimfieldsDropdown: claimFields,
                                          lineFieldsDropdown: lineFields,
                                          alsoAddAsDropdown: alsoAddAs,
                                        })
                                      );
                                    }}
                                  >
                                    {item.isLoading
                                      ? "Creating..."
                                      : "Create New Version"}
                                  </button>
                                </td>
                              ) : null}
                              {item.status === "Trained" ? (
                                <td
                                  key={`action${key}`}
                                  style={{ display: "flex" }}
                                >
                                  <button
                                    onClick={() => {
                                      const vals = [...engineResponse];

                                      vals[key].isLoading = true;
                                      setEngineResponse(vals);

                                      if (loading === "loading") {
                                        dispatch(updateLoading(false));
                                      }
                                      dispatch(
                                        getSavedData({
                                          props,
                                          templateID: item.payer_template_id,
                                          version: item.version_no,
                                          name: item.payer_name,
                                          checkDropdown: checkAddress,
                                          checkHeaderDropdown: checkHeaders,
                                          cardAddressDropdown: cardAddress,
                                          cardHeadersDropdown: cardHeaders,
                                          claimAddressDropdown: claimAddress,
                                          claimHeaderDropdown: claimHeaders,
                                          claimfieldsDropdown: claimFields,
                                          lineFieldsDropdown: lineFields,
                                          alsoAddAsDropdown: alsoAddAs,
                                        })
                                      );
                                    }}
                                    className="btn"
                                    style={{
                                      backgroundColor: "rgb(51 83 109)",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.isLoading
                                      ? "getting data..."
                                      : "Update"}
                                  </button>
                                  &nbsp;&nbsp;
                                  <button
                                    className="btn"
                                    style={{
                                      backgroundColor: "rgb(51 83 109)",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() =>
                                      handleDeploy(item.payer_template_id)
                                    }
                                  >
                                    Deploy
                                  </button>
                                </td>
                              ) : null}
                              {item.status === "Created" ? (
                                <td key={`action${key}`}>
                                  <button
                                    className="btn"
                                    disabled={item.isLoading}
                                    style={{
                                      backgroundColor: "rgb(51 83 109)",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      const vals = [...engineResponse];

                                      vals[key].isLoading = true;
                                      setEngineResponse(vals);

                                      if (loading === "loading") {
                                        dispatch(updateLoading(false));
                                      }
                                      dispatch(
                                        getSavedData({
                                          props,
                                          templateID: item.payer_template_id,
                                          version: item.version_no,
                                          name: item.payer_name,
                                          checkDropdown: checkAddress,
                                          checkHeaderDropdown: checkHeaders,
                                          cardAddressDropdown: cardAddress,
                                          cardHeadersDropdown: cardHeaders,
                                          claimAddressDropdown: claimAddress,
                                          claimHeaderDropdown: claimHeaders,
                                          claimfieldsDropdown: claimFields,
                                          lineFieldsDropdown: lineFields,
                                          alsoAddAsDropdown: alsoAddAs,
                                        })
                                      );
                                    }}
                                  >
                                    {item.isLoading
                                      ? "getting data..."
                                      : "Update"}
                                  </button>
                                </td>
                              ) : null}
                              {item.status === "Saved" && (
                                <td key={`action${key + 1}`}>
                                  <button
                                    className="btn"
                                    disabled={item.isLoading}
                                    style={{
                                      backgroundColor: "rgb(51 83 109)",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      const vals = [...engineResponse];

                                      vals[key].isLoading = true;

                                      setEngineResponse(vals);

                                      // setLoader(true);
                                      if (loading === "loading") {
                                        dispatch(updateLoading(false));
                                      }
                                      dispatch(
                                        getSavedData({
                                          templateID: item.payer_template_id,
                                          version: item.version_no,
                                          name: item.payer_name,
                                          checkDropdown: checkAddress,
                                          checkHeaderDropdown: checkHeaders,
                                          cardAddressDropdown: cardAddress,
                                          cardHeadersDropdown: cardHeaders,
                                          claimAddressDropdown: claimAddress,
                                          claimHeaderDropdown: claimHeaders,
                                          claimfieldsDropdown: claimFields,
                                          lineFieldsDropdown: lineFields,
                                          alsoAddAsDropdown: alsoAddAs,
                                        })
                                      );
                                      // setLoader(false);
                                    }}
                                  >
                                    {item.isLoading
                                      ? "getting data..."
                                      : "Update"}
                                  </button>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <NoDataFound key="nodata" />
            ),
          ]
        )}
      </>
    </div>
  );
};

export default withRouter(Dashboard);
