import React, { Component } from 'react';
import Swal from 'sweetalert2';

class LoginRestriction extends Component {
  hitAlert=() => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Sorry, Please Login!',
    })
      .then((result) => {
        if (result.value) {
          window.location.href = '/';
        }
      });
  }

  render() {
    return (
      <div>
        {this.hitAlert()}
      </div>
    );
  }
}

export default LoginRestriction;
