/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "../../Box";
import Tooltip from "@material-ui/core/Tooltip";
import Text from "../../Text";
import Template from "../../Forms/Template";
import { useDispatch, useSelector } from "react-redux";
import {
  disableActive1,
  formType,
  getDropDown,
  currentPDF,
} from "../../../store/Form/actions";
import Check from "../../Forms/Check";
import Claim from "../../Forms/Claim";
import Line from "../../Forms/Line";
import Image from "../../../Image";
import Next from "../../../assets/icons/next.png";
import Previous from "../../../assets/icons/previous.png";
import Footer from "../../Footer";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import {
  changeStatus,
  payerIdentificationApi,
  removeCheckStatus,
  saveState,
  submitJson,
  submitType,
  updateLoading,
} from "../../../store/Save/actions";
import Navbar from "../../Navbar/Navbar";
import env from "../../../environment";
import LZString from "lz-string";
import { Alert } from "rsuite";
import { refreshToken, validateApi } from "../../../constants";
import PreviewModal from "../../PreviewModal";
import ReactJson from "react-json-view";

const Home = (props) => {
  const history = useHistory();

  const loading = useSelector(({ save }) => save?.loadingUpdate);
  const isPreview = useSelector(({ save }) => save.submitType);
  const jsonData = useSelector(({ save }) => save.previewJson);

  const [pdfKeyArray, setPdfKeyArray] = useState(
    localStorage.getItem("pdfList")
      ? JSON.parse(localStorage.getItem("pdfList"))
      : []
  ); //pdf names

  const [apiResponse, setApiResponse] = useState(
    localStorage.getItem("apiPreprocessRes")
      ? JSON.parse(
        LZString.decompress(localStorage.getItem("apiPreprocessRes"))
      )
      : {}
  );
  const [pageKeys, setPageKey] = useState();
  const [modal, setModal] = useState(false);
  const [fileData, setFileData] = useState();
  const dispatch = useDispatch();
  const form = useSelector(({ form }) => form.form);
  const pdf = useSelector(({ form }) => form.pdf);
  const isError = useSelector(({ save }) => save.error);
  const status = useSelector(({ save }) => save.status);
  const statusCode = useSelector(({ save }) => save.statusCode);
  const name = useSelector(({ form }) => form.name);
  const templateID = useSelector(({ form }) => form.templateID);
  const templateIDFromSave = useSelector(({ save }) => save.templateID);
  const version = useSelector(({ save }) => save.version);

  const checkFiles = useSelector(({ save }) => save.state);
  const fileMessage = useSelector(({ save }) => save.message);
  const files = useSelector(({ save }) => save.files);
  const nameFromSave = useSelector(({ save }) => save.name);
  const [tab, setTab] = React.useState("template");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageDisplay, setPageDispaly] = useState(
    localStorage.getItem("pdfList") ? "Page-1" : ""
  );
  const [imageURL, setImageURL] = useState();
  const [fileName, setFileName] = useState();
  const [isIconVisibile, setIconVisibile] = React.useState(false);
  const [currentPdf, setCurrentPdf] = useState(
    pdf
      ? pdf
      : localStorage.getItem("pdfList")
        ? JSON.parse(localStorage.getItem("pdfList"))[0]
        : ""
  );
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [loader, setLoader] = useState(false);
  const [colorValidate, setColorValidate] = useState(
    localStorage.getItem("validateConfig") !== null
      ? localStorage.getItem("validateConfig")
      : "#ef930d"
  );

  const handleChange = (name) => {
    setTab(name);
  };

  const displayImagesData = async (key, pageNo = -1) => {
    if (key) {
      setFileName(key);
      let data = apiResponse[key];
      await setFileData(data);

      if (data) {
        // TODO - mitisha to handle this
        if (data.message === "file not found in AWS") return;
        await setPageKey(Object.keys(data?.raw_json));
        // await setPageDispaly("Page-1")
        if (pageNo < 0) await setPageNumber(pageNumber);
        else await setPageNumber(pageNo);
      }
    }
  };

  const imageToggler = async (type) => {
    if (type === "back") {
      await setPageNumber((page) => page - 1);
    } else {
      await setPageNumber((page) => page + 1);
    }
  };

  const click = (type) => {
    dispatch(submitType(type));

    dispatch(
      submitJson({
        status: "Created",
        name: name ? name : nameFromSave,
        templateID: templateID ? templateID : templateIDFromSave,
      })
    );
  };

  const deleteFile = async (fileName) => {
    if (pdfKeyArray.includes(fileName)) {
      let newArray = pdfKeyArray.filter((item) => item !== fileName);
      await setPdfKeyArray(newArray);

      localStorage.setItem("pdfList", JSON.stringify(newArray));
      await delete apiResponse[fileName];
      let x = apiResponse;
      await setApiResponse(x);
      localStorage.setItem(
        "apiPreprocessRes",
        LZString.compress(JSON.stringify(x))
      );
    }
  };
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "application/pdf,image/tiff,.tif ",
    maxFiles: 5,
    maxSize: 2000000,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 5) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You can upload only upto 5 files",
          type: "error",
          confirmButtonColor: "#54545E",
        }).then(() => {
          return;
        });
      }

      if (fileRejections.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "File size is too large, Select files less than 2MB!!",
                type: "error",
                confirmButtonColor: "#54545E",
              });
            }
          });
        });
      } else {
        uploadFiles(acceptedFiles);
      }
    },
  });
  const handleProcessImage = async (data, acceptedFiles) => {
    const header = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem("accessToken")
      )}`,
    };
    axios
      .post(
        `${env.BASE_URL}processimage`,
        data,
        {
          headers: header,
        },
        { timeout: 300000 }
      )
      .then((res) => {
        // payer iden api
        const names = acceptedFiles.map((item) => item.name);
        const json = {
          templateID: templateID ? templateID : templateIDFromSave,
          version,
          file_names: names,
        };
        let keys = Object.keys(res?.data);
        setPdfKeyArray([...pdfKeyArray, ...keys]);
        let updatedPdfList = [...pdfKeyArray, ...keys];
        localStorage.setItem("pdfList", JSON.stringify(updatedPdfList));
        let newApiResponse = {
          ...apiResponse,
          ...res?.data,
        };
        setApiResponse(newApiResponse);
        const result = payerIdentificationApi(json, true);
        result.then((res) => {
          setLoader(false);
          console.log(res)
          if (res && res.status === 202) {
            Alert.warning(showMessage(res.data.status, res.data.files), 0,()=>{
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Files Uploaded Successfully",
                confirmButtonText: "Ok",
                confirmButtonColor: "#4CAF50",
              });
            })
           
          }
          else {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Files Uploaded Successfully",
              confirmButtonText: "Ok",
              confirmButtonColor: "#4CAF50",
            });
          }
        })

      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Session Expired!",
            type: "error",
            confirmButtonColor: "#54545E",
          }).then(() => {
            localStorage.clear();
            props.history.push("/signin");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            type: "error",
            confirmButtonColor: "#54545E",
          });
        }
      });


  };

  //

  const uploadFiles = async (acceptedFiles) => {
    // setSelectedFiles(acceptedFiles);

    if (acceptedFiles.length || pdfKeyArray.length) {
      if (pdfKeyArray.includes(acceptedFiles[0].name))
       {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File Already Exist! You cannot upload same files",
          type: "error",
          confirmButtonColor: "rgb(51, 83, 109)",
        });
        return;
      }
      let updatedSelected = [...selectedFiles, ...acceptedFiles];
      if (updatedSelected.length > 5) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You can upload only upto 5 files",
          type: "error",
          confirmButtonColor: "rgb(51, 83, 109)",
        });
        return;
      }
      setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    }

    const data = new FormData();
    for (let i = 0; i < acceptedFiles.length; i++) {
      data.append(`file${i}`, acceptedFiles[i]);
    }
    data.append(
      "payer_template_id",
      templateID ? templateID : templateIDFromSave
    );
    if (acceptedFiles.length > 0) {
      setLoader(true);
      validateApi().then((valid) => {
        if (!valid) {
          refreshToken().then(() => {
            handleProcessImage(data, acceptedFiles);
          });
        } else {
          handleProcessImage(data, acceptedFiles);
        }
      });
    }
  };

  const showMessage = (text, images) => (
    <Box>
      {text}
      {!_.isEmpty(files) && (
        <ReactJson
          src={files}
          style={{ width: "auto", wordBreak: "break-word" }}
          name="details"
        />
      )}

      {!_.isEmpty(images) && (
        <ReactJson
          src={images}
          style={{ width: "auto", wordBreak: "break-word" }}
          name="details"
        />
      )}

      {/* {images.length > 0 && images.map((i) => <Text pageKeys={i}>{i}</Text>)} */}
    </Box>
  );

  React.useEffect(() => {
    const fromSave = templateIDFromSave && nameFromSave ? true : false; // from update click
    const fromForm = templateID && name ? true : false;
    const final = fromSave || fromForm;
    if (!final) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please create template",
        type: "error",
        confirmButtonColor: "rgb(51, 83, 109)",
      }).then(() => {
        props.history.push("/create");
      });
    }

    if (checkFiles === 202) {
      Alert.warning(showMessage(fileMessage), 0);
      dispatch(removeCheckStatus());
    }

    if (loading === "willReload") {
      dispatch(updateLoading("reloaded"));
    }
    if (loading === "reloaded") {
      dispatch(updateLoading(false));
    }
    dispatch(getDropDown());
  }, []);

  useEffect(() => {
    if (status === "isCreated") {
      dispatch(changeStatus(""));
      dispatch(saveState("Created"));
      dispatch(saveState(false));
      if (isPreview === "create") {
        Alert.success(`Create Successfull`);
        setTimeout(() => {
          history.push("/home");
        }, 1500);
      } else if (isPreview === "preview") setModal(true);
    } else if (status === "Failed") {
      setModal(false);
      Alert.error(
        `${isPreview === "preview" ? "Preview" : "Create"
        } unsuccessfull, please try again ${statusCode !== 200 ? `Error: ${isError}` : ""
        }`,
        15000,
        () => {
          window.location.href = "/form";
          dispatch(saveState(false));
          dispatch(changeStatus(false));
        }
      );

      dispatch(saveState(false));
      dispatch(changeStatus(false));
    }
  }, [pdfKeyArray, isError, status]);

  useEffect(() => {
    localStorage.setItem(
      "apiPreprocessRes",
      LZString.compress(JSON.stringify(apiResponse))
    );
    displayImagesData(pdfKeyArray[0]);
    dispatch(disableActive1());
  }, [apiResponse]);
  useEffect(() => {
    localStorage.setItem(
      "apiPreprocessRes",
      LZString.compress(JSON.stringify(apiResponse))
    );
    dispatch(disableActive1());
  }, [form]);

  useEffect(() => {
    if (pdfKeyArray) {
      displayImagesData(pdfKeyArray[0]);
    }
    dispatch(getDropDown());
  }, []);

  useEffect(() => {
    if (pageKeys !== undefined) {
      setImageURL(fileData?.raw_json[pageKeys[pageNumber]]);
      setPageDispaly(pageKeys[pageNumber]);
    }
  }, [pageKeys, pageNumber]);

  useEffect(() => {
    if (pageKeys !== undefined) {
      setImageURL(fileData?.raw_json[pageKeys[0]]);
    }
  }, [fileData]);

  useEffect(() => {
    dispatch(formType(tab));
  }, [tab]);
  const handleColor = (color) => {
    setColorValidate(color);
  };

  const isClose = () => setModal(false);
  const isOk = () => (window.location.href = "/form");

  return (
    <>
      <Navbar component="form" />
      <br />
      <br />
      {modal && <PreviewModal isClose={isClose} json={jsonData} isOk={isOk} />}

      <Box
        w="1348px"
        h="700px"
        b="1px solid black"
        margin="0vh auto"
        display="flex"
      >
        <Box display="flex">
          <Box
            background="#D3D3D3 0% 0% no-repeat padding-box;"
            position="relative"
          >
            <Box
              h="39px"
              background="rgb(51, 83, 109) 0% 0% no-repeat padding-box;"
              border=" 1px solid #707070;"
              display="flex"
              alignItems="center"
              w="763px"
            >
              <Text
                textColor="white"
                fontWeight="bold"
                fontSize="20px"
                margin="20px"
              >
                DOCUMENTS
              </Text>
              {/* <button onClick={reload}>Reload</button> */}
              {!loader ? (
                <>
                  &nbsp; &nbsp;
                  {pdfKeyArray.length >= 5 ? (
                    <Tooltip title="You can upload upto max 5 files">
                      <button
                        style={{
                          marginLeft: "50px",
                          background: "white 0% 0% no-repeat padding-box;",
                          marginBottom: "4px",
                          borderRadius: "4px",
                          padding: "6px 13px 6px 13px",
                        }}
                        {...getRootProps({ className: "dropzone" })}
                        disabled={pdfKeyArray.length >= 5}
                      >
                        <input {...getInputProps()} />

                        <Text textColor="#54545E" fontWeight="bold">
                          Upload File
                        </Text>
                      </button>
                    </Tooltip>
                  ) : (
                    <button
                      style={{
                        marginLeft: "50px",
                        background: "white 0% 0% no-repeat padding-box;",
                        marginBottom: "4px",
                        borderRadius: "4px",
                        padding: "6px 13px 6px 13px",
                      }}
                      {...getRootProps({ className: "dropzone" })}
                      disabled={pdfKeyArray.length >= 5}
                    >
                      <input {...getInputProps()} />

                      <Text textColor="#54545E" fontWeight="bold">
                        Upload File
                      </Text>
                    </button>
                  )}
                </>
              ) : (
                <>
                  <marquee direction="left">
                    <Text textColor="white" fontWeight="italic" marginTop="0">
                      <i
                        className="fa fa-circle-o-notch fa-spin"
                        style={{ fontSize: "16px" }}
                      ></i>
                      &nbsp; Please wait files are being processed
                    </Text>
                  </marquee>
                </>
              )}
              <Box marginLeft="auto" marginRight="10px">
                <Select
                  onChange={(e) => {
                    console.log(e.target.value);

                    setPageDispaly(e.target.value);
                    let inputData =
                      e.target.value.substring(5, e.target.value.length) / 1;
                    setPageNumber(inputData - 1);
                  }}
                  value={pageDisplay}
                  onOpen={() => setIconVisibile(true)}
                  onClose={() => setIconVisibile(false)}
                  style={{
                    width: "100px",
                    borderRadius: "6px",
                    height: "25px",
                    padding: "0 10px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  {pageKeys
                    ? pageKeys.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))
                    : null}
                </Select>
              </Box>

              <Box marginLeft="auto" marginRight="10px">
                <Select
                  onChange={async (e) => {
                    await displayImagesData(e.target.value, 0);
                    await setCurrentPdf(e.target.value);
                    dispatch(currentPDF(e.target.value));
                  }}
                  value={currentPdf === "" ? pdfKeyArray[0] : currentPdf}
                  onOpen={() => setIconVisibile(true)}
                  onClose={() => setIconVisibile(false)}
                  style={{
                    width: "150px",
                    borderRadius: "6px",
                    height: "25px",
                    padding: "0 10px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  {pdfKeyArray ? (
                    pdfKeyArray.map((item) => (
                      <MenuItem value={item}>
                        {item}
                        {isIconVisibile ? (
                          <ListItemSecondaryAction variant="outlined">
                            <IconButton edge="end" aria-label="delete">
                              {pdfKeyArray.length === 1 ? null : (
                                <DeleteIcon
                                  onClick={() => {
                                    deleteFile(item);
                                  }}
                                />
                              )}
                            </IconButton>
                          </ListItemSecondaryAction>
                        ) : null}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={"upload file"}>Upload fies</MenuItem>
                  )}
                </Select>
              </Box>
            </Box>
            <Box position="relative">
              {imageURL ? (
                <Image
                  top="50px"
                  width="-webkit-fill-available"
                  src={imageURL?.URL}
                  data={imageURL}
                  pageNo={pageNumber}
                  fileN={fileName}
                />
              ) : null}
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              position="absolute"
              left="292px"
              bottom="10px"
            >
              <button
                disabled={pageNumber > 0 ? false : true}
                onClick={() => imageToggler("back")}
              >
                <img
                  src={Previous}
                  style={{ height: 30, margin: 5, cursor: "pointer" }}
                  alt="previous"
                />
              </button>{" "}
              &nbsp; &nbsp;
              <button
                disabled={pageNumber !== pageKeys?.length - 1 ? false : true}
                onClick={() => imageToggler("next")}
              >
                <img
                  src={Next}
                  style={{ height: 30, margin: 5, cursor: "pointer" }}
                  alt="next"
                />
              </button>
            </Box>
          </Box>
        </Box>
        {/* forms */}
        <Box
          h="inherit"
          //  marginLeft="auto"
          b="1px solid gray"
          bt="none"
          minW="568px"
          br="none"
        >
          {/* buttons */}
          <Box display="flex" justifyContent="space-around">
            <Box
              cursor="pointer"
              b="1px solid gray"
              h="38px"
              w="160px"
              textAlign="center"
              bl="none"
              bt="none"
              background={
                tab === "TEMPLATE".toLowerCase()
                  ? "#FFFFFF 0% 0% no-repeat padding-box;"
                  : "#FFFFFF 0% 0% no-repeat padding-box;"
              }
              boxShadow={
                tab === "TEMPLATE".toLowerCase()
                  ? "inset 0px 0px 15px rgb(51, 83, 109);"
                  : "none"
              }
              opacity="1"
              onClick={() => handleChange("template")}
            >
              <Text margin="8px" textColor="rgb(51, 83, 109)" fontWeight="bold">
                TEMPLATE
              </Text>
            </Box>
            <Box
              cursor="pointer"
              b={"1px solid gray"}
              h="38px"
              w="160px"
              textAlign="center"
              background={
                tab === "CHEQUE".toLowerCase()
                  ? "#FFFFFF 0% 0% no-repeat padding-box;"
                  : "#FFFFFF 0% 0% no-repeat padding-box;"
              }
              boxShadow={
                tab === "CHEQUE".toLowerCase()
                  ? "inset 0px 0px 15px rgb(51, 83, 109);"
                  : "none"
              }
              opacity="1"
              onClick={() => handleChange("cheque")}
              bl="none"
              bt="none"
            >
              <Text margin="8px" textColor="rgb(51, 83, 109)" fontWeight="bold">
                PAYMENT
              </Text>
            </Box>
            <Box
              cursor="pointer"
              b="1px solid gray"
              h="38px"
              w="160px"
              textAlign="center"
              bl="none"
              bt="none"
              background={
                tab === "CLAIM".toLowerCase()
                  ? "#FFFFFF 0% 0% no-repeat padding-box;"
                  : "#FFFFFF 0% 0% no-repeat padding-box;"
              }
              boxShadow={
                tab === "CLAIM".toLowerCase()
                  ? "inset 0px 0px 15px rgb(51, 83, 109);"
                  : "none"
              }
              opacity="1"
              onClick={() => handleChange("claim")}
            >
              <Text margin="8px" textColor="rgb(51, 83, 109)" fontWeight="bold">
                ACCOUNT
              </Text>
            </Box>
            <Box
              cursor="pointer"
              b="1px solid gray"
              h="38px"
              w="160px"
              textAlign="center"
              bl="none"
              br="1px solid black"
              bt="none"
              background={
                tab === "LINE".toLowerCase()
                  ? "#FFFFFF 0% 0% no-repeat padding-box;"
                  : "#FFFFFF 0% 0% no-repeat padding-box;"
              }
              boxShadow={
                tab === "LINE".toLowerCase()
                  ? "inset 0px 0px 15px rgb(51, 83, 109);"
                  : "none"
              }
              opacity="1"
              onClick={() => handleChange("line")}
            >
              <Text margin="8px" textColor="rgb(51, 83, 109)" fontWeight="bold">
                LINE
              </Text>
            </Box>
          </Box>
          {/* save */}
          <Box
            h="30px"
            w="100%"
            justifyContent="flex-end"
            display="flex"
            bb="1px solid gray"
          >
            {/* <img
              src={Disk}
              style={{ height: 20, margin: 5, cursor: "pointer" }}
              onClick={() => dispatch(dispatchToSaveJson(store.getState()))}
            /> */}
          </Box>
          <Box>
            {form === "template" && <Template colorCheck={handleColor} />}
            {form === "cheque" && <Check />}
            {form === "claim" && <Claim />}
            {form === "line" && <Line />}
          </Box>
        </Box>
      </Box>
      {/* buttons */}
      <Footer
        home
        click={click}
        colorValidate={colorValidate}
        component="form"
        pdfs={pdfKeyArray}
      />
    </>
  );
};

export default Home;
