/* eslint-disable */

import React from "react";
import Box from "../Box";
import Text from "../Text";
import Down from "../../assets/icons/down.svg";
import Up from "../../assets/icons/up.svg";
import Add from "../../assets/icons/add.svg";
import Cross from "../../assets/icons/cross.svg";
import Help from "../../assets/icons/help.png";
import { Tooltip } from "@material-ui/core";
import { SelectPicker } from "rsuite";
import { withRouter } from "react-router-dom";
import { headingFont, labelColor, labelFont } from "../../utils";
import "./forms.css";
import { valueTypeRegexData } from "../../constants";
import "./Claim.css";
import { useSelector, useDispatch } from "react-redux";
import {
  checkAddressActive,
  cardAddressActive,
  checkAddressText,
  cardAddressText,
  checkHeaderActive,
  cardHeaderActive,
  checkHeaderText,
  cardHeaderText,
  disableCheckAddActive,
  disableCheckHeadActive,
  disableCardAddActive,
} from "../../store/Form/actions";
import {
  saveCheckAddress,
  saveCardAddress,
  saveCheckHeader,
  saveCardHeader,
  checkHeaderOpen,
  cardHeaderOpen,
  checkAddressOpen,
  selectedCheckAdd,
  selectedCardAdd,
  selectedCheckHeader,
  selectedCardHeader,
  cardAddressOpen,
} from "../../store/Save/actions";

const Check = (props) => {
  const dispatch = useDispatch();
  const state = useSelector(({ form }) => form);
  const saveState = useSelector(({ save }) => save.check);
  const saveStateAdd = useSelector(({ save }) => save.check.ckadd);
  const saveStateHead = useSelector(({ save }) => save.check.ckhead);

  const saveStateHeadOpen = useSelector(({ save }) => save.check.ckHeadOpen);

  const saveStateCardHead = useSelector(({ save }) => save.check.cardHead);
  const saveStateCardHeadOpen = useSelector(
    ({ save }) => save.check.cdHeadOpen
  );

  const saveStateAddOpen = useSelector(({ save }) => save.check.ckAddOpen);
  const saveStateCardAdd = useSelector(({ save }) => save.check.cardAdd);
  const saveStateCardAddOpen = useSelector(
    ({ save }) => save.check.cardAddOpen
  );
  const selectedCheckAddressOptions = useSelector(
    ({ save }) => save.check.selectedCheckAdd
  );
  const selectedCardAddressOptions = useSelector(
    ({ save }) => save.check.selectedCardAdd
  );

  const selectedCheckHeaderOptons = useSelector(
    ({ save }) => save.check.selectedCheckHead
  );

  const selectedCardHeaderOptons = useSelector(
    ({ save }) => save.check.selectedCardHead
  );

  // gettting dropdowns
  const checkAddressDropdown = useSelector(({ form }) => form?.checkAddress);
  const checkHeadersDropdown = useSelector(({ form }) => form?.checkHeaders);
  const cardAddressDropdown = useSelector(({ form }) => form?.cardAddress);
  const cardHeadersDropdown = useSelector(({ form }) => form?.cardHeaders);

  const [checkHeaderError, setCheckHeaderError] = React.useState([false]);
  const [cardHeaderError, setCardHeaderError] = React.useState([false]);

  const [checkAddDisabled, setCheckAddDisabled] = React.useState([]);
  const [checkHeadDisabled, setCheckHeadDisabled] = React.useState([]);
  const [checkAddressOptions, setcheckAddressOptions] = React.useState([]);
  const [cardAddressOptions, setcardAddressOptions] = React.useState([]);
  const [cardAddDisabled, setCardAddDisabled] = React.useState([]);
  const [cardHeadDisabled, setCardHeadDisabled] = React.useState([]);

  const [blockExtractorOptions] = React.useState(["text", "date", "amount($)"]);
  const [formBasedOptions] = React.useState(["form", "above", "below"]);
  const [checkHeaderOptions, setCheckHeaderOptions] = React.useState([]);
  const [cardHeaderOptions, setCardHeaderOptions] = React.useState([]);

  // load initial Data

  React.useEffect(() => {
    const s = selectedCheckAddressOptions.map((op) => {
      return Object.values(op);
    });
    setCheckAddDisabled([].concat(...s));
  }, [selectedCheckAddressOptions]);

  React.useEffect(() => {
    const s = selectedCardAddressOptions.map((op) => {
      return Object.values(op);
    });
    setCardAddDisabled([].concat(...s));
  }, [selectedCardAddressOptions]);

  React.useEffect(() => {
    const s = selectedCheckHeaderOptons.map((op) => {
      return Object.values(op);
    });
    setCheckHeadDisabled([].concat(...s));
  }, [selectedCheckHeaderOptons]);

  React.useEffect(() => {
    const s = selectedCardHeaderOptons.map((op) => {
      return Object.values(op);
    });
    setCardHeadDisabled([].concat(...s));
  }, [selectedCardHeaderOptons]);

  React.useEffect(() => {
    if (state) {
      Object.entries(state).map(([key], index) => {
        if (key.includes("checkAddress") && !key.includes("Active")) {
          const values = [...saveStateAdd];

          const lastNumber = parseInt(key.slice(-1), 10);

          const keyname = key.substring(0, key.length - 1);

          if (
            keyname === "checkAddressBlock" &&
            state[`${keyname}Active${lastNumber}`]
          ) {
            if (values.length >= lastNumber)
              if (values.length >= 1) {
                values[lastNumber - 1].blocks = state[key];

                if (!_.isEmpty(state[`checkAddressBlockdata${lastNumber}`])) {
                  values[lastNumber - 1].data =
                    state[`checkAddressBlockdata${lastNumber}`];
                  values[lastNumber - 1].data["Text"] = state[key];
                }
              }

            dispatch(saveCheckAddress(values));
          }
        }

        if (key.includes("cardAddress") && !key.includes("Active")) {
          const values = [...saveStateCardAdd];

          const lastNumber = parseInt(key.slice(-1), 10);

          const keyname = key.substring(0, key.length - 1);

          if (
            keyname === "cardAddressBlock" &&
            state[`${keyname}Active${lastNumber}`]
          ) {
            if (values.length >= lastNumber)
              if (values.length >= 1) {
                values[lastNumber - 1].blocks = state[key];

                if (!_.isEmpty(state[`cardAddressBlockdata${lastNumber}`])) {
                  values[lastNumber - 1].data =
                    state[`cardAddressBlockdata${lastNumber}`];
                  values[lastNumber - 1].data["Text"] = state[key];
                }
              }

            dispatch(saveCardAddress(values));
          }
        }
        if (key.includes("checkHeader") && !key.includes("Active")) {
          let lastNumber = parseInt(key.slice(-1), 10);

          // for more than 2 digits

          const secondLast = parseInt(key.slice(-2), 10);
          let keyname;
          if (secondLast) {
            keyname = key.substring(0, key.length - 2);
            lastNumber = secondLast;
          } else keyname = key.substring(0, key.length - 1);

          if (
            keyname === "checkHeaderValue" &&
            state[`${keyname}Active${lastNumber}`]
          ) {
            const values = [...saveStateHead];

            if (values.length >= lastNumber) {
              if (state[key] !== values[lastNumber - 1].valueBlock) {
                values[lastNumber - 1].valueType = "text";
                let checkHeaderErrorStatus = checkHeaderError
                checkHeaderErrorStatus[lastNumber - 1] = false
                setCheckHeaderError(checkHeaderErrorStatus)
              }

              values[lastNumber - 1].valueBlock = state[key];
              if (!_.isEmpty(state[`checkHeaderValuedata${lastNumber}`])) {
                values[lastNumber - 1].data =
                  state[`checkHeaderValuedata${lastNumber}`];
                values[lastNumber - 1].data["Text"] = state[key];
              }
            }
            dispatch(saveCheckHeader(values));

            // setCheckHead(values);
          }
          if (
            keyname === "checkHeadervalue" &&
            state[`${keyname}Active${lastNumber}`]
          ) {
            const values = [...saveStateHead];
            if (values.length >= lastNumber) {
              values[lastNumber - 1].value = state[key];
              if (!_.isEmpty(state[`checkHeadervaluedata${lastNumber}`])) {
                values[lastNumber - 1].data_value =
                  state[`checkHeadervaluedata${lastNumber}`];
                values[lastNumber - 1].data_value["Text"] = state[key];
              }
            }
            dispatch(saveCheckHeader(values));

            // setCheckHead(values);
          }
          if (
            keyname === "checkHeaderIdentifier" &&
            state[`${keyname}Active${lastNumber}`]
          ) {
            const values = [...saveStateHead];

            if (values.length >= 1) {
              if (values.length >= lastNumber) {
                if (values[lastNumber - 1].hasOwnProperty("identifier")) {
                  values[lastNumber - 1].identifier = state[key];
                  if (
                    !_.isEmpty(state[`checkHeaderIdentifierdata${lastNumber}`])
                  ) {
                    values[lastNumber - 1].data =
                      state[`checkHeaderIdentifierdata${lastNumber}`];
                    values[lastNumber - 1].data["Text"] = state[key];
                  }
                }
              }
            }
            //setCheckHead(values);
            dispatch(saveCheckHeader(values));
          }

          if (
            keyname === "checkHeaderEndIdentifier" &&
            state[`${keyname}Active${lastNumber}`]
          ) {
            const values = [...saveStateHead];
            if (values.length >= 1) {
              values[lastNumber - 1].endIdentifier = state[key];
              if (
                !_.isEmpty(state[`checkHeaderEndIdentifierdata${lastNumber}`])
              ) {
                values[lastNumber - 1].data_endIdentifier =
                  state[`checkHeaderEndIdentifierdata${lastNumber}`];
                values[lastNumber - 1].data_endIdentifier["Text"] = state[key];
              }
            }
            //setCheckHead(values);
            dispatch(saveCheckHeader(values));
          }
        }

        //card header

        if (key.includes("cardHeader") && !key.includes("Active")) {
          let lastNumber = parseInt(key.slice(-1), 10);

          // for more than 2 digits

          const secondLast = parseInt(key.slice(-2), 10);
          let keyname;
          if (secondLast) {
            keyname = key.substring(0, key.length - 2);
            lastNumber = secondLast;
          } else keyname = key.substring(0, key.length - 1);

          if (
            keyname === "cardHeaderValue" &&
            state[`${keyname}Active${lastNumber}`]
          ) {
            const values = [...saveStateCardHead];
            if (values.length >= lastNumber) {
              if (state[key] !== values[lastNumber - 1].valueBlock) {
                values[lastNumber - 1].valueType = "text";
                let cardHeaderErrorStatus = cardHeaderError
                cardHeaderErrorStatus[lastNumber - 1] = false
                setCardHeaderError(cardHeaderErrorStatus)
              }

              values[lastNumber - 1].valueBlock = state[key];
              if (!_.isEmpty(state[`cardHeaderValuedata${lastNumber}`])) {
                values[lastNumber - 1].data =
                  state[`cardHeaderValuedata${lastNumber}`];
                values[lastNumber - 1].data["Text"] = state[key];
              }
            }
            dispatch(saveCardHeader(values));

            // setCheckHead(values);
          }
          if (
            keyname === "cardHeaderIdentifier" &&
            state[`${keyname}Active${lastNumber}`]
          ) {
            const values = [...saveStateCardHead];

            if (values.length >= 1) {
              if (values.length >= lastNumber) {
                if (values[lastNumber - 1].hasOwnProperty("identifier")) {
                  values[lastNumber - 1].identifier = state[key];
                  if (
                    !_.isEmpty(state[`cardHeaderIdentifierdata${lastNumber}`])
                  ) {
                    values[lastNumber - 1].data =
                      state[`cardHeaderIdentifierdata${lastNumber}`];
                    values[lastNumber - 1].data["Text"] = state[key];
                  }
                }
              }
            }
            //setCheckHead(values);
            dispatch(saveCardHeader(values));
          }

          if (
            keyname === "cardHeaderEndIdentifier" &&
            state[`${keyname}Active${lastNumber}`]
          ) {
            const values = [...saveStateCardHead];
            if (values.length >= 1) {
              values[lastNumber - 1].endIdentifier = state[key];
              if (
                !_.isEmpty(state[`cardHeaderEndIdentifierdata${lastNumber}`])
              ) {
                values[lastNumber - 1].data_endIdentifier =
                  state[`cardHeaderEndIdentifierdata${lastNumber}`];
                values[lastNumber - 1].data_endIdentifier["Text"] = state[key];
              }
            }

            dispatch(saveCardHeader(values));
          }
          if (
            keyname === "cardHeadervalue" &&
            state[`${keyname}Active${lastNumber}`]
          ) {
            const values = [...saveStateCardHead];
            if (values.length >= 1) {
              values[lastNumber - 1].value = state[key];
              if (!_.isEmpty(state[`cardHeadervaluedata${lastNumber}`])) {
                values[lastNumber - 1].data_value =
                  state[`cardHeadervaluedata${lastNumber}`];
                // values[lastNumber - 1].data_value["Text"] = state[key];
              }
            }

            dispatch(saveCardHeader(values));
          }
        }
      });
    }
  }, [state]);

  React.useEffect(() => {
    if (checkAddressDropdown && checkAddressDropdown.length) {
      const ckAddOptions = checkAddressDropdown.map(
        ({ DisplayName, JsonKey }, key) => {
          let a = {
            key,
            value: DisplayName,
            label: DisplayName,
            JsonKey,
          };
          return a;
        }
      );
      setcheckAddressOptions(ckAddOptions);
    }

    if (checkHeadersDropdown && checkHeadersDropdown.length) {
      const options = checkHeadersDropdown.map(
        ({ DisplayName, JsonKey }, key) => {
          let a = {
            key,
            value: DisplayName,
            label: DisplayName,
            JsonKey,
          };
          return a;
        }
      );
      setCheckHeaderOptions(options);
    }

    if (cardAddressDropdown && cardAddressDropdown.length) {
      const cdAddOptions = cardAddressDropdown.map(
        ({ DisplayName, JsonKey }, key) => {
          let a = {
            key,
            value: DisplayName,
            label: DisplayName,
            JsonKey,
          };
          return a;
        }
      );
      setcardAddressOptions(cdAddOptions);
    }

    if (cardHeadersDropdown && cardHeadersDropdown.length) {
      const cdHeadOptions = cardHeadersDropdown.map(
        ({ DisplayName, JsonKey }, key) => {
          let a = {
            key,
            value: DisplayName,
            label: DisplayName,
            JsonKey,
          };
          return a;
        }
      );
      setCardHeaderOptions(cdHeadOptions);
    }
  }, [
    checkAddressDropdown,
    checkHeadersDropdown,
    cardAddressDropdown,
    cardHeadersDropdown,
  ]);

  const handleDeleteCheckAddress = (index, event) => {
    let key;
    selectedCheckAddressOptions.forEach((item) => {
      key = Object.keys(item);

      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedCheckAddressOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedCheckAdd(newOptions));

    const vals = [...saveStateAdd];

    vals[index].dropdown = null;
    vals[index].json = null;
    vals.splice(index, 1);
    dispatch(saveCheckAddress(vals));
  };

  const handleDeleteCardAddress = (index, event) => {
    let key;

    selectedCardAddressOptions.forEach((item) => {
      key = Object.keys(item);
      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedCardAddressOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedCardAdd(newOptions));
    const vals = [...saveStateCardAdd];
    vals[index].dropdown = null;
    vals[index].json = null;
    vals.splice(index, 1);
    dispatch(saveCardAddress(vals));
  };

  const handleDeleteCheckHeader = (index, event) => {
    let key;
    selectedCheckHeaderOptons.forEach((item) => {
      key = Object.keys(item);

      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedCheckHeaderOptons.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });

    dispatch(selectedCheckHeader(newOptions));

    const vals = [...saveStateHead];
    vals[index].dropdown = null;
    vals[index].json = null;
    vals.splice(index, 1);
    //setCheckHead(vals);
    dispatch(saveCheckHeader(vals));
  };
  const handleDeleteCardHeader = (index, event) => {
    let key;
    selectedCheckHeaderOptons.forEach((item) => {
      key = Object.keys(item);

      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedCardHeaderOptons.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });

    dispatch(selectedCardHeader(newOptions));

    const vals = [...saveStateCardHead];
    vals[index].dropdown = null;
    vals[index].json = null;
    vals.splice(index, 1);
    //setCheckHead(vals);
    dispatch(saveCardHeader(vals));
  };
  function matchInArray(str, expr) {
    let flag = false;
    let flag2 = false;
    expr.forEach(x => {
      flag = x.test(str)
      if (flag) {
        flag2 = flag
      }
    })
    return flag2
  };
  const handleAddCheckAddressRow = (e) => {
    e.stopPropagation();
    if (saveStateAdd.length > 2) return;

    const newCheckAdd = {
      dropdown: "",
      default: false,
      mandatory: false,
      blocks: "",
      minLenOfAddressLine: 1,
      isClose: true,
      isAdd: true,
    };
    const newArray = [...saveStateAdd, newCheckAdd];

    dispatch(saveCheckAddress(newArray));
  };

  const handleAddCardAddressRow = (e) => {
    e.stopPropagation();
    if (saveStateCardAdd.length > 2) return;

    const newCheckAdd = {
      dropdown: "",
      default: false,
      mandatory: false,
      blocks: "",
      minLenOfAddressLine: 1,
      isClose: true,
      isAdd: true,
    };
    const newArray = [...saveStateCardAdd, newCheckAdd];
    dispatch(saveCardAddress(newArray));
  };

  const handleAddCheckHeaderRow = (e) => {
    e.stopPropagation();

    const row = {
      default: false,
      dropdown: "",
      data_endIdentifier: {},
      data: {},
      isActive: true,
      mandatory: false,
      formBasedExtractor: true,
      blockExtractor: false,
      identifier: "",
      extractType: "form",
      endIdentifier: "",
      idlimit: 1,
      indexRange1: "0",
      indexRange2: "",
      isAdvanced: false,
      noOfBlocks: "1",
      valueBlock: "",
      valueType: "text",
      isClose: true,
      isAdd: true,
    };

    dispatch(saveCheckHeader([...saveStateHead, row]));
  };

  const handleAddCardHeaderRow = (e) => {
    e.stopPropagation();

    const row = {
      default: false,
      dropdown: "",
      data_endIdentifier: {},
      data: {},
      isActive: true,
      mandatory: false,
      formBasedExtractor: true,
      blockExtractor: false,
      identifier: "",
      extractType: "form",
      endIdentifier: "",
      idlimit: 1,
      indexRange1: "0",
      indexRange2: "",
      isAdvanced: false,
      noOfBlocks: "1",
      valueBlock: "",
      valueType: "text",
      isClose: true,
      isAdd: true,
    };
    //setCheckHead([...checkHead, row]);
    dispatch(saveCardHeader([...saveStateCardHead, row]));
  };

  const handleShow = () => dispatch(checkAddressOpen(!saveStateAddOpen));
  const handleShowCardAdd = () =>
    dispatch(cardAddressOpen(!saveStateCardAddOpen));
  const handleShowHeader = () => dispatch(checkHeaderOpen(!saveStateHeadOpen));
  const handleShowCardHeader = () =>
    dispatch(cardHeaderOpen(!saveStateCardHeadOpen));

  const handleResetAddress = (index) => {
    let key;
    selectedCheckAddressOptions.forEach((item) => {
      key = Object.keys(item);
      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedCheckAddressOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedCheckAdd(newOptions));
    const newckadd = {
      dropdown: "",
      isReset: true,
      default: true,
      json: "",
      isActive: false,
      mandatory: false,
      data: {},
      minLenOfAddressLine: 1,
      isAdd: !index ? false : true,
      isClose: !index ? false : true,
      blocks: "",
    };
    let vals = saveStateAdd;

    vals[index] = newckadd;

    vals[index].isReset = true;

    dispatch(
      checkAddressText({
        name: `checkAddressBlock${index + 1}`,
        text: "",
      })
    );

    dispatch(
      checkAddressText({
        name: `checkAddressBlockdata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      disableCheckAddActive({
        name: `checkAddressBlockActive${index + 1}`,
      })
    );

    dispatch(saveCheckAddress(vals));
  };

  const handleResetHeader = (index) => {
    let key;
    selectedCheckHeaderOptons.forEach((item) => {
      key = Object.keys(item);
      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedCheckHeaderOptons.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedCheckHeader(newOptions));
    const newckadd = {
      default: false,
      dropdown: "",
      value: "",
      data_value: {},
      noOfBlocks: 1,
      isReset: true,
      data_endIdentifier: {},
      data: {},
      isActive: true,
      mandatory: false,
      formBasedExtractor: true,
      blockExtractor: false,
      identifier: "",
      extractType: "form",
      endIdentifier: "",
      idlimit: 1,
      indexRange1: "0",
      indexRange2: "",
      isAdvanced: false,
      noOfBlocks: 1,
      valueBlock: "",
      valueType: "text",
      isAdd: !index ? false : true,
      isClose: !index ? false : true,
    };
    let vals = saveStateHead;

    vals[index] = newckadd;

    vals[index].isReset = true;

    dispatch(
      checkHeaderText({
        name: `checkHeaderIdentifier${index + 1}`,
        text: "",
      })
    );

    dispatch(
      checkHeaderText({
        name: `checkHeaderIdentifierdata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      checkHeaderText({
        name: `checkHeaderEndIdentifier${index + 1}`,
        text: "",
      })
    );

    dispatch(
      checkHeaderText({
        name: `checkHeaderEndIdentifierdata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      checkHeaderText({
        name: `checkHeaderValue${index + 1}`,
        text: "",
      })
    );

    dispatch(
      checkHeaderText({
        name: `checkHeaderValuedata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      disableCheckHeadActive({
        name: `checkHeaderValueActive${index + 1}`,
      })
    );

    // value
    dispatch(
      checkHeaderText({
        name: `checkHeadervalue${index + 1}`,
        text: "",
      })
    );

    dispatch(
      checkHeaderText({
        name: `checkHeadervaluedata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      disableCheckHeadActive({
        name: `checkHeadervalueActive${index + 1}`,
      })
    );

    dispatch(
      disableCheckHeadActive({
        name: `checkHeaderEndIdentifierActive${index + 1}`,
      })
    );

    dispatch(
      disableCheckHeadActive({
        name: `checkHeaderIdentifierActive${index + 1}`,
      })
    );

    dispatch(saveCheckHeader(vals));
  };

  const handleResetCardAddress = (index) => {
    let key;
    selectedCardAddressOptions.forEach((item) => {
      key = Object.keys(item);
      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedCardAddressOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedCardAdd(newOptions));
    const newckadd = {
      dropdown: "",
      isReset: true,
      default: true,
      json: "",
      isActive: false,
      mandatory: false,
      data: {},
      minLenOfAddressLine: 1,
      isAdd: !index ? false : true,
      isClose: !index ? false : true,
      blocks: "",
    };
    let vals = saveStateCardAdd;

    vals[index] = newckadd;

    vals[index].isReset = true;

    dispatch(
      cardAddressText({
        name: `cardAddressBlock${index + 1}`,
        text: "",
      })
    );

    dispatch(
      cardAddressText({
        name: `cardAddressBlockdata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      disableCardAddActive({
        name: `cardAddressBlockActive${index + 1}`,
      })
    );

    dispatch(saveCardAddress(vals));
  };

  const handleResetCardHeader = (index) => {
    let key;
    selectedCardHeaderOptons.forEach((item) => {
      key = Object.keys(item);
      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedCardHeaderOptons.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedCardHeader(newOptions));
    const newckadd = {
      default: false,
      isReset: true,
      value: "",
      data_value: {},
      dropdown: "",
      data_endIdentifier: {},
      data: {},
      isActive: true,
      mandatory: false,
      formBasedExtractor: true,
      blockExtractor: false,
      identifier: "",
      extractType: "form",
      endIdentifier: "",
      idlimit: 1,
      indexRange1: "0",
      indexRange2: "",
      isAdvanced: false,
      noOfBlocks: "",
      valueBlock: "",
      valueType: "text",
      isAdd: index ? true : false,
      isClose: index ? true : false,
      noOfBlocks: 1,
    };
    let vals = saveStateCardHead;

    vals[index] = newckadd;

    vals[index].isReset = true;

    dispatch(
      cardHeaderText({
        name: `cardHeaderIdentifier${index + 1}`,
        text: "",
      })
    );

    dispatch(
      cardHeaderText({
        name: `cardHeaderIdentifierdata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      cardHeaderText({
        name: `cardHeaderEndIdentifier${index + 1}`,
        text: "",
      })
    );

    dispatch(
      cardHeaderText({
        name: `cardHeaderEndIdentifierdata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      cardHeaderText({
        name: `cardHeaderValue${index + 1}`,
        text: "",
      })
    );

    dispatch(
      cardHeaderText({
        name: `cardHeaderValuedata${index + 1}`,
        text: {},
      })
    );
    dispatch(
      disableCardAddActive({
        name: `cardHeaderValueActive${index + 1}`,
      })
    );

    // value
    dispatch(
      cardHeaderText({
        name: `cardHeadervalue${index + 1}`,
        text: "",
      })
    );

    dispatch(
      cardHeaderText({
        name: `cardHeadervaluedata${index + 1}`,
        text: {},
      })
    );
    dispatch(
      disableCardAddActive({
        name: `cardHeadervalueActive${index + 1}`,
      })
    );

    dispatch(
      disableCardAddActive({
        name: `cardHeaderIdentifierActive${index + 1}`,
      })
    );

    dispatch(
      disableCardAddActive({
        name: `cardHeaderEndIdentifierActive${index + 1}`,
      })
    );

    dispatch(saveCardHeader(vals));
  };

  return (
    <Box
      h="600px"
      //w="678px"
      margin="0px auto"
    >
      <Box overflowX="hidden" className="inner" maxH="608px">
        <Box
          h="auto"
          margin="10px 20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="6px"
          padding="4px 5px"
        >
          <Box padding="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={handleShow}
              cursor="pointer"
            >
              <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                Check Address
              </Text>
              <Box display="flex" alignItems="center">
                <img
                  src={Add}
                  style={{ marginLeft: 20, cursor: "pointer", height: 20 }}
                  // onClick={addCheckAddressRow}
                  onClick={handleAddCheckAddressRow}
                  alt="add"
                />
                <img
                  src={saveStateAddOpen ? Up : Down}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  alt="saveStateAddOpen"
                />
              </Box>
            </Box>
            {saveStateAddOpen && (
              <>
                {saveStateAdd.map((ck, index) => (
                  <Box
                    key={index}
                    id={`checkAddress${index}`}
                    background="white"
                    b="1px solid black"
                    h="188px"
                    paddingTop="10px"
                    margin="10px 0px"
                  >
                    <Box display={"flex"} justifyContent="flex-end">
                      <Box marginRight={!ck?.isAdd ? "20px" : 0}>
                        <button onClick={() => handleResetAddress(index)}>
                          Reset
                        </button>
                      </Box>
                      <img
                        src={Add}
                        style={{
                          marginLeft: 20,
                          cursor: "pointer",
                          height: 20,
                          marginRight: 10,
                          display: ck?.isAdd ? "flex" : "none",
                        }}
                        onClick={handleAddCheckAddressRow}
                        alt="add"
                      />

                      <img
                        src={Cross}
                        alt="delete"
                        style={{
                          height: 13,
                          width: 13,
                          marginRight: 10,
                          marginBottom: 10,
                          cursor: "pointer",
                          display: ck?.isClose ? "flex" : "none",
                        }}
                        onClick={(event) => {
                          handleDeleteCheckAddress(index, event);
                        }}
                      />
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      margin="5px 10px"
                    >
                      <Tooltip title={ck.dropdown ? ck.dropdown : "empty"}>
                        <SelectPicker
                          onClean={() => {
                            let key;

                            selectedCheckAddressOptions.forEach((item) => {
                              key = Object.keys(item);

                              if (key[0] / 1 === index) {
                                delete item[key];
                              }
                            });
                            let newOptions = selectedCheckAddressOptions.filter(
                              (item) => {
                                if (Object.keys(item).length > 0) return true;
                              }
                            );

                            dispatch(selectedCheckAdd(newOptions));
                            const vals = [...saveStateAdd];
                            vals[index].dropdown = null;

                            vals[index].json = null;
                            //setCheckAdd(vals);
                            dispatch(saveCheckAddress(vals));
                          }}
                          value={ck.dropdown}
                          disabledItemValues={checkAddDisabled}
                          onChange={(v) => {
                            const values = [...saveStateAdd];
                            if (!values[index].dropdown) {
                              values[index].dropdown = v;
                              const json = checkAddressOptions.find(
                                (item) => item.value === v
                              );
                              values[index].json = json.JsonKey;
                              // setSelectedVal(v);
                              // setCheckAdd(values);

                              dispatch(
                                selectedCheckAdd([
                                  ...selectedCheckAddressOptions,
                                  { [index]: v },
                                ])
                              );
                              dispatch(saveCheckAddress(values));
                            }
                          }}
                          data={checkAddressOptions}
                          style={{ width: 344 }}
                          renderMenu={(menu) => {
                            if (!checkAddressOptions.length) {
                              return (
                                <p
                                  style={{
                                    padding: 4,
                                    color: "#999",
                                    textAlign: "center",
                                  }}
                                >
                                  <i
                                    className="fa fa-circle-o-notch fa-spin"
                                    style={{ fontSize: "16px" }}
                                  ></i>{" "}
                                  &nbsp; Loading...
                                </p>
                              );
                            }
                            return menu;
                          }}
                        />
                      </Tooltip>

                      <Box>
                        <input
                          type="checkbox"
                          id="mandatory"
                          checked={ck.mandatory}
                          //defaultChecked={ck?.mandatory}
                          onClick={(e) => {
                            console.log(index);
                            const values = [...saveStateAdd];
                            values[index].mandatory = e.target.checked;
                            // setCheckAdd(values);
                            dispatch(saveCheckAddress(values));
                          }}
                        />
                        <label style={{ fontStyle: "italic" }}>Mandatory</label>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      h="40px"
                    >
                      <Box display="flex" alignItems="center">
                        <Text
                          marginLeft="10px"
                          fontWeight="bold"
                          marginBottom="0px"
                        >
                          Blocks
                        </Text>
                        <Box marginLeft="10px">
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/1Hvf3uUd6bfWHtsz?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                Select all the blocks of the payee/payer name
                                and address based on the selected name.Press
                                Ctrl+click for selecting multiple blocks.(Click
                                for more information)
                              </a>
                            }
                            placement="right"
                          >
                            <img src={Help} style={{ height: 12 }} />
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box>
                        <Tooltip title={ck?.blocks}>
                          <input
                            type="text"
                            onClick={() => {
                              dispatch(
                                checkAddressActive({
                                  name: `checkAddressBlockActive${index + 1}`,
                                })
                              );
                            }}
                            name="blocks"
                            placeholder="click on a block in image"
                            onChange={(e) => {
                              const values = [...saveStateAdd];
                              if (values[index].blocks.length <= 0) {
                                alert(
                                  "please select value from image to proceed"
                                );
                                e.preventDefault();
                              } else {
                                values[index].blocks = e.target.value;
                                if (!e.target.value) {
                                  values[index].data = {};
                                } else if (
                                  e.target.value &&
                                  !_.isEmpty(values[index].data)
                                ) {
                                  values[index].data["Text"] = e.target.value;
                                }

                                // setCheckAdd(values);
                                dispatch(saveCheckAddress(values));

                                dispatch(
                                  checkAddressText({
                                    name: `checkAddressBlock${index + 1}`,
                                    text: e.target.value,
                                  })
                                );
                              }
                            }}
                            value={ck.blocks}
                            style={{
                              width: 200,
                              marginRight: 27,
                              fontStyle: "italic",
                              padding: 4,
                              border: "1px solid gray",
                              borderRadius: 4,
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      h="15px"
                      marginTop="15px"
                    >
                      <Box display="flex" alignItems="center">
                        <Text
                          marginLeft="10px"
                          fontWeight="bold"
                          marginBottom="0px"
                        >
                          Min length of address lines
                        </Text>
                        <Box marginLeft="10px">
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/1Hvf3uUd6bfWHtsz?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                Expected minimum length of address lines.Click
                                for more information
                              </a>
                            }
                            placement="right"
                          >
                            <img src={Help} style={{ height: 12 }} alt="help" />
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box w="138px">
                        <input
                          type="number"
                          min="1"
                          name="minLenOfAddressLine"
                          onChange={(event) => {
                            const values = [...saveState.ckadd];
                            values[index].minLenOfAddressLine =
                              event.target.value;
                            //setCheckAdd(values);
                            dispatch(saveCheckAddress(values));
                            // handleChangeInput(index, event, "isField")
                          }}
                          value={ck?.minLenOfAddressLine}
                          style={{
                            width: 60,
                            marginLeft: -89,
                            marginRight: 20,
                            fontStyle: "italic",
                            padding: 4,
                            border: "1px solid gray",
                            borderRadius: 4,
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>

        {/* check header */}
        <Box
          h="auto"
          margin="10px 20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="6px"
          padding="4px 5px"
        >
          <Box padding="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={handleShowHeader}
              cursor="pointer"
            >
              <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                Check Header
              </Text>
              <Box display="flex" alignItems="center">
                <img
                  src={Add}
                  style={{ marginLeft: 20, cursor: "pointer", height: 20 }}
                  onClick={handleAddCheckHeaderRow}
                  alt="add"
                />
                <img
                  src={saveStateHeadOpen ? Up : Down}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  alt="show"
                />
              </Box>
            </Box>
            {/* check header */}
            {saveStateHeadOpen && (
              <>
                {saveStateHead.map((ckHead, index) => (
                  <Box
                    key={index}
                    id={`checkHeader${index}`}
                    background="white"
                    b="1px solid black"
                    h="auto"
                    paddingTop="10px"
                    margin="10px 0px"
                  >
                    <Box display={"flex"} justifyContent="flex-end">
                      <Box marginRight={!ckHead?.isAdd ? "20px" : 0}>
                        <button onClick={() => handleResetHeader(index)}>
                          Reset
                        </button>
                      </Box>
                      <img
                        src={Add}
                        style={{
                          marginLeft: 20,
                          cursor: "pointer",
                          height: 20,
                          marginRight: 10,
                          display: ckHead?.isAdd ? "flex" : "none",
                        }}
                        onClick={handleAddCheckHeaderRow}
                        alt="add"
                      />

                      <img
                        src={Cross}
                        alt="delete"
                        style={{
                          height: 13,
                          width: 13,
                          marginRight: 10,
                          marginBottom: 10,
                          cursor: "pointer",
                          display: ckHead?.isClose ? "flex" : "none",
                        }}
                        onClick={(event) =>
                          handleDeleteCheckHeader(index, event)
                        }
                      />
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      margin="5px 10px"
                    >
                      <Tooltip
                        title={ckHead?.dropdown ? ckHead?.dropdown : "empty"}
                      >
                        <SelectPicker
                          onClean={() => {
                            let key;
                            selectedCheckHeaderOptons.forEach((item) => {
                              key = Object.keys(item);

                              if (key[0] / 1 === index) {
                                delete item[key];
                              }
                            });
                            let newOptions = selectedCheckHeaderOptons.filter(
                              (item) => {
                                if (Object.keys(item).length > 0) return true;
                              }
                            );
                            dispatch(selectedCheckHeader(newOptions));

                            const vals = [...saveStateHead];
                            vals[index].dropdown = null;
                            vals[index].json = null;
                            dispatch(saveCheckHeader(vals));
                          }}
                          value={ckHead?.dropdown}
                          disabledItemValues={checkHeadDisabled}
                          onChange={(v) => {
                            const values = [...saveStateHead];
                            if (!values[index].dropdown) {
                              values[index].dropdown = v;
                              const json = checkHeaderOptions.find(
                                (item) => item.value === v
                              );
                              values[index].json = json.JsonKey;

                              dispatch(
                                selectedCheckHeader([
                                  ...selectedCheckHeaderOptons,
                                  { [index]: v },
                                ])
                              );
                              dispatch(saveCheckHeader(values));
                            }
                          }}
                          data={checkHeaderOptions}
                          style={{ width: 344 }}
                          renderMenu={(menu) => {
                            if (checkHeaderOptions.length === 0) {
                              return (
                                <p
                                  style={{
                                    padding: 4,
                                    color: "#999",
                                    textAlign: "center",
                                  }}
                                >
                                  <i
                                    className="fa fa-circle-o-notch fa-spin"
                                    style={{ fontSize: "16px" }}
                                  ></i>{" "}
                                  &nbsp; Loading...
                                </p>
                              );
                            }
                            return menu;
                          }}
                        />
                      </Tooltip>
                      <Box>
                        <input
                          type="checkbox"
                          id="mandatory"
                          checked={ckHead?.mandatory}
                          onChange={(e) => {
                            const values = [...saveStateHead];
                            values[index]["mandatory"] = e.target.checked;

                            dispatch(saveCheckHeader(values));
                          }}
                        />
                        <label style={{ fontStyle: "italic" }}>Mandatory</label>
                      </Box>
                    </Box>

                    <Box
                      margin="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        w="-webkit-fill-available"
                        onClick={(e) => {
                          const values = [...saveStateHead];
                          values[index].formBasedExtractor = true;
                          values[index].blockExtractor = false;
                          //setCheckHead(values);
                          dispatch(saveCheckHeader(values));
                        }}
                        cursor="pointer"
                        b="1px solid black"
                        br="none"
                        padding="10px"
                        background={
                          ckHead?.formBasedExtractor === undefined ||
                            ckHead?.formBasedExtractor
                            ? "white"
                            : "lightgray"
                        }
                        justifyContent="space-between"
                        alignItems="center"
                        display="flex"
                        textAlign="center"
                      >
                        <span>Key value extractor</span>
                      </Box>
                      <Box
                        w="-webkit-fill-available"
                        onClick={() => {
                          const values = [...saveStateHead];
                          values[index]["blockExtractor"] = true;
                          values[index]["formBasedExtractor"] = false;
                          dispatch(saveCheckHeader(values));
                        }}
                        cursor="pointer"
                        b={
                          ckHead?.blockExtractor
                            ? "1px solid #ACACAC"
                            : "1px solid black"
                        }
                        bl="none"
                        padding="10px"
                        background={
                          ckHead?.blockExtractor ? "white" : "lightgray"
                        }
                        justifyContent="space-between"
                        alignItems="center"
                        display="flex"
                        textAlign="center"
                      >
                        <span style={{ color: "black" }}>Block extractor</span>
                      </Box>
                    </Box>

                    <Box
                      background="#DEDEDE 0% 0% no-repeat padding-box;"
                      borderRadius="5px"
                      padding="10px 0px"
                      margin="10px"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        h="40px"
                        w="220px"
                      >
                        <Box display="flex" alignItems="center">
                          {ckHead?.blockExtractor ? (
                            <>
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                marginBottom="0px"
                                width={"95px"}
                              >
                                Value block
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/VLwsB3DnSARHScxd?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    The block that has to be extracted. For
                                    selecting multiple blocks, press Ctrl+click
                                    and select the multiple blocks. (Click for
                                    more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                marginBottom="0px"
                              >
                                Identifier
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Identifier is the label to the required
                                    value. For Eg: AMOUNT PAID is the identifier
                                    to extract AMOUNT PAID: $$1203.23**.(Click
                                    for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </>
                          )}
                        </Box>

                        <Box display="flex" alignItems="center">
                          {ckHead?.formBasedExtractor ? (
                            <>
                              <Tooltip title={ckHead?.identifier}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      checkHeaderActive({
                                        name: `checkHeaderIdentifierActive${index + 1
                                          }`,
                                      })
                                    );
                                  }}
                                  placeholder="click on a block in image"
                                  onChange={(e) => {
                                    const values = [...saveStateHead];
                                    if (values[index].identifier.length <= 0) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                      return;
                                    } else {
                                      values[index].identifier = e.target.value;
                                    }

                                    if (!e.target.value) {
                                      values[index].data = {};
                                    } else if (
                                      e.target.value &&
                                      !_.isEmpty(values[index].data)
                                    ) {
                                      values[index].data["Text"] =
                                        e.target.value;
                                    }

                                    // setCheckHead(values);
                                    dispatch(saveCheckHeader(values));
                                    dispatch(
                                      checkHeaderText({
                                        name: `checkHeaderIdentifier${index + 1
                                          }`,

                                        text: e.target.value,
                                      })
                                    );
                                  }}
                                  value={ckHead?.identifier}
                                  type="text"
                                  style={{
                                    width: 221,
                                    marginLeft: ckHead?.blockExtractor
                                      ? 93
                                      : 134,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <Tooltip title={ckHead?.valueBlock}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      checkHeaderActive({
                                        name: `checkHeaderValueActive${index + 1
                                          }`,
                                      })
                                    );
                                  }}
                                  placeholder="click on a block in image"
                                  onChange={(e) => {
                                    const values = [...saveStateHead];
                                    values[index].valueType = "text"

                                    if (values[index].valueBlock.length <= 0) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                      return;
                                    } else {
                                      values[index].valueBlock = e.target.value;
                                    }

                                    if (!e.target.value) {
                                      values[index].data = {};
                                    } else if (
                                      e.target.value &&
                                      !_.isEmpty(values[index].data)
                                    ) {
                                      values[index].data["Text"] =
                                        e.target.value;
                                    }

                                    // setCheckHead(values);
                                    dispatch(saveCheckHeader(values));
                                    dispatch(
                                      checkHeaderText({
                                        name: `checkHeaderValue${index + 1}`,
                                        text: e.target.value,
                                      })
                                    );
                                  }}
                                  // }
                                  value={ckHead?.valueBlock}
                                  type="text"
                                  style={{
                                    width: 221,
                                    marginLeft: ckHead?.blockExtractor
                                      ? 93
                                      : 134,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </>
                          )}
                        </Box>
                      </Box>

                      {ckHead?.formBasedExtractor && (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center" w="228px">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                                marginBottom="0px"
                              >
                                Value
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Anything between the identifier and end
                                    identifier is extracted as the value. Used
                                    for form type of value extraction. Click for
                                    more information
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </Box>
                            <Box marginRight="10px">
                              <Tooltip title={ckHead?.value}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      checkHeaderActive({
                                        name: `checkHeadervalueActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...saveStateHead];
                                    if (
                                      values[index]?.value?.length <= 0 ||
                                      values[index]?.data_value?.length <= 0
                                    ) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[index].value = e.target.value;
                                      if (!e.target.value) {
                                        values[index].data_value = {};
                                      } else if (
                                        e.target.value &&
                                        !_.isEmpty(values[index].data_value)
                                      ) {
                                        values[index].data_value["Text"] =
                                          e.target.value;
                                      }
                                      dispatch(saveCheckHeader(values));
                                      dispatch(
                                        checkHeaderText({
                                          name: `checkHeadervalue${index + 1}`,
                                          text: e.target.value,
                                        })
                                      );
                                    }
                                  }}
                                  value={ckHead?.value}
                                  type="text"
                                  placeholder="click on a block in image"
                                  style={{
                                    width: 240,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                        </>
                      )}

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        h="40px"
                      >
                        <Box display="flex" alignItems="center">
                          <Text
                            marginLeft="10px"
                            font={labelFont}
                            marginBottom="0px"
                          >
                            {ckHead?.blockExtractor
                              ? "Value type"
                              : "Extract type"}
                          </Text>
                          {ckHead?.blockExtractor ? (
                            <Tooltip
                              interactive
                              title={
                                <a
                                  href="https://sway.office.com/VLwsB3DnSARHScxd?ref=Link"
                                  target="_blank"
                                  style={{ color: "white" }}
                                >
                                  Select this based on the type of the block
                                  extracted.(Click for more information)
                                </a>
                              }
                              placement="right"
                            >
                              <img
                                src={Help}
                                style={{ height: 12, marginLeft: 6 }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              interactive
                              title={
                                <a
                                  href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                  target="_blank"
                                  style={{ color: "white" }}
                                >
                                  Select this based on how the value and the
                                  identifier is placed.(Click for more
                                  information)
                                </a>
                              }
                              placement="right"
                            >
                              <img
                                src={Help}
                                style={{ height: 12, marginLeft: 6 }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                        <Box marginLeft="107px">
                          <select
                            className={checkHeaderError[index] ? "borderBlink" : ""}
                            onChange={(e) => {
                              const values = [...saveStateHead];
                              ckHead?.formBasedExtractor
                                ? (values[index].extractType = e.target.value)
                                : (values[index].valueType = e.target.value);
                              if (!ckHead?.formBasedExtractor) {
                                if (values[index].valueBlock.length <= 0) {
                                  alert(
                                    "please enter value in value block"
                                  );
                                }
                                else {
                                  if (e.target.value === "text") {
                                    values[index].valueType = "text"
                                    let checkHeaderErrorStatus = checkHeaderError
                                    checkHeaderErrorStatus[index] = false
                                    setCheckHeaderError(checkHeaderErrorStatus)

                                  }
                                  if (e.target.value === "amount($)") {
                                    let amountRegex = /^[$S8*]*\d+(,?\d+)*[. ]?\d+$/
                                    let checkBlock1 = true;
                                    if (
                                      values[index]?.valueBlock?.length >= 0
                                    ) {
                                      checkBlock1 = amountRegex.test(
                                        saveStateHead[index]?.valueBlock
                                      );
                                    }
                                    if (!checkBlock1) {
                                      alert("Select valid value type")
                                      values[index].valueType = "text"
                                      let checkHeaderErrorStatus = checkHeaderError
                                      checkHeaderErrorStatus[index] = true
                                      setCheckHeaderError(checkHeaderErrorStatus)
                                    }
                                    else {
                                      let checkHeaderErrorStatus = checkHeaderError
                                      checkHeaderErrorStatus[index] = false
                                      setCheckHeaderError(checkHeaderErrorStatus)
                                    }
                                  }
                                  Object.keys(valueTypeRegexData).find(
                                    (key) => {
                                      if (key === e.target.value.toUpperCase()) {
                                        if (e.target.value === "date") {
                                          let checkDate = matchInArray(saveStateHead[index]?.valueBlock, valueTypeRegexData[key])
                                          if (!checkDate) {
                                            alert("Select valid value type")
                                            values[index].valueType = "text"
                                            let checkHeaderErrorStatus = checkHeaderError
                                            checkHeaderErrorStatus[index] = true
                                            setCheckHeaderError(checkHeaderErrorStatus)
                                          }
                                          else {
                                            let checkHeaderErrorStatus = checkHeaderError
                                            checkHeaderErrorStatus[index] = false
                                            setCheckHeaderError(checkHeaderErrorStatus)
                                          }
                                        }
                                      }
                                    }
                                  );

                                }
                              }
                              dispatch(saveCheckHeader(values));
                            }}
                            value={
                              ckHead?.formBasedExtractor
                                ? ckHead?.extractType
                                : ckHead?.valueType
                            }
                            style={{
                              width: 150,
                              marginRight: 30,
                              fontWeight: "bold",
                              padding: 3,
                              outline: "0px",
                              border: checkHeaderError[index] ? '2px solid red' : '1px solid gray'
                            }}
                          >
                            {ckHead?.blockExtractor
                              ? blockExtractorOptions.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))
                              : formBasedOptions.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                          </select>
                        </Box>
                      </Box>
                    </Box>

                    {/* advanced */}
                    {!ckHead?.blockExtractor && (
                      <Box
                        background="#DEDEDE 0% 0% no-repeat padding-box;"
                        borderRadius="5px"
                        margin="10px"
                        h="auto"
                        padding="5px"
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          h="30px"
                          cursor="pointer"
                          onClick={(e) => {
                            const values = [...saveStateHead];
                            values[index].isAdvanced =
                              !values[index].isAdvanced;
                            // setCheckHead(values);
                            dispatch(saveCheckHeader(values));
                          }}
                        >
                          <Text
                            marginLeft="10px"
                            fontWeight="bold"
                            font={headingFont}
                            marginBottom="0px"
                          >
                            ADVANCED
                          </Text>

                          <img
                            src={ckHead?.isAdvanced ? Up : Down}
                            style={{ marginRight: 20, cursor: "pointer" }}
                          />
                        </Box>
                        {ckHead?.isAdvanced && !ckHead?.blockExtractor && (
                          <Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  // width="215px"
                                  marginBottom="0px"
                                  textColor={labelColor}
                                >
                                  End Identifier
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      Anything between the identifier and end
                                      identifier is extracted as the value. Used
                                      only for form type of value
                                      extraction.(Click for more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <Tooltip title={ckHead?.endIdentifier}>
                                  <input
                                    onClick={() => {
                                      dispatch(
                                        checkHeaderActive({
                                          name: `checkHeaderEndIdentifierActive${index + 1
                                            }`,
                                        })
                                      );
                                    }}
                                    type="text"
                                    placeholder="click on a block in image"
                                    onChange={(e) => {
                                      const values = [...saveStateHead];
                                      if (
                                        values[index].endIdentifier.length <= 0
                                      ) {
                                        alert(
                                          "please select value from image to proceed"
                                        );
                                        e.preventDefault();
                                      } else {
                                        values[index].endIdentifier =
                                          e.target.value;
                                        if (!e.target.value) {
                                          values[index].data_endIdentifier = {};
                                        } else if (
                                          e.target.value &&
                                          !_.isEmpty(
                                            values[index].data_endIdentifier
                                          )
                                        ) {
                                          values[index].data_endIdentifier[
                                            "Text"
                                          ] = e.target.value;
                                        }

                                        //setCheckHead(values);
                                        dispatch(saveCheckHeader(values));
                                        dispatch(
                                          checkHeaderText({
                                            name: `checkHeaderEndIdentifier${index + 1
                                              }`,
                                            text: e.target.value,
                                          })
                                        );
                                      }
                                    }}
                                    value={ckHead?.endIdentifier}
                                    style={{
                                      width: 240,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  ID limit
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      If there are multiple occurrences of the
                                      identifier, The nth (integer value
                                      specified) occurrence will be chosen as
                                      the identifier block.(Click for more
                                      information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <input
                                  onChange={(e) => {
                                    const values = [...saveStateHead];
                                    values[index].idlimit = e.target.value;
                                    //setCheckHead(values);
                                    dispatch(saveCheckHeader(values));
                                  }}
                                  value={ckHead?.idlimit}
                                  type="number"
                                  min="1"
                                  style={{
                                    width: 60,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box w="215px" display="flex" alignItems="center">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  Index range
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      From the list of extracted blocks, blocks
                                      from index x (inclusive) till index y
                                      (exclusive) will be captured.(Click for
                                      more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box
                                marginRight="10px"
                                display="flex"
                                alignItems="center"
                              >
                                <input
                                  type="number"
                                  min="-10"
                                  onChange={(e) => {
                                    const values = [...saveStateHead];
                                    values[index].indexRange1 = e.target.value;
                                    // setCheckHead(values);
                                    dispatch(saveCheckHeader(values));
                                  }}
                                  value={ckHead?.indexRange1}
                                  style={{
                                    width: 60,
                                    height: 24,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                    marginRight: 10,
                                  }}
                                />
                                <Text fontStyle="italic" marginBottom="0px">
                                  To{" "}
                                </Text>
                                <input
                                  type="number"
                                  onChange={(e) => {
                                    const values = [...saveStateHead];
                                    values[index].indexRange2 = e.target.value;
                                    //setCheckHead(values);
                                    dispatch(saveCheckHeader(values));
                                  }}
                                  value={ckHead?.indexRange2}
                                  style={{
                                    width: 60,
                                    height: 24,
                                    marginLeft: 10,

                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  No. of blocks
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      style={{ color: "white" }}
                                      target="_blank"
                                    >
                                      It is used when the end identifier is not
                                      found. By default, it is 1.If there is a
                                      specific number of blocks that you need
                                      that has to be extracted after the
                                      identifier then the number of blocks field
                                      is changed.(Click for more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <input
                                  type="number"
                                  min="1"
                                  defaultValue="1"
                                  // onkeydown="return false"
                                  onChange={(e) => {
                                    const values = [...saveStateHead];
                                    if (e.target.value == "0") return;
                                    else
                                      values[index].noOfBlocks = e.target.value;

                                    //setCheckHead(values);
                                    dispatch(saveCheckHeader(values));
                                  }}
                                  value={ckHead?.noOfBlocks}
                                  defaultValue="1"
                                  style={{
                                    width: 60,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>

        {/* Card Address */}
        <Box
          h="auto"
          margin="10px 20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="6px"
          padding="4px 5px"
        >
          <Box padding="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={handleShowCardAdd}
              cursor="pointer"
            >
              <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                Card Address
              </Text>
              <Box display="flex" alignItems="center">
                <img
                  src={Add}
                  style={{ marginLeft: 20, cursor: "pointer", height: 20 }}
                  // onClick={addCheckAddressRow}
                  onClick={handleAddCardAddressRow}
                  alt="add"
                />
                <img
                  src={saveStateCardAddOpen ? Up : Down}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  alt="saveStateCardAddOpen"
                />
              </Box>
            </Box>
            {saveStateCardAddOpen && (
              <>
                {saveStateCardAdd?.map((ck, index) => (
                  <Box
                    key={index}
                    background="white"
                    id={`cardAddress${index}`}
                    b="1px solid black"
                    h="188px"
                    paddingTop="10px"
                    margin="10px 0px"
                  >
                    <Box
                      display={"flex"}
                      justifyContent="flex-end"
                      marginBottom="20px"
                    >
                      <Box marginRight={!ck?.isAdd ? "20px" : 0}>
                        <button onClick={() => handleResetCardAddress(index)}>
                          Reset
                        </button>
                      </Box>
                      <img
                        src={Add}
                        style={{
                          marginLeft: 20,
                          cursor: "pointer",
                          height: 20,
                          marginRight: 10,
                          display: ck?.isAdd ? "flex" : "none",
                        }}
                        onClick={handleAddCardAddressRow}
                        alt="add"
                      />

                      <img
                        src={Cross}
                        alt="delete"
                        style={{
                          height: 13,
                          width: 13,
                          marginRight: 10,
                          marginBottom: 10,
                          cursor: "pointer",
                          display: ck?.isClose ? "flex" : "none",
                        }}
                        onClick={(event) => {
                          handleDeleteCardAddress(index, event);
                        }}
                      />
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      margin="5px 10px"
                    >
                      <Tooltip title={ck.dropdown ? ck.dropdown : "empty"}>
                        <SelectPicker
                          onClean={() => {
                            let key;
                            selectedCardAddressOptions.forEach((item) => {
                              key = Object.keys(item);

                              if (key[0] / 1 === index) {
                                delete item[key];
                              }
                            });
                            let newOptions = selectedCardAddressOptions.filter(
                              (item) => {
                                if (Object.keys(item).length > 0) return true;
                              }
                            );

                            dispatch(selectedCardAdd(newOptions));
                            const vals = [...saveStateCardAdd];
                            vals[index].dropdown = null;
                            vals[index].json = null;
                            //setCheckAdd(vals);
                            dispatch(saveCardAddress(vals));
                          }}
                          value={ck.dropdown}
                          disabledItemValues={cardAddDisabled}
                          onChange={(v) => {
                            const values = [...saveStateCardAdd];
                            if (!values[index].dropdown) {
                              values[index].dropdown = v;
                              const json = cardAddressOptions.find(
                                (item) => item.value === v
                              );
                              values[index].json = json.JsonKey;
                              // setSelectedVal(v);
                              // setCheckAdd(values);

                              dispatch(
                                selectedCardAdd([
                                  ...selectedCardAddressOptions,
                                  { [index]: v },
                                ])
                              );
                              dispatch(saveCardAddress(values));
                            }
                          }}
                          data={cardAddressOptions}
                          style={{ width: 344 }}
                          renderMenu={(menu) => {
                            if (!cardAddressOptions.length) {
                              return (
                                <p
                                  style={{
                                    padding: 4,
                                    color: "#999",
                                    textAlign: "center",
                                  }}
                                >
                                  <i
                                    className="fa fa-circle-o-notch fa-spin"
                                    style={{ fontSize: "16px" }}
                                  ></i>{" "}
                                  &nbsp; Loading...
                                </p>
                              );
                            }
                            return menu;
                          }}
                        />
                      </Tooltip>

                      <Box>
                        <input
                          type="checkbox"
                          id="mandatory"
                          checked={ck.mandatory}
                          //defaultChecked={ck?.mandatory}
                          onClick={(e) => {
                            console.log(index);
                            const values = [...saveStateCardAdd];
                            values[index].mandatory = e.target.checked;
                            // setCheckAdd(values);
                            dispatch(saveCardAddress(values));
                          }}
                        />
                        <label style={{ fontStyle: "italic" }}>Mandatory</label>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      h="40px"
                    >
                      <Box display="flex" alignItems="center">
                        <Text
                          marginLeft="10px"
                          fontWeight="bold"
                          marginBottom="0px"
                        >
                          Blocks
                        </Text>
                        <Box marginLeft="10px">
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/1d43RAA5XWqJGjYw?play"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                Select all the blocks of the payee/payer name
                                and address based on the selected name.Press
                                Ctrl+click for selecting multiple blocks.(Click
                                for more information)
                              </a>
                            }
                            placement="right"
                          >
                            <img src={Help} style={{ height: 12 }} />
                          </Tooltip>
                        </Box>
                      </Box>

                      <Box>
                        <Tooltip title={ck.blocks}>
                          <input
                            type="text"
                            onClick={() => {
                              dispatch(
                                cardAddressActive({
                                  name: `cardAddressBlockActive${index + 1}`,
                                })
                              );
                            }}
                            name="blocks"
                            placeholder="click on a block in image"
                            onChange={(e) => {
                              const values = [...saveStateCardAdd];
                              if (values[index].blocks.length <= 0) {
                                alert(
                                  "please select value from image to proceed"
                                );
                                e.preventDefault();
                              } else {
                                values[index].blocks = e.target.value;
                                if (!e.target.value) {
                                  values[index].data = {};
                                } else if (
                                  e.target.value &&
                                  !_.isEmpty(values[index].data)
                                ) {
                                  values[index].data["Text"] = e.target.value;
                                }

                                // setCheckAdd(values);
                                dispatch(saveCardAddress(values));

                                dispatch(
                                  cardAddressText({
                                    name: `cardAddressBlock${index + 1}`,
                                    text: e.target.value,
                                  })
                                );
                              }
                            }}
                            value={ck.blocks}
                            style={{
                              width: 200,
                              marginRight: 27,
                              fontStyle: "italic",
                              padding: 4,
                              border: "1px solid gray",
                              borderRadius: 4,
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      h="15px"
                      marginTop="15px"
                    >
                      <Box display="flex" alignItems="center">
                        <Text
                          marginLeft="10px"
                          fontWeight="bold"
                          marginBottom="0px"
                        >
                          Min length of address lines
                        </Text>
                        <Box marginLeft="10px">
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/1d43RAA5XWqJGjYw?play"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                Expected minimum length of address lines.Click
                                for more information
                              </a>
                            }
                            placement="right"
                          >
                            <img src={Help} style={{ height: 12 }} alt="help" />
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box w="138px">
                        <input
                          type="number"
                          min="1"
                          name="minLenOfAddressLine"
                          onChange={(event) => {
                            const values = [...saveState.cardAdd];
                            values[index].minLenOfAddressLine =
                              event.target.value;
                            //setCheckAdd(values);
                            dispatch(saveCardAddress(values));
                            // handleChangeInput(index, event, "isField")
                          }}
                          value={ck?.minLenOfAddressLine}
                          style={{
                            width: 60,
                            marginLeft: -89,
                            marginRight: 20,
                            fontStyle: "italic",
                            padding: 4,
                            border: "1px solid gray",
                            borderRadius: 4,
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
        {/* CARD HEADER */}
        <Box
          h="auto"
          margin="10px 20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="6px"
          padding="4px 5px"
        >
          <Box padding="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={handleShowCardHeader}
              cursor="pointer"
            >
              <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                Card Header
              </Text>
              <Box display="flex" alignItems="center">
                <img
                  src={Add}
                  style={{ marginLeft: 20, cursor: "pointer", height: 20 }}
                  onClick={handleAddCardHeaderRow}
                  alt="add"
                />
                <img
                  src={saveStateCardHeadOpen ? Up : Down}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  alt="show"
                />
              </Box>
            </Box>
            {/* card header */}
            {saveStateCardHeadOpen && (
              <>
                {saveStateCardHead.map((ckHead, index) => (
                  <Box
                    key={index}
                    id={`cardHeader${index}`}
                    background="white"
                    b="1px solid black"
                    h="auto"
                    paddingTop="10px"
                    margin="10px 0px"
                  >
                    <Box
                      display={"flex"}
                      justifyContent="flex-end"
                      marginBottom="20px"
                    >
                      <Box marginRight={!ckHead?.isAdd ? "20px" : 0}>
                        <button onClick={() => handleResetCardHeader(index)}>
                          Reset
                        </button>
                      </Box>
                      <img
                        src={Add}
                        style={{
                          marginLeft: 20,
                          cursor: "pointer",
                          height: 20,
                          marginRight: 10,
                          display: ckHead?.isAdd ? "flex" : "none",
                        }}
                        onClick={handleAddCardHeaderRow}
                        alt="add"
                      />

                      <img
                        src={Cross}
                        alt="delete"
                        style={{
                          height: 13,
                          width: 13,
                          marginRight: 10,
                          marginBottom: 10,
                          display: ckHead?.isClose ? "flex" : "none",
                          cursor: "pointer",
                        }}
                        onClick={(event) =>
                          handleDeleteCardHeader(index, event)
                        }
                      />
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      margin="5px 10px"
                    >
                      <Tooltip
                        title={ckHead.dropdown ? ckHead.dropdown : "empty"}
                      >
                        <SelectPicker
                          onClean={() => {
                            let key;
                            selectedCardHeaderOptons.forEach((item) => {
                              key = Object.keys(item);
                              if (key[0] / 1 === index) {
                                delete item[key];
                              }
                            });
                            let newOptions = selectedCardHeaderOptons.filter(
                              (item) => {
                                if (Object.keys(item).length > 0) return true;
                              }
                            );
                            dispatch(selectedCardHeader(newOptions));

                            const vals = [...saveStateCardHead];
                            vals[index].dropdown = null;
                            vals[index].json = null;
                            dispatch(saveCardHeader(vals));
                          }}
                          value={ckHead.dropdown}
                          disabledItemValues={cardHeadDisabled}
                          onChange={(v) => {
                            const values = [...saveStateCardHead];
                            if (!values[index].dropdown) {
                              values[index].dropdown = v;
                              const json = cardHeaderOptions.find(
                                (item) => item.value === v
                              );
                              values[index].json = json.JsonKey;
                              //setCheckHead(values);
                              dispatch(saveCardHeader(values));
                              dispatch(
                                selectedCardHeader([
                                  ...selectedCardHeaderOptons,
                                  { [index]: v },
                                ])
                              );
                            }
                          }}
                          data={cardHeaderOptions}
                          style={{ width: 344 }}
                          renderMenu={(menu) => {
                            if (cardHeaderOptions.length === 0) {
                              return (
                                <p
                                  style={{
                                    padding: 4,
                                    color: "#999",
                                    textAlign: "center",
                                  }}
                                >
                                  <i
                                    className="fa fa-circle-o-notch fa-spin"
                                    style={{ fontSize: "16px" }}
                                  ></i>{" "}
                                  &nbsp; Loading...
                                </p>
                              );
                            }
                            return menu;
                          }}
                        />
                      </Tooltip>
                      <Box>
                        <input
                          type="checkbox"
                          id="mandatory"
                          checked={ckHead?.mandatory}
                          onChange={(e) => {
                            const values = [...saveStateCardHead];
                            values[index]["mandatory"] = e.target.checked;

                            dispatch(saveCardHeader(values));
                          }}
                        />
                        <label style={{ fontStyle: "italic" }}>Mandatory</label>
                      </Box>
                    </Box>

                    <Box
                      margin="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        w="-webkit-fill-available"
                        onClick={(e) => {
                          const values = [...saveStateCardHead];
                          values[index].formBasedExtractor = true;
                          values[index].blockExtractor = false;
                          //setCheckHead(values);
                          dispatch(saveCardHeader(values));
                        }}
                        cursor="pointer"
                        b="1px solid black"
                        br="none"
                        padding="10px"
                        background={
                          ckHead?.formBasedExtractor === undefined ||
                            ckHead?.formBasedExtractor
                            ? "white"
                            : "lightgray"
                        }
                        justifyContent="space-between"
                        alignItems="center"
                        display="flex"
                        textAlign="center"
                      >
                        <span>Key value extractor</span>
                      </Box>
                      <Box
                        w="-webkit-fill-available"
                        onClick={(e) => {
                          const values = [...saveStateCardHead];
                          values[index]["blockExtractor"] = true;
                          values[index]["formBasedExtractor"] = false;
                          //setCheckHead(values);
                          dispatch(saveCardHeader(values));
                        }}
                        cursor="pointer"
                        b={
                          ckHead?.blockExtractor
                            ? "1px solid #ACACAC"
                            : "1px solid black"
                        }
                        bl="none"
                        padding="10px"
                        background={
                          ckHead?.blockExtractor ? "white" : "lightgray"
                        }
                        justifyContent="space-between"
                        alignItems="center"
                        display="flex"
                        textAlign="center"
                      >
                        <span style={{ color: "black" }}>Block extractor</span>
                      </Box>
                    </Box>

                    <Box
                      background="#DEDEDE 0% 0% no-repeat padding-box;"
                      borderRadius="5px"
                      padding="10px 0px"
                      margin="10px"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        h="40px"
                        w="220px"
                      >
                        <Box display="flex" alignItems="center">
                          {ckHead?.blockExtractor ? (
                            <>
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                marginBottom="0px"
                                width={"95px"}
                              >
                                Value block
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/VLwsB3DnSARHScxd?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    The block that has to be extracted. For
                                    selecting multiple blocks, press Ctrl+click
                                    and select the multiple blocks. (Click for
                                    more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                marginBottom="0px"
                              >
                                Identifier
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Identifier is the label to the required
                                    value. For Eg: AMOUNT PAID is the identifier
                                    to extract AMOUNT PAID: $$1203.23**.(Click
                                    for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </>
                          )}
                        </Box>

                        <Box display="flex" alignItems="center">
                          {ckHead?.formBasedExtractor ? (
                            <>
                              <Tooltip title={ckHead?.identifier}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      cardHeaderActive({
                                        name: `cardHeaderIdentifierActive${index + 1
                                          }`,
                                      })
                                    );
                                  }}
                                  placeholder="click on a block in image"
                                  onChange={(e) => {
                                    const values = [...saveStateCardHead];

                                    if (values[index].identifier.length <= 0) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                      return;
                                    } else {
                                      values[index].identifier = e.target.value;
                                    }

                                    if (!e.target.value) {
                                      values[index].data = {};
                                    } else if (
                                      e.target.value &&
                                      !_.isEmpty(values[index].data)
                                    ) {
                                      values[index].data["Text"] =
                                        e.target.value;
                                    }

                                    // setCheckHead(values);
                                    dispatch(saveCardHeader(values));
                                    dispatch(
                                      cardHeaderText({
                                        name: `cardHeaderIdentifier${index + 1
                                          }`,

                                        text: e.target.value,
                                      })
                                    );
                                  }}
                                  // }
                                  value={ckHead?.identifier}
                                  type="text"
                                  style={{
                                    width: 221,
                                    marginLeft: ckHead?.blockExtractor
                                      ? 93
                                      : 134,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <Tooltip title={ckHead?.valueBlock}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      cardHeaderActive({
                                        name: `cardHeaderValueActive${index + 1
                                          }`,
                                      })
                                    );
                                  }}
                                  placeholder="click on a block in image"
                                  onChange={(e) => {
                                    const values = [...saveStateCardHead];
                                    values[index].valueType = "text";
                                    if (values[index].valueBlock.length <= 0) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                      return;
                                    } else {
                                      values[index].valueBlock = e.target.value;
                                    }

                                    if (!e.target.value) {
                                      values[index].data = {};
                                    } else if (
                                      e.target.value &&
                                      !_.isEmpty(values[index].data)
                                    ) {
                                      values[index].data["Text"] =
                                        e.target.value;
                                    }

                                    // setCheckHead(values);
                                    dispatch(saveCardHeader(values));
                                    dispatch(
                                      cardHeaderText({
                                        name: `cardHeaderValue${index + 1}`,
                                        text: e.target.value,
                                      })
                                    );
                                  }}
                                  // }
                                  value={ckHead?.valueBlock}
                                  type="text"
                                  style={{
                                    width: 221,
                                    marginLeft: ckHead?.blockExtractor
                                      ? 93
                                      : 134,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </>
                          )}
                        </Box>
                      </Box>

                      {ckHead?.formBasedExtractor && (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center" w="228px">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                                marginBottom="0px"
                              >
                                Value
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Anything between the identifier and end
                                    identifier is extracted as the value. Used
                                    for form type of value extraction. Click for
                                    more information
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </Box>
                            <Box marginRight="10px">
                              <Tooltip title={ckHead?.value}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      cardHeaderActive({
                                        name: `cardHeadervalueActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...saveStateCardHead];
                                    if (
                                      values[index]?.value?.length <= 0 ||
                                      values[index]?.data_value?.length <= 0
                                    ) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[index].value = e.target.value;
                                      if (!e.target.value) {
                                        values[index].data_value = {};
                                      } else if (
                                        e.target.value &&
                                        !_.isEmpty(values[index].data_value)
                                      ) {
                                        values[index].data_value["Text"] =
                                          e.target.value;
                                      }
                                      dispatch(saveCheckHeader(values));
                                      dispatch(
                                        cardHeaderText({
                                          name: `cardHeadervalue${index + 1}`,
                                          text: e.target.value,
                                        })
                                      );
                                    }
                                  }}
                                  value={ckHead?.value}
                                  type="text"
                                  placeholder="click on a block in image"
                                  style={{
                                    width: 240,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                        </>
                      )}

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        h="40px"
                      >
                        <Box display="flex" alignItems="center">
                          <Text
                            marginLeft="10px"
                            font={labelFont}
                            marginBottom="0px"
                          >
                            {ckHead?.blockExtractor
                              ? "Value type"
                              : "Extract type"}
                          </Text>
                          {ckHead?.blockExtractor ? (
                            <Tooltip
                              interactive
                              title={
                                <a
                                  href="https://sway.office.com/VLwsB3DnSARHScxd?ref=Link"
                                  target="_blank"
                                  style={{ color: "white" }}
                                >
                                  Select this based on the type of the block
                                  extracted.(Click for more information)
                                </a>
                              }
                              placement="right"
                            >
                              <img
                                src={Help}
                                style={{ height: 12, marginLeft: 6 }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              interactive
                              title={
                                <a
                                  href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                  target="_blank"
                                  style={{ color: "white" }}
                                >
                                  Select this based on how the value and the
                                  identifier is placed.(Click for more
                                  information)
                                </a>
                              }
                              placement="right"
                            >
                              <img
                                src={Help}
                                style={{ height: 12, marginLeft: 6 }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                        <Box marginLeft="107px">
                          <select
                            className={cardHeaderError[index] ? "borderBlink" : ""}
                            onChange={(e) => {
                              const values = [...saveStateCardHead];
                              ckHead?.formBasedExtractor
                                ? (values[index].extractType = e.target.value)
                                : (values[index].valueType = e.target.value);
                              if (e.target.value === "text") {
                                values[index].valueType = "text"
                                let cardHeaderErrorStatus = cardHeaderError
                                cardHeaderErrorStatus[index] = false
                                setCardHeaderError(cardHeaderErrorStatus)
                              }
                              if (e.target.value === "amount($)") {
                                let amountRegex = /^[$S8*]*\d+(,?\d+)*[. ]?\d+$/
                                let checkBlock1 = true;
                                if (
                                  values[index]?.valueBlock?.length >= 0
                                ) {
                                  checkBlock1 = amountRegex.test(
                                    saveStateCardHead[index]?.valueBlock
                                  );
                                }
                                if (!checkBlock1) {
                                  alert("Select valid value type")
                                  values[index].valueType = "text"
                                  let cardHeaderErrorStatus = cardHeaderError
                                  cardHeaderErrorStatus[index] = true
                                  setCardHeaderError(cardHeaderErrorStatus)
                                }
                                else {
                                  let cardHeaderErrorStatus = cardHeaderError
                                  cardHeaderErrorStatus[index] = false
                                  setCardHeaderError(cardHeaderErrorStatus)
                                }
                              }
                              Object.keys(valueTypeRegexData).find(
                                (key) => {
                                  if (key === e.target.value.toUpperCase()) {
                                    if (e.target.value === "date") {
                                      let checkDate = matchInArray(saveStateCardHead[index]?.valueBlock, valueTypeRegexData[key])
                                      if (!checkDate) {
                                        alert("Select valid value type")
                                        values[index].valueType = "text"
                                        let cardHeaderErrorStatus = cardHeaderError
                                        cardHeaderErrorStatus[index] = true
                                        setCardHeaderError(cardHeaderErrorStatus)
                                      }
                                      else {
                                        let cardHeaderErrorStatus = cardHeaderError
                                        cardHeaderErrorStatus[index] = false
                                        setCardHeaderError(cardHeaderErrorStatus)
                                      }
                                    }
                                  }
                                }
                              );
                              dispatch(saveCardHeader(values));
                            }}
                            value={
                              ckHead?.formBasedExtractor
                                ? ckHead?.extractType
                                : ckHead?.valueType
                            }
                            style={{
                              width: 150,
                              marginRight: 30,
                              fontWeight: "bold",
                              padding: 3,
                              outline: "0px",
                              border: cardHeaderError[index] ? '2px solid red' : '1px solid gray'
                            }}
                          >
                            {ckHead?.blockExtractor
                              ? blockExtractorOptions.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))
                              : formBasedOptions.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                          </select>
                        </Box>
                      </Box>
                    </Box>

                    {/* advanced */}
                    {!ckHead?.blockExtractor && (
                      <Box
                        background="#DEDEDE 0% 0% no-repeat padding-box;"
                        borderRadius="5px"
                        margin="10px"
                        h="auto"
                        padding="5px"
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          h="30px"
                          cursor="pointer"
                          onClick={(e) => {
                            const values = [...saveStateCardHead];
                            values[index].isAdvanced =
                              !values[index].isAdvanced;
                            // setCheckHead(values);
                            dispatch(saveCardHeader(values));
                          }}
                        >
                          <Text
                            marginLeft="10px"
                            fontWeight="bold"
                            font={headingFont}
                            marginBottom="0px"
                          >
                            ADVANCED
                          </Text>

                          <img
                            src={ckHead?.isAdvanced ? Up : Down}
                            style={{ marginRight: 20, cursor: "pointer" }}
                          />
                        </Box>
                        {ckHead?.isAdvanced && !ckHead?.blockExtractor && (
                          <Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  // width="215px"
                                  marginBottom="0px"
                                  textColor={labelColor}
                                >
                                  End Identifier
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      Anything between the identifier and end
                                      identifier is extracted as the value. Used
                                      only for form type of value
                                      extraction.(Click for more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <Tooltip title={ckHead?.endIdentifier}>
                                  <input
                                    onClick={() => {
                                      dispatch(
                                        cardHeaderActive({
                                          name: `cardHeaderEndIdentifierActive${index + 1
                                            }`,
                                        })
                                      );
                                    }}
                                    type="text"
                                    placeholder="click on a block in image"
                                    onChange={(e) => {
                                      const values = [...saveStateCardHead];
                                      if (
                                        values[index].endIdentifier.length <= 0
                                      ) {
                                        alert(
                                          "please select value from image to proceed"
                                        );
                                        e.preventDefault();
                                      } else {
                                        values[index].endIdentifier =
                                          e.target.value;
                                        if (!e.target.value) {
                                          values[index].data_endIdentifier = {};
                                        } else if (
                                          e.target.value &&
                                          !_.isEmpty(
                                            values[index].data_endIdentifier
                                          )
                                        ) {
                                          values[index].data_endIdentifier[
                                            "Text"
                                          ] = e.target.value;
                                        }

                                        //setCheckHead(values);
                                        dispatch(saveCardHeader(values));
                                        dispatch(
                                          cardHeaderText({
                                            name: `cardHeaderEndIdentifier${index + 1
                                              }`,
                                            text: e.target.value,
                                          })
                                        );
                                      }
                                    }}
                                    value={ckHead?.endIdentifier}
                                    style={{
                                      width: 240,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  ID limit
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      If there are multiple occurrences of the
                                      identifier, The nth (integer value
                                      specified) occurrence will be chosen as
                                      the identifier block.(Click for more
                                      information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <input
                                  onChange={(e) => {
                                    const values = [...saveStateCardHead];
                                    values[index].idlimit = e.target.value;
                                    //setCheckHead(values);
                                    dispatch(saveCardHeader(values));
                                  }}
                                  value={ckHead?.idlimit}
                                  type="number"
                                  min="1"
                                  style={{
                                    width: 60,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box w="215px" display="flex" alignItems="center">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  Index range
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      From the list of extracted blocks, blocks
                                      from index x (inclusive) till index y
                                      (exclusive) will be captured.(Click for
                                      more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box
                                marginRight="10px"
                                display="flex"
                                alignItems="center"
                              >
                                <input
                                  type="number"
                                  min="-10"
                                  onChange={(e) => {
                                    const values = [...saveStateCardHead];
                                    values[index].indexRange1 = e.target.value;
                                    // setCheckHead(values);
                                    dispatch(saveCardHeader(values));
                                  }}
                                  value={ckHead?.indexRange1}
                                  style={{
                                    width: 60,
                                    height: 24,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                    marginRight: 10,
                                  }}
                                />
                                <Text fontStyle="italic" marginBottom="0px">
                                  To{" "}
                                </Text>
                                <input
                                  type="number"
                                  onChange={(e) => {
                                    const values = [...saveStateCardHead];
                                    values[index].indexRange2 = e.target.value;
                                    //setCheckHead(values);
                                    dispatch(saveCardHeader(values));
                                  }}
                                  value={ckHead?.indexRange2}
                                  style={{
                                    width: 60,
                                    height: 24,
                                    marginLeft: 10,

                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  No. of blocks
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      style={{ color: "white" }}
                                      target="_blank"
                                    >
                                      It is used when the end identifier is not
                                      found. By default, it is 1.If there is a
                                      specific number of blocks that you need
                                      that has to be extracted after the
                                      identifier then the number of blocks field
                                      is changed.(Click for more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <input
                                  type="number"
                                  min="1"
                                  defaultValue="1"
                                  // onkeydown="return false"
                                  onChange={(e) => {
                                    const values = [...saveStateCardHead];
                                    if (e.target.value == "0") return;
                                    else
                                      values[index].noOfBlocks = e.target.value;

                                    //setCheckHead(values);
                                    dispatch(saveCardHeader(values));
                                  }}
                                  value={ckHead?.noOfBlocks}
                                  defaultValue="1"
                                  style={{
                                    width: 60,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(Check);
