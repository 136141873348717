import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SignIn from "./Components/SignIn/SignIn";
import LandingPage from "./Components/LandingPage/LandingPage";
import LoginRestriction from "./Components/LoginRestriction";
import UpdatePassword from "./Components/UpdatePassword/UpdatePassword";
import Layout from "./Layout";

const Routes = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact component={LandingPage} path="/" />
          <Route exact component={SignIn} path="/signIn" />
          <Route exact component={UpdatePassword} path="/updatepassword" />

          <Route exact path="/restrict" component={LoginRestriction} />

          <Route path="/" render={(props) => <Layout {...props} />} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
