/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "../Box";
import Text from "../Text";
import Up from "../../assets/icons/arr-up.svg";
import Down from "../../assets/icons/arr-down.svg";
import Doc from "../../assets/icons/file.png";
import Navbar from "../Navbar/Navbar";
import { useDispatch } from "react-redux";
import { saveCreds } from "../../store/Form/actions";
import Swal from "sweetalert2";
import axios from "axios";
import env from "../../environment";
import keysToRemove from "../LocalStorageItems";
import { deleteData } from "../../store/Save/actions";
import { deleteFormData } from "../../store/Form/actions";
import { initialState } from "../../store/Save/reducer";
import { refreshToken, validateApi } from "../../constants";

const CreateTemplate = (props) => {
  const [existingTemplateId, setExistingTemplateId] = useState([]);
  const [existingMainTempId, setExistingMainTemlateId] = useState([]);
  const [existingReadyTempId, setExistingReadyTemlateId] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const [templateId, setTemplateId] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  // const [List, setList] = React.useState(list);
  const [item, setItem] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState("");
  const [messageFlag, setMessageFlag] = React.useState(false);
  const handleChangeName = (e) => setName(e.target.value);
  const handleChangeTemplateId = (e) => {
    setTemplateId(e.target.value);
    if (existingTemplateId?.includes(e.target.value)) {
      setMessageFlag(true);
    }
    if (!existingTemplateId?.includes(e.target.value)) {
      setMessageFlag(false);
    }
  };
  const handleChangeOpen = () => setOpen(!open);
  const handleSelect = (name) => {
    setItem(name);
  };
  const handleChangeItem = (e) => {
    const val = e.target.value;
    setItem(val);
    history.push({ pathname: "/" });
  };
  const handleTemplateId=()=>{
    const header = {
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem("accessToken")
      )}`,
    };
    axios
    .get(`${env.BASE_URL}templateconfigurations`, {
      headers: header,
    })
    .then((res) => {
      let templateIdArray = res.data.data.map(
        (item) => item.payer_template_id
      );
      setExistingMainTemlateId(templateIdArray);
    })
    .catch((err) => {
      console.log("error", err);
      if (err?.response?.status === 401) {
        handleRefreshToken();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Session Expired!",
          type: "error",
          confirmButtonColor: "#54545E",
        }).then(() => {
          localStorage.clear();
          props.history.push("/signin");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          type: "error",
          confirmButtonColor: "#54545E",
        }).then(() => {
          window.location.href = "/home";
        });
      }
    });

  axios
    .get(`${env.BASE_URL}templateconfigurations?type=new`, {
      headers: header,
    })
    .then((res) => {
      let templateIdArray2 = res.data.data.map(
        (item) => item.payer_template_id
      );
      setExistingReadyTemlateId(templateIdArray2);
    })
    .catch((err) => {
      console.log("error", err);
      if (err?.response?.status === 401) {
        handleRefreshToken();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Session Expired!",
          type: "error",
          confirmButtonColor: "#54545E",
        }).then(() => {
          localStorage.clear();
          props.history.push("/signin");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          type: "error",
          confirmButtonColor: "#54545E",
        }).then(() => {
          window.location.href = "/home";
        });
      }
    });
  }
  useEffect(() => {
    if (existingMainTempId && existingReadyTempId)
      setExistingTemplateId([...existingMainTempId, ...existingReadyTempId]);
  }, [existingMainTempId, existingReadyTempId]);

  useEffect(() => {
    validateApi().then((valid) => {
      if (!valid) {
        refreshToken().then(() => {
          handleTemplateId();
        });
      } else {
        handleTemplateId();
      }
    });  
  }, []);

  const createSubmit = () => {
    keysToRemove.forEach((k) => localStorage.removeItem(k));

    if (name && templateId) {
      let validate = validateFields(templateId);
      dispatch(deleteData());
      dispatch(deleteFormData());
      dispatch(
        saveCreds({
          name,
          templateId,
        })
      );
      if (validate === false) {
        props.history.push("/create");
      } else if (messageFlag == true) {
        props.history.push("/create");
      } else
        props.history.push({
          pathname: "/upload",
          state: {
            templateId: templateId,
            payerName: name,
          },
        });
    }
    if (name === "" || templateId === "") {
      if (name === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter payer name",
          type: "error",
          confirmButtonColor: "#54545E",
        });
      }
      if (templateId === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter template ID",
          type: "error",
          confirmButtonColor: "#54545E",
        });
      }
    }
  };
  const validateFields = (templateId) => {
    let regex = /^[A-Za-z]{1,}[_][0-9]+$/;
    let validTemplateId = regex.test(templateId);
    let valid = false;
    if (validTemplateId === false) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Template ID should begin with alphabat and accepts only alphanumeric values and  _ as a special character. For Example: (abc_001)",
        type: "error",
        confirmButtonColor: "#54545E",
      });

      valid = false;
      return valid;
    } else {
      valid = true;
      return valid;
    }
  };
  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      createSubmit();
    }
  };
  return (
    <>
      <Navbar component="create" />
      <Box h="auto" w="600px" b="1px solid #707070" margin="30px auto">
        <Box b="1px dashed #707070" h="auto" w="80%" margin="40px auto">
          <Box margin="20px 30px">
            <Text
              fontWeight="bold"
              textColor="rgb(51, 83, 109)"
              fontSize="20px"
              marginBottom="0px"
              marginTop="0"
            >
              Create template
            </Text>
            <Box display="flex" flexDirection="column">
              <input
                type="text"
                placeholder="template ID"
                style={{
                  padding: 15,
                  backgroundColor: "#EFEFEF",
                  border: "none",
                  margin: "20px 0",
                }}
                value={templateId}
                onChange={handleChangeTemplateId}
                onKeyPress={handleKeypress}
              />
              {messageFlag ? (
                <p style={{ margin: "-13px 5px 12px 17px", color: "red" }}>
                  {templateId} Already Exists!! Try again using different
                  template ID
                </p>
              ) : null}
              <input
                type="text"
                placeholder="Payer name"
                style={{
                  padding: 15,
                  backgroundColor: "#EFEFEF",
                  border: "none",
                }}
                value={name}
                onChange={handleChangeName}
                onKeyPress={handleKeypress}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Box
                cursor="pointer"
                display="flex"
                margin="20px 0"
                justifyContent="center"
                alignItems="center"
                background=" rgb(51, 83, 109) 0% 0% no-repeat padding-box;"
                border="1px solid #707070;"
                h="40px"
                w="144px"
                marginRight="10px"
                onClick={() => createSubmit()}
              >
                <Text textColor="white">Create</Text>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* <Box margin="0 60px">
          <Text fontWeight="bold" fontSize="20px">
            or select template to edit
          </Text>
        </Box> */}
        {/* <Box b="1px dashed #707070" h="100px" w="80%" margin="40px auto">
          <Box>
            <Box
              display="flex"
              marginLeft="60px"
              marginTop="30px"
              onClick={handleChangeOpen}
              cursor="pointer"
            >
              <input
                type="text"
                disabled={!open}
                style={{
                  padding: 8,
                  backgroundColor: "#EFEFEF",
                  border: "none",
                  width: 300,
                  height: 25,
                }}
                value={item}
                onChange={handleChangeItem}
              />
              <Box
                background="#EFEFEF"
                cursor="pointer"
                display="flex"
                w="30px"
              >
                <img src={open ? Up : Down} style={{ padding: 5, width: 10 }} />
              </Box>
            </Box>
            {open && (
              <Box
                h="200px"
                w="345px"
                background="#EFEFEF"
                marginLeft="60px"
                overoverflow="scroll"
                overflowX="hidden"
              >
                <Box>
                  {List.map(({ id, name, image }) => (
                    <Box
                      key={id}
                      display="flex"
                      alignItems="center"
                      padding="5px"
                      margin="0px 20px"
                      justifyContent="flex-start"
                      onHover="#C3C3C3"
                      cursor="pointer"
                      onClick={() => {
                        handleSelect(name);
                        setOpen(false);
                      }}
                    >
                      <img src={image} style={{ height: 25 }} />
                      <Text marginLeft="10px" fontSize="15px">
                        {name}
                      </Text>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
       */}
      </Box>
    </>
  );
};

export default CreateTemplate;
