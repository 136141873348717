export const camelCaseToWords = function (str) {
  return str
    .match(/^[a-z]+|[A-Z][a-z]*/g)
    .map(function (x) {
      return x[0].toUpperCase() + x.substr(1).toLowerCase();
    })
    .join(" ");
};

export const labelColor = "#262525";
export const labelFont = "normal bold 16px/21px Arial";
export const headingFont = "italic normal bold 16px/21px Arial";

export const updateState = (state, payload) =>
  Object.assign({}, state, payload);
