/* eslint-disable */
import React, { useEffect, useRef } from "react";
import _ from "lodash";
import Box from "../Box";
import Text from "../Text";
import Down from "../../assets/icons/down.svg";
import Up from "../../assets/icons/up.svg";
import Add from "../../assets/icons/add.svg";
import Minus from "../../assets/icons/minus-red.png";
import Cross from "../../assets/icons/cross.svg";
import { Tooltip } from "@material-ui/core";
import Help from "../../assets/icons/help.png";
import { useDispatch, useSelector } from "react-redux";
import "./Claim.css";
import { valueTypeRegexData } from "../../constants";
import {
  claimAddressActive,
  claimExtractionActive,
  claimExtractionText,
  claimAddressText,
  claimHeaderActive,
  claimHeaderIdenActive,
  claimHeaderIdenText,
  claimHeaderText,
  claimFieldsActive,
  claimFieldsText,
  deleteClaimAddData,
  disableClaimAddActive,
  deleteClaimHeadData,
  disableClaimHeadActive,
  deleteClaimFieldsData,
  disableClaimFieldsActive,
  disableClaimExtractionActive,
} from "../../store/Form/actions";
import {
  claimAddressOpen,
  claimExtractionOpen,
  claimHeaderIdentificationOpen,
  claimFieldsOpen,
  claimHeaderOpen,
  saveClaimAddress,
  saveClaimExtraction,
  saveClaimFields,
  saveClaimHeader,
  selectedClaimAddress,
  selectedClaimFeilds,
  selectedClaimHeader,
  saveClaimHeaderIden,
} from "../../store/Save/actions";
import { SelectPicker } from "rsuite";
import { withRouter } from "react-router-dom";
import {
  camelCaseToWords,
  headingFont,
  labelColor,
  labelFont,
} from "../../utils";

const Claim = (props) => {
  const dispatch = useDispatch();

  // selectors
  const state = useSelector(({ form }) => form);
  const claim1 = useSelector(({ form }) => form.ClaimHeaderIden1);
  const claim2 = useSelector(({ form }) => form.ClaimHeaderIden2);
  const claim3 = useSelector(({ form }) => form.ClaimHeaderIden3);
  const claim4 = useSelector(({ form }) => form.ClaimHeaderIden4);
  const claim5 = useSelector(({ form }) => form.ClaimHeaderIden5);
  const claimActive1 = useSelector(({ form }) => form.ClaimHeaderIdenActive1);
  const claimActive2 = useSelector(({ form }) => form.ClaimHeaderIdenActive2);
  const claimActive3 = useSelector(({ form }) => form.ClaimHeaderIdenActive3);
  const claimActive4 = useSelector(({ form }) => form.ClaimHeaderIdenActive4);
  const claimActive5 = useSelector(({ form }) => form.ClaimHeaderIdenActive5);

  // claimHeaderIdentification
  const stateClaim = useSelector(({ save }) => save.claims?.clheadIden);
  const claimExtraction = useSelector(({ save }) => save.claims?.clex);
  const claimHeader = useSelector(({ save }) => save.claims?.clhead);
  const claimAddress = useSelector(({ save }) => save.claims?.cladd);
  const claimFields = useSelector(({ save }) => save.claims?.clfield);
  const showClaimExtraction = useSelector(({ save }) => save.claims?.clexopen);
  const showClaimHeaderIdentification = useSelector(
    ({ save }) => save.claims?.clheaderIdenopen
  );

  const showClaimFields = useSelector(({ save }) => save.claims?.clfieldsopen);
  const showClaimHeader = useSelector(({ save }) => save.claims?.clheadopen);
  const showClaimAddress = useSelector(({ save }) => save.claims?.claddopen);
  const selectedClaimAddressOptions = useSelector(
    ({ save }) => save.claims?.selectedClaimAdd
  );
  const selectedClaimHeaderOptions = useSelector(
    ({ save }) => save.claims?.selectedClaimHead
  );

  const selectedClaimFieldsOptions = useSelector(
    ({ save }) => save.claims?.selectedClaimFeilds
  );
  const topIdentifier = useSelector(({ form }) => form?.claimExtraction1);

  const [claimHeaderIden, setClaimHeaderIden] = React.useState([
    {
      block: "",
      isAdd: true,
      data: {},
      no: 1,
    },
  ]);

  // gettting dropdowns
  const claimAddressDropdown = useSelector(({ form }) => form?.claimAddress);
  const claimHeadersDropdown = useSelector(({ form }) => form?.claimHeaders);
  const claimFieldsDropdown = useSelector(({ form }) => form?.claimFields);

  const bottomIdentifier = useSelector(({ form }) => form?.claimExtraction2);
  const headerIdentifier = useSelector(({ form }) => form?.claimExtraction3);
  const footerIdentifier = useSelector(({ form }) => form?.claimExtraction4);
  const pageBreakContinuationIdentifier = useSelector(
    ({ form }) => form?.claimExtraction5
  );
  const pageBreakdownIdentifier = useSelector(
    ({ form }) => form?.claimExtraction6
  );

  const claimAddressBlock1 = useSelector(({ form }) => form?.claimAddress1);
  const claimAddressBlock2 = useSelector(({ form }) => form?.claimAddress2);
  const claimAddressBlock3 = useSelector(({ form }) => form?.claimAddress3);

  // dynamic state
  const [claimHeaderError, setClaimHeaderError] = React.useState([false]);
  const [claimFieldsError, setClaimFieldsError] = React.useState([false]);

  const [claimHeaderOptions, setClaimHeaderOptions] = React.useState([]);
  const [claimFieldsOptions, setClaimFieldsOptions] = React.useState([]);

  const [claimHeaderDisabled, setClaimHeaderDisabled] = React.useState([]);
  const [claimFieldsDisabled, setClaimFieldsDisabled] = React.useState([]);
  const [showAdvancedClaimExtraction, setShowAdvacedClaimExtraction] =
    React.useState(false);
  const [claimAddressOptions, setClaimAddressressOptions] = React.useState([]);
  const [claimAddressDisabled, setClaimAddressDisabled] = React.useState([]);

  const [blockExtractor, setBlockExtractor] = React.useState(false);
  const [selectedClaims, setSelectedClaims] = React.useState([]);
  const [dateOptions] = React.useState([
    "Select",
    "MM/DD/YYYY (09/01/2021)",
    "MM-DD-YYYY (09-01-2021)",
    "DD/MM/YYYY (25/01/2020)",
    "YYYY/MM/DD (2021/01/13)",
    "MM/DD/YY (03/23/21)",
    "MMM/DD/YYYY (Apr/15/2021)",
    "MMM/DD/YY (Apr/15/21)",
    "DD/MMM/YYYY (15/Apr/2021)",
    "DD/MMM/YY (15/Apr/21)",
    "MM/DD/YYYY-MM/DD/YYYY (03/01/2021-03/01/2021)",
    "MM-DD-YYYY-MM-DD-YYYY (03-01-2021-03-01-2021)",
    "MM/DD/YY-MM/DD/YY (03/01/21-03/01/21)",
    "MM/DD/YYYY MM/DD/YYYY (03/01/2021 03/01/2021)",
    "MM/DD-MM/DD/YYYY (03/01-03/01/2021)",
    "MM/DD-MM/DD/YY (03/01-03/01/21)",
    "MMDDYYYY (03012021)",
    "MMDDYY (030121)",
    "MMDDYYYY-MMDDYYYY (03012021-03012021)",
    "MMDD-MMDDYYYY (0301-03012021)",
    "MMDDYY-MMDDYY (030121-030121)",
    "MMDD-MMDDYY (0301-030121)",
  ]);
  const [claimOptions, setClaimOptions] = React.useState([
    camelCaseToWords("claimId"),
    camelCaseToWords("patientId"),
    camelCaseToWords("paymentMethod"),
    camelCaseToWords("patientAccountNumber"),
    camelCaseToWords("totalClaimCharge"),
    camelCaseToWords("totalClaimPayment"),
    camelCaseToWords("payerControlNumber"),
    camelCaseToWords("interestAmount"),
    camelCaseToWords("billType"),
    camelCaseToWords("patientName"),
    camelCaseToWords("subscriberName"),
    "Subscriber Id",
    camelCaseToWords("referenceNumber"),
    camelCaseToWords("policyID"),
    //camelCaseToWords("payeeNameInEOB"),
    "Payee Name In EOB",
    camelCaseToWords("payeeAddressInEOB"),
    camelCaseToWords("renderringProviderName"),
    camelCaseToWords("placeOfService"),
    camelCaseToWords("payerAddressInEOB"),
    camelCaseToWords("referenceNumberInEOB"),
    camelCaseToWords("amountPaidInEOB"),
    camelCaseToWords("paymentDateInEOB"),
    camelCaseToWords("renderringProviderNpi"),
  ]);

  const [deletedIndex, setDeletedIndex] = React.useState([]);

  const addClaimHeaderIdenRow1 = (e) => {
    e.stopPropagation();
    if (claimHeaderIden.length === 5) return;

    const newBlock = {
      block: "",
      isAdd: false,
      isClose: true,
      data: {},
      no: claimHeaderIden.length + 1,
    };

    const vals = claimHeaderIden;
    if (deletedIndex.length) {
      vals.splice(deletedIndex[0] - 1, 0, newBlock);
      setClaimHeaderIden([...vals]);
      const newDelArray = deletedIndex;
      newDelArray.shift();
      setDeletedIndex(newDelArray);
    } else setClaimHeaderIden([...vals, newBlock]);
  };

  const handleResetClaimIden = (e) => {
    e.stopPropagation();
    const newClaimHeaderIden = [
      {
        block: "",
        isAdd: true,
        data: {},
      },
    ];
    setClaimHeaderIden(newClaimHeaderIden);
    dispatch(saveClaimHeaderIden(newClaimHeaderIden));
    for (let i = 1; i <= 5; i++) {
      dispatch(
        claimHeaderIdenText({
          name: `ClaimHeaderIden${i}`,
          text: "",
        })
      );
      dispatch(
        claimHeaderIdenText({
          name: `ClaimHeaderIdendata${i}`,
          text: {},
        })
      );
      dispatch(
        claimHeaderIdenActive({
          name: `ClaimHeaderIdenActive${i}`,
          text: false,
        })
      );
    }
    setDeletedIndex([]);
  };

  useEffect(() => {
    if (claimHeaderIden.length && claimHeaderIden[0].block)
      dispatch(saveClaimHeaderIden(claimHeaderIden));
  }, [claimHeaderIden]);

  function matchInArray(str, expr) {
    let flag = false;
    let flag2 = false;
    expr.forEach((x) => {
      flag = x.test(str);
      if (flag) {
        flag2 = flag;
      }
    });
    return flag2;
  }

  const handleDeleteClaimHeaderIden = (temp) => {
    if (temp.no === 1) return;

    const newTemp = claimHeaderIden.filter(({ no }) => no !== temp.no);

    setClaimHeaderIden(newTemp);
    // dispatch(saveClaimHeaderIden(newTemp));
    setDeletedIndex([...deletedIndex, temp.no]);

    dispatch(
      claimHeaderIdenText({
        name: `ClaimHeaderIden${temp.no}`,
        text: "",
      })
    );
    dispatch(
      claimHeaderIdenText({
        name: `ClaimHeaderIdendata${temp.no}`,
        text: {},
      })
    );
    dispatch(
      claimHeaderIdenActive({
        name: `ClaimHeaderIdenActive${temp.no}`,
        text: false,
      })
    );
  };

  useEffect(() => {
    if (claimActive1 && claim1) {
      const values = [...claimHeaderIden];
      values[0].block = claim1;
      if (!_.isEmpty(state["ClaimHeaderIdendata1"])) {
        values[0].data = state["ClaimHeaderIdendata1"];
        values[0].data["Text"] = claim1;
        return setClaimHeaderIden(values);
      }
    }
    if (claimActive2 && claim2) {
      const values = [...claimHeaderIden];
      if (values.length >= 2) {
        values[1].block = claim2;
        if (!_.isEmpty(state["ClaimHeaderIdendata2"])) {
          values[1].data = state["ClaimHeaderIdendata2"];
          values[1].data["Text"] = claim2;
          return setClaimHeaderIden(values);
        }
      }
    }
    if (claimActive3 && claim3) {
      const values = [...claimHeaderIden];
      if (values.length >= 3) {
        values[2].block = claim3;
        if (!_.isEmpty(state["ClaimHeaderIdendata3"])) {
          values[2].data = state["ClaimHeaderIdendata3"];
          values[2].data["Text"] = claim3;
          return setClaimHeaderIden(values);
        }
      }
    }
    if (claimActive4 && claim4) {
      const values = [...claimHeaderIden];
      if (values.length >= 4) {
        values[3].block = claim4;
        if (!_.isEmpty(state["ClaimHeaderIdendata4"])) {
          values[3].data = state["ClaimHeaderIdendata4"];
          values[3].data["Text"] = claim4;
          return setClaimHeaderIden(values);
        }
      }
    }
    if (claimActive5 && claim5) {
      const values = [...claimHeaderIden];
      if (values.length >= 5) {
        values[4].block = claim5;
        if (!_.isEmpty(state["ClaimHeaderIdendata5"])) {
          values[4].data = state["ClaimHeaderIdendata5"];
          values[4].data["Text"] = claim5;
          return setClaimHeaderIden(values);
        }
      }
    }
  }, [
    claim1,
    claim2,
    claim2,
    claim3,
    claim4,
    claim5,
    claimActive1,
    claimActive2,
    claimActive3,
    claimActive4,
    claimActive5,
  ]);

  const handleShowAdvancedClaimExtraction = () =>
    setShowAdvacedClaimExtraction(!showAdvancedClaimExtraction);

  const handleResetClaimExtraction = () => {
    const newClaimExtraction = {
      top: "",
      data_top: {},
      data_footer: {},
      data_bottom: {},
      data_heder: {},
      bottom: "",
      header: "",
      footer: "",
      isAdvanced: false,
      bottomLine: 0,
      captureBlockTillNextStart: false,
      pageBreakContinuationIdentifier: "",
      pageBreakdownIdentifier: "",
      isHeaderPresent: false,
      bottomLinesToSkip: 0,
      extractType: true,
    };
    for (let i = 1; i <= 6; i++) {
      dispatch(
        claimExtractionText({
          name: `claimExtraction${i}`,
          text: "",
        })
      );
      dispatch(
        claimExtractionText({
          name: `claimExtractiondata${i}`,
          text: {},
        })
      );
      dispatch(
        disableClaimExtractionActive({
          name: `claimExtractionActive${i}`,
        })
      );
    }

    dispatch(saveClaimExtraction([newClaimExtraction]));
  };

  const addClaimAddress = (e) => {
    if (e) e.stopPropagation();
    if (claimAddress.length === 3) return;
    const nca = {
      dropdown: "",
      isActive: true,
      isReset: true,
      mandatory: false,
      blocks: "",
      minLenOfAddressLine: 1,
      isAdd: true,
      isClose: true,
    };

    dispatch(saveClaimAddress([...claimAddress, nca]));
  };
  // const addClaimHeaderIdenRow1 = (e) => {
  //   e.stopPropagation();
  //   if (claimHeaderIden.length === 5) return;

  //   const newBlock = {
  //     block: "",
  //     isClose: true,
  //     data: {},
  //   };

  //   setClaimHeaderIden([...claimHeaderIden, newBlock]);
  // };

  // const handleDeleteClaimHeaderIden1 = (index) => {
  //   if (index === 0) return;
  //   const values = [...claimHeaderIden];
  //   values.splice(index, 1);
  //   setClaimHeaderIden(values);
  //   dispatch(
  //     claimHeaderIdenText({
  //       name: `ClaimHeaderIden${index + 1}`,
  //       text: "",
  //     })
  //   );

  //   dispatch(
  //     claimHeaderIdenActive({
  //       name: `ClaimHeaderIdenActive${index + 1}`,
  //     })
  //   );
  // };
  const [claimFields1, setClaimFields] = React.useState([
    {
      mandatory: false,
      nameType: "first_last_middle",
      json: "",
      dropdown: "",
      identifier: "",
      extractType: "form",
      isAdvanced: false,
      endIdentifier: "",
      idlimit: "1",
      indexRange1: "0",
      indexRange2: "",
      noOfBlocks: "1",
      isNameType: false,
      data_end_identifier: {},
    },
  ]);

  const handleShowClaimHeader = () =>
    dispatch(claimHeaderOpen(!showClaimHeader));
  const handleShowClaimAddress = () =>
    dispatch(claimAddressOpen(!showClaimAddress));
  const handleShowClaimFields = () =>
    dispatch(claimFieldsOpen(!showClaimFields));
  const handleShowClaimExtraction = () =>
    dispatch(claimExtractionOpen(!showClaimExtraction));
  const handleHeaderIdentification = () =>
    dispatch(claimHeaderIdentificationOpen(!showClaimHeaderIdentification));
  React.useEffect(() => {
    if (claimAddressDropdown && claimAddressDropdown.length) {
      const ckAddOptions = claimAddressDropdown.map(
        ({ DisplayName, JsonKey }, key) => {
          let a = {
            key,
            value: DisplayName,
            label: DisplayName,
            JsonKey,
          };
          return a;
        }
      );
      setClaimAddressressOptions(ckAddOptions);
    }

    if (claimHeadersDropdown && claimHeadersDropdown.length) {
      const options = claimHeadersDropdown.map(
        ({ DisplayName, JsonKey }, key) => {
          let a = {
            key,
            value: DisplayName,
            label: DisplayName,
            JsonKey,
          };
          return a;
        }
      );
      setClaimHeaderOptions(options);
    }
    if (claimFieldsDropdown && claimFieldsDropdown.length) {
      const options = claimFieldsDropdown.map(
        ({ DisplayName, JsonKey }, key) => {
          let a = {
            key,
            value: DisplayName,
            label: DisplayName,
            JsonKey,
          };
          return a;
        }
      );
      setClaimFieldsOptions(options);
    }
  }, [claimAddressDropdown, claimHeadersDropdown, claimFieldsDropdown]);

  React.useEffect(() => {
    Object.entries(state).map(([key]) => {
      if (key.includes("Header") && !key.includes("Active")) {
        const values = [...claimHeader];
        let lastNumber = parseInt(key.slice(-1), 10);
        // for more than 2 digits

        const secondLast = parseInt(key.slice(-2), 10);
        let keyname;
        if (secondLast) {
          keyname = key.substring(0, key.length - 2);
          lastNumber = secondLast;
        } else keyname = key.substring(0, key.length - 1);

        if (
          keyname === "claimHeaderEndIdentifier" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          values[lastNumber - 1].endIdentifier = state[key];
          if (!_.isEmpty(state[`claimHeaderEndIdentifierdata${lastNumber}`])) {
            values[lastNumber - 1].data_end_identifier =
              state[`claimHeaderEndIdentifierdata${lastNumber}`];
            values[lastNumber - 1].data_end_identifier["Text"] = state[key];
          }
          dispatch(saveClaimHeader(values));
          // setClaimHeader(values);
        }

        if (
          keyname === "claimHeaderIdentifier" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          values[lastNumber - 1].identifier = state[key];
          if (!_.isEmpty(state[`claimHeaderIdentifierdata${lastNumber}`])) {
            values[lastNumber - 1].data =
              state[`claimHeaderIdentifierdata${lastNumber}`];
            values[lastNumber - 1].data["Text"] = state[key];
          }
          dispatch(saveClaimHeader(values));
        }
        if (
          keyname === "claimHeaderValue" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          if (state[key] !== values[lastNumber - 1].values) {
            values[lastNumber - 1].valueType = "text";
            let claimHeaderErrorStatus = claimHeaderError;
            claimHeaderErrorStatus[lastNumber - 1] = false;
            setClaimHeaderError(claimHeaderErrorStatus);
            // claimHeaderErrorStatus[lastNumber-1]=true
          }

          // setClaimHeaderError(claimHeaderErrorStatus)
          values[lastNumber - 1].values = state[key];

          if (!_.isEmpty(state[`claimHeaderValuedata${lastNumber}`])) {
            values[lastNumber - 1].data =
              state[`claimHeaderValuedata${lastNumber}`];
            values[lastNumber - 1].data["Text"] = state[key];
          }
          // setClaimHeader(values);
          dispatch(saveClaimHeader(values));
        }
        if (
          keyname === "claimHeadervalue" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          values[lastNumber - 1].value = state[key];
          if (!_.isEmpty(state[`claimHeadervaluedata${lastNumber}`])) {
            values[lastNumber - 1].data_value =
              state[`claimHeadervaluedata${lastNumber}`];
            values[lastNumber - 1].data_value["Text"] = state[key];
          }

          dispatch(saveClaimHeader(values));
        }
      }
    });
  }, [state]);

  React.useEffect(() => {
    if (stateClaim) {
      if (stateClaim.length === 0) {
        setClaimHeaderIden(claimHeaderIden);
      } else {
        const modifiedStateClaim = stateClaim.map((item, i) => ({
          ...item,
          no: i + 1,
        }));
        setClaimHeaderIden(modifiedStateClaim);
      }

      dispatch(claimHeaderIdentificationOpen(showClaimHeaderIdentification));

      if (stateClaim.length && stateClaim[0].block) {
        dispatch(claimHeaderIdentificationOpen(true));
      }
    }
    return () => {
      if (claimHeaderIden.length && claimHeaderIden[0].block) {
        dispatch(saveClaimHeaderIden(claimHeaderIden));
      }
    };
  }, []);

  useEffect(() => {
    if (claimHeaderIden.length && claimHeaderIden[0].block)
      dispatch(saveClaimHeaderIden(claimHeaderIden));
  }, [claimHeaderIden]);

  React.useEffect(() => {
    Object.entries(state).map(([key, value], index) => {
      if (key.includes("claimFields") && !key.includes("Active")) {
        const values = [...claimFields];
        let lastNumber = parseInt(key.slice(-1), 10);

        // for more than 2 digits

        const secondLast = parseInt(key.slice(-2), 10);
        let keyname;
        if (secondLast) {
          keyname = key.substring(0, key.length - 2);
          lastNumber = secondLast;
        } else keyname = key.substring(0, key.length - 1);

        if (
          keyname === "claimFieldsEndIdentifier" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          values[lastNumber - 1].endIdentifier = state[key];
          if (!_.isEmpty(state[`claimFieldsEndIdentifierdata${lastNumber}`])) {
            values[lastNumber - 1].data_end_identifier =
              state[`claimFieldsEndIdentifierdata${lastNumber}`];
            values[lastNumber - 1].data_end_identifier["Text"] = state[key];
          }
          dispatch(saveClaimFields(values));
        }

        if (
          keyname === "claimFieldsIdentifier" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          if (values.length >= lastNumber) {
            values[lastNumber - 1].identifier = state[key];
            if (!_.isEmpty(state[`claimFieldsIdentifierdata${lastNumber}`])) {
              values[lastNumber - 1].data_identifier =
                state[`claimFieldsIdentifierdata${lastNumber}`];
              values[lastNumber - 1].data_identifier["Text"] = state[key];
            }
          }
          dispatch(saveClaimFields(values));
        }
        if (
          keyname === "claimFieldsValuesIdentifier" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          values[lastNumber - 1].values = state[key];
          if (
            !_.isEmpty(state[`claimFieldsValuesIdentifierdata${lastNumber}`])
          ) {
            values[lastNumber - 1].data =
              state[`claimFieldsValuesIdentifierdata${lastNumber}`];
            values[lastNumber - 1].data["Text"] = state[key];
          }
          dispatch(saveClaimFields(values));
        }
        if (
          keyname === "claimFieldsBlock" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          if (state[key] !== values[lastNumber - 1].block) {
            values[lastNumber - 1].valueType = "text";
            let claimFieldErrorStatus = claimFieldsError;
            claimFieldErrorStatus[lastNumber - 1] = false;
            setClaimFieldsError(claimFieldErrorStatus);
          }
          values[lastNumber - 1].block = state[key];

          if (!_.isEmpty(state[`claimFieldsBlockdata${lastNumber}`])) {
            values[lastNumber - 1].data =
              state[`claimFieldsBlockdata${lastNumber}`];
            values[lastNumber - 1].data["Text"] = state[key];
          }

          dispatch(saveClaimFields(values));
        }
        if (
          keyname === "claimFieldsvalue" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          values[lastNumber - 1].value = state[key];
          if (!_.isEmpty(state[`claimFieldsvaluedata${lastNumber}`])) {
            values[lastNumber - 1].data_value =
              state[`claimFieldsvaluedata${lastNumber}`];
            values[lastNumber - 1].data_value["Text"] = state[key];
          }

          dispatch(saveClaimFields(values));
        }
      }
      if (key.includes("claimAddress") && !key.includes("Active")) {
        const values = [...claimAddress];
        let lastNumber = parseInt(key.slice(-1), 10);

        const keyname = key.substring(0, key.length - 1);
        if (
          keyname === "claimAddress" &&
          state[`${keyname}Active${lastNumber}`]
        ) {
          if (values.length >= lastNumber) {
            values[lastNumber - 1].blocks = state[key];
            if (!_.isEmpty(state[`claimAddressdata${lastNumber}`])) {
              values[lastNumber - 1].data =
                state[`claimAddressdata${lastNumber}`];
              values[lastNumber - 1].data["Text"] = state[key];
            }
          }
          dispatch(saveClaimAddress(values));
        }
      }
    });
  }, [state]);

  React.useEffect(() => {
    if (topIdentifier) {
      const values = [...claimExtraction];
      values[0].top = topIdentifier;
      if (!_.isEmpty(state[`claimExtractiondata1`])) {
        values[0].data_top = state[`claimExtractiondata1`];
        values[0].data_top["Text"] = topIdentifier;
      }
      dispatch(saveClaimExtraction(values));

      //setClaimExtracition(values);
    }
    if (bottomIdentifier) {
      const values = [...claimExtraction];
      values[0].bottom = bottomIdentifier;
      if (!_.isEmpty(state[`claimExtractiondata2`])) {
        values[0].data_bottom = state[`claimExtractiondata2`];
        values[0].data_bottom["Text"] = bottomIdentifier;
      }
      dispatch(saveClaimExtraction(values));

      //setClaimExtracition(values);
    }
    if (headerIdentifier) {
      const values = [...claimExtraction];
      values[0].header = headerIdentifier;
      if (!_.isEmpty(state[`claimExtractiondata3`])) {
        values[0].data_heder = state[`claimExtractiondata3`];
        values[0].data_heder["Text"] = headerIdentifier;
      }
      dispatch(saveClaimExtraction(values));

      //setClaimExtracition(values);
    }
    if (footerIdentifier) {
      const values = [...claimExtraction];
      values[0].footer = footerIdentifier;
      if (!_.isEmpty(state[`claimExtractiondata4`])) {
        values[0].data_footer = state[`claimExtractiondata4`];
        values[0].data_footer["Text"] = footerIdentifier;
      }
      dispatch(saveClaimExtraction(values));

      //setClaimExtracition(values);
    }
    if (pageBreakContinuationIdentifier) {
      const values = [...claimExtraction];
      values[0].pageBreakContinuationIdentifier =
        pageBreakContinuationIdentifier;
      if (!_.isEmpty(state[`claimExtractiondata5`])) {
        values[0].data_pbci = state[`claimExtractiondata5`];
        values[0].data_pbci["Text"] = pageBreakContinuationIdentifier;
      }
      dispatch(saveClaimExtraction(values));

      //setClaimExtracition(values);
    }
    if (pageBreakdownIdentifier) {
      const values = [...claimExtraction];
      values[0].pageBreakdownIdentifier = pageBreakdownIdentifier;
      if (!_.isEmpty(state[`claimExtractiondata6`])) {
        values[0].data_pbi = state[`claimExtractiondata6`];
        values[0].data_pbi["Text"] = pageBreakdownIdentifier;
      }
      dispatch(saveClaimExtraction(values));

      //setClaimExtracition(values);
    }

    if (claimAddressBlock1) {
      const values = [...claimAddress];
      values[0].blocks = claimAddressBlock1;
      if (!_.isEmpty(state[`claimAddressdata1`])) {
        values[0].data = state[`claimAddressdata1`];
        values[0].data["Text"] = claimAddressBlock1;
      }

      // setClaimAddress(values);
      dispatch(saveClaimAddress(values));
    }
    if (claimAddressBlock2) {
      const values = [...claimAddress];
      if (values.length === 2) {
        values[1].blocks = claimAddressBlock2;
        if (!_.isEmpty(state[`claimAddressdata2`])) {
          values[1].data = state[`claimAddressdata2`];
          values[1].data["Text"] = claimAddressBlock2;
        }
        // setClaimAddress(values);
        dispatch(saveClaimAddress(values));
      }
    }
    if (claimAddressBlock3) {
      const values = [...claimAddress];
      if (values.length === 3) {
        if (!_.isEmpty(state[`claimAddressdata3`])) {
          values[2].blocks = claimAddressBlock3;
          values[2].data = state[`claimAddressdata3`];
        }
        // setClaimAddress(values);
        dispatch(saveClaimAddress(values));
      }
    }
  }, [
    topIdentifier,
    bottomIdentifier,
    headerIdentifier,
    footerIdentifier,
    pageBreakContinuationIdentifier,
    pageBreakdownIdentifier,
    claimAddressBlock1,
    claimAddressBlock2,
    claimAddressBlock3,
  ]);

  React.useEffect(() => {
    const s = selectedClaimAddressOptions.map((op) => {
      return Object.values(op);
    });
    setClaimAddressDisabled([].concat(...s));
  }, [selectedClaimAddressOptions]);

  React.useEffect(() => {
    const s = selectedClaimFieldsOptions.map((op) => {
      return Object.values(op);
    });
    setClaimFieldsDisabled([].concat(...s));
  }, [selectedClaimFieldsOptions]);

  React.useEffect(() => {
    const s = selectedClaimHeaderOptions.map((op) => {
      return Object.values(op);
    });
    setClaimHeaderDisabled([].concat(...s));
  }, [selectedClaimHeaderOptions]);

  const addClaimHeader = (e) => {
    e.stopPropagation();

    dispatch(
      saveClaimHeader([
        ...claimHeader,
        {
          dropdown: "",
          identifier: "",
          isAdd: true,
          isClose: true,
          isActive: true,
          json: "",
          mandatory: false,
          formBasedExtractor: true,
          blockExtractor: false,

          extractType: "form",
          data: {},
          endIdentifier: "",
          idlimit: "1",
          indexRange1: "0",
          indexRange2: "",
          isAdvanced: false,
          noOfBlocks: "1",
          values: "",
          valueType: "text",
          data_end_identifier: {},
        },
      ])
    );
  };

  const handleReset = (index) => {
    let key;
    selectedClaimAddressOptions.forEach((item) => {
      key = Object.keys(item);
      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedClaimAddressOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedClaimAddress(newOptions));
    const newckadd = {
      dropdown: "",
      json: "",
      isReset: true,
      isActive: true,
      mandatory: false,
      blocks: "",
      minLenOfAddressLine: 1,
      isAdd: index ? true : false,
      isClose: index ? true : false,
    };
    let vals = claimAddress;

    vals[index] = newckadd;

    vals[index].isReset = true;

    dispatch(
      claimAddressText({
        name: `claimAddress${index + 1}`,
        text: "",
      })
    );
    dispatch(
      claimAddressText({
        name: `claimAddressdata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      disableClaimAddActive({
        name: `claimAddressActive${index + 1}`,
      })
    );

    dispatch(saveClaimAddress(vals));
  };

  const handleResetHeader = (index) => {
    let key;
    selectedClaimHeaderOptions.forEach((item) => {
      key = Object.keys(item);
      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedClaimHeaderOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedClaimHeader(newOptions));
    const newckadd = {
      dropdown: "",
      isActive: true,
      isReset: true,
      json: "",
      mandatory: false,
      formBasedExtractor: true,
      blockExtractor: false,
      extractType: "form",
      data: {},
      data_end_identifier: {},
      identifier: "",
      endIdentifier: "",
      idlimit: "1",
      indexRange1: "0",
      indexRange2: "",
      isAdvanced: false,
      noOfBlocks: "1",
      values: "",
      isAdd: true,
      isClose: true,
      value: "",
      data_value: {},
      valueType: "text",
    };
    let vals = claimHeader;

    vals[index] = newckadd;

    vals[index].isReset = true;

    dispatch(
      claimHeaderText({
        name: `claimHeaderIdentifier${index + 1}`,
        text: "",
      })
    );

    dispatch(
      claimHeaderText({
        name: `claimHeaderEndIdentifier${index + 1}`,
        text: "",
      })
    );

    dispatch(
      claimHeaderText({
        name: `claimHeaderIdentifierdata${index + 1}`,
        text: {},
      })
    );
    dispatch(
      claimHeaderText({
        name: `claimHeaderEndIdentifierdata${index + 1}`,
        text: {},
      })
    );
    dispatch(
      claimHeaderText({
        name: `claimHeaderValuedata${index + 1}`,
        text: {},
      })
    );
    dispatch(
      claimHeaderText({
        name: `claimHeaderValue${index + 1}`,
        text: "",
      })
    );
    dispatch(
      claimHeaderText({
        name: `claimHeaderValueActive${index + 1}`,
        text: {},
      })
    );

    // value
    dispatch(
      claimHeaderText({
        name: `claimHeadervaluedata${index + 1}`,
        text: {},
      })
    );
    dispatch(
      claimHeaderText({
        name: `claimHeadervalue${index + 1}`,
        text: "",
      })
    );
    dispatch(
      claimHeaderText({
        name: `claimHeadervalueActive${index + 1}`,
        text: {},
      })
    );

    dispatch(
      disableClaimHeadActive({
        name: `claimHeaderIdentifierActive${index + 1}`,
      })
    );
    dispatch(
      disableClaimHeadActive({
        name: `claimHeaderEndIdentifierActive${index + 1}`,
      })
    );

    dispatch(saveClaimHeader(vals));
  };

  const handleResetFields = (index) => {
    let key;
    selectedClaimFieldsOptions.forEach((item) => {
      key = Object.keys(item);
      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedClaimFieldsOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedClaimFeilds(newOptions));
    const newckadd = {
      mandatory: false,
      value: "",
      data_value: {},
      regex: "",
      nextValueType: "",
      prevValueType: "",
      indexRegexPrev: "",
      minLength: 1,
      nextValueDate: "",
      prevValueDate: "",
      maxLength: 256,
      nameType: "first_last_middle",
      json: "",
      line: 0,
      indexRangeIndexExtractor2: "",
      indexRangeIndexExtractor1: 0,
      showBlock1: false,
      showBlock2: false,
      isReset: true,
      block: "",
      data: {},

      keyValueExtractor: true,
      indexBasedExtractor: false,
      dropdown: "",
      identifier: "",
      extractType: "form",
      isAdvanced: false,
      endIdentifier: "",
      idlimit: "1",
      indexRange1: "0",
      indexRange2: "",
      noOfBlocks: "1",
      isNameType: false,
      valueType: "text",
      isAdd: index ? true : false,
      isClose: index ? true : false,

      data_end_identifier: {},
    };
    let vals = claimFields;

    vals[index] = newckadd;

    vals[index].isReset = true;

    dispatch(
      claimFieldsText({
        name: `claimFieldsIdentifier${index + 1}`,
        text: "",
      })
    );

    //value

    dispatch(
      claimFieldsText({
        name: `claimFieldsvalue${index + 1}`,
        text: "",
      })
    );

    dispatch(
      claimFieldsActive({
        name: `claimFieldsvalueActive${index + 1}`,
        text: false,
      })
    );

    dispatch(
      claimFieldsText({
        name: `claimFieldsvaluedata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      claimFieldsText({
        name: `claimFieldsEndIdentifier${index + 1}`,
        text: "",
      })
    );

    dispatch(
      claimFieldsText({
        name: `claimFieldsIdentifierdata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      claimFieldsText({
        name: `claimFieldsBlock${index + 1}`,
        text: "",
      })
    );

    dispatch(
      claimFieldsText({
        name: `claimFieldsBlockdata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      claimFieldsActive({
        name: `claimFieldsBlockActive${index + 1}`,
        text: false,
      })
    );

    dispatch(
      claimFieldsText({
        name: `claimFieldsEndIdentifierdata${index + 1}`,
        text: {},
      })
    );

    dispatch(
      disableClaimFieldsActive({
        name: `claimFieldsIdentifierActive${index + 1}`,
      })
    );
    dispatch(
      disableClaimFieldsActive({
        name: `claimFieldsEndIdentifierActive${index + 1}`,
      })
    );

    dispatch(saveClaimFields(vals));
  };

  const addClaimFields = (e) => {
    e.stopPropagation();
    if (claimFieldsOptions.length === claimFields.length - 1) return;

    dispatch(
      saveClaimFields([
        ...claimFields,

        {
          mandatory: false,
          indexRegexPrev: "",
          minLength: 1,
          maxLength: 256,
          regex: "",
          prevValueType: "",
          nextValueeeType: "",
          indexRangeIndexExtractor2: "",
          indexRangeIndexExtractor1: 0,
          line: 0,
          showBlock1: false,
          showBlock2: false,
          isReset: true,
          block: "",
          data: {},

          keyValueExtractor: true,
          indexBasedExtractor: false,
          nameType: "first_last_middle",
          json: "",
          dropdown: "",
          identifier: "",
          extractType: "form",
          isAdvanced: false,
          endIdentifier: "",
          idlimit: "1",
          indexRange1: "0",
          indexRange2: "",
          noOfBlocks: "1",
          isNameType: false,
          valueType: "text",
          isAdd: true,
          isClose: true,
          data_end_identifier: {},
        },
      ])
    );
  };

  React.useEffect(() => {
    if (selectedClaims) {
      const newClaimOptions = claimOptions.filter(
        (item) => !selectedClaims.includes(item)
      );
      setClaimOptions(newClaimOptions);
    }
  }, [selectedClaims]);

  const handleDeleteClaimAddress = (index) => {
    let key;

    selectedClaimAddressOptions.forEach((item) => {
      key = Object.keys(item);

      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedClaimAddressOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });

    dispatch(selectedClaimAddress(newOptions));

    const vals = [...claimAddress];
    vals[index].dropdown = null;
    vals[index].json = null;

    vals.splice(index, 1);
    dispatch(saveClaimAddress(vals));
    //setClaimAddress(vals);
  };

  const handleDeleteClaimHeader = (index) => {
    let key;

    selectedClaimHeaderOptions.forEach((item) => {
      key = Object.keys(item);

      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedClaimHeaderOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedClaimHeader(newOptions));

    const vals = [...claimHeader];
    vals[index].dropdown = null;
    vals[index].json = null;

    vals.splice(index, 1);

    // setClaimHeader(vals);
    dispatch(saveClaimHeader(vals));
  };

  const handleDeleteClaimFields = (index) => {
    let key;

    selectedClaimFieldsOptions.forEach((item) => {
      key = Object.keys(item);

      if (key[0] / 1 === index) {
        delete item[key];
      }
    });
    let newOptions = selectedClaimFieldsOptions.filter((item) => {
      if (Object.keys(item).length > 0) return true;
    });
    dispatch(selectedClaimFeilds(newOptions));

    const vals = [...claimFields];
    vals[index].dropdown = null;
    vals[index].json = null;

    vals.splice(index, 1);

    dispatch(saveClaimFields(vals));
  };

  return (
    <Box h="600px" margin="0px auto">
      <Box maxH="608px" overflowX="hidden" className="inner">
        <Box
          h="auto"
          margin="20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="6px"
          padding="4px 5px"
        >
          <Box padding="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={handleShowClaimExtraction}
              cursor="pointer"
            >
              <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                Claim Extraction Properties
              </Text>
              <Box display="flex" alignItems="center">
                <img
                  src={showClaimExtraction ? Up : Down}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  alt="dropdown"
                />
              </Box>
            </Box>
            {showClaimExtraction && (
              <>
                {claimExtraction.map((clEx, index) => (
                  <Box
                    key={index}
                    id={`claimExtraction${index}`}
                    background="white"
                    b="1px solid black"
                    h="auto"
                    paddingTop="10px"
                    margin="10px 0px"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      margin="0 10px"
                      alignItems="center"
                    >
                      <Text font={headingFont} margin="10px">
                        BASIC
                      </Text>
                      <Box>
                        <button onClick={handleResetClaimExtraction}>
                          Reset
                        </button>
                      </Box>
                    </Box>
                    <Box
                      background="#DEDEDE 0% 0% no-repeat padding-box;"
                      borderRadius="5px"
                      padding="10px 0px"
                      margin="0 10px"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        h="40px"
                      >
                        <Box display="flex" alignItems="center">
                          <Text
                            marginLeft="10px"
                            font={labelFont}
                            textColor={labelColor}
                            marginBottom="0px"
                          >
                            Claim Start Identifier
                          </Text>
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/FrYHAN8QoW1rTRTL?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                The block that marks the start of each
                                claim.(Click for more information)
                              </a>
                            }
                            placement="right"
                          >
                            {/* <Tooltip title="tooltip" placement="right"> */}
                            <img
                              src={Help}
                              style={{ height: 12, marginLeft: 6 }}
                              alt="help"
                            />
                          </Tooltip>
                        </Box>
                        <Box>
                          <Tooltip title={clEx?.top}>
                            <input
                              onClick={() => {
                                dispatch(
                                  claimExtractionActive({
                                    name: "claimExtractionActive1",
                                  })
                                );
                              }}
                              placeholder="click on a block in image"
                              value={clEx?.top}
                              onChange={(e) => {
                                const values = [...claimExtraction];
                                if (values[index].top.length <= 0) {
                                  alert(
                                    "please select value from image to proceed"
                                  );
                                  e.preventDefault();
                                } else {
                                  values[index].top = e.target.value;
                                  if (!e.target.value) {
                                    values[index].data_top = {};
                                  } else if (
                                    e.target.value &&
                                    !_.isEmpty(values[index].data_top)
                                  ) {
                                    values[index].data_top["Text"] =
                                      e.target.value;
                                  }
                                  // setClaimExtracition(values);
                                  dispatch(saveClaimExtraction(values));
                                  const clData = {
                                    name: "claimExtraction1",
                                    text: e.target.value,
                                  };

                                  dispatch(claimExtractionText(clData));
                                }
                              }}
                              type="text"
                              style={{
                                width: 250,
                                marginLeft: 20,
                                marginRight: 20,
                                fontStyle: "italic",
                                padding: 4,
                                border: "1px solid gray",
                                borderRadius: 4,
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        h="40px"
                      >
                        <Box display="flex" alignItems="center">
                          <Text
                            marginLeft="10px"
                            font={labelFont}
                            textColor={labelColor}
                            marginBottom="0px"
                            marginBottom="0px"
                          >
                            Claim End Identifier
                          </Text>
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/FrYHAN8QoW1rTRTL?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                The blocks that marks the end of each
                                claim.(Click for more information)
                              </a>
                            }
                            placement="right"
                          >
                            <img
                              src={Help}
                              style={{ height: 12, marginLeft: 6 }}
                            />
                          </Tooltip>
                        </Box>
                        <Box>
                          <Tooltip title={clEx?.bottom}>
                            <input
                              onClick={() => {
                                dispatch(
                                  claimExtractionActive({
                                    name: "claimExtractionActive2",
                                  })
                                );
                              }}
                              value={clEx?.bottom}
                              onChange={(e) => {
                                const values = [...claimExtraction];
                                if (values[index].bottom.length <= 0) {
                                  alert(
                                    "please select value from image to proceed"
                                  );
                                  e.preventDefault();
                                } else {
                                  values[index].bottom = e.target.value;
                                  if (!e.target.value) {
                                    values[index].data_bottom = {};
                                  } else if (
                                    e.target.value &&
                                    !_.isEmpty(values[index].data_bottom)
                                  ) {
                                    values[index].data_bottom["Text"] =
                                      e.target.value;
                                  }

                                  //setClaimExtracition(values);
                                  dispatch(saveClaimExtraction(values));

                                  dispatch(
                                    claimExtractionText({
                                      name: "claimExtraction2",
                                      text: e.target.value,
                                    })
                                  );
                                }
                              }}
                              placeholder="click on a block in image"
                              type="text"
                              style={{
                                width: 250,
                                marginLeft: 20,
                                marginRight: 20,
                                fontStyle: "italic",
                                padding: 4,
                                border: "1px solid gray",
                                borderRadius: 4,
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <span style={{ color: "red" }}>*</span>
                        <Text
                          margin="5px 10px"
                          fontStyle="italic"
                          fontSize="12px"
                          fontWeight="light"
                          textColor="#262525"
                        >
                          Min 1 field reqiured
                        </Text>
                      </Box>
                    </Box>

                    <Box marginLeft="5px" marginTop="10px">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        h="40px"
                      >
                        <Box display="flex" alignItems="center">
                          <Text
                            marginLeft="10px"
                            marginBottom="0px"
                            font={labelFont}
                            textColor={labelColor}
                            fontWeight="bold"
                          >
                            Header Identifier{" "}
                            <span
                              style={{ color: "#969696", fontStyle: "italic" }}
                            >
                              (optional)
                            </span>
                          </Text>
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/FrYHAN8QoW1rTRTL?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                The header identifier block is on the top of the
                                page and the blocks above them should not
                                contain any claim details.Click for more
                                information
                              </a>
                            }
                            placement="right"
                          >
                            <img
                              src={Help}
                              style={{ height: 12, marginLeft: 6 }}
                            />
                          </Tooltip>
                        </Box>
                        <Box>
                          <Tooltip title={clEx?.header}>
                            <input
                              onClick={() => {
                                dispatch(
                                  claimExtractionActive({
                                    name: "claimExtractionActive3",
                                  })
                                );
                              }}
                              placeholder="click on a block in image"
                              type="text"
                              value={clEx?.header}
                              onChange={(e) => {
                                const values = [...claimExtraction];
                                if (values[index].header.length <= 0) {
                                  alert(
                                    "please select value from image to proceed"
                                  );
                                  e.preventDefault();
                                } else {
                                  values[0].header = e.target.value;
                                  if (!e.target.value) {
                                    values[0].data_heder = {};
                                  } else if (
                                    e.target.value &&
                                    !_.isEmpty(values[index].data_heder)
                                  ) {
                                    values[0].data_heder["Text"] =
                                      e.target.value;
                                  }

                                  // setClaimExtracition(values);
                                  dispatch(saveClaimExtraction(values));

                                  dispatch(
                                    claimExtractionText({
                                      name: "claimExtraction3",
                                      text: e.target.value,
                                    })
                                  );
                                }
                              }}
                              style={{
                                width: 250,
                                marginLeft: 20,
                                marginRight: 20,
                                fontStyle: "italic",
                                padding: 4,
                                border: "1px solid gray",
                                borderRadius: 4,
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        h="40px"
                      >
                        <Box display="flex" alignItems="center">
                          <Text
                            marginLeft="10px"
                            font={labelFont}
                            textColor={labelColor}
                            marginBottom="0px"
                          >
                            Footer Identifier{" "}
                            <span
                              style={{ color: "#969696", fontStyle: "italic" }}
                            >
                              (optional)
                            </span>
                          </Text>
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/FrYHAN8QoW1rTRTL?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                All the block in the same line and below the
                                selected footer block will be discarded and
                                won't be considered during claim extraction.
                                (Click for more information)
                              </a>
                            }
                            placement="right"
                          >
                            <img
                              src={Help}
                              style={{ height: 12, marginLeft: 6 }}
                            />
                          </Tooltip>
                        </Box>
                        <Box>
                          <Tooltip title={clEx?.footer}>
                            <input
                              onClick={() => {
                                dispatch(
                                  claimExtractionActive({
                                    name: "claimExtractionActive4",
                                  })
                                );
                              }}
                              placeholder="click on a block in image"
                              type="text"
                              value={clEx?.footer}
                              onChange={(e) => {
                                const values = [...claimExtraction];
                                if (values[index].footer.length <= 0) {
                                  alert(
                                    "please select value from image to proceed"
                                  );
                                  e.preventDefault();
                                } else {
                                  values[0].footer = e.target.value;
                                  if (!e.target.value) {
                                    values[0].data_footer = {};
                                  } else if (
                                    e.target.value &&
                                    !_.isEmpty(values[index].data_footer)
                                  ) {
                                    values[0].data_footer["Text"] =
                                      e.target.value;
                                  }
                                  // setClaimExtracition(values);
                                  dispatch(saveClaimExtraction(values));

                                  dispatch(
                                    claimExtractionText({
                                      name: "claimExtraction4",
                                      text: e.target.value,
                                    })
                                  );
                                }
                              }}
                              style={{
                                width: 250,
                                marginLeft: 20,
                                marginRight: 20,
                                fontStyle: "italic",
                                padding: 4,
                                border: "1px solid gray",
                                borderRadius: 4,
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                    {/* advanced */}
                    <Box
                      background="#DEDEDE 0% 0% no-repeat padding-box;"
                      borderRadius="5px"
                      margin="10px"
                      h="auto"
                      padding="5px"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        h="auto"
                        onClick={() => {
                          const values = [...claimExtraction];
                          values[index].isAdvanced = !values[index].isAdvanced;
                          dispatch(saveClaimExtraction(values));
                          //setClaimExtracition(values);
                        }}
                        cursor="pointer"
                      >
                        <Text
                          marginLeft="10px"
                          fontWeight="bold"
                          style={{ fontStyle: "italic" }}
                          marginBottom="0px"
                          padding="3px 0"
                        >
                          ADVANCED
                        </Text>
                        <img
                          src={clEx?.isAdvanced ? Up : Down}
                          style={{ marginRight: 20, cursor: "pointer" }}
                        />
                      </Box>
                      {clEx?.isAdvanced && (
                        <Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                textColor={labelColor}
                                marginBottom="0px"
                              >
                                Bottom additional line
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/FrYHAN8QoW1rTRTL?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Lines that have to be added after the end
                                    identifier. (Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="help"
                                />
                              </Tooltip>
                            </Box>
                            <Box marginRight="auto" marginLeft="60px">
                              <input
                                type="number"
                                min="0"
                                value={clEx?.bottomLine}
                                onChange={(e) => {
                                  const values = [...claimExtraction];
                                  values[0].bottomLine = e.target.value;
                                  dispatch(saveClaimExtraction(values));

                                  //                                  setClaimExtracition(values);
                                }}
                                style={{
                                  width: 50,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                textColor={labelColor}
                                marginBottom="0px"
                              >
                                Capture block till next start
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/FrYHAN8QoW1rTRTL?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Any blocks between the bottom identifier of
                                    the first claim and the top identifier of
                                    the following claim will be captured.(Click
                                    for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                {/* <Tooltip title="tooltip" placement="right"> */}
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="help"
                                />
                              </Tooltip>
                            </Box>
                            <Box marginLeft="22px" marginRight="auto">
                              <select
                                value={clEx?.captureBlockTillNextStart}
                                onChange={(e) => {
                                  const values = [...claimExtraction];
                                  if (e.target.value === "false")
                                    values[0].captureBlockTillNextStart = false;
                                  else
                                    values[0].captureBlockTillNextStart = true;
                                  dispatch(saveClaimExtraction(values));

                                  //setClaimExtracition(values);
                                }}
                                style={{
                                  width: 120,
                                  fontWeight: "bold",
                                  padding: 3,
                                }}
                              >
                                {["true", "false"].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                textColor={labelColor}
                                marginBottom="0px"
                              >
                                Page break continuation identifier
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/FrYHAN8QoW1rTRTL?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Block found in the first line of the
                                    continuation of the claim in the next page
                                    in case of a page break.(Click for more
                                    information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{
                                    height: 12,
                                    marginLeft: 6,
                                    marginRight: 21,
                                    marginTop: -20,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                            <Box marginRight="10px">
                              <Tooltip
                                title={clEx?.pageBreakContinuationIdentifier}
                              >
                                <input
                                  onClick={() => {
                                    dispatch(
                                      claimExtractionActive({
                                        name: "claimExtractionActive5",
                                      })
                                    );
                                  }}
                                  value={clEx?.pageBreakContinuationIdentifier}
                                  onChange={(e) => {
                                    const values = [...claimExtraction];
                                    if (
                                      values[index]
                                        .pageBreakContinuationIdentifier
                                        .length <= 0
                                    ) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[0].pageBreakContinuationIdentifier =
                                        e.target.value;
                                      if (!e.target.value) {
                                        values[0].data_pbci = {};
                                      } else if (
                                        e.target.value &&
                                        !_.isEmpty(values[index].data_pbci)
                                      ) {
                                        values[0].data_pbci["Text"] =
                                          e.target.value;
                                      }
                                      dispatch(saveClaimExtraction(values));

                                      //                                  setClaimExtracition(values);
                                      dispatch(
                                        claimExtractionText({
                                          name: "claimExtraction5",
                                          text: e.target.value,
                                        })
                                      );
                                    }
                                  }}
                                  type="text"
                                  placeholder="click on a block in image"
                                  style={{
                                    width: 212,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                textColor={labelColor}
                                marginBottom="0px"
                              >
                                Page break identifier
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/FrYHAN8QoW1rTRTL?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Any identifier that says there is a page
                                    break.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{
                                    height: 12,
                                    marginLeft: 6,
                                    marginRight: 21,
                                    marginTop: "unset",
                                  }}
                                />
                              </Tooltip>
                            </Box>
                            <Box marginRight="10px">
                              <Tooltip title={clEx?.pageBreakdownIdentifier}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      claimExtractionActive({
                                        name: "claimExtractionActive6",
                                      })
                                    );
                                  }}
                                  value={clEx?.pageBreakdownIdentifier}
                                  onChange={(e) => {
                                    const values = [...claimExtraction];
                                    if (
                                      values[index].pageBreakdownIdentifier
                                        .length <= 0
                                    ) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[index].pageBreakdownIdentifier =
                                        e.target.value;
                                      if (!e.target.value) {
                                        values[0].data_pbi = {};
                                      } else if (
                                        e.target.value &&
                                        !_.isEmpty(values[index].data_pbi)
                                      ) {
                                        values[0].data_pbi["Text"] =
                                          e.target.value;
                                      }
                                      dispatch(saveClaimExtraction(values));

                                      //setClaimExtracition(values);
                                      dispatch(
                                        claimExtractionText({
                                          name: "claimExtraction6",
                                          text: e.target.value,
                                        })
                                      );
                                    }
                                  }}
                                  type="text"
                                  placeholder="click on a block in image"
                                  style={{
                                    width: 212,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                textColor={labelColor}
                                marginBottom="0px"
                              >
                                Is header present?
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/FrYHAN8QoW1rTRTL?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    If the table header details are getting
                                    repeated again in the next page followed but
                                    the continuation of the line item then this
                                    is set to true.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </Box>
                            <Box marginLeft="86px" marginRight="auto">
                              <select
                                value={clEx?.isHeaderPresent}
                                onChange={(e) => {
                                  const values = [...claimExtraction];
                                  if (e.target.value === "false")
                                    values[0].isHeaderPresent = false;
                                  else values[0].isHeaderPresent = true;

                                  dispatch(saveClaimExtraction(values));
                                }}
                                style={{
                                  width: 120,
                                  fontWeight: "bold",
                                  padding: 3,
                                }}
                              >
                                {["false", "true"].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                textColor={labelColor}
                                marginBottom="0px"
                              >
                                Bottom lines to skip
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/FrYHAN8QoW1rTRTL?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    The number of lines to be ignore at the end
                                    of every page. (Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="help"
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <input
                                type="number"
                                min="0"
                                value={clEx?.bottomLinesToSkip}
                                onChange={(e) => {
                                  const values = [...claimExtraction];
                                  values[index].bottomLinesToSkip =
                                    e.target.value;
                                  // setClaimExtracition(values);
                                  dispatch(saveClaimExtraction(values));
                                }}
                                style={{
                                  width: 50,
                                  marginLeft: 20,
                                  marginRight: 169,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>

        {/* header identification */}

        <Box
          margin="10px 20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="10px"
        >
          <Box margin="10px" padding="10px" cursor="pointer">
            <Box
              display="flex"
              justifyContent="space-between"
              onClick={handleHeaderIdentification}
            >
              <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                Claim Header Identification:{" "}
                <span style={{ color: "#909090", fontStyle: "italic" }}>
                  (optional)
                </span>
              </Text>
              <Tooltip
                interactive
                title={
                  <a
                    href="https://sway.office.com/JffoZFaEIKyMGOSC?play"
                    target="_blank"
                    style={{ color: "white" }}
                  >
                    Claim header identification is configured when the headers
                    are present on a separate page and not in the claim pages.
                    These strings are selected from the header page
                  </a>
                }
                placement="right"
              >
                <img
                  src={Help}
                  style={{
                    height: 12,
                    marginLeft: showClaimHeaderIdentification ? -100 : -100,
                    marginTop: 4,
                  }}
                  alt="tooltip"
                />
              </Tooltip>
              <Box display="flex">
                <Box
                  display={showClaimHeaderIdentification ? "block" : "none"}
                  marginRight="10px"
                >
                  <button onClick={handleResetClaimIden}>Reset</button>
                </Box>
                <img
                  src={showClaimHeaderIdentification ? Up : Down}
                  style={{
                    cursor: "pointer",
                    height: "7px",
                    width: "15px",
                    marginTop: "8px",
                    marginRight: "-8px",
                  }}
                />
              </Box>
            </Box>
            {showClaimHeaderIdentification && (
              <>
                <Box display="flex" alignItems="baseline">
                  <Box
                    background="white"
                    b="1px solid black"
                    h="auto"
                    padding="6px"
                    margin="10px 0px"
                    w="456px"
                    style={{ marginLeft: 40 }}
                  >
                    {claimHeaderIden.map((start, index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        key={index}
                        margin="10px 0"
                      >
                        <Tooltip title={start?.block}>
                          <input
                            id={`claimHeaderIdentification${index}`}
                            onClick={() => {
                              dispatch(
                                claimHeaderIdenActive({
                                  name: `ClaimHeaderIdenActive${index + 1}`,
                                })
                              );
                            }}
                            value={start?.block}
                            onChange={(e) => {
                              const values = [...claimHeaderIden];
                              if (values[index].block.length <= 0) {
                                alert(
                                  "please select value from image to proceed"
                                );
                                e.preventDefault();
                              } else {
                                values[index].block = e.target.value;
                                if (!e.target.value) {
                                  values[index].data = {};
                                } else {
                                  values[index].data["Text"] = e.target.value;
                                }

                                setClaimHeaderIden(values);
                                dispatch(
                                  claimHeaderIdenText({
                                    name: `ClaimHeaderIden${index + 1}`,
                                    text: e.target.value,
                                  })
                                );
                              }
                              dispatch(saveClaimHeaderIden(values));
                            }}
                            type="text"
                            placeholder="Select the block in the image"
                            style={{
                              width: 400,
                              marginLeft: 10,
                              fontStyle: "italic",
                              padding: 4,
                            }}
                          />
                        </Tooltip>
                        {start?.isAdd && (
                          <img
                            src={Add}
                            onClick={addClaimHeaderIdenRow1}
                            style={{
                              marginLeft: 10,
                              cursor: "cursor",
                              height: 17,
                            }}
                          />
                        )}

                        {start?.isClose && (
                          <img
                            src={Minus}
                            onClick={() => handleDeleteClaimHeaderIden(start)}
                            style={{
                              marginLeft: 10,
                              cursor: "cursor",
                              height: 17,
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>

        {/* claim address */}
        <Box
          h="auto"
          margin="20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="6px"
          padding="4px 5px"
        >
          <Box padding="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={handleShowClaimAddress}
              cursor="pointer"
            >
              <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                Claim Address
              </Text>
              <Box display="flex" alignItems="center">
                <img
                  src={Add}
                  style={{ marginLeft: 20, cursor: "pointer", height: 20 }}
                  onClick={addClaimAddress}
                  alt="Add"
                />
                <img
                  src={showClaimAddress ? Up : Down}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  alt="show"
                />
              </Box>
            </Box>
            <Box>
              {showClaimAddress && (
                <>
                  {claimAddress.map((clAdd, index) => (
                    <Box
                      key={index}
                      id={`claimAddress${index}`}
                      background="white"
                      b="1px solid black"
                      h="180px"
                      paddingTop="10px"
                      margin="10px 0px"
                    >
                      <Box
                        display={"flex"}
                        justifyContent="flex-end"
                        marginBottom="20px"
                      >
                        <Box marginRight={!clAdd?.isAdd ? "20px" : 0}>
                          <button onClick={() => handleReset(index)}>
                            Reset
                          </button>
                        </Box>
                        <img
                          src={Add}
                          style={{
                            marginLeft: 20,
                            cursor: "pointer",
                            height: 20,
                            marginRight: 10,
                            display: clAdd?.isAdd ? "flex" : "none",
                          }}
                          onClick={addClaimAddress}
                          //onClick={handleAddClaimAddress}
                          alt="add"
                        />

                        <img
                          src={Cross}
                          alt="delete"
                          style={{
                            height: 13,
                            width: 13,
                            marginRight: 10,
                            marginBottom: 10,
                            cursor: "pointer",
                            display: clAdd?.isClose ? "flex" : "none",
                          }}
                          onClick={(event) =>
                            handleDeleteClaimAddress(index, event)
                          }
                        />
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        margin="5px 10px"
                      >
                        <Tooltip
                          title={clAdd.dropdown ? clAdd.dropdown : "empty"}
                        >
                          <SelectPicker
                            // searchable={false}
                            onClean={() => {
                              let key;

                              selectedClaimAddressOptions.forEach((item) => {
                                key = Object.keys(item);

                                if (key[0] / 1 === index) {
                                  delete item[key];
                                }
                              });
                              let newOptions =
                                selectedClaimAddressOptions.filter((item) => {
                                  if (Object.keys(item).length > 0) return true;
                                });
                              dispatch(selectedClaimAddress(newOptions));
                              const vals = [...claimAddress];
                              vals[index].dropdown = null;
                              vals[index].json = null;
                            }}
                            value={clAdd.dropdown}
                            disabledItemValues={claimAddressDisabled}
                            onChange={(v) => {
                              const values = [...claimAddress];
                              if (!values[index].dropdown) {
                                values[index].dropdown = v;
                                // set json
                                const json = claimAddressOptions.find(
                                  (item) => item.value === v
                                );
                                values[index].json = json.JsonKey;

                                dispatch(saveClaimAddress(values));
                                dispatch(
                                  selectedClaimAddress([
                                    ...selectedClaimAddressOptions,
                                    { [index]: v },
                                  ])
                                );
                              }
                            }}
                            data={claimAddressOptions}
                            style={{ width: 344 }}
                            renderMenu={(menu) => {
                              if (claimAddressOptions.length === 0) {
                                return (
                                  <p
                                    style={{
                                      padding: 4,
                                      color: "#999",
                                      textAlign: "center",
                                    }}
                                  >
                                    <i
                                      className="fa fa-circle-o-notch fa-spin"
                                      style={{ fontSize: "16px" }}
                                    ></i>{" "}
                                    &nbsp; Loading...
                                  </p>
                                );
                              }
                              return menu;
                            }}
                          />
                        </Tooltip>

                        <Box>
                          <input
                            type="checkbox"
                            id="mandatory"
                            onChange={(event) => {
                              const values = [...claimAddress];
                              values[index].mandatory = event.target.checked;
                              //setClaimAddress(values);
                              dispatch(saveClaimAddress(values));
                            }}
                            checked={clAdd?.mandatory}
                          />
                          <label style={{ fontStyle: "italic" }}>
                            Mandatory
                          </label>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        h="40px"
                      >
                        <Box display="flex" alignItems="center">
                          <Text
                            marginLeft="10px"
                            fontWeight="bold"
                            marginBottom="0px"
                          >
                            Blocks
                          </Text>
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/1Hvf3uUd6bfWHtsz?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                Select all the blocks of the payee/payer name
                                and address based on the selected name.For
                                selecting multiple blocks, press Ctrl+click .
                                (Click for more information)
                              </a>
                            }
                            placement="right"
                          >
                            <img
                              src={Help}
                              style={{ height: 12, marginLeft: 6 }}
                            />
                          </Tooltip>
                        </Box>
                        <Box>
                          <Tooltip title={clAdd?.blocks}>
                            <input
                              onClick={() => {
                                dispatch(
                                  claimAddressActive({
                                    name: `claimAddressActive${index + 1}`,
                                  })
                                );
                              }}
                              type="text"
                              placeholder="click on a block in image"
                              onChange={(e) => {
                                const values = [...claimAddress];
                                if (values[index].blocks.length <= 0) {
                                  alert(
                                    "please select value from image to proceed"
                                  );
                                  e.preventDefault();
                                } else {
                                  values[index].blocks = e.target.value;
                                  if (!e.target.value) {
                                    values[index].data = {};
                                  } else if (
                                    e.target.value &&
                                    !_.isEmpty(values[index].data)
                                  ) {
                                    values[index].data["Text"] = e.target.value;
                                  }
                                  dispatch(saveClaimAddress(values));

                                  //setClaimAddress(values);
                                  dispatch(
                                    claimAddressText({
                                      name: `claimAddress${index + 1}`,
                                      text: values[index].blocks,
                                    })
                                  );
                                }
                              }}
                              value={clAdd?.blocks}
                              style={{
                                width: 209,
                                marginLeft: 20,
                                marginRight: 20,
                                fontStyle: "italic",
                                padding: 4,
                                border: "1px solid gray",
                                borderRadius: 4,
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        h="15px"
                        marginTop="15px"
                      >
                        <Box display="flex" alignItems="center">
                          <Text
                            marginLeft="10px"
                            fontWeight="bold"
                            marginBottom="0px"
                          >
                            Min length of address lines
                          </Text>
                          <Tooltip
                            interactive
                            title={
                              <a
                                href="https://sway.office.com/1Hvf3uUd6bfWHtsz?ref=Link"
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                Expected minimum length of address lines.Click
                                for more information
                              </a>
                            }
                            placement="right"
                          >
                            <img
                              src={Help}
                              style={{ height: 12, marginLeft: 6 }}
                              alt="help"
                            />
                          </Tooltip>
                        </Box>
                        <Box w="138px">
                          <input
                            type="number"
                            min="1"
                            onChange={(e) => {
                              const values = [...claimAddress];
                              values[index].minLenOfAddressLine =
                                e.target.value;
                              //setClaimAddress(values);
                              dispatch(saveClaimAddress(values));
                            }}
                            value={clAdd?.minLenOfAddressLine}
                            placeholder="click on a block in image"
                            style={{
                              width: 60,
                              marginLeft: -89,
                              marginRight: 20,
                              fontStyle: "italic",
                              padding: 4,
                              border: "1px solid gray",
                              borderRadius: 4,
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </Box>

        {/* claim header */}
        <Box
          h="auto"
          margin="20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="6px"
          padding="4px 5px"
        >
          <Box padding="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={handleShowClaimHeader}
              cursor="pointer"
            >
              <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                Claim Header
              </Text>
              <Box display="flex" alignItems="center">
                <img
                  src={Add}
                  style={{ marginLeft: 20, cursor: "pointer", height: 20 }}
                  onClick={addClaimHeader}
                  alt="add"
                />
                <img
                  src={showClaimHeader ? Up : Down}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  alt="show"
                />
              </Box>
            </Box>
            {showClaimHeader && (
              <>
                {claimHeader.map((clHeader, index) => (
                  <Box
                    key={index}
                    id={`claimHeader${index}`}
                    background="white"
                    b="1px solid black"
                    h="auto"
                    paddingTop="10px"
                    margin="10px 0px"
                  >
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      marginBottom="20px"
                    >
                      <Box marginRight={!clHeader?.isAdd ? "20px" : 0}>
                        <button onClick={() => handleResetHeader(index)}>
                          Reset
                        </button>
                      </Box>
                      <img
                        src={Add}
                        style={{
                          marginLeft: 20,
                          cursor: "pointer",
                          height: 20,
                          marginRight: 10,
                          display: clHeader?.isAdd ? "flex" : "none",
                        }}
                        onClick={addClaimHeader}
                        alt="add"
                      />

                      <img
                        src={Cross}
                        alt="delete"
                        style={{
                          height: 13,
                          width: 13,
                          marginRight: 10,
                          marginBottom: 10,
                          cursor: "pointer",
                          display: clHeader?.isClose ? "flex" : "none",
                        }}
                        onClick={(event) =>
                          handleDeleteClaimHeader(index, event)
                        }
                      />
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      margin="5px 10px"
                    >
                      <Tooltip
                        title={clHeader.dropdown ? clHeader.dropdown : "empty"}
                      >
                        <SelectPicker
                          onClean={() => {
                            let key;

                            selectedClaimHeaderOptions.forEach((item) => {
                              key = Object.keys(item);

                              if (key[0] / 1 === index) {
                                delete item[key];
                              }
                            });
                            let newOptions = selectedClaimHeaderOptions.filter(
                              (item) => {
                                if (Object.keys(item).length > 0) return true;
                              }
                            );
                            dispatch(selectedClaimHeader(newOptions));
                            const vals = [...claimHeader];
                            vals[index].dropdown = null;
                            vals[index].json = null;
                            dispatch(saveClaimHeader(vals));
                          }}
                          value={clHeader.dropdown}
                          disabledItemValues={claimHeaderDisabled}
                          onChange={(v) => {
                            const values = [...claimHeader];
                            if (!values[index].dropdown) {
                              values[index].dropdown = v;
                              const json = claimHeaderOptions.find(
                                (item) => item.value === v
                              );
                              values[index].json = json.JsonKey;
                              dispatch(saveClaimHeader(values));

                              //setClaimHeader(values);
                              dispatch(
                                selectedClaimHeader([
                                  ...selectedClaimHeaderOptions,
                                  { [index]: v },
                                ])
                              );
                            }
                          }}
                          data={claimHeaderOptions}
                          style={{ width: 344 }}
                          renderMenu={(menu) => {
                            if (claimHeaderOptions.length === 0) {
                              return (
                                <p
                                  style={{
                                    padding: 4,
                                    color: "#999",
                                    textAlign: "center",
                                  }}
                                >
                                  <i
                                    className="fa fa-circle-o-notch fa-spin"
                                    style={{ fontSize: "16px" }}
                                  ></i>{" "}
                                  &nbsp; Loading...
                                </p>
                              );
                            }
                            return menu;
                          }}
                        />
                      </Tooltip>

                      <Box>
                        <input
                          type="checkbox"
                          id="mandatory"
                          onChange={(e) => {
                            const values = [...claimHeader];
                            values[index].mandatory = e.target.checked;
                            //                            setClaimHeader(values);
                            dispatch(saveClaimHeader(values));
                          }}
                          checked={clHeader?.mandatory}
                        />
                        <label style={{ fontStyle: "italic" }}>Mandatory</label>
                      </Box>
                    </Box>
                    <Box
                      margin="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        w="-webkit-fill-available"
                        onClick={(e) => {
                          const values = [...claimHeader];
                          values[index].blockExtractor = false;
                          values[index].formBasedExtractor = true;
                          dispatch(saveClaimHeader(values));

                          // /                          setClaimHeader(values);
                        }}
                        cursor="pointer"
                        b="1px solid black"
                        br="none"
                        padding="10px"
                        background={
                          clHeader?.formBasedExtractor &&
                          !clHeader?.blockExtractor
                            ? "white"
                            : "lightgray"
                        }
                        justifyContent="space-between"
                        alignItems="center"
                        display="flex"
                        textAlign="center"
                      >
                        <span>Key value extractor</span>
                      </Box>
                      <Box
                        w="-webkit-fill-available"
                        onClick={(e) => {
                          const values = [...claimHeader];
                          values[index].blockExtractor = true;
                          values[index].formBasedExtractor = false;
                          //                          setClaimHeader(values);
                          dispatch(saveClaimHeader(values));
                        }}
                        cursor="pointer"
                        b={
                          clHeader?.blockExtractor
                            ? "1px solid #ACACAC"
                            : "1px solid black"
                        }
                        bl="none"
                        padding="10px"
                        background={
                          clHeader?.blockExtractor ? "white" : "lightgray"
                        }
                        justifyContent="space-between"
                        alignItems="center"
                        display="flex"
                        textAlign="center"
                      >
                        <span style={{ color: "black" }}>Block extractor</span>
                      </Box>
                    </Box>

                    <Box
                      background="#DEDEDE 0% 0% no-repeat padding-box;"
                      borderRadius="5px"
                      padding="10px 0px"
                      margin="10px"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        h="40px"
                        w="220px"
                      >
                        <Box display="flex" alignItems="center">
                          {clHeader?.blockExtractor ? (
                            <>
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                marginBottom="0px"
                                width={"95px"}
                              >
                                Value block
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/VLwsB3DnSARHScxd?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    The block that has to be extracted. For
                                    selecting multiple blocks, press Ctrl+click
                                    and select the multiple blocks.
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                marginBottom="0px"
                              >
                                Identifier
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Identifier is the label to the required
                                    value. For Eg: AMOUNT PAID is the identifier
                                    to extract AMOUNT PAID: $$1203.23**.(Click
                                    for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </>
                          )}
                        </Box>

                        <Box display="flex" alignItems="center">
                          {clHeader?.blockExtractor ? (
                            <>
                              <Tooltip title={clHeader?.values}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      claimHeaderActive({
                                        name: `claimHeaderValueActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...claimHeader];
                                    if (values[index].values.length <= 0) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[index].valueType = "text";
                                      values[index].values = e.target.value;
                                      if (!e.target.value) {
                                        values[index].data = {};
                                      } else if (
                                        e.target.value &&
                                        !_.isEmpty(values[index].data)
                                      ) {
                                        values[index].data["Text"] =
                                          e.target.value;
                                      }
                                      dispatch(saveClaimHeader(values));
                                      //                                setClaimHeader(values);
                                      dispatch(
                                        claimHeaderText({
                                          name: `claimHeaderValue${index + 1}`,
                                          text: values[index].values,
                                        })
                                      );
                                    }
                                  }}
                                  value={clHeader?.values}
                                  placeholder="click on a block in image"
                                  type="text"
                                  style={{
                                    width: 221,
                                    marginLeft: blockExtractor ? 100 : 84,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <Tooltip title={clHeader?.identifier}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      claimHeaderActive({
                                        name: `claimHeaderIdentifierActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...claimHeader];
                                    if (values[index].identifier.length <= 0) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      dispatch(
                                        claimHeaderText({
                                          name: `claimHeaderIdentifier${
                                            index + 1
                                          }`,
                                          text: e.target.value,
                                        })
                                      );
                                      values[index].identifier = e.target.value;
                                      if (!e.target.value) {
                                        values[index].data = {};
                                      } else if (
                                        e.target.value &&
                                        !_.isEmpty(values[index].data)
                                      ) {
                                        values[index].data["Text"] =
                                          e.target.value;
                                      }
                                      dispatch(saveClaimHeader(values));

                                      // /                                setClaimHeader(values);
                                    }
                                  }}
                                  value={clHeader?.identifier}
                                  placeholder="click on a block in image"
                                  type="text"
                                  style={{
                                    width: 221,
                                    marginLeft: clHeader?.blockExtractor
                                      ? 100
                                      : 125,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </>
                          )}
                        </Box>
                      </Box>

                      {clHeader?.formBasedExtractor && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          h="40px"
                        >
                          <Box display="flex" alignItems="center" w="220px">
                            <Text
                              marginLeft="10px"
                              fontWeight="bold"
                              marginBottom="0px"
                              marginBottom="0px"
                            >
                              Value
                            </Text>
                            <Tooltip
                              interactive
                              title={
                                <a
                                  href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                  target="_blank"
                                  style={{ color: "white" }}
                                >
                                  Anything between the identifier and end
                                  identifier is extracted as the value. Used for
                                  form type of value extraction. Click for more
                                  information
                                </a>
                              }
                              placement="right"
                            >
                              <img
                                src={Help}
                                style={{ height: 12, marginLeft: 6 }}
                              />
                            </Tooltip>
                          </Box>
                          <Box marginRight="10px">
                            <Tooltip title={clHeader?.value}>
                              <input
                                onClick={() => {
                                  dispatch(
                                    claimHeaderActive({
                                      name: `claimHeadervalueActive${
                                        index + 1
                                      }`,
                                    })
                                  );
                                }}
                                onChange={(e) => {
                                  const values = [...claimHeader];
                                  if (
                                    values[index]?.value?.length <= 0 ||
                                    values[index]?.data_value?.length <= 0
                                  ) {
                                    alert(
                                      "please select value from image to proceed"
                                    );
                                    e.preventDefault();
                                  } else {
                                    values[index].value = e.target.value;
                                    if (!e.target.value) {
                                      values[index].data_value = {};
                                    } else if (
                                      e.target.value &&
                                      !_.isEmpty(values[index].data_value)
                                    ) {
                                      values[index].data_value["Text"] =
                                        e.target.value;
                                    }

                                    dispatch(
                                      claimHeaderText({
                                        name: `claimHeadervalue${index + 1}`,
                                        text: e.target.value,
                                      })
                                    );
                                    dispatch(saveClaimHeader(values));
                                  }
                                }}
                                value={clHeader?.value}
                                type="text"
                                placeholder="click on a block in image"
                                style={{
                                  width: 240,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                      )}

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        h="40px"
                      >
                        <Box display="flex" alignItems="center">
                          <Text
                            marginLeft="10px"
                            fontWeight="bold"
                            marginBottom="0px"
                            width={blockExtractor ? "95px" : "auto"}
                          >
                            {clHeader?.blockExtractor
                              ? "Value type"
                              : " Extract type"}
                          </Text>
                          {clHeader?.blockExtractor ? (
                            <Tooltip
                              interactive
                              title={
                                <a
                                  href="https://sway.office.com/VLwsB3DnSARHScxd?ref=Link"
                                  style={{ color: "white" }}
                                  target="_blank"
                                >
                                  Select this based on the type of the block
                                  extracted.. (Click for more information)
                                </a>
                              }
                              placement="right"
                            >
                              <img
                                src={Help}
                                style={{ height: 12, marginLeft: 6 }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              interactive
                              title={
                                <a
                                  href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                  style={{ color: "white" }}
                                  target="_blank"
                                >
                                  Select this based on how the value and the
                                  identifier is placed. Click for more
                                  information
                                </a>
                              }
                              placement="right"
                            >
                              <img
                                src={Help}
                                style={{ height: 12, marginLeft: 6 }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                        <Box>
                          <select
                            className={
                              claimHeaderError[index] ? "borderBlink" : ""
                            }
                            onChange={(e) => {
                              const values = [...claimHeader];
                              clHeader?.blockExtractor
                                ? (values[index].valueType = e.target.value)
                                : (values[index].extractType = e.target.value);
                              if (clHeader?.blockExtractor) {
                                if (values[index].values.length <= 0) {
                                  alert("please enter value in value block");
                                } else {
                                  if (e.target.value === "text") {
                                    let claimHeaderErrorStatus =
                                      claimHeaderError;
                                    claimHeaderErrorStatus[index] = false;
                                    setClaimHeaderError(claimHeaderErrorStatus);
                                  }
                                  if (e.target.value === "amount($)") {
                                    let amountRegex =
                                      /^[$S8*]*\d+(,?\d+)*[. ]?\d+$/;
                                    let checkBlock1 = true;
                                    if (values[index]?.values?.length >= 0) {
                                      checkBlock1 = amountRegex.test(
                                        claimHeader[index]?.values
                                      );
                                    }
                                    if (!checkBlock1) {
                                      values[index].valueType = "text";
                                      let claimHeaderErrorStatus =
                                        claimHeaderError;
                                      claimHeaderErrorStatus[index] = true;
                                      setClaimHeaderError(
                                        claimHeaderErrorStatus
                                      );
                                      alert("Select valid value type");
                                    } else {
                                      let claimHeaderErrorStatus =
                                        claimHeaderError;
                                      claimHeaderErrorStatus[index] = false;
                                      setClaimHeaderError(
                                        claimHeaderErrorStatus
                                      );
                                    }
                                  }
                                  Object.keys(valueTypeRegexData).find(
                                    (key) => {
                                      if (
                                        key === e.target.value.toUpperCase()
                                      ) {
                                        if (e.target.value === "date") {
                                          let checkDate = matchInArray(
                                            claimHeader[index]?.values,
                                            valueTypeRegexData[key]
                                          );
                                          if (!checkDate) {
                                            values[index].valueType = "text";
                                            let claimHeaderErrorStatus =
                                              claimHeaderError;
                                            claimHeaderErrorStatus[
                                              index
                                            ] = true;
                                            setClaimHeaderError(
                                              claimHeaderErrorStatus
                                            );
                                            alert("Select valid value type");
                                          } else {
                                            let claimHeaderErrorStatus =
                                              claimHeaderError;
                                            claimHeaderErrorStatus[
                                              index
                                            ] = false;
                                            setClaimHeaderError(
                                              claimHeaderErrorStatus
                                            );
                                          }
                                        }
                                      }
                                    }
                                  );
                                }
                              }

                              dispatch(saveClaimHeader(values));
                            }}
                            value={
                              clHeader?.blockExtractor
                                ? clHeader?.valueType
                                : clHeader?.extractType
                            }
                            style={{
                              width: 250,
                              fontWeight: "bold",
                              padding: 3,
                              marginRight: 30,
                              outline: "0px",
                              border: claimHeaderError[index]
                                ? "2px solid red"
                                : "1px solid gray",
                            }}
                          >
                            {clHeader?.blockExtractor
                              ? ["text", "date", "amount($)"].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))
                              : ["form", "above", "below"].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                          </select>
                        </Box>
                      </Box>
                    </Box>

                    {/* advanced */}
                    {!clHeader?.blockExtractor && (
                      <Box
                        background="#DEDEDE 0% 0% no-repeat padding-box;"
                        borderRadius="5px"
                        margin="10px"
                        h="auto"
                        padding="5px"
                        cursor="pointer"
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          padding="3px"
                          onClick={(e) => {
                            e.stopPropagation();
                            const values = [...claimHeader];
                            values[index].isAdvanced =
                              !values[index].isAdvanced;
                            //                            setClaimHeader(values);
                            dispatch(saveClaimHeader(values));
                          }}
                        >
                          <Text
                            marginLeft="10px"
                            fontWeight="bold"
                            font={headingFont}
                            marginBottom="0px"
                            padding="3px 0"
                          >
                            ADVANCED
                          </Text>

                          <img
                            src={clHeader?.isAdvanced ? Up : Down}
                            style={{ marginRight: 20, cursor: "pointer" }}
                            alt="toggle"
                          />
                        </Box>
                        {clHeader?.isAdvanced && (
                          <>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  // width="215px"
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  End Identifier
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      Anything between the identifier and end
                                      identifier is extracted as the value. Used
                                      for form type of value extraction. Click
                                      for more information
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <Tooltip title={clHeader?.endIdentifier}>
                                  <input
                                    onClick={() => {
                                      dispatch(
                                        claimHeaderActive({
                                          name: `claimHeaderEndIdentifierActive${
                                            index + 1
                                          }`,
                                        })
                                      );
                                    }}
                                    onChange={(e) => {
                                      const values = [...claimHeader];
                                      if (
                                        values[index]?.endIdentifier?.length <=
                                          0 ||
                                        values[index]?.data_end_identifier
                                          ?.length <= 0
                                      ) {
                                        alert(
                                          "please select value from image to proceed"
                                        );
                                        e.preventDefault();
                                      } else {
                                        values[index].endIdentifier =
                                          e.target.values;
                                        if (!e.target.value) {
                                          values[index].data_end_identifier =
                                            {};
                                        } else if (
                                          e.target.value &&
                                          !_.isEmpty(
                                            values[index].data_end_identifier
                                          )
                                        ) {
                                          values[index].data_end_identifier[
                                            "Text"
                                          ] = e.target.value;
                                        }
                                        //setClaimHeader(values);
                                        dispatch(saveClaimHeader(values));
                                      }
                                    }}
                                    value={clHeader?.endIdentifier}
                                    type="text"
                                    placeholder="click on a block in image"
                                    style={{
                                      width: 240,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  ID limit
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      If there are multiple occurrences of the
                                      identifier, The nth (integer value
                                      specified) occurrence will be chosen as
                                      the identifier block
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <input
                                  type="number"
                                  min="1"
                                  onChange={(e) => {
                                    const vals = [...claimHeader];
                                    vals[index].idlimit = e.target.value;
                                    //setClaimHeader(vals);
                                    dispatch(saveClaimHeader(vals));
                                  }}
                                  value={clHeader?.idlimit}
                                  style={{
                                    width: 60,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box w="215px" display="flex" alignItems="center">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  Index range
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      From the list of extracted blocks, blocks
                                      from index x (inclusive) till index y
                                      (exclusive) will be captured.
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box
                                marginRight="10px"
                                display="flex"
                                alignItems="center"
                              >
                                <input
                                  type="number"
                                  min="-10"
                                  onChange={(e) => {
                                    const vals = [...claimHeader];
                                    vals[index].indexRange1 = e.target.value;
                                    // setClaimHeader(vals);
                                    dispatch(saveClaimHeader(vals));
                                  }}
                                  value={clHeader?.indexRange1}
                                  style={{
                                    width: 60,
                                    height: 24,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                    marginRight: 10,
                                  }}
                                />
                                <Text fontStyle="italic"> To </Text>
                                <input
                                  type="number"
                                  max="10"
                                  onChange={(e) => {
                                    const vals = [...claimHeader];
                                    vals[index].indexRange2 = e.target.value;
                                    //setClaimHeader(vals);
                                    dispatch(saveClaimHeader(vals));
                                  }}
                                  value={clHeader?.indexRange2}
                                  style={{
                                    width: 60,
                                    height: 24,
                                    marginLeft: 10,

                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box display="flex" alignItems="center" w="215px">
                                <Text
                                  marginLeft="10px"
                                  font={labelFont}
                                  textColor={labelColor}
                                  marginBottom="0px"
                                >
                                  No. of blocks
                                </Text>
                                <Tooltip
                                  interactive
                                  title={
                                    <a
                                      href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                      target="_blank"
                                      style={{ color: "white" }}
                                    >
                                      It is used when the end identifier is not
                                      found. By default, it is 1.If there is a
                                      specific number of blocks that you need
                                      that has to be extracted after the
                                      identifier then the number of blocks field
                                      is changed.(Click for more information)
                                    </a>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={Help}
                                    style={{ height: 12, marginLeft: 6 }}
                                    alt="help"
                                  />
                                </Tooltip>
                              </Box>
                              <Box marginRight="10px">
                                <input
                                  type="number"
                                  min="1"
                                  defaultValue="1"
                                  onChange={(e) => {
                                    const vals = [...claimHeader];
                                    if (e.target.value == "0") return;
                                    else
                                      vals[index].noOfBlocks = e.target.value;
                                    // /setClaimHeader(vals);
                                    dispatch(saveClaimHeader(vals));
                                  }}
                                  value={clHeader?.noOfBlocks}
                                  style={{
                                    width: 60,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>

        {/* claim fields */}
        <Box
          h="auto"
          margin="20px"
          background="transparent linear-gradient(180deg, #E5E5E5 0%, #A8A8A8 100%) 0% 0% no-repeat padding-box;"
          borderRadius="6px"
          padding="4px 5px"
        >
          <Box padding="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={handleShowClaimFields}
              cursor="pointer"
            >
              <Text fontWeight={"bolder"} textColor="#57565B" margin="0">
                Claim Fields
              </Text>
              <Box display="flex" alignItems="center">
                <img
                  src={Add}
                  style={{ marginLeft: 20, cursor: "pointer", height: 20 }}
                  onClick={addClaimFields}
                  alt="add"
                />
                <img
                  src={showClaimFields ? Up : Down}
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  alt="show"
                />
              </Box>
            </Box>
            {showClaimFields && (
              <>
                {claimFields.map((clFields, index) => (
                  <Box
                    key={index}
                    id={`claimFields${index}`}
                    background="white"
                    b="1px solid black"
                    h="auto"
                    paddingTop="10px"
                    margin="10px 0px"
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <Box marginRight={!clFields?.isAdd ? "20px" : 0}>
                        <button onClick={() => handleResetFields(index)}>
                          Reset
                        </button>
                      </Box>
                      <img
                        src={Add}
                        style={{
                          marginLeft: 20,
                          cursor: "pointer",
                          height: 20,
                          marginRight: 10,
                          display: clFields?.isAdd ? "flex" : "none",
                        }}
                        onClick={addClaimFields}
                        alt="add"
                      />

                      <img
                        src={Cross}
                        alt="delete"
                        style={{
                          height: 13,
                          width: 13,
                          marginRight: 10,
                          marginBottom: 10,
                          cursor: "pointer",
                          display: clFields?.isClose ? "flex" : "none",
                        }}
                        onClick={(event) =>
                          handleDeleteClaimFields(index, event)
                        }
                      />
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      margin="5px 10px"
                    >
                      <Box display="flex" alignItems="center">
                        <Tooltip
                          title={
                            clFields.dropdown ? clFields.dropdown : "empty"
                          }
                        >
                          <SelectPicker
                            // searchable={false}
                            onClean={() => {
                              let key;

                              selectedClaimFieldsOptions.forEach((item) => {
                                key = Object.keys(item);

                                if (key[0] / 1 === index) {
                                  delete item[key];
                                }
                              });
                              let newOptions =
                                selectedClaimFieldsOptions.filter((item) => {
                                  if (Object.keys(item).length > 0) return true;
                                });

                              dispatch(selectedClaimFeilds(newOptions));
                              const vals = [...claimFields];
                              vals[index].dropdown = null;
                              vals[index].json = null;
                            }}
                            value={clFields.dropdown}
                            disabledItemValues={claimFieldsDisabled}
                            onChange={(v) => {
                              const values = [...claimFields];

                              if (!values[index].dropdown) {
                                values[index].dropdown = v;

                                if (
                                  v.includes("Name") &&
                                  v !== "Payee Name In EOB (C6)"
                                )
                                  values[index].isNameType = true;
                                else values[index].isNameType = false;
                                const json = claimFieldsOptions.find(
                                  (item) => item.value === v
                                );
                                values[index].json = json.JsonKey;
                                dispatch(saveClaimFields(values));

                                dispatch(
                                  selectedClaimFeilds([
                                    ...selectedClaimFieldsOptions,
                                    { [index]: v },
                                  ])
                                );
                              }
                            }}
                            data={claimFieldsOptions}
                            style={{ width: 344 }}
                            renderMenu={(menu) => {
                              if (claimFieldsOptions.length === 0) {
                                return (
                                  <p
                                    style={{
                                      padding: 4,
                                      color: "#999",
                                      textAlign: "center",
                                    }}
                                  >
                                    <i
                                      className="fa fa-circle-o-notch fa-spin"
                                      style={{ fontSize: "16px" }}
                                    ></i>{" "}
                                    &nbsp; Loading...
                                  </p>
                                );
                              }
                              return menu;
                            }}
                          />
                        </Tooltip>
                      </Box>

                      <Box>
                        <input
                          type="checkbox"
                          id="mandatory"
                          checked={clFields?.mandatory}
                          onChange={(e) => {
                            const values = [...claimFields];
                            values[index].mandatory = e.target.checked;
                            dispatch(saveClaimFields(values));
                          }}
                        />
                        <label style={{ fontStyle: "italic" }}>Mandatory</label>
                      </Box>
                    </Box>
                    <Box
                      margin="10px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        w="-webkit-fill-available"
                        onClick={() => {
                          const values = [...claimFields];
                          values[index].keyValueExtractor = true;
                          values[index].indexBasedExtractor = false;
                          dispatch(saveClaimFields(values));
                        }}
                        cursor="pointer"
                        b="1px solid black"
                        br="none"
                        padding="10px"
                        background={
                          clFields?.keyValueExtractor &&
                          !clFields?.indexBasedExtractor
                            ? "white"
                            : "lightgray"
                        }
                        justifyContent="space-between"
                        alignItems="center"
                        display="flex"
                        textAlign="center"
                      >
                        <span>Key value extractor</span>
                      </Box>
                      <Box
                        w="-webkit-fill-available"
                        onClick={() => {
                          const values = [...claimFields];
                          values[index].keyValueExtractor = false;
                          values[index].indexBasedExtractor = true;

                          dispatch(saveClaimFields(values));
                        }}
                        cursor="pointer"
                        b={
                          clFields?.keyValueExtractor
                            ? "1px solid #ACACAC"
                            : "1px solid black"
                        }
                        bl="none"
                        padding="10px"
                        background={
                          clFields?.indexBasedExtractor ? "white" : "lightgray"
                        }
                        justifyContent="space-between"
                        alignItems="center"
                        display="flex"
                        textAlign="center"
                      >
                        <span style={{ color: "black" }}>
                          Index Based extractor
                        </span>
                      </Box>
                    </Box>
                    {clFields.keyValueExtractor ? (
                      <>
                        <Box
                          background="#DEDEDE 0% 0% no-repeat padding-box;"
                          borderRadius="5px"
                          padding="10px 0px"
                          margin="10px 10px"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                              >
                                Identifier
                              </Text>
                              {/* <Tooltip title="tooltip" placement="right"> */}
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Identifier is the label to the required
                                    value. For Eg: AMOUNT PAID is the identifier
                                    to extract AMOUNT PAID: $$1203.23**
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </Box>

                            <Box display="flex" alignItems="center">
                              <Tooltip title={clFields?.identifier}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      claimFieldsActive({
                                        name: `claimFieldsIdentifierActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  placeholder="click on a block in image"
                                  onChange={(e) => {
                                    const values = [...claimFields];
                                    if (values[index].identifier.length <= 0) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[index].identifier = e.target.value;
                                      if (!e.target.value) {
                                        values[index].data_identifier = {};
                                      } else if (
                                        e.target.value &&
                                        !_.isEmpty(
                                          values[index].data_identifier
                                        )
                                      ) {
                                        values[index].data_identifier["Text"] =
                                          e.target.value;
                                      }

                                      dispatch(saveClaimFields(values));

                                      dispatch(
                                        claimFieldsText({
                                          name: `claimFieldsIdentifier${
                                            index + 1
                                          }`,
                                          text: e.target.value,
                                        })
                                      );
                                    }
                                  }}
                                  value={clFields?.identifier}
                                  type="text"
                                  style={{
                                    width: 221,
                                    marginRight: 74,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                          {/* value */}
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center" w="205px">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                                marginBottom="0px"
                              >
                                Value
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Anything between the identifier and end
                                    identifier is extracted as the value. Used
                                    for form type of value extraction. Click for
                                    more information
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </Box>
                            <Box marginRight="10px">
                              <Tooltip title={clFields?.value}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      claimFieldsActive({
                                        name: `claimFieldsvalueActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  onChange={(e) => {
                                    const values = [...claimFields];
                                    if (
                                      values[index]?.value?.length <= 0 ||
                                      values[index]?.data_value?.length <= 0
                                    ) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[index].value = e.target.value;
                                      if (!e.target.value) {
                                        values[index].data_value = {};
                                      } else if (
                                        e.target.value &&
                                        !_.isEmpty(values[index].data_value)
                                      ) {
                                        values[index].data_value["Text"] =
                                          e.target.value;
                                      }
                                      //setClaimHeader(values);
                                      dispatch(
                                        claimFieldsText({
                                          name: `claimFieldsvalue${
                                            index + 1
                                          }`,
                                          text: e.target.value,
                                        })
                                      );
                                      dispatch(saveClaimFields(values));
                                    }
                                  }}
                                  value={clFields?.value}
                                  type="text"
                                  placeholder="click on a block in image"
                                  style={{
                                    width: 240,
                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                              >
                                Extract type
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                    style={{ color: "white" }}
                                    target="_blank"
                                  >
                                    Select this based on how the value and the
                                    identifier is placed. Click for more
                                    information
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <select
                                onChange={(e) => {
                                  const values = [...claimFields];
                                  values[index].extractType = e.target.value;
                                  dispatch(saveClaimFields(values));
                                }}
                                value={clFields?.extractType}
                                style={{
                                  width: 250,
                                  fontWeight: "bold",
                                  padding: 3,
                                  marginRight: 46,
                                }}
                              >
                                {["form", "above", "below"].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>

                          <Box
                            display={clFields.isNameType ? "flex" : "none"}
                            //display={"flex"}
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box w="218px" display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                textColor={labelColor}
                                marginBottom="0px"
                              >
                                Name type
                              </Text>
                              <Tooltip title="tooltip" placement="right">
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </Box>

                            <select
                              onChange={(e) => {
                                if (e.target.value === "Select") return;
                                const values = [...claimFields];

                                values[index].nameType = e.target.value;
                                dispatch(saveClaimFields(values));
                              }}
                              value={clFields?.nameType}
                              style={{
                                width: 250,
                                fontWeight: "bold",
                                padding: 3,
                                marginRight: 72,
                              }}
                            >
                              {[
                                "Select",
                                "first_middle_last",
                                "last_first_middle",
                                "first_last_middle",
                              ].map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </Box>
                        </Box>

                        {/* advanced */}
                        <Box
                          background="#DEDEDE 0% 0% no-repeat padding-box;"
                          borderRadius="5px"
                          margin="10px"
                          h="auto"
                          padding="5px"
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            h="30px"
                            cursor="pointer"
                            onClick={() => {
                              const values = [...claimFields];
                              values[index].isAdvanced =
                                !values[index].isAdvanced;
                              dispatch(saveClaimFields(values));
                            }}
                          >
                            <Text
                              marginLeft="10px"
                              fontWeight="bold"
                              font={headingFont}
                              marginBottom="0px"
                              padding="3px 0"
                            >
                              ADVANCED
                            </Text>

                            <img
                              src={clFields?.isAdvanced ? Up : Down}
                              style={{ marginRight: 20, cursor: "pointer" }}
                            />
                          </Box>
                          {clFields?.isAdvanced && (
                            <Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  w="215px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    font={labelFont}
                                    // width="215px"
                                    marginBottom="0px"
                                    textColor={labelColor}
                                  >
                                    End Identifier
                                  </Text>
                                  <Tooltip
                                    interactive
                                    title={
                                      <a
                                        href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                        target="_blank"
                                        style={{ color: "white" }}
                                      >
                                        Anything between the identifier and end
                                        identifier is extracted as the value.
                                        Used only for form type of value
                                        extraction.
                                      </a>
                                    }
                                    placement="right"
                                  >
                                    <img
                                      src={Help}
                                      style={{ height: 12, marginLeft: 6 }}
                                    />
                                  </Tooltip>
                                </Box>
                                <Box marginRight="10px">
                                  <Tooltip title={clFields?.endIdentifier}>
                                    <input
                                      onClick={() => {
                                        dispatch(
                                          claimFieldsActive({
                                            name: `claimFieldsEndIdentifierActive${
                                              index + 1
                                            }`,
                                          })
                                        );
                                      }}
                                      onChange={(e) => {
                                        const values = [...claimFields];
                                        if (
                                          values[index].endIdentifier.length <=
                                          0
                                        ) {
                                          alert(
                                            "please select value from image to proceed"
                                          );
                                          e.preventDefault();
                                        } else {
                                          values[index].endIdentifier =
                                            e.target.value;
                                          if (!e.target.value) {
                                            values[index].data_end_identifier =
                                              {};
                                          } else if (
                                            e.target.value &&
                                            !_.isEmpty(
                                              values[index].data_end_identifier
                                            )
                                          ) {
                                            values[index].data_end_identifier[
                                              "Text"
                                            ] = e.target.value;
                                          }

                                          dispatch(saveClaimFields(values));

                                          dispatch(
                                            claimFieldsText({
                                              name: `claimFieldsEndIdentifier${
                                                index + 1
                                              }`,
                                              text: e.target.value,
                                            })
                                          );
                                        }
                                      }}
                                      value={clFields?.endIdentifier}
                                      type="text"
                                      placeholder="click on a block in image"
                                      style={{
                                        width: 240,
                                        fontStyle: "italic",
                                        padding: 4,
                                        border: "1px solid gray",
                                        borderRadius: 4,
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                              </Box>

                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  w="215px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    font={labelFont}
                                    textColor={labelColor}
                                    marginBottom="0px"
                                  >
                                    ID limit
                                  </Text>
                                  <Tooltip
                                    interactive
                                    title={
                                      <a
                                        href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                        target="_blank"
                                        style={{ color: "white" }}
                                      >
                                        If there are multiple occurrences of the
                                        identifier, The nth (integer value
                                        specified) occurrence will be chosen as
                                        the identifier block
                                      </a>
                                    }
                                    placement="right"
                                  >
                                    <img
                                      src={Help}
                                      style={{ height: 12, marginLeft: 6 }}
                                      alt="help"
                                    />
                                  </Tooltip>
                                </Box>
                                <Box marginRight="10px">
                                  <input
                                    onChange={(e) => {
                                      const values = [...claimFields];
                                      values[index].idlimit = e.target.value;
                                      dispatch(saveClaimFields(values));
                                    }}
                                    value={clFields?.idlimit}
                                    type="number"
                                    min="1"
                                    defaultValue="1"
                                    style={{
                                      width: 60,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>

                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  w="215px"
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Text
                                    marginLeft="10px"
                                    font={labelFont}
                                    marginBottom="0px"
                                  >
                                    Index range
                                  </Text>
                                  <Tooltip
                                    interactive
                                    title={
                                      <a
                                        href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                        target="_blank"
                                        style={{ color: "white" }}
                                      >
                                        From the list of extracted blocks,
                                        blocks from index x (inclusive) till
                                        index y (exclusive) will be captured.on
                                      </a>
                                    }
                                    placement="right"
                                  >
                                    <img
                                      src={Help}
                                      style={{ height: 12, marginLeft: 6 }}
                                      alt="help"
                                    />
                                  </Tooltip>
                                </Box>
                                <Box
                                  marginRight="10px"
                                  display="flex"
                                  alignItems="center"
                                >
                                  <input
                                    onChange={(e) => {
                                      const values = [...claimFields];
                                      values[index].indexRange1 =
                                        e.target.value;
                                      dispatch(saveClaimFields(values));
                                    }}
                                    value={clFields?.indexRange1}
                                    type="number"
                                    min="-10"
                                    style={{
                                      width: 60,
                                      height: 24,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                      marginRight: 10,
                                    }}
                                  />
                                  <Text fontStyle="italic" marginBottom="0px">
                                    {" "}
                                    To{" "}
                                  </Text>
                                  <input
                                    onChange={(e) => {
                                      const values = [...claimFields];
                                      values[index].indexRange2 =
                                        e.target.value;
                                      dispatch(saveClaimFields(values));
                                    }}
                                    value={clFields?.indexRange2}
                                    type="number"
                                    defaultValue="0"
                                    style={{
                                      width: 60,
                                      height: 24,
                                      marginLeft: 10,

                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>

                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                                h="40px"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  w="215px"
                                >
                                  <Text
                                    marginLeft="10px"
                                    font={labelFont}
                                    textColor={labelColor}
                                    marginBottom="0px"
                                  >
                                    No. of blocks
                                  </Text>
                                  <Tooltip
                                    interactive
                                    title={
                                      <a
                                        href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                        target="_blank"
                                        style={{ color: "white" }}
                                      >
                                        It is used when the end identifier is
                                        not found. By default, it is 1.If there
                                        is a specific number of blocks that you
                                        need that has to be extracted after the
                                        identifier then the number of blocks
                                        field is changed.(Click for more
                                        information)
                                      </a>
                                    }
                                    placement="right"
                                  >
                                    <img
                                      src={Help}
                                      style={{ height: 12, marginLeft: 6 }}
                                      alt="help"
                                    />
                                  </Tooltip>
                                </Box>
                                <Box marginRight="10px">
                                  <input
                                    onChange={(e) => {
                                      const values = [...claimFields];
                                      if (e.target.value == "0") return;
                                      else
                                        values[index].noOfBlocks =
                                          e.target.value;
                                      dispatch(saveClaimFields(values));
                                    }}
                                    value={clFields?.noOfBlocks}
                                    type="number"
                                    min="1"
                                    style={{
                                      width: 60,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          background="#DEDEDE 0% 0% no-repeat padding-box;"
                          borderRadius="5px"
                          padding="10px 0px"
                          margin="10px 10px"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center" w="215px">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                              >
                                Blocks
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/pl2E4usHrvWUukNP?play"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    Select the block that has to be
                                    configured.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </Box>

                            <Box display="flex" alignItems="center">
                              <Tooltip title={clFields?.block}>
                                <input
                                  onClick={() => {
                                    dispatch(
                                      claimFieldsActive({
                                        name: `claimFieldsBlockActive${
                                          index + 1
                                        }`,
                                      })
                                    );
                                  }}
                                  placeholder="click on a block in image"
                                  onChange={(e) => {
                                    const values = [...claimFields];
                                    values[index].valueType = "text";
                                    if (values[index].block.length <= 0) {
                                      alert(
                                        "please select value from image to proceed"
                                      );
                                      e.preventDefault();
                                    } else {
                                      values[index].block = e.target.value;
                                      if (!e.target.value) {
                                        values[index].data = {};
                                      } else if (
                                        e.target.value &&
                                        !_.isEmpty(values[index].data)
                                      ) {
                                        values[index].data["Text"] =
                                          e.target.value;
                                      }

                                      dispatch(saveClaimFields(values));

                                      dispatch(
                                        claimFieldsText({
                                          name: `claimFieldsBlock${index + 1}`,
                                          text: e.target.value,
                                        })
                                      );
                                    }
                                  }}
                                  value={clFields?.block}
                                  type="text"
                                  style={{
                                    width: 221,

                                    fontStyle: "italic",
                                    padding: 4,
                                    border: "1px solid gray",
                                    borderRadius: 4,
                                  }}
                                />
                              </Tooltip>
                              {/* <img src={Add} style={{ height: 20, marginRight: 10 }} /> */}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center" w="215px">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                              >
                                Line Number
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/pl2E4usHrvWUukNP?play"
                                    style={{ color: "white" }}
                                    target="_blank"
                                  >
                                    The line number in which the block is
                                    present. Line number starts from 0.(Click
                                    for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <input
                                onChange={(e) => {
                                  const values = [...claimFields];
                                  values[index].line = e.target.value;
                                  dispatch(saveClaimFields(values));
                                }}
                                value={clFields?.line}
                                type="number"
                                min="-100"
                                defaultValue="0"
                                style={{
                                  width: 60,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box w="215px" display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                //font={labelFont}
                                //textColor={labelColor}
                                marginBottom="0px"
                                fontWeight="bold"
                              >
                                Index range
                              </Text>
                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/pl2E4usHrvWUukNP?play"
                                    target="_blank"
                                    style={{ color: "white" }}
                                  >
                                    - This is the position of the block in that
                                    particular line. Range contains two
                                    values.(Click for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                  alt="help"
                                />
                              </Tooltip>
                            </Box>
                            <Box
                              marginRight="10px"
                              display="flex"
                              alignItems="center"
                            >
                              <input
                                onChange={(e) => {
                                  const values = [...claimFields];
                                  values[index].indexRangeIndexExtractor1 =
                                    e.target.value;
                                  dispatch(saveClaimFields(values));
                                }}
                                value={clFields?.indexRangeIndexExtractor1}
                                type="number"
                                min="-10"
                                style={{
                                  width: 60,
                                  height: 24,
                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                  marginRight: 10,
                                }}
                              />
                              <Text fontStyle="italic" marginBottom="0px">
                                {" "}
                                To{" "}
                              </Text>
                              <input
                                onChange={(e) => {
                                  const values = [...claimFields];
                                  values[index].indexRangeIndexExtractor2 =
                                    e.target.value;
                                  dispatch(saveClaimFields(values));
                                }}
                                value={clFields?.indexRangeIndexExtractor2}
                                type="number"
                                defaultValue="0"
                                style={{
                                  width: 60,
                                  height: 24,
                                  marginLeft: 10,

                                  fontStyle: "italic",
                                  padding: 4,
                                  border: "1px solid gray",
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box display="flex" alignItems="center" w="215px">
                              <Text
                                marginLeft="10px"
                                fontWeight="bold"
                                marginBottom="0px"
                              >
                                Value type
                              </Text>

                              <Tooltip
                                interactive
                                title={
                                  <a
                                    href="https://sway.office.com/pl2E4usHrvWUukNP?play"
                                    style={{ color: "white" }}
                                    target="_blank"
                                  >
                                    The available value types are text, amount,
                                    date, number, regex and alphanumeric. (Click
                                    for more information)
                                  </a>
                                }
                                placement="right"
                              >
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <select
                                className={
                                  claimFieldsError[index] ? "borderBlink" : ""
                                }
                                onChange={(e) => {
                                  const values = [...claimFields];
                                  values[index].valueType = e.target.value;
                                  if (e.target.value === "text") {
                                    let claimFieldErrorStatus =
                                      claimFieldsError;
                                    claimFieldErrorStatus[index] = false;
                                    setClaimFieldsError(claimFieldErrorStatus);
                                  }
                                  if (e.target.value === "amount($)") {
                                    let amountRegex =
                                      /^[$S8*]*\d+(,?\d+)*[. ]?\d+$/;
                                    let checkBlock1 = true;
                                    if (values[index]?.block?.length >= 0) {
                                      checkBlock1 = amountRegex.test(
                                        claimFields[index]?.block
                                      );
                                    }
                                    if (!checkBlock1) {
                                      alert("Select valid value type");
                                      values[index].valueType = "text";
                                      let claimFieldErrorStatus =
                                        claimFieldsError;
                                      claimFieldErrorStatus[index] = true;
                                      setClaimFieldsError(
                                        claimFieldErrorStatus
                                      );
                                    }
                                  } else {
                                    Object.keys(valueTypeRegexData).find(
                                      (key) => {
                                        if (
                                          key === e.target.value.toUpperCase()
                                        ) {
                                          if (e.target.value === "date") {
                                            let checkDate = matchInArray(
                                              claimFields[index]?.block,
                                              valueTypeRegexData[key]
                                            );
                                            if (!checkDate) {
                                              alert("Select valid value type");
                                              values[index].valueType = "text";
                                              let claimFieldErrorStatus =
                                                claimFieldsError;
                                              claimFieldErrorStatus[
                                                index
                                              ] = true;
                                              setClaimFieldsError(
                                                claimFieldErrorStatus
                                              );
                                            } else {
                                              let claimFieldErrorStatus =
                                                claimFieldsError;
                                              claimFieldErrorStatus[
                                                index
                                              ] = false;
                                              setClaimFieldsError(
                                                claimFieldErrorStatus
                                              );
                                            }
                                          } else {
                                            let regex = new RegExp(
                                              valueTypeRegexData[key]
                                            );
                                            let checkBlock1 = true;
                                            if (
                                              claimFields[index]?.block
                                                ?.length > 0
                                            ) {
                                              checkBlock1 = regex.test(
                                                claimFields[index]?.block
                                              );
                                            }
                                            if (!checkBlock1) {
                                              alert("Select valid value type");
                                              values[index].valueType = "text";
                                              let claimFieldErrorStatus =
                                                claimFieldsError;
                                              claimFieldErrorStatus[
                                                index
                                              ] = true;
                                              setClaimFieldsError(
                                                claimFieldErrorStatus
                                              );
                                            } else {
                                              let claimFieldErrorStatus =
                                                claimFieldsError;
                                              claimFieldErrorStatus[
                                                index
                                              ] = false;
                                              setClaimFieldsError(
                                                claimFieldErrorStatus
                                              );
                                            }
                                          }
                                        }
                                      }
                                    );
                                  }

                                  dispatch(saveClaimFields(values));
                                }}
                                value={clFields?.valueType}
                                style={{
                                  width: 150,
                                  fontWeight: "bold",
                                  padding: 3,
                                  marginRight: 30,
                                  outline: "0px",
                                  border: claimFieldsError[index]
                                    ? "2px solid red"
                                    : "1px solid gray",
                                }}
                              >
                                {[
                                  "text",
                                  "date",
                                  "amount($)",
                                  "number",
                                  "regex",
                                  "alphanumeric",
                                ].map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>
                          {clFields.valueType === "regex" && (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box
                                display="flex"
                                justifyContent="felx-start"
                                alignItems="center"
                                w="215px"
                              >
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0px"
                                >
                                  Regex
                                  <span style={{ color: "red", fontSize: 20 }}>
                                    *
                                  </span>
                                </Text>
                              </Box>
                              <Box>
                                <Tooltip title={clFields?.regex}>
                                  <input
                                    onChange={(e) => {
                                      const values = [...claimFields];
                                      values[index].regex = e.target.value;
                                      dispatch(saveClaimFields(values));
                                    }}
                                    value={clFields?.regex}
                                    type="text"
                                    style={{
                                      width: 100,

                                      marginRight: 20,
                                      fontStyle: "italic",
                                      padding: 4,
                                      border: "1px solid gray",
                                      borderRadius: 4,
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            </Box>
                          )}
                          {clFields.valueType === "date" && (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              h="40px"
                            >
                              <Box
                                display="flex"
                                justifyContent="felx-start"
                                alignItems="center"
                                w="215px"
                              >
                                <Text
                                  marginLeft="10px"
                                  fontWeight="bold"
                                  marginBottom="0px"
                                >
                                  Date Format
                                  <span style={{ color: "red", fontSize: 20 }}>
                                    *
                                  </span>
                                </Text>
                              </Box>
                              <Box>
                                <Tooltip title={clFields?.date}>
                                  <select
                                    onChange={(e) => {
                                      const values = [...claimFields];
                                      values[index].date = e.target.value;
                                      dispatch(saveClaimFields(values));
                                    }}
                                    value={clFields?.date}
                                    style={{
                                      width: 120,
                                      fontWeight: "bold",
                                      padding: 3,
                                    }}
                                  >
                                    {dateOptions.map((item) => (
                                      <option key={item} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                </Tooltip>
                              </Box>
                            </Box>
                          )}
                          <Box
                            display={clFields.isNameType ? "flex" : "none"}
                            //display={"flex"}
                            alignItems="center"
                            justifyContent="flex-start"
                            h="40px"
                          >
                            <Box w="218px" display="flex" alignItems="center">
                              <Text
                                marginLeft="10px"
                                font={labelFont}
                                textColor={labelColor}
                                marginBottom="0px"
                              >
                                Name type
                              </Text>
                              <Tooltip title="tooltip" placement="right">
                                <img
                                  src={Help}
                                  style={{ height: 12, marginLeft: 6 }}
                                />
                              </Tooltip>
                            </Box>

                            <select
                              onChange={(e) => {
                                if (e.target.value === "Select") return;
                                const values = [...claimFields];

                                values[index].nameType = e.target.value;
                                dispatch(saveClaimFields(values));
                              }}
                              value={clFields?.nameType}
                              style={{
                                width: 250,
                                fontWeight: "bold",
                                padding: 3,
                              }}
                            >
                              {[
                                "Select",
                                "first_middle_last",
                                "last_first_middle",
                                "first_last_middle",
                              ].map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </Box>
                        </Box>

                        {/* advanced */}
                        <Box
                          background="#DEDEDE 0% 0% no-repeat padding-box;"
                          borderRadius="5px"
                          margin="10px"
                          h="auto"
                          padding="5px"
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            h="30px"
                            cursor="pointer"
                            onClick={() => {
                              const values = [...claimFields];

                              values[index].isAdvanced =
                                !values[index].isAdvanced;
                              dispatch(saveClaimFields(values));
                            }}
                          >
                            <Text
                              marginLeft="10px"
                              fontWeight="bold"
                              font={headingFont}
                              color={"#262525"}
                              marginBottom="0px"
                              padding="3px 0"
                            >
                              ADVANCED
                            </Text>

                            <img
                              src={clFields?.isAdvanced ? Up : Down}
                              style={{ marginRight: 20, cursor: "pointer" }}
                            />
                          </Box>
                          {clFields?.isAdvanced && (
                            <Box>
                              {clFields.keyValueExtractor ? (
                                <Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    h="40px"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      w="215px"
                                    >
                                      <Text
                                        marginLeft="10px"
                                        font={labelFont}
                                        // width="215px"
                                        marginBottom="0px"
                                        textColor={labelColor}
                                      >
                                        End Identifier
                                      </Text>
                                      <Tooltip
                                        interactive
                                        title={
                                          <a
                                            href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                            target="_blank"
                                            style={{ color: "white" }}
                                          >
                                            Anything between the identifier and
                                            end identifier is extracted as the
                                            value. Used only for form type of
                                            value extraction.
                                          </a>
                                        }
                                        placement="right"
                                      >
                                        <img
                                          src={Help}
                                          style={{ height: 12, marginLeft: 6 }}
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box marginRight="10px">
                                      <Tooltip title={clFields?.endIdentifier}>
                                        <input
                                          onClick={() => {
                                            dispatch(
                                              claimFieldsActive({
                                                name: `claimFieldsEndIdentifierActive${
                                                  index + 1
                                                }`,
                                              })
                                            );
                                          }}
                                          onChange={(e) => {
                                            const values = [...claimFields];
                                            if (
                                              values[index].endIdentifier
                                                .length <= 0
                                            ) {
                                              alert(
                                                "please select value from image to proceed"
                                              );
                                              e.preventDefault();
                                            } else {
                                              values[index].endIdentifier =
                                                e.target.value;
                                              if (!e.target.value) {
                                                values[
                                                  index
                                                ].data_end_identifier = {};
                                              } else if (
                                                e.target.value &&
                                                !_.isEmpty(
                                                  values[index]
                                                    .data_end_identifier
                                                )
                                              ) {
                                                values[
                                                  index
                                                ].data_end_identifier["Text"] =
                                                  e.target.value;
                                              }

                                              dispatch(saveClaimFields(values));

                                              dispatch(
                                                claimFieldsText({
                                                  name: `claimFieldsEndIdentifier${
                                                    index + 1
                                                  }`,
                                                  text: e.target.value,
                                                })
                                              );
                                            }
                                          }}
                                          value={clFields?.endIdentifier}
                                          type="text"
                                          placeholder="click on a block in image"
                                          style={{
                                            width: 240,
                                            fontStyle: "italic",
                                            padding: 4,
                                            border: "1px solid gray",
                                            borderRadius: 4,
                                          }}
                                        />
                                      </Tooltip>
                                    </Box>
                                  </Box>

                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    h="40px"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      w="215px"
                                    >
                                      <Text
                                        marginLeft="10px"
                                        font={labelFont}
                                        textColor={labelColor}
                                        marginBottom="0px"
                                      >
                                        ID limit
                                      </Text>
                                      <Tooltip
                                        interactive
                                        title={
                                          <a
                                            href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                            target="_blank"
                                            style={{ color: "white" }}
                                          >
                                            If there are multiple occurrences of
                                            the identifier, The nth (integer
                                            value specified) occurrence will be
                                            chosen as the identifier block
                                          </a>
                                        }
                                        placement="right"
                                      >
                                        <img
                                          src={Help}
                                          style={{ height: 12, marginLeft: 6 }}
                                          alt="help"
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box marginRight="10px">
                                      <input
                                        onChange={(e) => {
                                          const values = [...claimFields];
                                          values[index].idlimit =
                                            e.target.value;
                                          dispatch(saveClaimFields(values));
                                        }}
                                        value={clFields?.idlimit}
                                        type="number"
                                        min="1"
                                        defaultValue="1"
                                        style={{
                                          width: 60,
                                          fontStyle: "italic",
                                          padding: 4,
                                          border: "1px solid gray",
                                          borderRadius: 4,
                                        }}
                                      />
                                    </Box>
                                  </Box>

                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    h="40px"
                                  >
                                    <Box
                                      w="215px"
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Text
                                        marginLeft="10px"
                                        font={labelFont}
                                        textColor={labelColor}
                                        marginBottom="0px"
                                      >
                                        Index range
                                      </Text>
                                      <Tooltip
                                        interactive
                                        title={
                                          <a
                                            href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                            target="_blank"
                                            style={{ color: "white" }}
                                          >
                                            From the list of extracted blocks,
                                            blocks from index x (inclusive) till
                                            index y (exclusive) will be
                                            captured.on
                                          </a>
                                        }
                                        placement="right"
                                      >
                                        <img
                                          src={Help}
                                          style={{ height: 12, marginLeft: 6 }}
                                          alt="help"
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box
                                      marginRight="10px"
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <input
                                        onChange={(e) => {
                                          const values = [...claimFields];
                                          values[index].indexRange1 =
                                            e.target.value;
                                          dispatch(saveClaimFields(values));
                                        }}
                                        value={clFields?.indexRange1}
                                        type="number"
                                        min="-10"
                                        style={{
                                          width: 60,
                                          height: 24,
                                          fontStyle: "italic",
                                          padding: 4,
                                          border: "1px solid gray",
                                          borderRadius: 4,
                                          marginRight: 10,
                                        }}
                                      />
                                      <Text
                                        fontStyle="italic"
                                        marginBottom="0px"
                                      >
                                        {" "}
                                        To{" "}
                                      </Text>
                                      <input
                                        onChange={(e) => {
                                          const values = [...claimFields];
                                          values[index].indexRange2 =
                                            e.target.value;
                                          dispatch(saveClaimFields(values));
                                        }}
                                        value={clFields?.indexRange2}
                                        type="number"
                                        defaultValue="0"
                                        style={{
                                          width: 60,
                                          height: 24,
                                          marginLeft: 10,

                                          fontStyle: "italic",
                                          padding: 4,
                                          border: "1px solid gray",
                                          borderRadius: 4,
                                        }}
                                      />
                                    </Box>
                                  </Box>

                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    h="40px"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      w="215px"
                                    >
                                      <Text
                                        marginLeft="10px"
                                        font={labelFont}
                                        textColor={labelColor}
                                        marginBottom="0px"
                                      >
                                        No. of blocks
                                      </Text>
                                      <Tooltip
                                        interactive
                                        title={
                                          <a
                                            href="https://sway.office.com/9EM3JokewgOgBu0c?ref=Link"
                                            target="_blank"
                                            style={{ color: "white" }}
                                          >
                                            It is used when the end identifier
                                            is not found. By default, it is 1.If
                                            there is a specific number of blocks
                                            that you need that has to be
                                            extracted after the identifier then
                                            the number of blocks field is
                                            changed.(Click for more information)
                                          </a>
                                        }
                                        placement="right"
                                      >
                                        <img
                                          src={Help}
                                          style={{ height: 12, marginLeft: 6 }}
                                          alt="help"
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box marginRight="10px">
                                      <input
                                        onChange={(e) => {
                                          const values = [...claimFields];
                                          if (e.target.value == "0") return;
                                          else
                                            values[index].noOfBlocks =
                                              e.target.value;
                                          dispatch(saveClaimFields(values));
                                        }}
                                        value={clFields?.noOfBlocks}
                                        type="number"
                                        min="1"
                                        style={{
                                          width: 60,
                                          fontStyle: "italic",
                                          padding: 4,
                                          border: "1px solid gray",
                                          borderRadius: 4,
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              ) : (
                                <Box>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    h="40px"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      w="215px"
                                    >
                                      <Text
                                        marginLeft="10px"
                                        font={labelFont}
                                        // width="215px"
                                        marginBottom="0px"
                                      >
                                        Next Value type
                                      </Text>
                                      <Tooltip
                                        interactive
                                        title={
                                          <a
                                            href="https://sway.office.com/pl2E4usHrvWUukNP?play"
                                            style={{ color: "white" }}
                                          >
                                            The value type of the next
                                            block..(Click for more information)
                                          </a>
                                        }
                                        placement="right"
                                      >
                                        <img
                                          src={Help}
                                          style={{ height: 12, marginLeft: 6 }}
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box marginRight="10px">
                                      <Box>
                                        <select
                                          onChange={(e) => {
                                            const values = [...claimFields];
                                            if (e.target.value === "Select")
                                              values[index].nextValueType = "";
                                            else
                                              values[index].nextValueType =
                                                e.target.value;

                                            dispatch(saveClaimFields(values));
                                          }}
                                          value={clFields?.nextValueType}
                                          style={{
                                            width: 150,
                                            fontWeight: "bold",
                                            padding: 3,
                                            marginRight: 30,
                                          }}
                                        >
                                          {[
                                            "Select",
                                            "text",
                                            "date",
                                            "amount($)",
                                            "number",
                                            "regex",
                                            "alphanumeric",
                                          ].map((item) => (
                                            <option key={item} value={item}>
                                              {item}
                                            </option>
                                          ))}
                                        </select>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {clFields.nextValueType === "regex" && (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      h="40px"
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="felx-start"
                                        alignItems="center"
                                        w="215px"
                                      >
                                        <Text
                                          marginLeft="10px"
                                          fontWeight="bold"
                                          marginBottom="0px"
                                        >
                                          Regex
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: 20,
                                            }}
                                          >
                                            *
                                          </span>
                                        </Text>
                                      </Box>
                                      <Box>
                                        <Tooltip title={clFields?.indexRegex}>
                                          <input
                                            onChange={(e) => {
                                              const values = [...claimFields];
                                              values[index].indexRegex =
                                                e.target.value;
                                              dispatch(saveClaimFields(values));
                                            }}
                                            value={clFields?.indexRegex}
                                            type="text"
                                            style={{
                                              width: 100,

                                              marginRight: 20,
                                              fontStyle: "italic",
                                              padding: 4,
                                              border: "1px solid gray",
                                              borderRadius: 4,
                                            }}
                                          />
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  )}
                                  {clFields.nextValueType === "date" && (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      h="40px"
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="felx-start"
                                        alignItems="center"
                                        w="215px"
                                      >
                                        <Text
                                          marginLeft="10px"
                                          fontWeight="bold"
                                          marginBottom="0px"
                                        >
                                          Date Format
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: 20,
                                            }}
                                          >
                                            *
                                          </span>
                                        </Text>
                                      </Box>
                                      <Box>
                                        <Tooltip
                                          title={clFields?.nextValueDate}
                                        >
                                          <select
                                            onChange={(e) => {
                                              const values = [...claimFields];
                                              values[index].nextValueDate =
                                                e.target.value;
                                              dispatch(saveClaimFields(values));
                                            }}
                                            value={clFields?.nextValueDate}
                                            style={{
                                              width: 120,
                                              fontWeight: "bold",
                                              padding: 3,
                                            }}
                                          >
                                            {dateOptions.map((item) => (
                                              <option key={item} value={item}>
                                                {item}
                                              </option>
                                            ))}
                                          </select>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    h="40px"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      w="215px"
                                    >
                                      <Text
                                        marginLeft="10px"
                                        font={labelFont}
                                        // width="215px"
                                        marginBottom="0px"
                                        // textColor={labelColor}
                                      >
                                        Previous Value type
                                      </Text>
                                      <Tooltip
                                        interactive
                                        title={
                                          <a
                                            href="https://sway.office.com/pl2E4usHrvWUukNP?play"
                                            target="_blank"
                                            style={{ color: "white" }}
                                          >
                                            The value type of the previous
                                            block.(Click for more information)
                                          </a>
                                        }
                                        placement="right"
                                      >
                                        <img
                                          src={Help}
                                          style={{ height: 12, marginLeft: 6 }}
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box marginRight="10px">
                                      <Box>
                                        <select
                                          onChange={(e) => {
                                            const values = [...claimFields];
                                            if (e.target.value === "Select")
                                              values[index].prevValueType = "";
                                            else
                                              values[index].prevValueType =
                                                e.target.value;

                                            dispatch(saveClaimFields(values));
                                          }}
                                          value={clFields?.prevValueType}
                                          style={{
                                            width: 150,
                                            fontWeight: "bold",
                                            padding: 3,
                                            marginRight: 30,
                                          }}
                                        >
                                          {[
                                            "Select",
                                            "text",
                                            "date",
                                            "amount($)",
                                            "number",
                                            "regex",
                                            "alphanumeric",
                                          ].map((item) => (
                                            <option key={item} value={item}>
                                              {item}
                                            </option>
                                          ))}
                                        </select>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {clFields.prevValueType === "regex" && (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      h="40px"
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        w="215px"
                                      >
                                        <Text
                                          marginLeft="10px"
                                          fontWeight="bold"
                                          marginBottom="0px"
                                        >
                                          Regex
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: 20,
                                            }}
                                          >
                                            *
                                          </span>
                                        </Text>
                                      </Box>
                                      <Box>
                                        <Tooltip
                                          title={clFields?.indexRegexPrev}
                                        >
                                          <input
                                            onChange={(e) => {
                                              const values = [...claimFields];
                                              values[index].indexRegexPrev =
                                                e.target.value;
                                              dispatch(saveClaimFields(values));
                                              dispatch(
                                                claimFieldsText({
                                                  name: "indexRegexPrev",
                                                  text: e.target.value,
                                                })
                                              );
                                            }}
                                            value={clFields?.indexRegexPrev}
                                            type="text"
                                            style={{
                                              width: 100,

                                              marginRight: 20,
                                              fontStyle: "italic",
                                              padding: 4,
                                              border: "1px solid gray",
                                              borderRadius: 4,
                                            }}
                                          />
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  )}
                                  {clFields.prevValueType === "date" && (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      h="40px"
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="felx-start"
                                        alignItems="center"
                                        w="215px"
                                      >
                                        <Text
                                          marginLeft="10px"
                                          fontWeight="bold"
                                          marginBottom="0px"
                                        >
                                          Date Format
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: 20,
                                            }}
                                          >
                                            *
                                          </span>
                                        </Text>
                                      </Box>
                                      <Box>
                                        <Tooltip
                                          title={clFields?.prevValueDate}
                                        >
                                          <select
                                            onChange={(e) => {
                                              const values = [...claimFields];
                                              values[index].prevValueDate =
                                                e.target.value;
                                              dispatch(saveClaimFields(values));
                                            }}
                                            value={clFields?.prevValueDate}
                                            style={{
                                              width: 120,
                                              fontWeight: "bold",
                                              padding: 3,
                                            }}
                                          >
                                            {dateOptions.map((item) => (
                                              <option key={item} value={item}>
                                                {item}
                                              </option>
                                            ))}
                                          </select>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    h="40px"
                                  >
                                    <Box
                                      w="215px"
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Text
                                        marginLeft="10px"
                                        font={labelFont}
                                        // textColor={labelColor}
                                        marginBottom="0px"
                                      >
                                        Min Length
                                      </Text>
                                      <Tooltip
                                        interactive
                                        title={
                                          <a
                                            href="https://sway.office.com/pl2E4usHrvWUukNP?play"
                                            target="_blank"
                                            style={{ color: "white" }}
                                          >
                                            The minimum characters of the block
                                            that is configured. (Click for more
                                            information)
                                          </a>
                                        }
                                        placement="right"
                                      >
                                        <img
                                          src={Help}
                                          style={{ height: 12, marginLeft: 6 }}
                                          alt="help"
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box
                                      marginRight="10px"
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <input
                                        onChange={(e) => {
                                          const values = [...claimFields];
                                          values[index].minLength =
                                            e.target.value;
                                          dispatch(saveClaimFields(values));
                                        }}
                                        value={clFields?.minLength}
                                        type="number"
                                        min="1"
                                        defaultValue="1"
                                        style={{
                                          width: 60,
                                          height: 24,
                                          fontStyle: "italic",
                                          padding: 4,
                                          border: "1px solid gray",
                                          borderRadius: 4,
                                          marginRight: 10,
                                        }}
                                      />
                                    </Box>
                                  </Box>

                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    h="40px"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      w="215px"
                                    >
                                      <Text
                                        marginLeft="10px"
                                        font={labelFont}
                                        //textColor={labelColor}
                                        marginBottom="0px"
                                      >
                                        Max Length
                                      </Text>
                                      <Tooltip
                                        interactive
                                        title={
                                          <a
                                            href="https://sway.office.com/pl2E4usHrvWUukNP?play"
                                            target="_blank"
                                            style={{ color: "white" }}
                                          >
                                            The maximum characters of the block
                                            that is configured.(Click for more
                                            information)
                                          </a>
                                        }
                                        placement="right"
                                      >
                                        <img
                                          src={Help}
                                          style={{ height: 12, marginLeft: 6 }}
                                          alt="help"
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box marginRight="10px">
                                      <input
                                        onChange={(e) => {
                                          const values = [...claimFields];
                                          if (e.target.value == "0") return;
                                          else
                                            values[index].maxLength =
                                              e.target.value;
                                          dispatch(saveClaimFields(values));
                                        }}
                                        value={clFields?.maxLength}
                                        type="number"
                                        min="1"
                                        max="256"
                                        defaultValue="256"
                                        style={{
                                          width: 60,
                                          fontStyle: "italic",
                                          padding: 4,
                                          border: "1px solid gray",
                                          borderRadius: 4,
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(Claim);
